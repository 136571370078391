<template>
  <div>
    <section class="main-notice ly-cont">
      <h2 class="hidden">다비치 회사 소식</h2>
      <article class="notice-cont notice-davich scroll-fade-in">
        <h3>다비치 소식</h3>
        <div class="notice-desc-wrap">
          <router-link
            :to="`/whatsnew/davichNewsDetail/${item.id}`"
            class="notice-desc"
            v-for="item in listToShow"
            :key="item.id"
          >
            <h4 class="notice-ttl">
              {{ item.title }}
            </h4>
          </router-link>
        </div>
        <router-link to="/whatsnew/davichNews" class="btn-all">
          <span></span>
          <span></span>
          <p class="hidden">전체보기</p>
        </router-link>
      </article>
      <!-- 다비치 소식 베너 -->
      <article class="notice-cont notice-store scroll-fade-in scroll-delay-1">
        <h3>신규 오픈 매장</h3>
        <router-link to="/partnership/franchise" class="store-slider">
          <div class="store-slider-inner">
            <div class="store-slide" v-for="item of newOpen" :key="item.id">
              <img src="https://img.davich.com/image/main/img-grand-open.png" alt="grand open bg" />
              <div class="store-desc">
                <h4
                  class="store-name"
                  :class="{
                    longStoreName: item.lengthName >= 8,
                    longStoreName2: item.lengthName >= 10,
                  }"
                >
                  {{ item.name }}
                </h4>
                <p>{{ item.openDate }}</p>
              </div>
            </div>
          </div>
        </router-link>
        <router-link to="/partnership/franchise" class="btn-all">
          <span></span>
          <span></span>
          <p class="hidden">전체보기</p>
        </router-link>
      </article>
      <!-- 신규오픈매장 베너 -->
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  beforeCreate() {
    this.$store.dispatch("whatsnew/FETCH_NEWS");
    this.$store.dispatch("partnership/FETCH_OPENED");
  },

  data() {
    return {
      isUpdated: false,
      interval: null,
      timeout: null,
    };
  },

  computed: {
    ...mapGetters("whatsnew", ["getNewsData"]),
    ...mapState("partnership", {
      newOpen: (state) => state.newOpen,
    }),
    ...mapState({
      videoData: (state) => state.videoData,
    }),

    listToShow() {
      const self = this;
      return self.getNewsData.slice(0, 3);
    },
  },

  mounted() {},
  updated() {
    const self = this;
    let slideList = document.querySelector(".store-slider-inner");
    let slideContents = document.querySelectorAll(".store-slide");
    var slideListTop = slideList.offsetTop;
    var winH = window.innerHeight;
    const slideLen = slideContents.length; // slide length
    const slideWidth = 680; // slide width
    const slideSpeed = 3000; // slide speed
    const startNum = 0; // initial slide index (0 ~ 4)

    // Copy first and last slide
    let firstChild = slideList.firstElementChild;
    let clonedFirst = firstChild.cloneNode(true);
    slideList.appendChild(clonedFirst);

    slideList.style.transform = "translate3d(0px, 0px, 0px)";
    let curIndex = startNum; // current slide index (except copied slide)
    var curSlide = slideContents[curIndex]; // current slide dom
    if (window.scrollY >= slideListTop - winH) {
      if (self.isUpdated == false) {
        self.interval = setInterval(function () {
          if (curIndex <= slideLen - 1) {
            slideList.style.transition = 1000 + "ms";
            slideList.style.transform = "translate3d(-" + slideWidth * (curIndex + 1) + "px, 0px, 0px)";
          }
          if (curIndex === slideLen - 1) {
            self.timeout = setTimeout(function () {
              slideList.style.transition = "0ms";
              slideList.style.transform = "translate3d(0px, 0px, 0px)";
            }, slideSpeed);
            curIndex = -1;
          }
          curSlide = slideContents[++curIndex];
        }, 4000);
        self.isUpdated = true;
      }
    }
  },

  destroyed() {
    const self = this;
    clearInterval(self.interval);
    clearTimeout(self.timeout);
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.main-notice {
  display: flex;
  justify-content: space-between;
  padding: 80px 0;
  .notice-cont {
    width: 680px;
    position: relative;
    h3 {
      font-size: 35px;
      line-height: 70px;
      font-weight: 900;
      margin-bottom: 20px;
    }
    .btn-all {
      position: absolute;
      top: 10px;
      right: 0;
    }
  } // 다비치 소식, 신규오픈매장 공통 레이아웃
  .notice-davich {
    .notice-desc-wrap {
      display: flex;
      height: 239px;
      flex-direction: column;

      .notice-desc {
        flex: 1;
        width: 100%;
        display: flex;
        align-items: center;
        border-top: 1px solid $color-gray;
        &:last-child {
          border-bottom: 1px solid $color-gray;
        }
        .notice-ttl {
          @include font-main;
        }
      }
    }
  }
  .notice-store {
    .store-slider {
      height: 239px;
      overflow: hidden;
      width: 100%;
      position: relative;
      .store-slider-inner {
        display: flex;
        flex-wrap: nowrap;
        .store-slide {
          width: 100%;
          height: 100%;
          position: relative;
          flex-shrink: 0;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .store-desc {
            color: $color-white;
            position: absolute;
            top: 75px;
            left: 404px;
            width: 100%;
            font-weight: 700;
            h4 {
              width: calc(680px - 404px);
              font-size: 40px;
              margin-bottom: 10px;
              line-height: 70px;
              &.longStoreName {
                font-size: 32px;
              }
              &.longStoreName2 {
                font-size: 28px;
                line-height: 40px;
              }
            }
            p {
              width: calc(680px - 404px);
              font-size: 22px;
            }
          }
        }
      }
    }
  }
}

// 화면전환 시 효과
.do-transition {
  animation: slide 0.1s linear forwards;
}

@keyframes slide {
  from {
    margin-left: 100%;
  }

  to {
    margin-left: 0%;
  }
}

.do-transition-reverse {
  animation: slide-reverse 0.1s linear forwards;
}

@keyframes slide-reverse {
  from {
    margin-left: 100%;
  }

  to {
    margin-left: 0%;
  }
}
</style>
