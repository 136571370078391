import { mutations } from "./mutations";
import { actions } from "./actions";
import { getters } from "./getters";

const state = {
  boardData: [],
  picVideoData: [],
  successStory: [],
  departmentData: [],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
