<template>
  <div class="ly-w1400">
    <sub-title-1 :title="title" :content="content" :content2="content2">
    </sub-title-1>
    <section>
      <tab-menu :tabTitle="tabTitle"></tab-menu>
      <div class="tab-items">
        <!-- 연구원 소개 페이지 -->
        <div class="tab-item display tab_a">
          <div class="ly-cont">
            <h2 class="scroll-fade-in-f">교육연구원</h2>
            <h3 class="scroll-fade-in-f">
              다비치에서는 여러분이 꿈꾸는 모든 꿈을 이룰 수 있습니다. <br />
              다비치 고객가치경영연구원에서 여러분의 꿈을 이루세요.
            </h3>
            <img
              class="scroll-fade-in-f"
              src="https://img.davich.com/image/sub/education/education_intro_01.png"
              alt="education_intro_01"
            />
            <p class="scroll-fade-in-f">
              안경업계 최초 서울지방노동청에서 자체 훈련기관으로 승인 받은
              안경사 전문 교육기관입니다.<br />
              ‘안경사 교육이 미래다’ 라는 신념을 바탕으로 신규입사(OJT)교육 및
              전문가교육과정, 안경원 점주 경영자 회의과정, 안경 사관학교를
              운영하고 있습니다.
            </p>
            <h4 class="scroll-fade-in-f">교육연구원의 다짐</h4>
            <span class="scroll-fade-in-f">
              눈 건강 시력지킴이 다비치안경사는 안경만 판매하는 사람이 아닌
              국민의 눈건강을 책임지는 전문가 입니다. <br />
              다비치 교육원은 안경전문 교육 기관으로 다비치 안경만의 차별화된
              교육시설과 특성화된 교육 운영으로 실무능력 향상 및 개인 역량강화로
              고객에게 가장 적합한 렌즈와 안경을 제공합니다. <br />
              교육연구원 아카데미는 실습 중심의 교육으로 실무능력 겸비하고, 수준
              높은 교육 서비스 제공하여 안경 산업현장에서 꼭 필요한 기술 인재를
              양성하여 안경전문가를 배출합니다.
            </span>
            <h2 class="right scroll-fade-in-f">설립배경</h2>
            <h3 class="right scroll-fade-in-f">
              대한민국 문화재청에서 지정한 근대 문화유산 <br />
              ‘다비치 고객가치경영연구원 건물’
            </h3>
            <img
              class="scroll-fade-in-f"
              src="https://img.davich.com/image/sub/education/education_intro_02.png"
              alt="education_intro_02"
            />
            <p class="right scroll-fade-in-f">
              다비치 고객가치경영연구원은 2005년 12월 29일 근대 문화유적시설
              건물에서 안경사 개개인의 전문성을 향상 시키고 <br />
              직무 능력 향상 훈련, 관리자 교육 등 시뮬레이션을 포함한 다양한
              전문 교육을 위하여 설립되었습니다.
            </p>
            <p class="right scroll-fade-in-f">
              안경 전문 교육 기관으로서 차별화된 실무중심 교육 시설과 안경전문
              특성화 교육 과정 운영으로 고용노동부 훈련기관으로 지정되었습니다.
              <br />
              다비치 고객가치경영연구원은 이에 만족하지 않고 더욱 발전된
              교육기관이 되고자 불철주야 정진하고 있습니다. <br />
              다비치 고객가치경영연구원의 교육은 전문 이론과 실무능력을 강화하기
              위하여 안경광학과를 졸업한 학생들에게 생동감 있는 교육을 통해
              <br />
              안경 산업 현장에 꼭 필요한 우수 전문 인력을 양성∙배출하고
              있습니다. <br />
              재직 안경사에 대한 수준별 맞춤 교육을 통하여 다비치 전문화에
              이바지하고 있습니다. <br />
              다비치 고객가치경영연구원은 국제적 경쟁력을 갖춘 안경 훈련기관으로
              성장하여 대한민국 안경 산업 발전에 기여하고자 합니다.
            </p>
          </div>
        </div>
        <!-- 조직 소개 페이지 -->
        <div class="tab-item tab_b">
          <div class="team_main">
            <!-- 연구개발확산본부 -->
            <div class="ly-cont scroll-active" v-bind="getDepartment[0]">
              <div class="main_icon scroll-fade-in-f"></div>
              <h2 class="scroll-fade-in-f">{{ getDepartment[0].name }}</h2>
              <div class="main_box">
                <div
                  class="form scroll-fade-in-f"
                  v-for="employee in getDepartment[0].employee"
                  :key="employee.id"
                >
                  <img
                    v-if="employee.attachment"
                    :src="employee.attachment.download_url"
                    alt="team_img"
                  />
                  <img
                    v-else
                    src="https://img.davich.com/image/sub/education/education_team_ready.png"
                    alt="team_img"
                  />
                  <p class="name">{{ employee.name }}</p>
                  <p class="rank">{{ employee.title }}</p>
                  <p class="career" v-html="employee.job_history"></p>
                </div>
              </div>
            </div>
          </div>
          <!-- 연구개발확산본부 end-->
          <div class="team_sub ly-cont scroll-active">
            <!-- 연구개발확산본부 - 하위부서 -->
            <section
              class="depart-section"
              v-for="department in listToShow"
              :key="department.id"
            >
              <div class="sub_icon scroll-fade-in-f"></div>
              <h3>{{ department.name }}</h3>
              <!-- 부서명 /// 안경렌즈 연구 ~ -->
              <div class="container">
                <div
                  class="sub_box item"
                  v-for="employee in department.employee"
                  :key="employee.id"
                >
                  <div class="form scroll-fade-in-f">
                    <img
                      v-if="employee.attachment"
                      :src="employee.attachment.download_url"
                      alt="team_img"
                    />
                    <img
                      v-else
                      src="https://img.davich.com/image/sub/education/education_team_ready.png"
                      alt="team_img"
                    />
                    <p class="name">{{ employee.name }}</p>
                    <p class="rank">{{ employee.title }}</p>
                    <p class="career" v-html="employee.job_history"></p>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <!-- 연구개발확산본부 - 하위부서 end -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SubTitle1 from "../../components/SubTitle1.vue";
import TabMenu from "../../components/TabMenu.vue";

import { mapGetters } from "vuex";

export default {
  beforeCreate() {
    this.$store.dispatch("education/FETCH_DEPARTMENT");
  },

  components: {
    SubTitle1,
    TabMenu,
  },

  computed: {
    ...mapGetters("education", ["getDepartment"]),

    listToShow() {
      const self = this;
      return self.getDepartment.slice(1);
    },
  },

  data() {
    return {
      //sub title
      title: "고객가치경영연구원",
      content: "안경업계 최초로 자체 훈련기관으로",
      content2:
        "서울지방노동청의 승인을 받은 전문 교육기관에서 여러분의 꿈을 이루세요.",
      //tabmenu
      tabTitle: ["연구원 소개", "조직 소개"],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.sub-title {
  background-image: url("https://img.davich.com/image/sub/subtitle/sub-edu-bg-01.png");
}

/*연구원 소개 SCSS*/
.tab_a {
  @include font-main;
  margin-bottom: 200px;
  h2 {
    color: $color-blue;
    margin-top: 100px;
    @include font-h-20;
  }
  h3 {
    margin-top: 15px;
    margin-bottom: 70px;
    @include font-h-35;
  }
  h4 {
    margin-top: 70px;
    @include font-h-25;
  }
  p {
    margin-top: 70px;
  }
  span {
    display: block;
    padding-top: 17px;
    padding-bottom: 50px;
  }
  .right {
    text-align: right;
  }
}

/*조직 소개 SCSS*/
.tab_b {
  // 연구개발확산본부
  .team_main {
    width: 100%;
    height: auto;
    background: #f0f8ff;
    overflow: hidden;
    .main_icon {
      margin-top: 100px;
      width: 30px;
      height: 30px;
      background: url("https://img.davich.com/image/sub/education/education_team_main_icon.png")
        no-repeat;
    }
    h2 {
      @include font-h-35;
    }
    .main_box {
      width: 100%;
      height: auto;
      margin-top: 100px;
      margin-bottom: 150px;
      overflow: hidden;
    }
  }

  // 연구개발확산본부 - 하위 부서

  .container {
    display: flex;
    flex-wrap: wrap;
    .item {
      flex: 1 1 40%;
    }
  }

  .depart-section {
    border-bottom: 1px solid $color-gray;
    &:last-child {
      border-bottom: none;
    }
  }

  .team_sub {
    // width: 100%;
    height: auto;
    overflow: hidden;
    .sub_icon {
      margin-top: 100px;
      width: 26px;
      height: 14px;
      background: url("https://img.davich.com/image/sub/education/education_team_sub_icon.png")
        no-repeat;
    }
    h3 {
      @include font-h-25;
      color: $color-darkblue;
    }
    .sub_box {
      width: 100%;
      height: auto;
      margin-top: 50px;
      padding-bottom: 50px;
      overflow: hidden;
      &:last-child {
        border: none;
        padding-bottom: 150px;
      }
    }
  }

  // 조직원 form
  .form {
    width: 700px;
    height: 300px;
    float: left;
    display: block;
    margin-bottom: 50px;
    img {
      float: left;
      display: block;
      width: 250px;
      height: 300px;
      object-fit: cover;
      margin-right: 30px;
    }
    .name {
      float: left;
      display: block;
      @include font-h-25;
      line-height: 45px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
    .rank {
      float: left;
      display: block;
      @include font-main;
      font-weight: bold;
      line-height: 45px;
    }
    .career {
      float: left;
      display: block;
      width: 390px;
      height: 244px;
      font-size: 17px;
      line-height: 35px;
      overflow: hidden;
    }
  }
}
</style>
