<template>
  <div>
    <sub-title
      :title="title"
      :content="content"
      :content2="content2"
      :content3="content3"
    >
    </sub-title>
    <section>
      <tab-menu :tabTitle="tabTitle"></tab-menu>
      <div class="tab-items">
        <!-- 연구원 소개 페이지 -->
        <div class="tab-item display tab_a">
          <div>
            <h2 class="ly-cont-m scroll-fade-in-f">교육연구원</h2>
            <h3 class="ly-cont-m scroll-fade-in-f">
              다비치에서는 여러분이 꿈꾸는 모든 꿈을 이룰 수 있습니다. <br />
              다비치 고객가치경영연구원에서 여러분의 꿈을 이루세요.
            </h3>
            <div class="img-wrap">
              <img
                class="scroll-fade-in-f"
                src="https://img.davich.com/mobile/image/sub/education/education_intro_01.png"
                alt="education_intro_01"
              />
            </div>
            <p class="ly-cont-m scroll-fade-in-f">
              안경업계 최초 서울지방노동청에서 자체 훈련기관으로 승인 받은
              안경사 전문 교육기관입니다.<br />
              ‘안경사 교육이 미래다’ 라는 신념을 바탕으로 신규입사(OJT)교육 및
              전문가교육과정, 안경원 점주 경영자 회의과정, 안경 사관학교를
              운영하고 있습니다.
            </p>
            <h4 class="ly-cont-m scroll-fade-in-f">교육연구원의 다짐</h4>
            <span class="ly-cont-m scroll-fade-in-f">
              눈 건강 시력지킴이 다비치안경사는 안경만 판매하는 사람이 아닌
              국민의 눈건강을 책임지는 전문가 입니다. <br />
              다비치 교육원은 안경전문 교육 기관으로 다비치 안경만의 차별화된
              교육시설과 특성화된 교육 운영으로 실무능력 향상 및 개인 역량강화로
              고객에게 가장 적합한 렌즈와 안경을 제공합니다. <br />
              교육연구원 아카데미는 실습 중심의 교육으로 실무능력 겸비하고, 수준
              높은 교육 서비스 제공하여 안경 산업현장에서 꼭 필요한 기술 인재를
              양성하여 안경전문가를 배출합니다.
            </span>
            <!-- 교육 연구원 -->
            <h2 class="ly-cont-m scroll-fade-in-f">설립배경</h2>
            <h3 class="ly-cont-m scroll-fade-in-f">
              대한민국 문화재청에서 지정한<br />
              근대 문화유산 <br />
              ‘다비치 고객가치경영연구원 건물’
            </h3>
            <div class="img-wrap">
              <img
                class="scroll-fade-in-f"
                src="https://img.davich.com/mobile/image/sub/education/education_intro_02.png"
                alt="education_intro_02"
              />
            </div>
            <p class="ly-cont-m scroll-fade-in-f">
              다비치 고객가치경영연구원은 2005년 12월 29일 근대 문화유적시설
              건물에서 안경사 개개인의 전문성을 향상 시키고 직무 능력 향상 훈련,
              관리자 교육 등 시뮬레이션을 포함한 다양한 전문 교육을 위하여
              설립되었습니다.
            </p>
            <p class="ly-cont-m scroll-fade-in-f">
              안경 전문 교육 기관으로서 차별화된 실무중심 교육 시설과 안경전문
              특성화 교육 과정 운영으로 고용노동부 훈련기관으로 지정되었습니다.
              <br />
              다비치 고객가치경영연구원은 이에 만족하지 않고 더욱 발전된
              교육기관이 되고자 불철주야 정진하고 있습니다. <br />
              다비치 고객가치경영연구원의 교육은 전문 이론과 실무능력을 강화하기
              위하여 안경광학과를 졸업한 학생들에게 생동감 있는 교육을 통해 안경
              산업 현장에 꼭 필요한 우수 전문 인력을 양성∙배출하고 있습니다.
              <br />
              재직 안경사에 대한 수준별 맞춤 교육을 통하여 다비치 전문화에
              이바지하고 있습니다. <br />
              다비치 고객가치경영연구원은 국제적 경쟁력을 갖춘 안경 훈련기관으로
              성장하여 대한민국 안경 산업 발전에 기여하고자 합니다.
            </p>
          </div>
        </div>
        <!-- 설립배경 -->
        <!-- 조직 소개 페이지 -->
        <div class="tab-item tab_b">
          <div class="team_main">
            <!-- 연구개발확산본부 -->
            <div class="ly-cont-m">
              <div class="team_main_ttl" v-bind="getDepartment[0]">
                <h2 class="scroll-fade-in-f">{{ getDepartment[0].name }}</h2>
              </div>
              <div class="main_box">
                <div
                  class="form scroll-fade-in-f"
                  v-for="employee in getDepartment[0].employee"
                  :key="employee.id"
                >
                  <div class="form_main">
                    <div class="img_wrap">
                      <img
                        v-if="employee.attachment"
                        :src="employee.attachment.download_url"
                        alt="team_img"
                      />
                      <img
                        v-else
                        src="https://img.davich.com/image/sub/education/education_team_ready.png"
                        alt="team_img"
                      />
                    </div>
                    <span class="form_ttl">
                      <p class="name">{{ employee.name }}</p>
                      <p class="rank">{{ employee.title }}</p>
                    </span>
                  </div>
                  <p class="career" v-html="employee.job_history"></p>
                </div>
              </div>
            </div>
          </div>
          <!-- 연구개발확산본부 end-->

          <div class="team_sub ly-cont-m">
            <!-- 연구개발확산본부 - 하위부서 -->
            <section v-for="department in listToShow" :key="department.id">
              <div class="team_sub_ttl">
                <h3 class="scroll-fade-in-f">{{ department.name }}</h3>
              </div>
              <!-- 부서명 /// 안경렌즈 연구 -->
              <div class="sub_box">
                <div
                  class="form scroll-fade-in-f"
                  v-for="employee in department.employee"
                  :key="employee.id"
                >
                  <div class="form_main">
                    <div class="img_wrap">
                      <img
                        v-if="employee.attachment"
                        :src="employee.attachment.download_url"
                        alt="team_img"
                      />
                      <img
                        v-else
                        src="https://img.davich.com/image/sub/education/education_team_ready.png"
                        alt="team_img"
                      />
                    </div>
                    <span class="form_ttl">
                      <p class="name">{{ employee.name }}</p>
                      <p class="rank">{{ employee.title }}</p>
                    </span>
                  </div>
                  <p class="career" v-html="employee.job_history"></p>
                </div>
              </div>
            </section>
          </div>
          <!-- 연구개발확산본부 - 하위부서 end -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SubTitle from "../../../components/mobile/MSubTitle1.vue";
import TabMenu from "../../../components/mobile/MTabMenu.vue";

import { mapGetters } from "vuex";

export default {
  beforeCreate() {
    this.$store.dispatch("education/FETCH_DEPARTMENT");
  },

  components: {
    SubTitle,
    TabMenu,
  },

  computed: {
    ...mapGetters("education", ["getDepartment"]),

    listToShow() {
      const self = this;
      return self.getDepartment.slice(1);
    },
  },

  data() {
    return {
      //sub title
      title: "고객가치경영연구원",
      content: "안경업계 최초로 자체 훈련기관으로",
      content2: "서울지방노동청의 승인을 받은",
      content3: "전문 교육기관에서 여러분의 꿈을 이루세요.",
      //tabmenu
      tabTitle: ["연구원 소개", "조직 소개"],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.sub-title {
  background-image: url("https://img.davich.com/mobile/image/sub/subtitle/sub-edu-bg-01.png");
  background-size: cover;
}

// 연구원 소개 SCSS
.tab_a {
  @include font-main-m;
  margin: 70px 0 100px 0;
  h2 {
    color: $color-blue;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: -0.45px;
  }
  h3 {
    margin-bottom: 40px;
    @include font-h-22;
  }
  .img-wrap {
    height: 295px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  h4 {
    margin-top: 70px;
    font-weight: bold;
  }
  p {
    margin-top: 40px;
  }
  span {
    display: block;
    padding-top: 17px;
    padding-bottom: 50px;
  }
}

// 조직 소개 SCSS
.tab_b {
  // 연구개발확산본부
  .team_main {
    padding: 100px 0;
    width: 100%;
    background: #f0f8ff;
    .main_icon {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .team_main_ttl {
      text-align: center;
      h2 {
        @include font-h-30;
        display: inline-block;
        position: relative;
        &:before {
          position: absolute;
          content: "";
          top: -17px;
          left: 3px;
          width: 16.8px;
          height: 16.9px;
          background: url("https://img.davich.com/mobile/image/sub/education/education_team_main_icon.png");
          background-size: cover;
        }
      }
    }
    .main_box {
      margin-top: 60px;
    }
  }
  // 연구개발확산본부 - 하위 부서
  .team_sub {
    section {
      border-bottom: 1px solid $color-gray;
      &:last-child {
        border: none;
        padding-bottom: 150px;
      }
      .team_sub_ttl {
        margin-top: 114px;
        text-align: center;
        h3 {
          @include font-h-25;
          color: $color-darkblue;
          display: inline-block;
          margin-bottom: 60px;
          position: relative;
          &:before {
            position: absolute;
            content: "";
            top: -10px;
            left: 3px;
            width: 25.68px;
            height: 13.6px;
            background: url("https://img.davich.com/mobile/image/sub/education/education_team_sub_icon.png");
            background-size: cover;
          }
        }
      }
      .sub_box {
        width: 100%;
        height: auto;
        overflow: hidden;
      }
    }
  }
  // 조직원 form
  .form {
    margin-bottom: 80px;
    .form_main {
      display: flex;
      align-items: flex-end;
      margin-bottom: 30px;
      .img_wrap {
        width: 140px;
        height: 168px;
        margin-right: 30px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .form_ttl {
        .name {
          @include font-h-22;
          margin-bottom: 5px;
        }
        .rank {
          font-weight: bold;
        }
      }
    }
    .career {
      @include font-main-m;
    }
  }
}
</style>
