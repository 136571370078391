import { mutations } from "./mutations";
import { actions } from "./actions";
import { getters } from "./getters";

const state = {
  openArea: [],
  newOpen: [],
  opened: [],
  manager: [],
  partnershipType: [],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
