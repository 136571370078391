export const getters = {
  getHistory: function (state) {
    return state.history;
  },

  getBoardData: function (state) {
    return state.boardData;
  },
  
  getDavichNumbers: function(state) {
    return state.davichNumbers;
  },
};
