import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions";
import mutations from "./mutations";

import about from "@/store/modules/about";
import education from "@/store/modules/education";
import others from "@/store/modules/others";
import partnership from "@/store/modules/partnership";
import products from "@/store/modules/products";
import support from "@/store/modules/support";
import whatsnew from "@/store/modules/whatsnew";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    about,
    education,
    others,
    partnership,
    products,
    support,
    whatsnew,
  },
  state: {
    popupData: {},
    videoData: [],
    instaItem: {},
    board1: {},
    faqData: [],
    mainCountItem: {},
    uploadPercent: 0,
    isUploading: false,
  },
  mutations: mutations,
  actions: actions,
  plugins: [
    createPersistedState({
      paths: ["about.history", "products", "education", "whatsnew", "others", "support.storeData", "partnership"],
    }),
  ],
});
