<template>
  <div>
    <section class="main-brand to-index">
      <h2 class="hidden">DAVICH BRAND</h2>
      <div class="brand-items scroll-fade-in">
        <router-link to="/products/m_glasses" class="brand-item brand-gl">
          <span class="el-btn el-btn-m el-btn-b el-btn-179">안경테 	&#38; 선글라스</span>
          <div class="brand-img -img"></div>
        </router-link>
        <router-link
          to="/products/m_glassLens"
          class="brand-item scroll-fade-in scroll-delay-1 brand-gl-lens"
        >
          <span class="el-btn el-btn-m el-btn-b el-btn-179">안경렌즈</span>
          <div class="brand-img -img"></div>
        </router-link>
        <router-link
          to="/products/m_contactLens"
          class="brand-item scroll-fade-in scroll-delay-2 brand-lens"
        >
          <span href="#" class="el-btn el-btn-m el-btn-b el-btn-179"
            >콘택트렌즈</span
          >
          <div class="brand-img"></div>
        </router-link>
        <router-link
          to="/products/m_hearingAid"
          class="brand-item scroll-fade-in scroll-delay-3 brand-hearing"
        >
          <span href="#" class="el-btn el-btn-m el-btn-b el-btn-179"
            >보청기</span
          >
          <div class="brand-img"></div>
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.main-brand {
  height: auto;
  .brand-items {
    display: flex;
    flex-direction: column;
    height: auto;
    .brand-item {
      width: 100%;
      height: max(250px, 53vw);
      position: relative;

      &.brand-gl {
        background: center / cover no-repeat
          url("https://img.davich.com/mobile/image/main/img-product-gl.png");
      }
      &.brand-gl-lens {
        background: center / cover no-repeat
          url("https://img.davich.com/mobile/image/main/img-product-lens.png");
      }
      &.brand-lens {
        background: center / cover no-repeat
          url("https://img.davich.com/mobile/image/main/img-product-contact.png");
      }
      &.brand-hearing {
        background: center / cover no-repeat
          url("https://img.davich.com/mobile/image/main/img-product-bo.png");
      }

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        font-size: 16px;
      }
    }
  }
}
</style>
