<template>
  <div>
    <sub-title :title="title" :content="content" :content2="content2">
    </sub-title>
    <div class="show-brand-wrap">
      <div v-for="item in getHearingAid" :key="item.id" class="show-brand">
        <show-Product :companyData="item"></show-Product>
      </div>
    </div>
  </div>
</template>

<script>
import SubTitle from "../../../components/mobile/MSubTitle1.vue";
import ShowProduct from "../../../components/mobile/PRODUCTS/MShowProduct.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    SubTitle,
    ShowProduct,
  },
  data() {
    return {
      //sub title
      title: "보청기",
      content: "다비치 브랜드에는 신뢰, 조율, 공감의",
      content2: "핵심가치가 담겨 있습니다.",
      // background text
      text: "BRAND",
    };
  },

  computed: {
    ...mapGetters("products", ["getHearingAid"]),
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.sub-title {
  background-image: url("https://img.davich.com/mobile/image/sub/subtitle/sub-product-ready.png");
  background-size: cover;
}

.show-brand-wrap {
  margin-bottom: 80px;
}
</style>
