export const getters = {
  getGlassesFrame: function (state) {
    return state.glassesFrame;
  },
  getGlassesLens: function (state) {
    return state.glassesLens;
  },
  getContactLens: function (state) {
    return state.contantLens;
  },
  getHearingAid: function (state) {
    return state.hearingAid;
  },
};
