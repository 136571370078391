<template>
  <div class="ly-w1400">
    <section class="main-fullbanner">
      <h2 class="hidden">다비치의 활동</h2>
      <article class="fullbanner-cont to-index">
        <img
          src="https://img.davich.com/image/main/img-main-fullbanner-01.png"
          alt="fullbanner bg"
        />
        <div class="fullbanner-desc ly-cont">
          <h3 class="main-ttl-v1">
            <div class="scroll-fade-in-f">눈건강</div>
            <div class="scroll-fade-in-f scroll-delay-1">시력 지킴이</div>
          </h3>
          <p class="scroll-fade-in-f scroll-delay-2">
            눈건강 시력 지킴이는 소외된 어려운 이웃까지 맑고 밝게 비춰주는<br />
            봉사단으로 전국 사회봉사시설을 방문해 시력검사 및 돋보기, 교정용
            안경을 무료로 제공합니다.<br />
            다비치는 모두가 함께하는 더 나은 사회를 만들기 위해 기여하겠습니다.
          </p>
          <router-link to="/about/contribution" class="el-btn"
            >자세히 보기</router-link
          >
        </div>
      </article>
      <article class="fullbanner-cont banner-customer to-index">
        <img
          src="https://img.davich.com/image/main/img-main-fullbanner-02.png"
          alt="fullbanner bg"
        />
        <div class="fullbanner-desc ly-cont">
          <h3 class="main-ttl-v1">
            <div class="scroll-fade-in-f">고객가치</div>
            <div class="scroll-fade-in-f scroll-delay-1">경영연구원</div>
          </h3>
          <p class="scroll-fade-in-f scroll-delay-2">
            안경업계 최초 서울지방노동청에서 자체<br />
            훈련기관으로 승인 받은 안경사 전문 교육기관입니다. <br />
            다비치 고객가치경영연구원에서 여러분의 꿈을 이루세요.
          </p>
          <router-link to="/education/researchCenter" class="el-btn"
            >자세히 보기</router-link
          >
        </div>
      </article>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.main-fullbanner {
  overflow: hidden;
  .fullbanner-cont {
    position: relative;
    width: 100%;
    height: calc(100vh - 80px);
    overflow: hidden;
    transition: 0.6s;
    &:hover {
      img {
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -o-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
      }
      .fullbanner-desc {
        a {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.6s;
    }
    .fullbanner-desc {
      @include box-center;
      h3 {
        margin-bottom: 20px;
      }
      p {
        @include font-main;
        margin-bottom: 90px;
      }
      a {
        opacity: 0;
        visibility: hidden;
        transition: 0.3s;
      }
    }
    &.banner-customer {
      .fullbanner-desc {
        text-align: right;
      }
    }
  }
}
</style>
