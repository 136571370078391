<template>
  <div class="main-wrapper">
    <sub-title :title="title" :content="content" :content2="content2" :content3="content3"> </sub-title>
    <section>
      <tab-menu :tabTitle="tabTitle"></tab-menu>
      <div class="tab-items">
        <!--사관∙공채란?-->
        <div class="tab-item tab_a display">
          <div>
            <div class="videobox scroll-fade-in-f">
              <video
                muted
                playsinline
                autoplay
                poster="https://img.davich.com/mobile/image/sub/education/poster_education_officer_01.png"
                src="https://img.davich.com/video/education_officer_video01.mp4"
                type="video/mp4"
                class="autoplay-video"
              ></video>
            </div>
          </div>
          <div class="intro">
            <div class="intro_box">
              <h2 class="title01 ly-cont-m scroll-fade-in-f">사관∙공채란?</h2>
              <p class="scroll-fade-in-f ly-cont-m">
                <span>매장의 중간관리자</span>로서 그와 맞는 <span>다비치다움</span>을 교육받아, 전략정책을 수행하고
                실무능력을 향상 시켜 고객 행복 실천을 위해 매장에 변화와 혁신을 전도하는 인재
              </p>
              <div class="txt scroll-fade-in-f ly-cont-m">
                <h3>17년간 우수 인재 발굴 지속하여<br />현재까지 양성한 졸업생</h3>
                <div class="txt-num">
                  <div class="line01">
                    <span>사관 졸업생</span>
                    <h4>1,012</h4>
                  </div>
                  <div class="line02">
                    <span>공채 졸업생</span>
                    <h4>612</h4>
                  </div>
                </div>
              </div>
              <div class="intro_bg">
                <div class="intro_img">
                  <img
                    src="https://img.davich.com/mobile/image/sub/education/education_officer_01.png"
                    alt="intro_img"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- 사관∙공채란? -->
          <!--./intro-->
          <div class="ability">
            <!-- <div class="shadow"></div> -->
            <h2>사관∙공채 선발 기준</h2>
            <div class="txt ly-cont-m">
              <div class="txt_left scroll-fade-in-f">
                <div class="line01 txt_1 scroll-fade-in-f">
                  <p>모집</p>
                  <h3>매년 12월 모집 접수</h3>
                </div>
                <span class="txt_2 scroll-fade-in-f">
                  - 전국 안경광학과 졸업생
                </span>
                <article class="txt_3 scroll-fade-in-f">
                  <h4>준비사항</h4>
                  <span>
                    - 지원서 (필수)
                  </span>
                  <span>
                    - 이력서 (선택)
                  </span>
                </article>
              </div>

              <div class="txt_right scroll-fade-in-f">
                <div class="line02 txt_1 scroll-fade-in-f">
                  <p>면접</p>
                  <h3>1월 초 면접진행</h3>
                </div>
                <span class="txt_2 scroll-fade-in-f">
                  - 지원자 중 선발 과정<br />
                  - 대표이사를 포함한 약 6명의 심사단
                </span>
                <article class="txt_3 scroll-fade-in-f">
                  <h4>준비사항</h4>
                  <span>
                    - 구두 면접<br />
                    - 한국식 실무 예비검사 실기평가
                  </span>
                </article>
              </div>
            </div>
          </div>
          <!--./ability-->
          <div class="slider-wrap">
            <h5 class="slider_title scroll-fade-in-f">다비치에서는 이러한 <br />역량을 가진 인재를 발굴합니다.</h5>
            <div class="ab_slider">
              <ul class="slider-inner">
                <li class="scroll-fade-in-f">
                  <img
                    src="https://img.davich.com/mobile/image/sub/education/icon_ability01.png"
                    alt="icon_ability01"
                  />
                  <p>셀프리더쉽</p>
                </li>
                <li class="scroll-fade-in-f">
                  <img
                    src="https://img.davich.com/mobile/image/sub/education/icon_ability02.png"
                    alt="icon_ability02"
                  />
                  <p>변화관리 능력</p>
                </li>
                <li class="scroll-fade-in-f">
                  <img
                    src="https://img.davich.com/mobile/image/sub/education/icon_ability03.png"
                    alt="icon_ability03"
                  />
                  <p>업무 추진력</p>
                </li>
                <li class="scroll-fade-in-f">
                  <img
                    src="https://img.davich.com/mobile/image/sub/education/icon_ability04.png"
                    alt="icon_ability04"
                  />
                  <p>갈등관리 능력</p>
                </li>
                <li class="scroll-fade-in-f">
                  <img
                    src="https://img.davich.com/mobile/image/sub/education/icon_ability05.png"
                    alt="icon_ability05"
                  />
                  <p>고객중심 마인드</p>
                </li>
                <li class="scroll-fade-in-f">
                  <img
                    src="https://img.davich.com/mobile/image/sub/education/icon_ability06.png"
                    alt="icon_ability06"
                  />
                  <p>문제해결 능력</p>
                </li>
                <li class="scroll-fade-in-f">
                  <img
                    src="https://img.davich.com/mobile/image/sub/education/icon_ability07.png"
                    alt="icon_ability07"
                  />
                  <p>자기관리 능력</p>
                </li>
              </ul>
            </div>
          </div>
          <!-- 사관∙공채 선발 기준 -->
          <div class="roadmap ly-cont-m">
            <h2 class="scroll-fade-in-f">인재 양성 로드맵(전문성)</h2>
            <p class="scroll-fade-in-f">
              다비치의 인재 양성 프로그램은 단 기간에 여러분을 최고의 전문가로 만들어 드립니다.
            </p>
            <img
              class="roadmap-img scroll-fade-in-f"
              src="https://img.davich.com/mobile/image/sub/education/roadmap_01.png"
              alt="roadmap"
            />

            <div class="roadmap-items">
              <div class="roadmap-item">
                <h3 class="roadmap-ttl ttl-1">원장/점주</h3>
                <div class="roadmap-txt">
                  <em class="badge"> #경영자양성 </em>
                  <p>
                    <span>- 경영지표 관리를 위한 초청강연 </span>
                    <span>- 상권 분석 및 빅데이터 관리</span>
                  </p>
                </div>
              </div>
              <div class="roadmap-item">
                <h3 class="roadmap-ttl ttl-2">
                  COO 팀장<br />
                  콘택트렌즈<br />
                  매니져
                </h3>
                <div class="roadmap-txt">
                  <em class="badge"> #사례 토론 </em>
                  <p>
                    <span>- 사관 공채 워크숍 </span>
                    <span>- 임상 사례 공유·토론·시스템 개선</span>
                  </p>
                </div>
              </div>
              <div class="roadmap-item">
                <h3 class="roadmap-ttl ttl-3">
                  다비치<br />
                  사관·공채
                </h3>
                <div class="roadmap-txt">
                  <em class="badge"> #실무 중심 </em>
                  <p>
                    <span>- 사관 공채 교육 과정 </span>
                    <span>- 수준별 맞춤식 실무 교육</span>
                  </p>
                </div>
              </div>
              <div class="roadmap-item">
                <h3 class="roadmap-ttl ttl-4">예비 안경사</h3>
                <div class="roadmap-txt">
                  <em class="badge"> #산학 협동 </em>
                  <p>
                    <span>- 산학협력 밀착형 맞춤식 교육 </span>
                    <span>- 방학 현장 교육·사수 인턴제도</span>
                  </p>
                </div>
              </div>
            </div>
            <a href="#" @click="goToCurriculum()" class="el-btn el-btn-w100 scroll-fade-in-f"
              >특전 및 교육과정 바로가기</a
            >
          </div>
        </div>
        <!--특전 및 교육과정-->
        <div class="tab-item tab_b">
          <section class="perks">
            <div class="txt">
              <h2 class="scroll-fade-in-f scroll-delay-1">사관∙공채 특전</h2>
              <div class="scroll-fade-in-f scroll-delay-2">
                <p>교육 수료 후<br />전국 다비치안경체인 매장 근무 시 혜택</p>
              </div>
              <ul>
                <li class="line1 scroll-fade-in-f scroll-delay-3">
                  <p>사관<br /><span>최대<br/>4,000만원</span></p>
                </li>
                <li class="line3 scroll-fade-in-f scroll-delay-3">
                  <p>공채<br /><span>2,900만원</span></p>
                </li>
              </ul>
              <p class="scroll-fade-in-f">+ 1년 근무 시 퇴직금 별도</p>
              <span class="box scroll-fade-in-f">
                - 2~3년제 졸업 시 학점은행제 병행 가능(3+1 학사과정)<br />
                - 학사과정 및 4년제 졸업 시 석사과정 병행 가능<br />
                - 4대 보험 가입(근로기준법 적용)<br />
                - 대학 안경광학과 산업체 실무 교육 강의 기회 및 각종 학술 세미나 우선 참여권 부여<br />
                - 교육 기간 중 소정의 훈련수당 지급
              </span>
            </div>
          </section>
          <img src="https://img.davich.com/mobile/image/sub/education/process_bg.png" alt="process_bg" />
          <!-- 사관∙공채 특전 -->
          <section class="table ly-cont-m">
            <h2 class="scroll-fade-in-f scroll-delay-1">하루 일과표</h2>
            <div class="img-wrap">
              <img
                class="scroll-fade-in-f scroll-delay-2"
                src="@/assets/image/sub/education/process_table.png"
                alt="process_table"
              />
            </div>
          </section>
          <!-- 하루 일과표 -->
          <section class="subject">
            <article>
              <div class="subject_a">
                <h2 class="scroll-fade-in-f scroll-delay-1">교육과목</h2>
                <ul class="abox">
                  <li class="scroll-fade-in-f scroll-delay-2">
                    <div>A. 전략정책</div>
                    <p>
                      - 다비치다움<br />
                      - 30가지 전략정책<br />
                      - 다비치 차별화 시스템<br />
                      - 고객감동 CS교육
                    </p>
                  </li>
                  <li class="scroll-fade-in-f scroll-delay-2">
                    <div>B. 전문성</div>
                    <p>
                      - 한국식 실무 예비검사<br />
                      - 다비치 표준화 검사<br />
                      - 기능성/사무용 검사<br />
                      - 누진/난시 피팅센터를 활용한 고객응대교육
                    </p>
                  </li>
                  <li class="scroll-fade-in-f scroll-delay-2">
                    <div>C. 상품지식</div>
                    <p>
                      - 안경테 상품지식(국내, 수입)<br />
                      - 안경렌즈별 상품지식 블루컷, 사무용, 누진, 기능성 콘택트 상품지식(난시안, 멀티포컬 등)
                    </p>
                  </li>
                  <li class="scroll-fade-in-f scroll-delay-2">
                    <div>D. 양안시</div>
                  </li>
                </ul>
              </div>
              <!-- 교육과목 -->
              <div class="subject_b">
                <h2 class="scroll-fade-in-f scroll-delay-1">평가내용</h2>
                <ul class="bbox">
                  <div class="scroll-fade-in-f scroll-delay-2">
                    <li>
                      <img
                        src="https://img.davich.com/mobile/image/sub/education/process_icon_01.png"
                        alt="icon_01.png"
                      />
                    </li>
                    <p>전략정책 평가</p>
                  </div>
                  <div class="scroll-fade-in-f scroll-delay-2">
                    <li>
                      <img
                        src="https://img.davich.com/mobile/image/sub/education/process_icon_02.png"
                        alt="icon_01.png"
                      />
                    </li>
                    <p>고객응대 시뮬레이션</p>
                    <span>현장투입전 고객응대가<br />가능토록<br />시뮬레이션 평가진행</span>
                  </div>
                  <div class="scroll-fade-in-f scroll-delay-2">
                    <li>
                      <img
                        src="https://img.davich.com/mobile/image/sub/education/process_icon_03.png"
                        alt="icon_01.png"
                      />
                    </li>
                    <p>모의상권 분석</p>
                    <span>나의 매장을 오픈한다면? 직접 상권을 분석하여 매장을 오픈해보자</span>
                  </div>
                  <div class="scroll-fade-in-f scroll-delay-2">
                    <li>
                      <img
                        src="https://img.davich.com/mobile/image/sub/education/process_icon_04.png"
                        alt="icon_01.png"
                      />
                    </li>
                    <p>재고교육</p>
                    <span>중간관리자로써 매장의 전반적인 재고관리 능력 평가</span>
                  </div>
                </ul>
              </div>
            </article>
          </section>
          <!-- 평가내용 -->
          <section class="gallery">
            <h2 class="scroll-fade-in-f scroll-delay-1">교육장 소개</h2>
            <div class="tab_a">
              <div class="a_detail scroll-fade-in-f scroll-delay-2">
                <!-- <p class="bigap">본관</p> -->
                <img class="biga" src="https://img.davich.com/image/sub/education/process_img_01.png" alt="본관" />
              </div>

              <p class="bigap">본관</p>

              <div class="a_list scroll-fade-in-f scroll-delay-3">
                <li class="smallboxa active">
                  <img
                    class="smalla"
                    src="https://img.davich.com/image/sub/education/process_img_01.png"
                    alt="본관"
                    name="0"
                  />
                </li>
                <li class="smallboxa">
                  <img
                    class="smalla"
                    src="https://img.davich.com/image/sub/education/process_img_02.png"
                    alt="대강의실(연회장, 워크숍 진행)"
                    name="1"
                  />
                </li>
                <li class="smallboxa">
                  <img
                    class="smalla"
                    src="https://img.davich.com/image/sub/education/process_img_03.png"
                    alt="1강의실(이론 및 컴퓨터 수업)"
                    name="2"
                  />
                </li>
                <li class="smallboxa">
                  <img
                    class="smalla"
                    src="https://img.davich.com/image/sub/education/process_img_04.png"
                    alt="소회의실(미팅룸, 귀빈실)"
                    name="3"
                  />
                </li>
                <li class="smallboxa">
                  <img
                    class="smalla"
                    src="https://img.davich.com/image/sub/education/process_img_05.png"
                    alt="지하 1강의실(실습 및 컴퓨터 수업)"
                    name="4"
                  />
                </li>
                <li class="smallboxa">
                  <img
                    class="smalla"
                    src="https://img.davich.com/image/sub/education/process_img_06.png"
                    alt="지하 2강의실(실습 및 컴퓨터 수업)"
                    name="5"
                  />
                </li>
              </div>
            </div>

            <div class="tab_b">
              <div class="b_detail scroll-fade-in-f scroll-delay-2">
                <img class="bigb" src="https://img.davich.com/image/sub/education/process_img_07.png" alt="신관" />
              </div>

              <p class="bigbp">신관</p>

              <div class="b_list scroll-fade-in-f scroll-delay-3">
                <li class="smallboxb active">
                  <img
                    class="smallb"
                    src="https://img.davich.com/image/sub/education/process_img_07.png"
                    alt="신관"
                    name="0"
                  />
                </li>
                <li class="smallboxb">
                  <img
                    class="smallb"
                    src="https://img.davich.com/image/sub/education/process_img_08.png"
                    alt="1층 양안시 센터(양안시 이론 및 실습)"
                    name="1"
                  />
                </li>
                <li class="smallboxb">
                  <img
                    class="smallb"
                    src="https://img.davich.com/image/sub/education/process_img_09.png"
                    alt="1층 미디어실(다용도 목적)"
                    name="2"
                  />
                </li>
                <li class="smallboxb">
                  <img
                    class="smallb"
                    src="https://img.davich.com/image/sub/education/process_img_10.png"
                    alt="2층 굴절검사(실습전용공간)"
                    name="3"
                  />
                </li>
                <li class="smallboxb">
                  <img
                    class="smallb"
                    src="https://img.davich.com/image/sub/education/process_img_11.png"
                    alt="2층 콘택트렌즈실(컴퓨터 수업 가능)"
                    name="4"
                  />
                </li>
                <li class="smallboxb">
                  <img
                    class="smallb"
                    src="https://img.davich.com/image/sub/education/process_img_12.png"
                    alt="2층 안경렌즈실(컴퓨터 수업가능)"
                    name="5"
                  />
                </li>
                <li class="smallboxb">
                  <img
                    class="smallb"
                    src="https://img.davich.com/image/sub/education/process_img_13.png"
                    alt="3층 시뮬레이션장(매장과 동일한 구성)"
                    name="6"
                  />
                </li>
              </div>
            </div>
          </section>
          <!-- 교육장 소개 -->
        </div>

        <!--사진 및 영상-->
        <div class="tab-item tab_c ly-cont-m">
          <article class="success-video">
            <h4 class="scroll-fade-in-f">성공사례</h4>
            <div class="success-video-items">
              <div v-for="item in getSuccess" :key="item.id" class="success-video-item">
                <div v-if="item.id % 2 == 0" class="right">
                  <div class="img-wrap scroll-fade-in-f">
                    <img :src="item.imgurl" alt="유투브썸네일" @click="showModal(item.id)" />
                    <img
                      src="https://img.davich.com/mobile/image/sub/education/btn-play-b.png"
                      alt="play-button"
                      class="play-btn"
                    />
                  </div>
                  <div class="desc scroll-fade-in-f scroll-delay-1">
                    <h5>{{ item.title }}</h5>
                    <em>{{ item.subtitle }}</em>
                    <p v-html="item.story"></p>
                  </div>
                </div>
                <div v-else>
                  <div class="img-wrap scroll-fade-in-f">
                    <img :src="item.imgurl" alt="유투브썸네일" @click="showModal(item.id)" />
                    <img
                      src="https://img.davich.com/mobile/image/sub/education/btn-play-b.png"
                      alt="play-button"
                      class="play-btn"
                    />
                  </div>
                  <div class="desc scroll-fade-in-f scroll-delay-1">
                    <h5>{{ item.title }}</h5>
                    <em>{{ item.subtitle }}</em>
                    <p v-html="item.story"></p>
                  </div>
                </div>
              </div>
            </div>
          </article>
          <!-- 성공사례 -->
          <article class="graduate-video">
            <h4 class="scroll-fade-in-f">사진 및 졸업동영상</h4>
            <div class="graduate-video-items scroll-fade-in-f">
              <div v-for="item in graduateItemsForList" :key="item.id" class="graduate-video-item">
                <div class="img-wrap">
                  <img :src="item.imgurl" alt="유투브썸네일" @click="showModal2(item.id)" />
                  <div class="hover-icon">
                    <img
                      src="https://img.davich.com/mobile/image/sub/education/btn-play-b.png"
                      alt="play-button"
                      class="play-btn"
                    />
                  </div>
                </div>
                <h5 @click="showModal2(item.id)">{{ item.title }}</h5>
              </div>
            </div>
            <b-pagination id="pagination" v-model="currentPage" :per-page="perPage"></b-pagination>
            <button class="el-btn-m scroll-fade-in-f" id="btnMoreData" @click="getMoreData">더보기</button>
          </article>
          <!-- 사진 및 졸업동영상 -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SubTitle from "../../../components/mobile/MSubTitle1.vue";
import PlayYoutube from "../../../components/mobile/EDUCATION/MPlayYoutube.vue";
import TabMenu from "../../../components/mobile/MTabMenu.vue";

import { mapGetters } from "vuex";

export default {
  beforeCreate() {
    this.$store.dispatch("education/FETCH_PIC_VIDEO");
    this.$store.dispatch("education/FETCH_SUCCESS");
  },

  components: {
    SubTitle,
    TabMenu,
  },
  data() {
    return {
      // subtitle
      title: "다비치 사관∙공채",
      content: "안경업계 최초로 자체 훈련기관으로",
      content2: "서울지방노동청의 승인을 받은",
      content3: "전문 교육기관에서 여러분의 꿈을 이루세요.",
      //tab menu
      tabTitle: ["사관∙공채란?", "특전 및 교육과정", "사진 및 영상"],
      playId: 0,

      //pagination
      perPage: 6,
      currentPage: 1,
    };
  },

  computed: {
    ...mapGetters("education", ["getPicVideo", "getSuccess"]),

    graduateItemsForList() {
      return this.getPicVideo.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
    },
  },

  methods: {
    showModal(playId) {
      this.playId = playId;

      this.$modal.show(
        PlayYoutube,
        {
          playId: this.playId,
          youtubeUrl: this.getSuccess,
        },
        {
          adaptive: true,
          minWidth: 320,
          minHeight: 180,
        },
        {
          "before-open": this.beforeOpen,
        }
      );
    },
    showModal2(playId) {
      this.playId = playId;

      this.$modal.show(
        PlayYoutube,
        {
          playId: this.playId,
          youtubeUrl: this.getPicVideo,
        },
        {
          adaptive: true,
          minWidth: 320,
          minHeight: 295,
          width: "auto",
        },
        {
          width: 428,
          height: 295,
        }
      );
    },

    //더보기 버튼 클릭 시 데이터 추가로 표사.
    getMoreData() {
      const btn = document.getElementById("btnMoreData");
      this.perPage += 3;

      //마지막 데이터에서 더보기버튼 숨김
      if (this.perPage >= this.getPicVideo.length) {
        btn.style.display = "none";
      }
    },

    // 특전 및 교육과정 바로가기 링크 이동
    goToCurriculum() {
      const tabBtn = document.querySelectorAll(".tab-btn-m");
      const tabCont = document.querySelectorAll(".tab-item");
      tabBtn[0].classList.remove("active");
      tabBtn[1].classList.add("active");
      tabCont[0].classList.remove("display");
      tabCont[1].classList.add("display");
    },
  },

  // 자동 슬라이드 배너
  mounted() {
    // 교육장 소개 탭 갤러리 AAAAAAAAAAAAAAA
    var bigPicA = document.querySelector(".biga"); // 디테일 이미지 경로
    var bigPicAp = document.querySelector(".bigap"); // p 태그 경로
    var smallPicsA = document.querySelectorAll(".smalla"); // 리스트 이미지들
    var smallBoxA = document.querySelectorAll(".smallboxa"); // 리스트 박스들

    for (var p = 0; p < smallPicsA.length; p++) {
      smallPicsA[p].addEventListener("click", changepic);
    }

    function changepic() {
      // 교체 함수
      var smallPicAttribute = this.getAttribute("src"); // 선택 이미지 주소 저장
      var smallPicAtxt = this.getAttribute("alt"); // 선택 제목 저장
      var smallIndexA = this.getAttribute("name"); // 인덱스 구하기
      let smallClassA = smallBoxA[smallIndexA]; // 해당 박스 클래스 선택

      smallBoxA[0].classList.remove("active"); // 전체 액티브 클래스 삭제
      smallBoxA[1].classList.remove("active"); // 전체 액티브 클래스 삭제
      smallBoxA[2].classList.remove("active"); // 전체 액티브 클래스 삭제
      smallBoxA[3].classList.remove("active"); // 전체 액티브 클래스 삭제
      smallBoxA[4].classList.remove("active"); // 전체 액티브 클래스 삭제
      smallBoxA[5].classList.remove("active"); // 전체 액티브 클래스 삭제

      smallClassA.classList.add("active"); // 액티브 클래스 추가

      bigPicA.setAttribute("src", smallPicAttribute); // 이미지 교체
      bigPicAp.innerHTML = smallPicAtxt; // 텍스트 교체
    }

    // 교육장 소개 탭 갤러리 BBBBBBBB
    var bigPicB = document.querySelector(".bigb"); // 디테일 이미지 경로
    var bigPicBp = document.querySelector(".bigbp"); // p 태그 경로
    var smallPicsB = document.querySelectorAll(".smallb"); // 리스트 이미지들
    var smallBoxB = document.querySelectorAll(".smallboxb"); // 리스트 박스들

    for (var b = 0; b < smallPicsB.length; b++) {
      smallPicsB[b].addEventListener("click", changepicB);
    }

    function changepicB() {
      // 교체 함수
      var smallPicBttribute = this.getAttribute("src"); // 선택 이미지 주소 저장
      var smallPicBtxt = this.getAttribute("alt"); // 선택 제목 저장
      var smallIndexB = this.getAttribute("name"); // 인덱스 구하기
      let smallClassB = smallBoxB[smallIndexB]; // 해당 박스 클래스 선택

      smallBoxB[0].classList.remove("active"); // 전체 액티브 클래스 삭제
      smallBoxB[1].classList.remove("active"); // 전체 액티브 클래스 삭제
      smallBoxB[2].classList.remove("active"); // 전체 액티브 클래스 삭제
      smallBoxB[3].classList.remove("active"); // 전체 액티브 클래스 삭제
      smallBoxB[4].classList.remove("active"); // 전체 액티브 클래스 삭제
      smallBoxB[5].classList.remove("active"); // 전체 액티브 클래스 삭제
      smallBoxB[6].classList.remove("active"); // 전체 액티브 클래스 삭제

      smallClassB.classList.add("active"); // 액티브 클래스 추가

      bigPicB.setAttribute("src", smallPicBttribute); // 이미지 교체
      bigPicBp.innerHTML = smallPicBtxt; // 텍스트 교체
    }

    // video pause
    function videoPause() {
      let vid = document.querySelector(".autoplay-video");
      let tabMenu = document.querySelectorAll(".tab-btn-m");
      for (var i = 0; i < tabMenu.length; i++) {
        tabMenu[i].addEventListener("click", function () {
          if (tabMenu[0].classList.contains("active")) {
            vid.play();
          } else {
            vid.pause();
          }
        });
      }
    }
    videoPause();
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.sub-title {
  background-image: url("https://img.davich.com/mobile/image/sub/subtitle/sub-edu-bg-02.png");
  background-size: cover;
}
.waiting {
  font-size: 40px;
  color: $color-blue;
  font-weight: bold;
  text-align: center;
  line-height: 50px;
  padding: 100px 0;
}
h2 {
  @include font-h-30;
  margin-bottom: 40px;
  text-align: center;
}
p {
  @include font-main-m;
}
// 사관∙공채란? tab_a
.tab_a {
  .videobox {
    video {
      width: 100%;
    }
    margin-top: 70px;
    overflow: hidden;
  }
  // 사관 공채란???
  .intro {
    .intro_box {
      .title01 {
        padding-top: 70px;
        margin-bottom: 30px;
        text-align: center;
      }
      .txt {
        h3 {
          font-size: 19px;
          font-weight: 500;
          margin-top: 90px;
          text-align: center;
          margin-bottom: 27px;
          line-height: 29px;
        }
        .txt-num {
          display: flex;
          justify-content: center;
          .line01,
          .line02 {
            width: 43vw;
            height: 43vw;
            max-width: 197px;
            max-height: 197px;
            border: 1px solid $color-blue;
            border-radius: 50%;
            flex-shrink: 0;
            text-align: center;
            @include box-center-flex;
            flex-direction: column;
            h4 {
              font-family: $en-font;
              font-size: 46px;
              font-weight: 600;
              color: $color-blue;
            }
            span {
              display: block;
              margin-bottom: 10px;
            }
          }
          .line02 {
            margin-left: -15px;
          }
        }
      }
      .intro_bg {
        margin-top: 60px;
        .intro_img {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    p {
      span {
        font-weight: bold;
      }
    }
  }
  // 사관 공채 선발 기준
  .ability {
    padding: 100px 0;
    position: relative;
    h3 {
      @include font-h-25;
      color: $color-blue;
    }
    .txt {
      @include font-main-m;
      h4 {
        @include font-h-20;
      }
      .txt_left,
      .txt_right {
        .txt_1 {
          margin: auto;
          width: 100vw;
          height: 100vw;
          max-width: 300px;
          max-height: 300px;
          border: 1px dashed $color-blue;
          border-radius: 50%;
          text-align: center;
          z-index: 2;
          @include box-center-flex;
          flex-direction: column;
        }
        .txt_2 {
          margin: 0 auto;
          width: 178px;
          display: block;
          text-align: left;
          padding: 30px 0 50px 0;
        }
        .txt_3 {
          width: 100%;
          overflow: hidden;
          span {
            width: 50%;
            float: left;
          }
        }
      }
      .txt_right {
        padding-top: 80px;
        .line02 {
          background: $color-blue;
          p,
          h3 {
            color: $color-white;
          }
        }
        .txt_2 {
          width: 235px;
        }
        .txt_3 {
          span {
            width: 100%;
          }
        }
      }
    }
    article {
      width: 100%;
      padding: 20px 20px 34px 20px;
      border-top: 1px solid $color-black;
      border-bottom: 1px solid #cccccc;
      h4 {
        margin-bottom: 22px;
      }
    }
  }
  //다비치에서는 이러한 인재를 발굴합니다.
  .slider-wrap {
    padding: 100px 0;
    background: #f0f8ff;
    .slider_title {
      font-size: 19px;
      font-weight: 500;
      line-height: 29px;
      color: $color-blue;
      letter-spacing: -0.57px;
      text-align: center;
      margin-bottom: 50px;
    }
    .ab_slider {
      width: 90vw;
      margin: 0 auto;
      ul {
        width: 100%;
        height: auto;
        margin-bottom: -22px;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        li {
          width: 40vw;
          height: 40vw;
          max-width: 170px;
          max-height: 170px;
          margin: 0 2.5vw;
          border-radius: 50%;
          background-color: $color-white;
          @include box-center-flex;
          flex-direction: column;
          margin-bottom: 22px;
          img {
            width: 9.3vw;
            max-width: 45px;
          }
          p {
            margin-top: 10px;
            @include font-main-m;
            font-weight: 500;
          }
        }
      }
    }
  }

  // 인재 양성 로드맵(전문성)
  .roadmap {
    padding: 100px 0;
    @include box-center-flex;
    flex-direction: column;
    > p {
      margin-bottom: 70px;
    }
  }
  .roadmap-img {
    width: 280px;
    height: 800px;
  }
  .roadmap-items {
    padding: 54px 0 30px 0;
    .roadmap-item {
      margin-bottom: 70px;
      display: flex;
      .roadmap-ttl {
        @include font-h-20;
        color: $color-darkblue;
        width: 27.7vw;
        max-width: 140px;
        &.ttl-2 {
          color: $color-blue;
        }
        &.ttl-3 {
          color: $color-skyblue;
        }
        &.ttl-4 {
          color: #6cc24a;
        }
      }
      .roadmap-txt {
        .badge {
          padding: 10px 17px;
          display: inline-block;
          margin-bottom: 15px;
          background-color: #f7f7f7;
        }
        span {
          display: block;
        }
      }
    }
  }
}
// 특전 및 교육과정 ///////////
.tab_b {
  .perks {
    height: 746px;
    background: #07070d;
    position: relative;
    .txt {
      top: 70px;
      position: absolute;
      color: $color-white;
      text-align: center;
      width: 85vw;
      left: 50%;
      transform: translateX(-50%);
      ul {
        display: flex;
        justify-content: center;
      }
      li {
        width: 40vw;
        height: 40vw;
        max-width: 170px;
        max-height: 170px;
        border: 1px solid $color-white;
        border-radius: 50%;
        flex-shrink: 0;
        @include box-center-flex;
        flex-direction: column;
        &:first-child {
          margin-right: 5vw;
        }
      }
      .line1 {
        p {
          @include font-h-25;
          font-weight: 300;
          span {
            @include font-h-25;
            line-height: 30px;
            display: inline-block
          }
        }
      }
      .line3 {
        p {
          @include font-h-22;
          font-weight: 300;
          span {
            @include font-h-25;
          }
        }
      }
      p {
        margin: 24px 0 30px 0;
      }
      .box {
        display: block;
        font-size: 16px;
        font-weight: 200;
        text-align: left;
        line-height: 35px;
        padding: 20px 0;
        border-top: 1px solid $color-white;
        border-bottom: 1px solid $color-white;
      }
    }
  }
  img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    margin-top: -1px;
  }
  // 하루일과표
  .table {
    height: auto;
    width: 100vw;
    overflow: hidden;
    padding: 100px 0;
    h2 {
      padding-bottom: 40px;
      margin: 0;
    }
    .img-wrap {
      width: 92.5vw;
      margin-left: 7.5vw;
      overflow-x: scroll;
      overflow-y: hidden;
      &::-webkit-scrollbar {
        width: 16px;
      }
      &::-webkit-scrollbar-track {
        background-color: #f9f9f9;
      }
      &::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        border: 4px solid transparent;
        background-color: #cbcbcb;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
      }
      img {
        width: 842px;
        height: 218px;
      }
    }
    p {
      margin-top: 20px;
      margin-left: 7.5vw;
    }
  }

  // 교육과목
  .subject {
    width: 100%;
    background: #f0f8ff;
    padding-bottom: 50px;
    article {
      width: 100%;
      h2 {
        padding: 90px 0 0px;
      }
      .subject_a {
        width: 85vw;
        margin: 0 auto;
        .abox {
          li {
            width: 100%;
            div {
              @include font-h-20;
              width: 100%;
              height: 70px;
              line-height: 70px;
              color: $color-blue;
              text-align: center;
              border: 1px solid $color-blue;
              border-radius: 50px;
            }
            p {
              @include font-main-m;
              padding-top: 20px;
              padding-left: 30px;
              padding-bottom: 40px;
            }
          }
        }
      }
      // 평가내용
      .subject_b {
        width: 100%;
        .bbox {
          width: 100%;
          display: flex;
          justify-content: center;
          align-content: center;
          flex-wrap: wrap;
          div {
            width: 53vw;
            max-width: 170px;
            margin-left: 3vw;
            margin-right: 3vw;
            margin-bottom: 50px;
            li {
              width: 53vw;
              height: 53vw;
              max-width: 170px;
              max-height: 170px;
              flex-shrink: 0;
              text-align: center;
              @include box-center-flex;
              flex-direction: column;
              background: $color-white;
              border-radius: 150px;
              text-align: center;
              img {
                width: 94px;
                height: 94px;
              }
            }
            p {
              @include font-h-20;
              font-size: 18px;
              color: $color-blue;
              text-align: center;
              margin: 15px 0 10px 0;
            }
            span {
              @include font-main-m;
              text-align: center;
              display: inline-block;
            }
          }
        }
      }
    }
  }
  // 교육장 소개
  .gallery {
    width: 100vw;
    h2 {
      margin: 100px auto 40px;
    }
    .tab_a {
      padding-bottom: 80px;
      .a_detail {
        width: 100%;
        height: 100%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .bigap {
        @include font-main-m;
        text-align: center;
        margin: 20px 0 40px;
      }
      .a_list {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        margin-left: 5vw;
        margin-right: 5vw;
        li {
          width: 80px;
          height: 80px;
          @include box-center-flex;
          flex-direction: column;
          margin: 8px 8px;
          border: 3px solid transparent;
          &.active {
            border: 3px solid $color-blue;
          }
          img {
            overflow: hidden;
            object-fit: cover;
          }
        }
      }
    }
    //신관 이미지
    .tab_b {
      padding-bottom: 80px;
      .b_detail {
        width: 100%;
        height: 100%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .bigbp {
        @include font-main-m;
        text-align: center;
        margin: 20px 0 40px;
      }
      .b_list {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        margin-left: 5vw;
        margin-right: 5vw;
        li {
          width: 80px;
          height: 80px;
          @include box-center-flex;
          flex-direction: column;
          margin: 8px 8px;
          border: 3px solid transparent;
          &.active {
            border: 3px solid $color-blue;
          }
          img {
            overflow: hidden;
            object-fit: cover;
          }
        }
      }
    }
  }
}

// 사진 및 영상
.tab_c {
  h4 {
    @include font-h-30;
    margin-top: 70px;
    margin-bottom: 40px;
    text-align: center;
  }
  // 성공사례
  .success-video {
    .success-video-items {
      .success-video-item {
        &:last-child {
          > div {
            .desc {
              position: relative;
              margin-top: 30px;
            }
          }
        }
        > div {
          width: 100%;
          position: relative;
          .img-wrap {
            position: relative;
            width: 100%;
            height: 47vw;
            cursor: pointer;
            margin-bottom: 30px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .play-btn {
              width: 37px;
              height: 49px;
              @include box-center;
              pointer-events: none;
            }
          }
          .desc {
            margin-bottom: 70px;
            h5 {
              @include font-h-20;
              margin-bottom: 10px;
            }
            em {
              @include font-main-m;
              color: #777;
            }
            p {
              @include font-main-m;
              margin-top: 30px;
            }
          }
        }
      }
    }
  }
  // 사진 및 졸업 동영상
  .graduate-video {
    margin-bottom: 50px;
    h4 {
      margin-top: 100px;
    }
    .el-btn {
      margin-top: 50px;
    }
    .graduate-video-items {
      display: flex;
      flex-wrap: wrap;

      .graduate-video-item {
        width: 100%;
        margin-bottom: 50px;
        .img-wrap {
          width: 100%;
          height: 47vw;
          cursor: pointer;
          position: relative;
          &:hover {
            .hover-icon {
              opacity: 1;
              visibility: visible;
              pointer-events: none;
            }
            box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.4);
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .hover-icon {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: #0055aac0;
            z-index: 1;
            opacity: 0;
            visibility: hidden;
            transition: 0.3s;
            img {
              @include box-center;
              width: 37px;
              height: 49px;
            }
          }
        }
        h5 {
          margin-top: 30px;
          @include font-h-20;
        }
      }
    }
  }
}
.el-btn-m {
  margin-top: 50px;
  margin-bottom: 50px;
}
//popup
.vm--modal {
  overflow: auto;
  @include box-center;
  #play-frame {
  }
}
#pagination {
  display: none;
}
</style>
