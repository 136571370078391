<template>
  <div>
    <sub-title :title="title" :content="content" :content2="content2"> </sub-title>
    <section class="greeting-cont">
      <div class="greeting-cont-inner">
        <div class="greeting-ttl">
          <h2 class="scroll-fade-in-f">
            차별화된 경쟁력으로 <br />
            국내 최대 안경체인 브랜드로 성장
          </h2>
          <p class="scroll-fade-in-f scroll-delay-1">
            다비치안경은 고객이 전국 어느 매장에서나 동일한 서비스를 제공받을 수 있도록 다비치안경체인의 전략 정책을 담은 서비스와 절차를 모두 표준화 및
            시스템화 했으며, 제조사 직거래 유통 방식으로 좋은 품질의 제품을 정액정찰제로 제공했습니다.<br />
            이로서 280여개의 대형 체인망을 갖추며, 국민에게 신뢰받는 국내 최대 안경체인 브랜드로 자리매김했습니다.
          </p>
          <img class="greeting-img-01 scroll-fade-in-f scroll-delay-1" src="https://img.davich.com/mobile/image/sub/about/img-greeting-01.png" alt="" />
        </div>
        <!--greeting title-->
        <div class="greeting-txt">
          <h3 class="scroll-fade-in-f">글로벌 기업으로 도약</h3>
          <p class="scroll-fade-in-f scroll-delay-1">
            다비치안경은 더 큰 도약을 이루고자 합니다.<br />
            중국 시장을 비롯한 아시아 및 전 세계의 1,000여개의 다비치 체인망을 구축해 전 세계에게 ‘Light Up Your Life’ 비전으로 건강한 시야를 제공하고
            명실상부한 글로벌 그룹으로 성장하겠습니다.
          </p>
          <img class="greeting-img-02 scroll-fade-in-f scroll-delay-1" src="https://img.davich.com/mobile/image/sub/about/img-greeting-02.png" alt="" />
          <h3 class="scroll-fade-in-f">맑은 세상, 인류를 행복하게</h3>
          <p class="scroll-fade-in-f scroll-delay-1">
            또한 다비치안경은 온 세상을 맑고 밝게 다 비추는 마음으로 소외되고 어려운 생활을 하는 이웃에게 사회적 책임을 다하려고 합니다.<br />
            판매 수익의 일부를 기부하고 봉사활동을 통해 시력 검사 및 교정용 안경을 제공하는 등 다양한 방법으로 지원하여 더 나은 사회를 만들기 위해
            기여하겠습니다.
          </p>
          <img class="greeting-img-03 scroll-fade-in-f scroll-delay-1" src="https://img.davich.com/mobile/image/sub/about/img-greeting-03.png" alt="" />

          <p class="greet-inAddition scroll-fade-in-f scroll-delay-1">
            앞으로 다비치안경은 다비치의 브랜드 가치를 제고하기 위해 노력해 나가겠습니다.<br />
            신성장동력을 발굴하고 지속 가능한 성장을 위해 시대에 부응하는 혁신에 앞장서며 모든 기업 활동에서 다비치다운 길을 걸으며 고객과 함께하겠습니다.
          </p>
          <p class="greet-thanks scroll-fade-in-f scroll-delay-3">
            여러분들의 많은 관심과 사랑을 부탁드립니다. <br />
            감사합니다.
          </p>
          <strong class="ceo-name scroll-fade-in-f scroll-delay-3" style="margin-top: 25px">대표 김인규</strong>
        </div>
        <!--greeting text-->
      </div>
    </section>
  </div>
</template>

<script>
import SubTitle from "../../../components/mobile/MSubTitle1.vue";
export default {
  components: {
    SubTitle,
  },
  data() {
    return {
      title: "대표 인사말",
      content: "전문성과 신뢰를 바탕으로 한",
      content2: "최고의 안경전문기업, 다비치입니다.",
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.sub-title {
  background-image: url("https://img.davich.com/mobile/image/sub/subtitle/sub-about-bg-01.png");
  background-position: bottom;
}
.greeting-cont {
  background-color: #fff;
  position: relative;
  .greeting-cont-inner {
    padding: 50px 0px 0px 0px;
    line-height: normal;
    //title
    .greeting-ttl {
      font-weight: 700;
      h2 {
        margin: 0 auto;
        width: 85vw;
        font-size: 23px;
        font-weight: 700;
        letter-spacing: -1px;
        font-family: $ko-font;
      }
      p {
        margin: 0 auto;
        width: 85vw;
        margin-top: 25px;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
      }
      img {
        width: 100%;
        margin-top: 35px;
      }
    }
    //desc
    .greeting-txt {
      margin-bottom: 40px;
      h3 {
        width: 85vw;
        margin: 25px auto 20px auto;
        font-size: 20px;
        font-weight: 500;
      }
      p {
        width: 85vw;
        margin: 0 auto;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        &.greet-inAddition,
        &.greet-aspiration,
        &.greet-thanks {
          margin-top: 30px;
        }
      }
      img {
        width: 100%;
        margin-top: 35px;
      }
    }
    strong {
      width: 85vw;
      margin: 0 auto;
      display: block;
      font-weight: bold;
    }
  }
}
</style>
