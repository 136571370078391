export const mutations = {
  SET_HISTORY: function (state, resData) {
    let sorted;

    sorted = resData.sort(function (a, b) {
      return a.yyyy - b.yyyy;
    });
    state.history = sorted;
  },

  SET_CONTRIBUTION_BOARD: function (state, resData) {
    //날짜 포맷 변경
    for (let item of resData) {
      item.created_dttm = item.created_dttm.split("T")[0];

      item.img_url = [];
      if (item.attachments) {
        for (let attc of item.attachments) {
          if (attc.type == "main") {
            item.main_image = attc.download_url;
          } else {
            item.img_url.push(attc.download_url);
          }
        }

        if (item.main_image == null) {
          item.main_image =
            "https://davichadm.davich.com/files/eyesight/no-image.png";
        }
      }
    }

    //id순으로 정렬
    let sorted;
    sorted = resData.sort(function (a, b) {
      return b.id - a.id;
    });

    //정렬된 리스트에 인덱스 부여
    for (let i = 0; i < sorted.length; i++) {
      sorted[i]["index"] = sorted.length - i;
    }

    state.boardData = sorted;
  },

  SET_DAVICH_NUMBERS: function (state, resData) {
    state.davichNumbers = resData; 
  },

  SEARCH_CONTRIBUTION_BOARD: function (state, payload) {
    let boardData = payload[0];
    let searchText = payload[1];

    let result;
    result = boardData.filter((item) => {
      let filtered = true;
      filtered =
        item.title.includes(searchText) || item.contents.includes(searchText);
      return filtered;
    });

    state.searchedData = result;
  },
};
