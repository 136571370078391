<template>
  <div class="overflow-wrap">
    <div class="close-icon"></div>
    <div class="iframe-wrap">
      <iframe
        id="play-frame"
        :src="srcData"
        title="YouTube video player"
        frameborder="0"
        mute="1"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      srcData: "",
    };
  },
  props: ["playId", "youtubeUrl"],

  mounted() {
    const youtubeUrl = this.youtubeUrl;

    let filteredUrl = youtubeUrl.filter((item) => {
      let filtered = true;
      filtered = item.id == this.playId;
      return filtered;
    });

    const newUniqueUrl = filteredUrl[0].uniqueUrl;
    const newSrc = `https://www.youtube.com/embed/${newUniqueUrl}?autoplay=1&mute=1`;

    this.srcData = newSrc;
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.close-icon {
  display: block;
  z-index: 99999999;
  position: absolute;
  right: 10px;
  top: -30px;
  background-color: rgba(255, 255, 255, 0);
  pointer-events: none;
  &:after {
    width: 100%;
    height: 100%;
    display: inline-block;
    content: "CLOSE \00d7";
    font-size: 15px;
    color: #fff;
    background-color: rgba(255, 255, 255, 0);
    pointer-events: none;
  }
}
body > #modals-container > .vm--container > .vm--modal {
}
body > #modals-container > .vm--container > .vm--modal .overflow-wrap {
  width: 88vw;
  height: 48vw;
  margin: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
body > #modals-container > .vm--container > .vm--modal .iframe-wrap {
  width: 100%;
  height: 100%;
}
iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
