import { fetchFaq, fetchStoreData } from "@/api/index";

export const actions = {
  FETCH_FAQ: function (context) {
    fetchFaq()
      .then((res) => {
        context.commit("SET_FAQ", res.data);
      })
      .catch((err) => console.log(err));
  },

  FETCH_STORE: function (context) {
    fetchStoreData()
      .then((res) => {
        context.commit("SET_STORE", res.data);
      })
      .catch((err) => console.log(err));
  },
};
