<template>
  <div class="franchise ly-w1400">
    <sub-title :title="title" :content="content"> </sub-title>
    <davichbrand-txt-bg :text="text"></davichbrand-txt-bg>
    <div class="ly-cont-p franchise-inner">
      <div class="new-store ly-cont">
        <h2 class="scroll-fade-in-f">신규오픈</h2>
        <div class="store-items">
          <div
            class="store-item scroll-fade-in-f scroll-delay-1"
            v-for="item in getNewOpen"
            :key="item.id"
          >
            <img
              src="https://img.davich.com/image/main/img-grand-open.png"
              alt="grand open bg"
            />
            <div class="store-desc">
              <h3
                class="store-name"
                :class="{
                  longStoreName: item.lengthName >= 8,
                  longStoreName2: item.lengthName >= 10,
                }"
              >
                {{ item.name }}
              </h3>
              <p>{{ item.openDate }}</p>
            </div>
          </div>
        </div>
      </div>
      <!--/.new-store-->
      <div class="store-open-year ly-cont">
        <h2 class="scroll-fade-in-f">연혁별 오픈</h2>
        <div class="store-open-year-cont">
          <div v-for="item in getOpened" :key="item.index">
            <list-by-year :franchiseData="item"></list-by-year>
          </div>
        </div>
      </div>
      <!--/.store-open-year-->
    </div>
  </div>
</template>

<script>
import ListByYear from "../../components/PARTNERSHIP/ListByYear.vue";
import SubTitle from "../../components/SubTitle1.vue";
import DavichbrandTxtBg from "../../components/PRODUCTS/DavichbrandTxtBg.vue";

import { mapGetters } from "vuex";

export default {
  beforeCreate() {
    this.$store.dispatch("partnership/FETCH_OPENED");
  },

  components: {
    ListByYear,
    SubTitle,
    DavichbrandTxtBg,
  },
  data() {
    return {
      //sub title
      title: "가맹점 현황",
      content: "우리지역 1등 매장! No.1 브랜드 다비치와 함께하세요.",
      // background text
      text: "HISTORY",
    };
  },

  computed: {
    ...mapGetters("partnership", ["getOpened", "getNewOpen"]),
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.franchise {
  .sub-title {
    background-image: url("https://img.davich.com/image/sub/subtitle/sub-partnership-bg.png");
  }
  .franchise-inner {
    position: relative;
    h2 {
      @include font-h-35;
      margin-bottom: 70px;
    }
    // 신규오픈
    .new-store {
      .store-items {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -40px;
        .store-item {
          margin-right: 40px;
          margin-bottom: 40px;
          position: relative;
          &:nth-child(2n) {
            margin-right: 0;
          }
          img {
            width: 680px;
            height: 239px;
          }
          .store-desc {
            color: $color-white;
            position: absolute;
            width: 270px;
            top: 50%;
            transform: translateY(-50%);
            left: 390px;
            font-weight: 700;
            h3 {
              font-size: 40px;
              margin-bottom: 10px;
              line-height: 50px;
              &.longStoreName {
                font-size: 32px;
              }
              &.longStoreName2 {
                font-size: 28px;
                line-height: 40px;
              }
            }
            p {
              font-size: 22px;
            }
          }
        }
      }
    }

    //연혁별 오픈
    .store-open-year {
      margin-top: 140px;
      margin-bottom: -150px;
      .store-open-year-cont {
        margin-left: 510px;
      }
    }
  }
}
</style>
