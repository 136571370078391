<template>
  <div>
    <section class="privacy-policy ly-wrap">
      <div class="privacy-policy-ttl">
        <h2 class="ly-cont-m">개인정보처리방침</h2>
        <div class="ly-cont-m">
          <div class="select_box">
            <div class="select">{{ selectedTitle }}</div>
            <ul class="list" @click="getSelected($event)">
              <li class="selected" v-for="item in privacyData" :key="item.id" :value="item.id">
                {{ item.title }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="ly-cont-m privacy-policy-txt" v-html="selectedContents"></div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      selectedTitle: null,
      selectedContents: null,
    };
  },

  computed: {
    ...mapState({
      privacyData: (state) => state.others.privacy,
    }),
  },

  created() {
    const self = this;

    self.selectedTitle = self.privacyData[0].title;
    self.selectedContents = self.privacyData[0].contents;
  },

  mounted() {
    let selectBox = document.querySelector(".select_box");
    selectBox.addEventListener("click", function () {
      selectBox.classList.toggle("on");
    });
  },

  methods: {
    getSelected(click) {
      const self = this;

      if (click.target.tagName == "LI") {
        let selectedId = click.target.value;

        let selectedData = self.privacyData.filter((item) => {
          let filtered = true;
          filtered = item.id == selectedId;
          return filtered;
        });

        self.selectedTitle = selectedData[0].title;
        self.selectedContents = selectedData[0].contents;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.privacy-policy {
  .privacy-policy-ttl {
    border-bottom: 1px solid $color-black;
    height: 290px;
    text-align: center;
    h2 {
      padding-top: 90px;
      font-size: 30px;
      font-weight: bold;
      letter-spacing: -1.2px;
    }
    .ly-cont-m * {
      margin: 0;
      padding: 0;
    }
    .ly-cont ul li,
    .ly-cont ol li {
      list-style: none;
    }
    .select_box {
      width: 100%;
      margin: 20px 0px;
      display: inline-block;
      position: relative;
    }
    .select_box .select {
      margin-top: 50px;
      width: 100%;
      position: relative;
      box-sizing: border-box;
      height: 40px;
      line-height: 40px;
      font-size: 13px;
      background-color: #fff;
      align-content: center;
      text-align: left;
      padding-left: 5px;
      border: 1px solid #222;
    }
    .select_box.on .select {
      border-bottom: 0px solid #222;
    }
    .select_box .select:after {
      content: url("https://img.davich.com/image/sub/icon-arrow-down.png");
      position: absolute;
      top: 0px;
      right: 10px;
    }
    .select_box.on .select:after {
      content: url("https://img.davich.com/image/sub/about/icon-arrow-up.png");
      align-content: center;
    }
    .select_box .list {
      display: none;
      overflow-y: auto;
      position: relative;
      left: 0;
      z-index: 10;
      font-size: 13px;
      border: 1px solid #222;
      border-top: 0px solid #222;
      box-sizing: border-box;
      width: 85vw;
      background-color: #fff;
      text-align: left;
      padding-left: 5px;
    }
    .select_box.on .list {
      display: block;
    }
    .select_box .list > li {
      box-sizing: border-box;
      width: 100%;
      line-height: 35px;
      cursor: pointer;
    }
    .select_box .list > li:hover {
      background-color: #f0f8ff;
    }
  }
  .privacy-policy-txt {
    padding: 70px 0 200px 0;

    .txt-detail {
      h3 {
        font-weight: bold;
      }
    }
    @include font-main;
    h3,
    h4,
    p {
      margin-bottom: 20px;
      font-size: 16px;
    }
  }
}
</style>
