<template>
  <div>
    <div class="container">
      <div class="slider to-index">
        <div class="slide" v-for="item in videoData" :key="item.id">
          <video muted autoplay loop>
            <source :src="item.pcUrl" type="video/mp4" />
            <strong>Your browser does not support the video tag.</strong>
          </video>
          <div class="slide-desc">
            <h2>
              {{ item.title }}
            </h2>
            <p>{{ item.subtitle }}</p>
          </div>
        </div>

        <div class="arrow-wrap">
          <button class="arrow-left">
            <img src="https://img.davich.com/image/main/btn-caret-left.png" alt="" />
            <span class="en-font">Prev</span>
          </button>
          <button class="arrow-right">
            <img src="https://img.davich.com/image/main/btn-caret-right.png" alt="" />
            <span class="en-font">Next</span>
          </button>
        </div>
        <div class="video-control">
          <div class="progress-wrap en-font">
            <div class="progress-bar">
              <p @click="selectVideo(0)" class="select-video-btn">01</p>
              <div class="progress">
                <div class="progress__filled bar0"></div>
              </div>
            </div>
            <div class="progress-bar">
              <p @click="selectVideo(1)" class="select-video-btn">02</p>
              <div class="progress">
                <div class="progress__filled bar1"></div>
              </div>
            </div>
            <div class="progress-bar">
              <p @click="selectVideo(2)" class="select-video-btn">03</p>
              <div class="progress">
                <div class="progress__filled bar2"></div>
              </div>
            </div>
            <div class="progress-bar">
              <p @click="selectVideo(3)" class="select-video-btn">04</p>
              <div class="progress">
                <div class="progress__filled bar3"></div>
              </div>
            </div>
            <div class="progress-bar">
              <p @click="selectVideo(4)" class="select-video-btn">05</p>
              <div class="progress">
                <div class="progress__filled bar4"></div>
              </div>
            </div>
          </div>
          <div class="slider-ctrl-btn-box">
            <button class="slider-ctrl-btn__play sm" @click="playVideo()">
              <img src="https://img.davich.com/image/main/btn-play.png" alt="play button" />
            </button>
            <button class="slider-ctrl-btn__pause sm on" @click="pauseVideo()">
              <img src="https://img.davich.com/image/main/btn-stop.png" alt="play button" />
            </button>
          </div>
        </div>
        <div class="scroll-mouse">
          <img src="https://img.davich.com/image/main/icon-scroll.png" alt="스크롤" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  beforeCreate() {
    this.$store.dispatch("FETCH_MAIN_VIDEO");
  },

  data() {
    return {
      ranNumber: 0,

      count: null,
      videoNow: null,
      videoList: [],

      progressList: [],
      progressBar: null,
    };
  },

  computed: {
    ...mapState({
      videoData: (state) => state.videoData,
    }),
  },

  created() {
    let self = this;
    function ranNumber(min, max) {
      let ranNumber = Math.floor(Math.random() * (max - min + 1)) + min;
      self.ranNumber = ranNumber;
    }
    ranNumber(0, 4);
  },

  updated() {
    const self = this;
    self.videoList = document.querySelectorAll("video");
    const itemCount = self.videoList.length;
    const nextItem = document.querySelector(".arrow-right");
    const previousItem = document.querySelector(".arrow-left");
    self.count = self.ranNumber;
    self.videoNow = self.videoList[self.count];

    self.progressList = document.getElementsByClassName("progress__filled");
    self.progressBar = self.progressList[self.count];

    self.videoNow.classList.add("active"); //랜덤하게 나온 비디오에 active

    function showNextItem() {
      self.videoList[self.count].classList.remove("active");
      self.progressBar.parentElement.style.display = "none"; //기본 bar 하이드

      if (self.count < itemCount - 1) {
        self.count++;
      } else {
        self.count = 0;
      }
      self.videoList[self.count].classList.add("active");

      self.videoNow = self.videoList[self.count]; //변수재설정
      self.progressBar = self.progressList[self.count]; //변수재설정
      self.progressBar.parentElement.style.display = "flex";
      self.videoNow.addEventListener("timeupdate", self.handleProgress);
    }

    function showPreviousItem() {
      self.videoList[self.count].classList.remove("active");
      self.progressBar.parentElement.style.display = "none"; //기본 bar 하이드

      if (self.count > 0) {
        self.count--;
      } else {
        self.count = itemCount - 1;
      }
      self.videoList[self.count].classList.add("active");

      self.videoNow = self.videoList[self.count]; //변수재설정
      self.progressBar = self.progressList[self.count]; //변수재설정
      self.progressBar.parentElement.style.display = "flex";
      self.videoNow.addEventListener("timeupdate", self.handleProgress);
    }

    nextItem.addEventListener("click", showNextItem);
    previousItem.addEventListener("click", showPreviousItem);
    self.videoNow.addEventListener("timeupdate", self.handleProgress);
  },

  methods: {
    handleProgress() {
      const self = this;
      const percent = (self.videoNow.currentTime / self.videoNow.duration) * 100;

      self.progressBar.parentElement.style.display = "flex";
      self.progressBar.style.flexBasis = `${percent}%`;
    },

    //숫자클릭하여 비디오변경
    selectVideo(index) {
      const self = this;

      self.videoList[self.count].classList.remove("active");
      self.count = index;

      self.progressBar.parentElement.style.display = "none"; //기본 bar 하이드

      self.videoList[index].classList.add("active");

      self.videoNow = self.videoList[index]; //변수재설정
      self.progressBar = self.progressList[index]; //변수재설정
      self.progressBar.parentElement.style.display = "flex";
      self.videoNow.addEventListener("timeupdate", self.handleProgress);
    },

    pauseVideo() {
      const video = document.getElementsByClassName("active")[0];
      let btnPlay = document.querySelector(".slider-ctrl-btn__play");
      let btnPause = document.querySelector(".slider-ctrl-btn__pause");
      btnPlay.classList.add("on");
      btnPause.classList.remove("on");
      video.pause();
    },
    playVideo() {
      const video = document.getElementsByClassName("active")[0];
      let btnPlay = document.querySelector(".slider-ctrl-btn__play");
      let btnPause = document.querySelector(".slider-ctrl-btn__pause");
      video.play();
      btnPlay.classList.remove("on");
      btnPause.classList.add("on");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.container {
  position: relative;
  height: 100vh;
  min-width: 1720px;
  min-width: 1400px;
  overflow: hidden;
  color: white;
  .slider {
    .slide {
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $color-black;
        opacity: 0.05;
        z-index: 0;
      }
      video {
        width: 100%;
        height: 100vh;
        object-fit: cover;
        display: none;
        margin: auto;

        &.active {
          display: block;
          animation: fadeImg 0.8s;
        }
      }
      video.active + .slide-desc {
        display: block;
      }
      .slide-desc {
        display: none;
        position: absolute;
        width: 1400px;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        h2 {
          font-size: 70px;
          margin-bottom: 7px;
          font-weight: 700;
          line-height: 103px;
          letter-spacing: -2.1px;
          span {
            color: $color-skyblue;
          }
        }
        p {
          font-size: 25px;
          opacity: 0.8;
          font-weight: 300;
          line-height: 37px;
          margin-left: 4px;
        }
      }
    }
    .arrow-wrap {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1820px;
      height: auto;
      display: flex;
      justify-content: space-between;
      z-index: 2;
      .arrow-left,
      .arrow-right {
        display: flex;
        color: $color-white;
        flex-direction: column;
        align-items: center;
        font-size: 16px;
        font-weight: 300;
        img {
          margin-bottom: 10px;
        }
      }
    }
    .video-control {
      position: absolute;
      width: 1400px;
      left: calc(50% + 7px);
      transform: translate(-50%, -50%);
      top: 70%;
      display: flex;
      .slider-ctrl-btn-box {
        .checked {
          opacity: 0.3;
        }
      }
      .progress-wrap {
        font-size: 14px;
        font-weight: 600;
        display: flex;
        .progress-bar {
          display: flex;
          align-items: center;
          margin-right: 30px;
          p {
            margin-right: 10px;
          }
          .progress {
            width: 60px;
            height: 2px;
            display: flex;
            background: rgba(255, 255, 255, 0.5);
            display: none;
            .progress__filled {
              background-color: $color-white;
            }
          }
        }
      }
      .slider-ctrl-btn-box {
        button {
          padding: 0;
          padding-right: 20px;
          &.on {
            opacity: 0.4;
          }
        }
      }
    }
  }
}
//화면전환 시 효과
@keyframes fadeImg {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.select-video-btn {
  cursor: pointer;
}
// [class^="slider-ctrl-btn__"] {
//   display: inline-block;
//   margin: 0 0.5rem;
//   background-repeat: no-repeat;
//   background-position: center;
//   background-color: transparent !important;
//   background-size: 100%;
//   opacity: 0.4;
//   cursor: pointer;
// }
// [class^="slider-ctrl-btn__"].sm {
//   width: 10px;
//   height: 8px;
// }
</style>
