import { fetchPrivacy } from "@/api/index";

export const actions = {
  FETCH_PRIVACY(context) {
    fetchPrivacy()
      .then((res) => {
        context.commit("SET_PRIVACY", res.data);
      })
      .catch((err) => console.log(err));
  },
};
