import { fetchHistory, fetchContributionBoard , fetchDavichNumbers} from "@/api/index";

export const actions = {
  FETCH_HISTORY: {
    root: true,
    handler(context) {
      fetchHistory()
        .then((res) => {
          context.commit("SET_HISTORY", res.data);
        })
        .catch((err) => console.log(err));
    },
  },

  FETCH_CONTRIBUTION_BOARD: {
    root: true,
    handler(context) {
      fetchContributionBoard()
        .then((res) => {
          context.commit("SET_CONTRIBUTION_BOARD", res.data);
        })
        .catch((err) => console.log(err));
    },
  },

  FETCH_DAVICH_NUMBERS: {
    root: true,
    handler(context) {
      fetchDavichNumbers()
        .then((res) => {
          context.commit("SET_DAVICH_NUMBERS", res.data);
        })
        .catch((err) => console.log(err));
    },
  },



};
