<template>
  <div class="search-comp">
    <input
      type="text"
      placeholder="검색어를 입력하세요"
      v-model="searchText"
      v-on:keypress.enter="searchContents()"
    />
    <b-button @click="searchContents()"
      ><img
        src="https://img.davich.com/image/sub/icon-search.png"
        alt="search icon"
    /></b-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchText: "",
    };
  },
  props: ["mutationName", "boardData"],
  methods: {
    searchContents() {
      const mutationName = this.mutationName;
      const boardData = this.boardData;
      let searchText = this.searchText;

      if (searchText.length != 0) {
        this.$store.commit(mutationName, [boardData, searchText]);
        this.$emit("switchVar", true);
      } else {
        this.$emit("switchVar", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.search-comp {
  position: relative;
  width: 370px;
  height: 40px;
  border-bottom: 1px solid #dedede;
  input {
    width: 340px;
    @include font-main;
    height: 40px;
    padding-right: 50px;

    &::placeholder {
      color: #ccc;
    }
  }
  .btn-secondary {
    padding: 0;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
</style>
