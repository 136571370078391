<template>
  <div class="list-by-year scroll-fade-in-f scroll-delay-1 ly-cont-m">
    <div class="open-year">
      <!-- 연혁 년도 각각 표시하는 부분 -->
      {{ year }}년
    </div>
    <div>
      <p v-for="item in data" :key="item.index">
        <!-- 월,오픈위치 각각 표시하는 부분 -->
        <span class="open-month">{{ item.month }}월</span>
        <span>
          <span class="open-date" v-for="(location, index) in item.location" :key="index"> {{ location }} &nbsp;</span>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["franchiseData"],
  data() {
    return {
      year: 0,
      data: [],
    };
  },
  created() {
    this.year = this.franchiseData.year;
    this.data = this.franchiseData.data;
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.list-by-year {
  .open-year {
    font-size: 20px;
    line-height: 60px;
    letter-spacing: -0.6px;
    font-weight: bold;
    margin-bottom: 40px;
    text-align: center;
  }
  p {
    display: flex;
    line-height: 35px;
    letter-spacing: 0.48px;
    margin-bottom: 38px;
    .open-month {
      font-weight: bold;
      margin-right: 10px;
      min-width: 37px;
      text-align: right;
    }
  }
}
</style>
