<template>
  <div>
    <sub-title :title="title" :content="content" :content2="content2">
    </sub-title>
    <div class="show-brand">
      <div class="product-ttl">
        <h2>
          Hope To Someone<br />
          Eyewear
        </h2>
        <div class="grayLine"></div>
        <div class="darkGray"></div>
        <div class="grayLine2"></div>
      </div>
      <div>
        <show-Product :companyData="getGlassesFrame[0]"></show-Product>
      </div>
      <div class="product-ttl">
        <h2>
          DAVICH<br />
          EYEWEAR
        </h2>
        <div class="grayLine"></div>
        <div class="darkGray"></div>
        <div class="grayLine2"></div>
      </div>
      <div v-for="(item, index) in getGlassesFrame" :key="item.id">
        <show-Product v-if="index > 0" :companyData="item"></show-Product>
      </div>
    </div>
  </div>
</template>

<script>
import SubTitle from "../../../components/mobile/MSubTitle1.vue";
import ShowProduct from "../../../components/mobile/PRODUCTS/MShowProduct.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    SubTitle,
    ShowProduct,
  },
  data() {
    return {
      // sub title
      title: "안경테 & 선글라스",
      content: "다비치 브랜드에는 신뢰, 조율, 공감의",
      content2: "핵심가치가 담겨 있습니다.",
      // background text
      text: "BRAND",
    };
  },

  computed: {
    ...mapGetters("products", ["getGlassesFrame"]),
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.sub-title {
  background-image: url("https://img.davich.com/mobile/image/sub/subtitle/sub-product-gl-bg.png");
  background-size: cover;
}

.show-brand {
  padding: 40px 0 80px 0;
  .product-ttl {
    position: relative;
    h2 {
      font-size: 24px;
      font-family: $en-font;
      font-weight: 700;
      text-align: center;
      margin-top: 60px;
      text-transform: uppercase;
      line-height: 30px;
    }
    .grayLine {
      width: 25vw;
      border: 1px solid #ddd;
      display: flex;
      position: absolute;
      top: 70px;
    }
    .darkGray {
      width: 50vw;
      border: 1px solid #aaa;
      display: flex;
      position: absolute;
      top: 70px;
      left: 25vw;
    }
    .grayLine2 {
      width: 25vw;
      border: 1px solid #ddd;
      display: flex;
      position: absolute;
      top: 70px;
      left: 75vw;
    }
  }
}
</style>
