<template>
  <div class="franchise">
    <sub-title :title="title" :content="content" :content2="content2">
    </sub-title>

    <div class="franchise-inner">
      <div class="new-store">
        <h2 class="scroll-fade-in-f">신규오픈</h2>
        <div class="store-items">
          <div
            class="store-item scroll-fade-in-f scroll-delay-1"
            v-for="item in getNewOpen"
            :key="item.id"
          >
            <img
              src="https://img.davich.com/mobile/image/main/img-grand-open-m.png"
              alt="grand open bg"
            />
            <div class="store-desc">
              <h3>{{ item.name }}</h3>
              <p>{{ item.openDate }}</p>
            </div>
          </div>
        </div>
      </div>
      <!--/.new-store-->
      <div class="store-open-year">
        <h2 class="scroll-fade-in-f">연혁별 오픈</h2>
        <div class="store-open-year-cont">
          <div v-for="item in getOpened" :key="item.index">
            <list-by-year :franchiseData="item"></list-by-year>
          </div>
        </div>
      </div>
      <!--/.store-open-year-->
    </div>
  </div>
</template>

<script>
import ListByYear from "../../../components/mobile/PARTNERSHIP/MListByYear.vue";

import SubTitle from "../../../components/mobile/MSubTitle1.vue";

import { mapGetters } from "vuex";

export default {
  beforeCreate() {
    this.$store.dispatch("partnership/FETCH_OPENED");
  },

  components: {
    ListByYear,

    SubTitle,
  },
  data() {
    return {
      //sub title
      title: "가맹점 현황",
      content: "우리지역 1등 매장! ",
      content2: "No.1 브랜드 다비치와 함께하세요.",
      // background text
      text: "HISTORY",
    };
  },

  computed: {
    ...mapGetters("partnership", ["getOpened", "getNewOpen"]),
  },

  mounted() {
    // 우선 특정한 위치를 구한다
    // 그 위치에 오면 그때부터
    var sct = window.scrollY;
    window.addEventListener("scroll", function () {
      if (window.scrollY == 100) {
        var bgTxt = this.document.querySelector(".span1");
        bgTxt.style.transform = "translateX(" + sct - (100 - 1) + "px)";
      }
    });

    // 신규오픈매장 글자 사이즈 변경
    // let slide = document.querySelector('.store-slide h3');
    // if( slide.length)
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.franchise {
  .sub-title {
    background-image: url("https://img.davich.com/mobile/image/sub/subtitle/sub-partnership-bg.png");
    background-size: cover;
  }

  .franchise-inner {
    position: relative;
    padding: 70px 0 100px 0;
    h2 {
      @include font-h-30;
      margin-bottom: 40px;
      text-align: center;
    }
    .new-store {
      .store-items {
        .store-item {
          position: relative;
          width: 100%;
          height: 206px;
          margin-bottom: 10px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .store-desc {
            color: $color-white;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 63vw;
            max-width: 33vw;
            font-weight: 700;
            h3 {
              font-size: 25px;
              margin-bottom: 10px;
              line-height: 31px;
              &:last-child {
                margin-bottom: 0;
              }
            }
            p {
              font-size: 20px;
            }
          }
        }
      }
    }
    .store-open-year {
      padding-top: 100px;
    }
  }
}
</style>
