<template>
  <div>
    <main class="contribution ly-wrap">
      <section class="contribution-main">
        <div class="contribution-desc">
          <div class="main-txt">
            <h2 class="scroll-fade-in-f">눈건강 시력 지킴이</h2>
            <p class="scroll-fade-in-f scroll-delay-1">
              눈건강 시력 지킴이는 소외된 어려운 이웃까지 맑고 밝게 비춰주는 봉사단으로 전국 사회봉사시설을 방문해 시력검사 및 돋보기, 교정용 안경을 무료로
              제공합니다.<br />
              다비치는 모두가 함께하는 더 나은 사회를 만들기 위해 기여하겠습니다.
            </p>
            <router-link class="el-btn-m scroll-fade-in-f scroll-delay-2" to="/about/m_contributionPostList">히스토리 보기​​</router-link>
          </div>
          <div class="main-counting">
            <div class="counting-volun">
              <span><div>봉사활동</div></span>
              <p><em>{{this.getDavichNumbers.volunteer_count}}</em>&nbsp;회</p>
            </div>
            <div class="counting-volun2">
              <span><div>시력검사 및 안경기부</div></span>
              <p><em>{{this.getDavichNumbers.glasses_give_count}}</em>&nbsp;명</p>
            </div>
          </div>
        </div>
        <div class="contribution-items ly-cont-m" v-bind="listToShow">
          <router-link :to="`/about/m_contributionPostDetail/${listToShow[0].id}`" class="contribution-item-l">
            <img :src="listToShow[0].main_image" alt="이미지" />
            <p v-html="listToShow[0].title"></p>
          </router-link>
          <router-link :to="`/about/m_contributionPostDetail/${listToShow[2].id}`" class="contribution-item-r">
            <img :src="listToShow[2].main_image" alt="이미지" />
            <p v-html="listToShow[2].title"></p>
          </router-link>
        </div>
        <div class="contribution-items ly-cont-m" v-bind="listToShow">
          <router-link :to="`/about/m_contributionPostDetail/${listToShow[1].id}`" class="contribution-item-l">
            <img :src="listToShow[1].main_image" alt="이미지" />
            <p v-html="listToShow[1].title"></p>
          </router-link>
          <router-link :to="`/about/m_contributionPostDetail/${listToShow[3].id}`" class="contribution-item-r">
            <img :src="listToShow[3].main_image" alt="이미지" />
            <p v-html="listToShow[3].title"></p>
          </router-link>
        </div>
      </section>
      <!--눈건강시력지킴이-->
      <section class="contribution-volun">
        <div class="contribution-img-wrap">
          <div class="contribution-img-inner">
            <div class="contribution-img-bg">
              <img src="https://img.davich.com/mobile/image/sub/about/img-contribution.png" alt="bg blue" />
            </div>
            <div class="contribution-img">
              <img src="https://img.davich.com/mobile/image/sub/about/img-contribution-02.png" alt="heart pic" />
            </div>
          </div>
        </div>
        <div class="contribution-txt">
          <h2>해외봉사</h2>
          <p>
            눈건강 시력 지킴이는 베트남과 캄보디아에서 봉사활동을 하고 있습니다.<br />
            전국 다비치안경을 통해 모아진 헌 안경은 어려운 국가의 이웃들에게 전달하고 있습니다.
          </p>
        </div>
      </section>

      <!--캠페인-->
      <section class="contribution-campaign">
        <div class="campaign-item">
          <span class="slogan-1">봉사</span>
          <span class="slogan-2">배려</span>
          <span class="slogan-3">미래</span>
        </div>
        <div>
          <div class="campaign-item-show">
            <div class="campaign-ttl ly-cont-m">
              <h2>BIBIEM</h2>
              <p>
                봉사, 배려, 미래를 뜻하는 안경테 ‘비비엠’ 라인은 판매 제품 한 개당 한 개씩 전 세계의 안경이 필요한 소외된 이웃에게 기부하는 사회공헌
                캠페인입니다.
              </p>
            </div>
          </div>
        </div>
      </section>
      <!--캠페인-->
      <span class="empty"></span>
    </main>

    <!-- <contribution-post></contribution-post> -->
  </div>
</template>

<script>
import ContributionPost from "../../../components/mobile/ABOUT/MContributionPost.vue";

import { mapGetters } from "vuex";

export default {
  beforeCreate() {
    this.$store.dispatch("FETCH_CONTRIBUTION_BOARD");
    this.$store.dispatch("FETCH_DAVICH_NUMBERS");
  },
  components: {
    ContributionPost,
  },

  computed: {
    ...mapGetters("about", ["getBoardData", "getDavichNumbers"]),

    listToShow() {
      return this.getBoardData.slice(0, 4);
    },
  },
  methods: {
    fixed() {
      var campaign = document.querySelector(".contribution-campaign");
      var campaignH = campaign.getBoundingClientRect().top;
      var contributionVolun = document.querySelector(".contribution-volun"); // 형제 요소
      var campaignTop = window.pageYOffset + contributionVolun.getBoundingClientRect().top + contributionVolun.offsetHeight;
      if (campaignH < 10 && !campaign.classList.contains("animation")) {
        campaign.classList.add("fixed");

        setTimeout(function () {
          campaign.classList.add("animation"); //애니메이션 추가
        }, 500);
        setTimeout(function () {
          window.scrollTo(0, campaignTop); // 위치이동(하드코딩...)
        }, 800);
        setTimeout(function () {
          campaign.classList.remove("fixed"); //fixed 해제
          let empty = document.querySelector(".empty"); //빈공간 삭제
          empty.style.height = "0";
        }, 1000);
      }
    },
  },
  mounted() {
    //특정위치에 가면 fixed된다
    window.addEventListener("scroll", this.fixed);
  },
  destroyed() {
    window.removeEventListener("scroll", this.fixed);
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.contribution {
  .contribution-main {
    .contribution-desc {
      width: 100vw;
      .main-txt {
        h2 {
          font-size: 30px;
          padding-top: 45px;
          text-align: center;
          font-weight: 700;
          padding-bottom: 35px;
        }
        p {
          width: 85vw;
          text-align: left;
          margin: 0px auto;
          line-height: 28px;
          letter-spacing: -0.75px;
        }
        .el-btn-m {
          width: 85vw;
          display: block;
          margin: 40px auto;
          // font-size: 17px;
        }
      }
      .main-counting {
        .counting-volun {
          span {
            display: block;
            text-align: center;
            line-height: 30px;
            margin-top: 50px;
          }
          em {
            color: #6cc24a;
            font-size: 50px;
            font-weight: 800;
            text-align: center;
            font-family: $en-font;
          }
          p {
            color: #6cc24a;
            font-size: 18px;
            font-weight: 800;
            text-align: center;
          }
        }
        .counting-volun2 {
          display: block;
          padding-bottom: 20px;
          span {
            display: block;
            text-align: center;
            line-height: 30px;
            margin-top: 20px;
          }
          em {
            color: $color-skyblue;
            font-size: 50px;
            font-weight: 800;
            text-align: center;
            font-family: $en-font;
          }
          p {
            color: $color-skyblue;
            font-size: 18px;
            font-weight: 800;
            text-align: center;
          }
        }
      }
    }
  }
  .contribution-items {
    width: 85vw;
    margin-top: 60px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    height: 100%;
    .contribution-item-l {
      flex: 1;
      width: 40vw;
      margin-right: 5vw;
      img {
        width: 100%;
        height: 40vw;
        object-fit: cover;
      }
      p {
        line-height: 24px;
        font-size: 16px;
        letter-spacing: -0.75px;
        //3줄이상 ... 처리
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin: 15px 0 10px 0;
      }
    }
    .contribution-item-r {
      flex: 1;
      margin-right: 0vw;
      width: 40vw;
      img {
        width: 100%;
        height: 40vw;
        object-fit: cover;
      }
      p {
        line-height: 24px;
        font-size: 16px;
        letter-spacing: -0.75px;
        //3줄이상 ... 처리
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin: 15px 0 10px 0;
      }
    }
  }
  .contribution-volun {
    padding-bottom: 100px;
    .contribution-img-wrap {
      margin-top: 130px;
      height: 340px;
      position: relative;
      overflow: hidden;
      .contribution-img-inner {
        width: 825px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        .contribution-img-bg {
          position: absolute;
          top: 0;
          left: 0px;
          z-index: -12;
          height: 324px;
        }
        .contribution-img {
          position: absolute;
          top: 22px;
          right: 0px;
          z-index: 25;
          height: 324px;
        }
        .contribution-img-bg img {
          height: 100%;
        }
        .contribution-img img {
          height: 100%;
        }
      }
    }
    .contribution-txt {
      h2 {
        font-size: 30px;
        padding-top: 45px;
        text-align: center;
        font-weight: 700;
        padding-bottom: 35px;
      }
      p {
        width: 85vw;
        text-align: left;
        margin: 0px auto;
        line-height: 28px;
        letter-spacing: -0.75px;
      }
    }
  }
}

.empty {
  height: 300vh;
  width: 100%;
  display: block;
}
.contribution-campaign {
  width: 100%;
  background-color: $color-white;
  position: relative;
  top: 0;
  height: 100vh;
  overflow: hidden;
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  }
  &.animation {
    .slogan-1 {
      // 정중앙으로 위치
      // 위치하면서 사라지기
      animation: circleMove 0.8s linear both;
    }
    .slogan-2 {
      animation: circleMove 0.8s linear both;
    }
    .slogan-3 {
      animation: circleMove 0.8s linear both;
    }
    .campaign-item-show {
      opacity: 1;
      .campaign-ttl {
        h2 {
          animation: downtoup-f 0.6s linear both;
          animation-delay: 0.8s;
        }
        p {
          width: 85vw;
          animation: downtoup-f 0.6s linear both;
          animation-delay: 1.2s;
        }
      }
    }
  }
  .campaign-item {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    span {
      position: absolute;
      width: 247px;
      height: 247px;
      text-align: center;
      line-height: 247px;
      font-size: 20px;
      border: 1px dashed $color-black;
      border-radius: 50%;
      &.slogan-1 {
        left: -200px;
      }
      &.slogan-2 {
        left: calc(50% - 123.5px);
      }
      &.slogan-3 {
        left: calc(100% - 47px);
      }
    }
  }
  .campaign-item-show {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    background: center/cover no-repeat url("https://img.davich.com/mobile/image/sub/about/img-contribution-03.png");
    transition: 2s;
    transition-delay: 1s;
    .campaign-ttl {
      text-align: center;
      h2 {
        font-family: $en-font;
        font-size: 35px;
        line-height: 70px;
        letter-spacing: -1.5px;
        margin-bottom: 10px;
        font-weight: 800;
      }
      p {
        @include font-main-m;
        margin-bottom: 60px;
        width: 550px;
        letter-spacing: -0.54px;
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes circleMove {
  0% {
    opacity: 1;
  }
  100% {
    left: calc(50% - 123.5px);
    opacity: 0;
  }
}
</style>
