<template>
  <div>
    <div class="find-result-items">
      <div class="find-result-item" v-bind="mapDataItem">
        <a href="#" v-on:click="moveLocation(mapDataItem)"
          ><h3>{{ mapDataItem.name }}</h3>
          <div class="classify-items">
            <em class="classify-item" v-for="(tag, index) in mapDataItem.tag" :key="index">
              <span class="icon" v-if="tag.includes('wifi')">와이파이</span>
              <span class="icon" v-if="tag.includes('parking')">주차장</span>
              <span class="icon" v-if="tag.includes('earaid')">보청기</span>
              <span class="icon" v-if="tag.includes('lens')">렌즈샵</span>
              <span class="icon" v-if="tag.includes('fitting')">누진피팅센터</span>
            </em>
          </div>
          <div class="find-result-item-desc">
            <p>
              <span>
                {{ mapDataItem.addr }}
              </span>
            </p>
            <p>{{ mapDataItem.tel }}</p>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["mapDataItem"],

  data() {
    return {
      iconSrc: "https://img.davich.com/image/sub/support/map/icon-store-all.png",
    };
  },

  methods: {
    moveLocation(mapDataItem) {
      this.$emit("moveLocation", mapDataItem);
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";
.m-find-store {
  // 분류결과
  .find-result-items {
    flex: 1;
    @include scroll-bar;
    .find-result-item {
      border-bottom: 1px solid #dedede;

      > a {
        display: block;
        width: 100%;
        padding: 20px;
        h3 {
          font-weight: bold;
          font-size: 20px;
          line-height: 35px;
          letter-spacing: 0.6px;
          display: inline-block;
          margin-right: 15px;
        }
        .find-result-item-desc {
          p {
            font-size: 14px;
            line-height: 28px;
            color: #777;
          }
          button {
            display: inline-block;
            width: 50px;
            height: 30px;
            border: 1px solid #dedede;
            font-size: 13px;
            line-height: 30px;
            color: #777;
            border-radius: 15px;
          }
        }
      }
    }
  }

  // 분류
  .classify-items {
    margin-bottom: 10px;
    font-size: 14px;
    display: inline-block;
    .classify-item {
      display: inline-block;
      margin-right: 20px;
      position: relative;
      color: #aaa;
      align-items: center;

      &:before {
        content: "";
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        right: -10px;
        background-color: #aaa;
      }
      &:last-child:before {
        display: none;
      }
    }
  }
}
</style>
