<template>
  <div class="find-store ly-wrap ly-w1400">
    <main class="find-store-cont">
      <section class="find-section">
        <h2 class="hidden">매장찾기 검색</h2>
        <ul class="find-btns">
          <li class="find-btn active">
            <button v-on:click="closeDetail()">주변에서 찾기</button>
          </li>
          <li class="find-btn">
            <button v-on:click="closeDetail()">검색해서 찾기</button>
          </li>
        </ul>
        <!--./find button-->
        <div class="find-items">
          <div class="find-item nearby display">
            <ul class="classify-btns">
              <li class="classify-btn">
                <button v-on:click="filterStoreNearby(0)">와이파이</button>
              </li>
              <li class="classify-btn">
                <button v-on:click="filterStoreNearby(1)">주차장</button>
              </li>
              <li class="classify-btn">
                <button v-on:click="filterStoreNearby(2)">보청기</button>
              </li>
            </ul>
            <!--./classify tab button-->
            <div class="find-result-comment">
              <p>
                <em
                  >총 &nbsp;<span v-bind="listToShowNearby">{{ listToShowNearby.length }}</span
                  >개</em
                >의 매장이 검색되었습니다.
              </p>
            </div>
            <!--./find result comment-->

            <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ ShowStore 컴포넌트 -->
            <div class="find-result-items">
              <show-store
                v-for="(item, index) in listToShowNearby"
                :key="index"
                :mapDataItem="item"
                v-on:moveLocation="moveLocation"
                v-on:click.native="showDetail(item, index, true)"
              ></show-store>
            </div>
          </div>

          <!-- ===========================================검색해서 찾기================================================= -->
          <!--./find-item(01 nearby)-->
          <div class="find-item search">
            <p class="search-input">
              <label class="hidden" for="search-store">검색해서 찾기</label>
              <input
                v-model="searchText"
                id="search-store"
                type="search"
                placeholder="주소 또는 매장명으로 검색해보세요."
                v-on:keypress.enter="searchStore()"
              /><img
                src="https://img.davich.com/image/sub/icon-search.png"
                alt="search icon"
                v-on:click="searchStore()"
              />
            </p>
            <ul class="classify-btns">
              <li class="classify-btn2">
                <button v-on:click="filterStoreSearch(0)">와이파이</button>
              </li>
              <li class="classify-btn2">
                <button v-on:click="filterStoreSearch(1)">주차장</button>
              </li>
              <li class="classify-btn2">
                <button v-on:click="filterStoreSearch(2)">보청기</button>
              </li>
            </ul>
            <!--./classify tab button-->
            <div class="find-result-comment">
              <p>
                <em
                  >총 &nbsp;<span v-bind="listToShowSearch">{{ listToShowSearch.length }}</span
                  >개</em
                >의 매장이 검색되었습니다.
              </p>
            </div>
            <!--./find result comment-->
            <div class="find-result-items">
              <show-store
                v-for="(item, index) in listToShowSearch"
                :key="index"
                :mapDataItem="item"
                v-on:moveLocation="moveLocation"
                v-on:click.native="showDetail(item, index, false)"
              ></show-store>
            </div>
          </div>
        </div>
      </section>
      <section class="map-section" id="map">
        <button class="cur-location-btn" @click="moveCurrent">
          <img src="https://img.davich.com/image/sub/support/map/icon-map.png" alt="current location button" />
        </button>
      </section>

      <!-- ==================================@@매장정보 디테일@@====================================== -->
      <aside class="detail-section out" id="detail-section">
        <button class="detail-btn">
          <img src="https://img.davich.com/image/sub/icon-arrow-up.png" alt="arrow btn" />
        </button>
        <detail-store :storeProps="storeProps"></detail-store>
      </aside>
    </main>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "../../api/axios-auth";

import ShowStore from "../../components/SUPPORT/ShowStore.vue";
import DetailStore from "../../components/SUPPORT/DetailStore.vue";

export default {
  components: { ShowStore, DetailStore },

  data() {
    return {
      initLat: 37.5544678, //다비치 본사 lat,위치동의 시 현재위치
      initLng: 126.9673742, //다비치 본사 lng, 위치동의 시 현재위치
      nowLat: null,
      nowLng: null,
      map: {},
      mapMarker: {},
      searchText: "",

      mapData: [],

      dataForNearby: [], //주변검색완료된 데이터(주변에서찾기)
      listToShowNearby: [], //화면에 뿌려줄 데이터(주변에서찾기)
      filterWordNearby: [0, 0, 0], //필터링 단어(주변에서찾기) wifi,parking,earaid순

      dataForSearch: [], //주소 or 매장명 검색완료된 데이터(검색해서찾기)
      listToShowSearch: [], // 화면에 뿌려줄 데이터(검색해서찾기)
      filterWordSearch: [0, 0, 0], //필터링 단어(검색해서찾기) wifi,parking,earaid순

      storeProps: "",

      markers: [],

      defaultIcon: "https://img.davich.com/image/sub/support/map/icon-sub-marker.png",
      activeIcon: "https://img.davich.com/image/sub/support/map/icon-main-marker.png",
      activeMarker: null,
    };
  },

  created() {
    const self = this;
    // self.mapData = this.$store.state.support.storeData;

    axios.get(`/support/findstore`).then((res) => {
      let resData = res.data;
      let result = [];

      for (let store of resData) {
        let obj = {};

        obj.id = store.id;
        obj.name = store.str_name;
        obj.lat = store.latitude;
        obj.lng = store.longitude;
        obj.addr = `${store.addr1} ${store.addr2}`;
        obj.tel = store.tel_no;
        obj.tag = [];
        if (store.wifi_yn == "Y") {
          obj.tag.push("wifi");
        }
        if (store.parking_yn == "Y") {
          obj.tag.push("parking");
        }
        if (store.hearing_aid == "Y") {
          obj.tag.push("earaid");
        }
        obj.blogUrl = store.blog_add;
        obj.kakaoUrl = store.kakao_add;
        obj.locationInfo = store.location_information;

        //매장 이미지
        obj.imgSrc = [];
        if (store.store_image_masters != "" || store.store_image_masters.length != 0) {
          for (let imgAttc of store.store_image_masters) {
            obj.imgSrc.push(imgAttc.store_photo_url);
          }
        }

        //제휴할인
        if (store.store_affiliate_masters != "" || store.store_affiliate_masters.length != 0) {
          obj.discount = store.store_affiliate_masters;
        }

        if (store.end_ind == 1) {
          result.push(obj);
        }
      }

      self.mapData = result;

      //주변에서 찾기 데이터 초기화
      self.listToShowNearby = self.dataForNearby = self.mapData;
      //검색해서 찾기 데이터 초기화
      self.listToShowSearch = self.dataForSearch = self.mapData;
      self.initMap(false);

      //현재위치 조회
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {
          //위치 동의시 현재위치 초기화
          self.initLat = position.coords.latitude;
          self.initLng = position.coords.longitude;
          //현재위치 마커찍기위해서 저장
          self.nowLat = position.coords.latitude;
          self.nowLng = position.coords.longitude;

          self.getNearbyStore(position.coords.latitude, position.coords.longitude);
        }
      });
    });
  },

  mounted() {
    // tab button
    function tabButton() {
      const findBtn = document.querySelectorAll(".find-btn");
      findBtn[0].classList.add("active");
      const findCont = document.querySelectorAll(".find-item");
      for (let i = 0; i < findBtn.length; i++) {
        findBtn[i].addEventListener("click", function () {
          // button color
          for (let i = 0; i < findBtn.length; i++) {
            findBtn[i].classList.remove("active");
          }
          findBtn[i].classList.add("active");
          // gift card show
          for (let i = 0; i < findCont.length; i++) {
            findCont[i].classList.remove("display");
          }
          findCont[i].classList.add("display");
        });
      }
    }
    tabButton();

    let detailSection = document.querySelector(".detail-section");

    // marker
    function markerClk() {
      let marker = document.querySelectorAll(".marker");
      for (var i = 0; i < marker.length; i++) {
        marker[i].addEventListener("click", function () {
          detailSection.classList.remove("out");
          //클릭하면 해당 데이터 가지고 올수 있도록!
        });
      }
    }
    markerClk();

    // show store detail
    function showStoreDetail() {
      let storeName = document.querySelectorAll(".find-result-item"); //ShowStore 컴포넌트의 요소임
      for (var i = 0; i < storeName.length; i++) {
        storeName[i].addEventListener("click", function (e) {
          //클릭하면 해당 데이터 보이게
          detailSection.classList.remove("out");
          //색상초기화
          for (var z = 0; z < storeName.length; z++) {
            storeName[z].style.backgroundColor = "white";
          }
          //색상변경
          this.style.backgroundColor = "#f0f8ff";
        });
      }
    }
    showStoreDetail();

    // detail toggle button
    function detailToggleBtn() {
      let detailBtn = document.querySelector(".detail-btn");
      detailBtn.addEventListener("click", function () {
        detailSection.classList.toggle("out");
      });
    }
    detailToggleBtn();
  },

  updated() {
    // detail info img 작은이미지 클릭 시 이미지변경
    let mainImg = document.querySelector(".main-img");
    let subImg = document.querySelectorAll(".sub-img");
    for (var i = 0; i < subImg.length; i++) {
      subImg[i].addEventListener("click", function () {
        let imgLink = this.src;
        mainImg.src = imgLink;
      });
    }
  },

  methods: {
    initMap(isAgree) {
      const self = this;
      const mapZone = document.getElementById("map");
      let lat = self.initLat;
      let lng = self.initLng;
      const coord = new naver.maps.LatLng(lat, lng);

      let name = self.dataForNearby[0].name;

      let map = new naver.maps.Map(mapZone, {
        center: coord,
        zoom: 17,
      });

      let markerOpt = {};
      if (isAgree == false) {
        markerOpt = {
          position: coord,
          map: map,
          icon: {
            content: `<div class="marker"><div class="marker-txt" >다비치 안경체인 본사</div><img src="https://img.davich.com/image/sub/support/map/icon-main-marker.png" alt=""  style="margin: 0px; padding: 0px; border: 0px solid transparent; display: block; max-width: none; max-height: none; -webkit-user-select: none; position: absolute; width: 45px; height: 45px; left: 0px; top: 0px;""><div>`,
            size: new naver.maps.Size(45, 45),
          },
        };
      } else {
        markerOpt = {
          position: coord,
          map: map,
          icon: {
            content: `<div class="marker"><div class="marker-txt" >${name}</div><img src="https://img.davich.com/image/sub/support/map/icon-main-marker.png" alt=""  style="margin: 0px; padding: 0px; border: 0px solid transparent; display: block; max-width: none; max-height: none; -webkit-user-select: none; position: absolute; width: 45px; height: 45px; left: 0px; top: 0px;""><div>`,
            size: new naver.maps.Size(45, 45),
          },
        };
      }

      let mapMarker = new naver.maps.Marker(markerOpt);

      //현재위치에 마커
      new naver.maps.Marker({
        position: new naver.maps.LatLng(self.nowLat, self.nowLng),
        map: map,
        icon: "https://img.davich.com/image/sub/support/map/icon_gps.png",
      });

      this.map = map;
      this.mapMarker = mapMarker;

      //전체 마커찍기
      for (let [index, item] of self.mapData.entries()) {
        let marker = new naver.maps.Marker({
          position: new naver.maps.LatLng(item.lat, item.lng),
          map: self.map,
          clickable: true,
          icon: {
            content: `<div class="marker"><div class="marker-txt">${item.name}</div><img src=${self.defaultIcon} alt=""  style="margin: 0px; padding: 0px; border: 0px solid transparent; display: block; max-width: none; max-height: none; -webkit-user-select: none; position: absolute; width: 45px; height: 45px; left: 0px; top: 0px;""><div>`,
            size: new naver.maps.Size(45, 45),
          },
        });
        marker.addListener("click", self.showDetailForMarker(index, marker));
        //데이터에 멤버로 마커넣기
        item.markerObj = marker;
      }
    },

    moveLocation(mapDataItem) {
      const self = this;
      const map = self.map;
      let newPosition = new naver.maps.LatLng(mapDataItem.lat, mapDataItem.lng);

      map.setCenter(newPosition);
    },

    //주변에서 찾기
    getNearbyStore(nowLat, nowLng) {
      const self = this;
      let mapData = self.mapData;
      let dataForNearby = [];
      const mapMarker = self.mapMarker;

      //현재위치와 다비치 매장들간의 거리 구함
      if (self.mapData.length != 0) {
        for (let item of mapData) {
          let latStore = item.lat;
          let lngStore = item.lng;

          let distanceFromNow = Math.sqrt(Math.pow(latStore - nowLat, 2) + Math.pow(lngStore - nowLng, 2));

          item.distanceFromNow = distanceFromNow;

          if (distanceFromNow < 0.1) {
            dataForNearby.push(item);
          }
        }
      }

      //거리가 가까운 순서대로 정렬
      dataForNearby.sort(function (a, b) {
        if (a.distanceFromNow > b.distanceFromNow) {
          return 1;
        }
        if (a.distanceFromNow < b.distanceFromNow) {
          return -1;
        }
        return 0;
      });

      self.initLat = dataForNearby[0].lat;
      self.initLng = dataForNearby[0].lng;

      let setData = new Promise(function (resolve, reject) {
        self.listToShowNearby = self.dataForNearby = dataForNearby;
      });

      setData.then(self.initMap(true)).catch((err) => {
        console.log(err);
      });

      self.storeProps = dataForNearby[0];
    },

    //마커 클릭 시 디테일 보여줌
    showDetailForMarker(index, marker) {
      const self = this;
      let mapData = self.mapData;
      return function (e) {
        const detailSection = document.getElementById("detail-section");
        detailSection.classList.remove("out");
        self.storeProps = mapData[index];

        //이미 활성화된 마커가 있다면 비활성화
        if (self.activeMarker != null) {
          let activeMarkerElement = self.activeMarker.getElement();
          //마커 icon이미지바꾸기
          let img = activeMarkerElement.querySelector("img");
          img.setAttribute("src", self.defaultIcon);
          //마커색 바꾸기
          let elementChild = activeMarkerElement.getElementsByClassName("marker-txt");
          elementChild[0].classList.remove("active");
        }

        let markerElement = marker.getElement();
        //마커이미지바꾸기
        let img = markerElement.querySelector("img");
        img.setAttribute("src", self.activeIcon);
        //마커색 바꾸기
        let elementChild = markerElement.getElementsByClassName("marker-txt");
        elementChild[0].classList.add("active");

        self.activeMarker = marker;
      };
    },

    //검색해서 찾기
    searchStore() {
      const self = this;
      const searchText = self.searchText;
      const mapData = self.mapData;
      let searchedList = [];

      if (searchText != "" && searchText.length != 0) {
        searchedList = mapData.filter((item) => {
          let nameAndAddr = item.name + item.addr;
          return nameAndAddr.includes(searchText);
        });
      } else {
        searchedList = mapData;
      }

      self.listToShowSearch = self.dataForSearch = searchedList;

      self.map.setCenter(new naver.maps.LatLng(searchedList[0].lat, searchedList[0].lng));

      self.closeDetail();
    },

    //'주변에서 찾기' 탭에서 필터링
    filterStoreNearby(filterIndex) {
      const self = this;
      let dataForNearby = self.dataForNearby; //주변에서찾기 탭에서 보여주는 데이터리스트
      let arraySelected = self.filterWordNearby; //필터링할 단어 배열  wifi,parking,earaid순

      const btnClassList = document.getElementsByClassName("classify-btn"); //css적용

      switch (filterIndex) {
        case 0:
          if (arraySelected[0] == "" || arraySelected[0].length == 0) {
            arraySelected[0] = 1;
            btnClassList[0].classList.add("active");
          } else {
            arraySelected[0] = 0;
            btnClassList[0].classList.remove("active");
          }
          break;

        case 1:
          if (arraySelected[1] == "" || arraySelected[1].length == 0) {
            arraySelected[1] = 1;
            btnClassList[1].classList.add("active");
          } else {
            arraySelected[1] = 0;
            btnClassList[1].classList.remove("active");
          }
          break;

        case 2:
          if (arraySelected[2] == "" || arraySelected[2].length == 0) {
            arraySelected[2] = 1;
            btnClassList[2].classList.add("active");
          } else {
            arraySelected[2] = 0;
            btnClassList[2].classList.remove("active");
          }
          break;
      }

      let filteredList = [];
      if (dataForNearby.length != 0) {
        filteredList = dataForNearby.filter((item) => {
          let arrayTag = [0, 0, 0];
          item.tag.map((tag) => {
            if (tag == "wifi") {
              arrayTag[0] = 1;
            }
            if (tag == "parking") {
              arrayTag[1] = 1;
            }
            if (tag == "earaid") {
              arrayTag[2] = 1;
            }
          });

          //사용자가 선택한 필터값이 1인 인덱스 찾기
          let indexSelected = [];
          let pos = 0;
          while (true) {
            let foundPos = arraySelected.indexOf(1, pos);
            if (foundPos == -1) break;

            indexSelected.push(foundPos);
            pos = foundPos + 1;
          }

          if (indexSelected.length == 0) {
            return true;
          } else if (indexSelected.length == 1) {
            return arrayTag[indexSelected[0]];
          } else if (indexSelected.length == 2) {
            return arrayTag[indexSelected[0]] && arrayTag[indexSelected[1]];
          } else if (indexSelected.length == 3) {
            return arrayTag[indexSelected[0]] && arrayTag[indexSelected[1]] && arrayTag[indexSelected[2]];
          }
        });
      }

      if (filteredList.length != 0) {
        self.listToShowNearby = filteredList;
      } else {
        self.listToShowNearby = dataForNearby;
      }
    },

    //'검색해서 찾기' 탭에서 필터링
    filterStoreSearch(filterIndex) {
      const self = this;
      let dataForSearch = self.dataForSearch; //주변에서찾기 탭에서 보여주는 데이터리스트
      let arraySelected = self.filterWordSearch; //필터링할 단어 배열

      const btnClassList = document.getElementsByClassName("classify-btn2"); //css적용

      switch (filterIndex) {
        case 0:
          if (arraySelected[0] == "" || arraySelected[0].length == 0) {
            arraySelected[0] = 1;
            btnClassList[0].classList.add("active");
          } else {
            arraySelected[0] = "";
            btnClassList[0].classList.remove("active");
          }
          break;

        case 1:
          if (arraySelected[1] == "" || arraySelected[1].length == 0) {
            arraySelected[1] = 1;
            btnClassList[1].classList.add("active");
          } else {
            arraySelected[1] = 0;
            btnClassList[1].classList.remove("active");
          }
          break;

        case 2:
          if (arraySelected[2] == "" || arraySelected[2].length == 0) {
            arraySelected[2] = 1;
            btnClassList[2].classList.add("active");
          } else {
            arraySelected[2] = 0;
            btnClassList[2].classList.remove("active");
          }
          break;
      }

      let filteredList = [];
      if (dataForSearch.length != 0) {
        filteredList = dataForSearch.filter((item) => {
          let arrayTag = [0, 0, 0];
          item.tag.map((tag) => {
            if (tag == "wifi") {
              arrayTag[0] = 1;
            }
            if (tag == "parking") {
              arrayTag[1] = 1;
            }
            if (tag == "earaid") {
              arrayTag[2] = 1;
            }
          });

          //사용자가 선택한 필터값이 1인 인덱스 찾기
          let indexSelected = [];
          let pos = 0;
          while (true) {
            let foundPos = arraySelected.indexOf(1, pos);
            if (foundPos == -1) break;

            indexSelected.push(foundPos);
            pos = foundPos + 1;
          }

          if (indexSelected.length == 0) {
            return true;
          } else if (indexSelected.length == 1) {
            return arrayTag[indexSelected[0]];
          } else if (indexSelected.length == 2) {
            return arrayTag[indexSelected[0]] && arrayTag[indexSelected[1]];
          } else if (indexSelected.length == 3) {
            return arrayTag[indexSelected[0]] && arrayTag[indexSelected[1]] && arrayTag[indexSelected[2]];
          }
        });
      }

      if (filteredList.length != 0) {
        self.listToShowSearch = filteredList;
      } else {
        self.listToShowSearch = dataForSearch;
      }
    },

    showDetail(item, index, isNearby) {
      const self = this;

      const marker = item.markerObj;

      //이미 활성화된 마커가 있다면 비활성화
      if (self.activeMarker != null) {
        let activeMarkerElement = self.activeMarker.getElement();
        //마커 icon이미지바꾸기
        let img = activeMarkerElement.querySelector("img");
        img.setAttribute("src", self.defaultIcon);
        //마커색 바꾸기
        let elementChild = activeMarkerElement.getElementsByClassName("marker-txt");
        elementChild[0].classList.remove("active");
      }

      let markerElement = marker.getElement();
      //마커이미지바꾸기
      let img = markerElement.querySelector("img");
      img.setAttribute("src", self.activeIcon);
      //마커색 바꾸기
      let elementChild = markerElement.getElementsByClassName("marker-txt");
      elementChild[0].classList.add("active");

      self.activeMarker = marker;

      //데이터 토글
      if (isNearby == true) {
        self.storeProps = self.listToShowNearby[index];
        //현재 버튼 색상 원래대로
        let currentBtn = document.querySelector(".cur-location-btn img");
        currentBtn.classList.remove("checked");
      } else {
        self.storeProps = self.listToShowSearch[index];
      }
    },

    closeDetail() {
      const detailSection = document.getElementById("detail-section");
      detailSection.classList.add("out");
    },

    //현재위치로 이동
    moveCurrent() {
      const self = this;
      const map = self.map;
      let newPosition = new naver.maps.LatLng(self.nowLat, self.nowLng);
      map.setCenter(newPosition);

      // 현재 버튼 색상 변경
      let currentBtn = document.querySelector(".cur-location-btn img");
      currentBtn.classList.add("checked");
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.marker {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45px;
  height: 45px;

  .marker-txt {
    position: absolute;
    margin-top: -10px;
    box-sizing: content-box;
    text-align: center;
    top: -40px;
    white-space: nowrap;
    min-width: 100px;
    left: calc(50%);
    transform: translateX(-50%);
    padding: 15px 20px;
    background-color: white;
    color: black;
    border-radius: 30px;
    margin-bottom: 10px;
    max-width: none;
    max-height: none;
    box-shadow: 0 2px 5px 0 rgba(34, 34, 34, 0.1);
    font-weight: 500;
    &.active {
      background-color: $color-blue;
      color: $color-white;
    }
  }
}

//전체 레이아웃
.find-store.ly-w1400 {
  width: 100%;
  max-width: 100vw;
  height: 100vh;
  overflow: hidden;
  .find-store-cont {
    width: 100%;
    height: calc(100vh - 80px);
    display: flex;
    //매장찾기 검색
    .find-section {
      width: 500px;
      background-color: $color-white;
      // 주변에서 찾기, 검색해서 찾기
      //버튼
      .find-btns {
        width: 100%;
        border-bottom: 1px solid #dedede;
        .find-btn {
          display: inline-block;
          width: 250px;
          height: 80px;
          line-height: 80px;
          text-align: center;
          &.active button {
            background-color: $color-blue;
            color: $color-white;
          }
          button {
            font-size: 20px;
            font-weight: 500;
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
      // 상세
      .find-items {
        position: relative;

        //공통부분 레이아웃
        height: calc(100% - 80px);
        .find-item {
          height: 100%;

          flex-direction: column;
          display: none;
          &.display {
            display: flex;
          }
          ul {
            padding: 0 20px 0;
          }
          //분류/검색버튼
          .classify-btns {
            padding-top: 19.5px;
            display: flex;
            justify-content: center;
            .classify-btn {
              width: 50%;
              height: 60px;
              text-align: center;
              border-top: 1px solid #dedede;
              border-bottom: 1px solid #dedede;
              border-left: 1px solid #dedede;
              &:last-child {
                border-right: 1px solid #dedede;
              }
              &.active {
                border: 1px solid $color-blue;
                button {
                  color: $color-blue;
                }
              }
              button {
                width: 100%;
                height: 100%;
                font-size: 17px;
                letter-spacing: -0.51px;
                font-weight: 500;
                display: block;
              }
            }
            .classify-btn2 {
              width: 50%;
              height: 60px;
              text-align: center;
              border-top: 1px solid #dedede;
              border-bottom: 1px solid #dedede;
              border-left: 1px solid #dedede;
              &:last-child {
                border-right: 1px solid #dedede;
              }
              &.active {
                border: 1px solid $color-blue;
                button {
                  color: $color-blue;
                }
              }
              button {
                width: 100%;
                height: 100%;
                font-size: 17px;
                letter-spacing: -0.51px;
                font-weight: 500;
                display: block;
              }
            }
          }
          // 총--개
          .find-result-comment {
            padding: 30px 0;
            text-align: center;
            border-bottom: 1px solid #dedede;
            p {
              @include font-main;
              em {
                color: $color-blue;
              }
            }
          }
          //검색해서 찾기
          &.search {
            // position: absolute;
            // top: 0;
            .search-input {
              position: relative;
              background-color: #f4f5f8;
              width: 100%;
              height: 70px;

              background-color: #f4f5f8;
              input {
                display: block;
                width: 100%;
                height: 70px;
                padding: 0 55px 0 20px;
                &::placeholder {
                  color: #c2c2c4;
                  @include font-main;
                }
              }
              img {
                position: absolute;
                bottom: 22px;
                right: 36px;
              }
            }
          }
        }
      }
    }
    // 지도
    .map-section {
      background-color: $color-gray;
      flex: 1;

      .cur-location-btn {
        position: fixed;
        width: 50px;
        height: 50px;
        top: 100px;
        right: 19px;
        z-index: 999999;
        overflow: hidden;
        border: 1px solid #dedede;
        img {
          position: absolute;
          top: 0;
          left: -2px;
          &.checked {
            transform: translateX(-50%);
            border: none;
          }
        }
      }
    }
  }

  // 세부 정보
  .detail-section {
    background-color: $color-white;
    position: fixed;
    top: 80px;
    right: 0;
    z-index: 99999999;
    height: calc(100vh - 80px);
    width: 500px;
    transition: 0.3s;
    &.out {
      right: -500px;
      // transform: translateX(100%);
      .detail-btn {
        img {
          transform: rotate(-90deg);
        }
      }
    }
    // 숨기기 버튼
    .detail-btn {
      position: absolute;
      width: 25px;
      height: 50px;
      background-color: $color-white;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      display: block;
      top: 50%;
      transform: translateY(-50%);
      left: -25px;
      @include box-center-flex;
      transition: 0.4s;
      img {
        transform: rotate(90deg);
      }
    }
  }
}
</style>
