<template>
  <div>
    <sub-title :title="title" :content="content" :content2="content2"> </sub-title>
    <section class="contribution-post-list">
      <h2 class="hidden">눈 건강시력 지킴이 게시글 리스트</h2>
      <search-input
        :mutationName="'about/SEARCH_CONTRIBUTION_BOARD'"
        :boardData="getBoardData"
        v-on:switchVar="switchVar"
      ></search-input>
      <!--/검색창-->
      <div class="container post-list-cont">
        <div class="post-list-items">
          <contribution-post-list class="item" v-for="item in listToShow" :key="item.id" :boardData="item">
          </contribution-post-list>
        </div>
        <!--/게시물 리스트-->
      </div>
      <button id="btnMoreData" class="el-btn-m" @click="getMoreData">더보기</button>

      <b-pagination id="pagination" v-model="currentPage" :per-page="perPage" aria-controls="container"></b-pagination>
      <!--/페이징-->
    </section>
  </div>
</template>

<script>
import SubTitle from "../../../components/mobile/MSubTitle1.vue";
import SearchInput from "../../../components/mobile/MSearchInput.vue";
import ContributionPostList from "../../../components/mobile/ABOUT/MContributionPostList.vue";
import { mapGetters, mapState } from "vuex";

export default {
  beforeCreate() {
    this.$store.dispatch("FETCH_CONTRIBUTION_BOARD");
  },

  components: {
    SubTitle,
    SearchInput,
    ContributionPostList,
  },

  data() {
    return {
      perPage: 6,
      currentPage: 1,

      //subtitle
      title: "눈 건강 시력 지킴이",
      content: "전문성과 신뢰를 바탕으로 한",
      content2: "최고의 안경전문기업, 다비치입니다.",

      // search option

      isSearch: false,
    };
  },

  computed: {
    ...mapGetters("about", ["getBoardData"]),
    ...mapState("about", {
      searchedData: (state) => state.searchedData,
    }),

    listToShow() {
      const self = this;
      let result;
      let boardData = [];
      if (self.isSearch == false) {
        boardData = self.getBoardData;
      } else {
        boardData = self.searchedData;
      }
      result = boardData.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
      return result;
    },
  },

  created() {
    const self = this;
    if (localStorage.perPage) {
      self.perPage = localStorage.perPage * 1;
    }
  },

  watch: {
    perPage() {
      const self = this;
      localStorage.perPage = self.perPage;
    },
  },

  methods: {
    switchVar(param) {
      if (param == true) {
        this.isSearch = true;
      } else {
        this.isSearch = false;
      }
    },

    getMoreData() {
      const btn = document.getElementById("btnMoreData");
      this.perPage += 4;

      //마지막 데이터에서 더보기버튼 숨김
      if (this.perPage >= this.getBoardData.length) {
        btn.style.display = "none";
      }
    },
  },

  //사회공헌 게시판 벗어날 시 로컬스토리지 currentPage 초기화
  beforeRouteLeave(to, from, next) {
    if (to.name == "m_contributionPostDetail") {
      next();
    } else {
      localStorage.perPage = 6;
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.sub-title {
  background-image: url("https://img.davich.com/mobile/image/sub/subtitle/sub-about-bg-03.png");
}
.contribution-post-list {
  margin-bottom: 50px;
  .post-list-cont {
    width: 90vw;
    margin: 30px auto 0;
    .post-list-items {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: row;
      height: 100%;
      margin: 0 auto;
      justify-content: flex-start;
      .item {
        margin-bottom: 50px;
        width: 40vw;
        margin-right: 2.5vw;
        margin-left: 2.5vw;
        min-width: 40vw;
        display: flex;
        flex-direction: column;
        display: block;
      }
    }
  }
}

.el-btn-m {
  margin: 50px 7.5vw;
}

.paging {
  margin-top: 50px;
  margin-bottom: 40px;
  text-align: center;
  .paging-inner {
    .prev img {
      transform: rotate(360deg);
    }
    .next img {
      transform: rotate(360deg);
    }
  }
}

#pagination {
  display: none;
}
</style>
