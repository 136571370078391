import Vue from "vue";
import VueRouter from "vue-router";

import index from "../views/index.vue";

import notFoundPage from "../views/notFoundPage.vue";

//데스크탑
// ABOUT
import greetings from "../views/ABOUT/1_greetings.vue";
import information from "../views/ABOUT/2_information.vue";
import identity from "../views/ABOUT/3_identity.vue";
import contribution from "../views/ABOUT/4_contribution.vue";
import contributionPostList from "../views/ABOUT/4_1_contributionPostList.vue";
import contributionPostDetail from "../views/ABOUT/4_2_contributionPostDetail.vue";
import location from "../views/ABOUT/5_location.vue";

// PRODUCTS
import glasses from "../views/PRODUCTS/1_glasses.vue";
import glassLens from "../views/PRODUCTS/2_glassLens.vue";
import contactLens from "../views/PRODUCTS/3_contactLens.vue";
import hearingAid from "../views/PRODUCTS/4_hearingAid.vue";

// PARTNERSHIP
import franchise from "../views/PARTNERSHIP/1_franchise.vue";
import howto from "../views/PARTNERSHIP/2_howto.vue";
import inquiryFranchise from "../views/PARTNERSHIP/3_inquiryFranchise.vue";
import inquiryPartnership from "../views/PARTNERSHIP/4_inquiryPartnership.vue";

// EDUCATION
import researchCenter from "../views/EDUCATION/1_researchCenter.vue";
import officer from "../views/EDUCATION/2_officer.vue";
import recruit from "../views/EDUCATION/3_recruit.vue";
import recruitDetail from "../views/EDUCATION/3_2_recruitDetail.vue";

// WHATSNEW
import cf from "../views/WHATSNEW/1_cf.vue";
import davichNews from "../views/WHATSNEW/2_davichNews.vue";
import davichNewsDetail from "../views/WHATSNEW/2_2_davichNewsDetail.vue";
import event from "../views/WHATSNEW/3_event.vue";

// SUPPORT
import ask from "../views/SUPPORT/1_ask.vue";
import faq from "../views/SUPPORT/2_faq.vue";
import giftCard from "../views/SUPPORT/3_giftCard.vue";
import findStore from "../views/SUPPORT/4_findStore.vue";

import privacyPolicy from "../views/PRIVACY/1_privacyPolicy.vue";
import emailProtect from "../views/PRIVACY/2_emailProtect.vue";

import VueGtag from "vue-gtag";

Vue.use(VueRouter);

//////////////////데스크탑 라우터
const w_router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
  routes: [
    {
      path: "/",
      name: "index",
      component: index,
      meta: {
        title: "DAVICH",
      },
    },

    //PRIVACY
    {
      path: "/privacy/privacyPolicy",
      name: "privacyPolicy",
      component: privacyPolicy,
      meta: {
        title: "개인정보처리방침 - DAVICH",
      },
    },
    {
      path: "/privacy/emailProtect",
      name: "emailProtect",
      component: emailProtect,
      meta: {
        title: "이메일주소 무단수집거부 - DAVICH",
      },
    },

    // ABOUT
    {
      path: "/about/greetings",
      name: "greetings",
      component: greetings,
    },
    {
      path: "/about/information",
      name: "information",
      component: information,
    },
    {
      path: "/about/identity",
      name: "identity",
      component: identity,
    },
    {
      path: "/about/contribution",
      name: "contribution",
      component: contribution,
    },
    {
      path: "/about/contributionPostList",
      name: "contributionPostList",
      component: contributionPostList,
      props: true,
    },
    {
      path: "/about/contributionPostDetail/:boardid",
      name: "contributionPostDetail",
      component: contributionPostDetail,
      props: true,
    },
    {
      path: "/about/location",
      name: "location",
      component: location,
    },

    // PRODUCTS
    {
      path: "/products/glasses",
      name: "glasses",
      component: glasses,
    },
    {
      path: "/products/glassLens",
      name: "glassLens",
      component: glassLens,
    },
    {
      path: "/products/contactLens",
      name: "contactLens",
      component: contactLens,
    },
    {
      path: "/products/hearingAid",
      name: "hearingAid",
      component: hearingAid,
    },

    // PARTNERSHIP
    {
      path: "/partnership/franchise",
      name: "franchise",
      component: franchise,
    },
    {
      path: "/partnership/howto",
      name: "howto",
      component: howto,
    },
    {
      path: "/partnership/inquiryFranchise",
      name: "inquiryFranchise",
      component: inquiryFranchise,
    },
    {
      path: "/partnership/inquiryPartnership",
      name: "inquiryPartnership",
      component: inquiryPartnership,
    },

    // EDUCATION
    {
      path: "/education/researchCenter",
      name: "researchCenter",
      component: researchCenter,
    },
    {
      path: "/education/officer",
      name: "officer",
      component: officer,
    },
    {
      path: "/education/recruit",
      name: "recruit",
      component: recruit,
      props: true,
    },
    {
      path: "/education/recruitDetail/:boardid",
      name: "recruitDetail",
      component: recruitDetail,
      props: true,
    },

    // WHATSNEW
    {
      path: "/whatsnew/cf",
      name: "cf",
      component: cf,
    },
    {
      path: "/whatsnew/davichNews",
      name: "davichNews",
      component: davichNews,
    },
    {
      path: "/whatsnew/davichNewsDetail/:boardid",
      name: "davichNewsDetail",
      component: davichNewsDetail,
      props: true,
    },
    {
      path: "/whatsnew/event",
      name: "event",
      component: event,
    },

    // SUPPORT
    {
      path: "/support/ask",
      name: "ask",
      component: ask,
    },
    {
      path: "/support/faq",
      name: "faq",
      component: faq,
    },
    {
      path: "/support/giftCard",
      name: "giftCard",
      component: giftCard,
      props: true,
    },
    {
      path: "/support/findStore",
      name: "findStore",
      component: findStore,
    },


    // 모바일 기기에서 pc url로도 사이트 들어갈 수 있도록 하기 위해 추가(23.10.17)
    //PRIVACY
    {
      path: "/privacy/m_privacyPolicy",
      name: "privacyPolicy",
      component: privacyPolicy,
      meta: {
        title: "개인정보처리방침 - DAVICH",
      },
    },
    {
      path: "/privacy/m_emailProtect",
      name: "emailProtect",
      component: emailProtect,
      meta: {
        title: "이메일주소 무단수집거부 - DAVICH",
      },
    },

    // ABOUT
    {
      path: "/about/m_greetings",
      name: "greetings",
      component: greetings,
    },
    {
      path: "/about/m_information",
      name: "information",
      component: information,
    },
    {
      path: "/about/m_identity",
      name: "identity",
      component: identity,
    },
    {
      path: "/about/m_contribution",
      name: "contribution",
      component: contribution,
    },
    {
      path: "/about/m_contributionPostList",
      name: "contributionPostList",
      component: contributionPostList,
      props: true,
    },
    {
      path: "/about/m_contributionPostDetail/:boardid",
      name: "contributionPostDetail",
      component: contributionPostDetail,
      props: true,
    },
    {
      path: "/about/m_location",
      name: "location",
      component: location,
    },

    // PRODUCTS
    {
      path: "/products/m_glasses",
      name: "glasses",
      component: glasses,
    },
    {
      path: "/products/m_glassLens",
      name: "glassLens",
      component: glassLens,
    },
    {
      path: "/products/m_contactLens",
      name: "contactLens",
      component: contactLens,
    },
    {
      path: "/products/m_hearingAid",
      name: "hearingAid",
      component: hearingAid,
    },

    // PARTNERSHIP
    {
      path: "/partnership/m_franchise",
      name: "franchise",
      component: franchise,
    },
    {
      path: "/partnership/m_howto",
      name: "howto",
      component: howto,
    },
    {
      path: "/partnership/m_inquiryFranchise",
      name: "inquiryFranchise",
      component: inquiryFranchise,
    },
    {
      path: "/partnership/m_inquiryPartnership",
      name: "inquiryPartnership",
      component: inquiryPartnership,
    },

    // EDUCATION
    {
      path: "/education/m_researchCenter",
      name: "researchCenter",
      component: researchCenter,
    },
    {
      path: "/education/m_officer",
      name: "officer",
      component: officer,
    },
    {
      path: "/education/m_recruit",
      name: "recruit",
      component: recruit,
      props: true,
    },
    {
      path: "/education/m_recruitDetail/:boardid",
      name: "recruitDetail",
      component: recruitDetail,
      props: true,
    },

    // WHATSNEW
    {
      path: "/whatsnew/m_cf",
      name: "cf",
      component: cf,
    },
    {
      path: "/whatsnew/m_davichNews",
      name: "davichNews",
      component: davichNews,
    },
    {
      path: "/whatsnew/m_davichNewsDetail/:boardid",
      name: "davichNewsDetail",
      component: davichNewsDetail,
      props: true,
    },
    {
      path: "/whatsnew/m_event",
      name: "event",
      component: event,
    },

    // SUPPORT
    {
      path: "/support/m_ask",
      name: "ask",
      component: ask,
    },
    {
      path: "/support/m_faq",
      name: "faq",
      component: faq,
    },
    {
      path: "/support/m_giftCard",
      name: "giftCard",
      component: giftCard,
      props: true,
    },
    {
      path: "/support/m_findStore",
      name: "findStore",
      component: findStore,
    },

    // PC notfoundpage
    {
      path: "/notFound",
      name: "notFoundPage",
      component: notFoundPage,
    },
    {
      path:"/:pathMatch(.*)*",
      redirect:"/notFound",
    },
  ],
});

//////////////////모바일 라우터
import m_index from "../views/mobile/index.vue";

import m_notFoundPage from "../views/mobile/m_notFoundPage.vue";

// ABOUT
import m_greetings from "../views/mobile/ABOUT/1_m_greetings.vue";
import m_information from "../views/mobile/ABOUT/2_m_information.vue";
import m_identity from "../views/mobile/ABOUT/3_m_identity.vue";
import m_contribution from "../views/mobile/ABOUT/4_m_contribution.vue";
import m_contributionPostList from "../views/mobile/ABOUT/4_1_m_contributionPostList.vue";
import m_contributionPostDetail from "../views/mobile/ABOUT/4_2_m_contributionPostDetail.vue";
import m_location from "../views/mobile/ABOUT/5_m_location.vue";

// PRODUCTS
import m_glasses from "../views/mobile/PRODUCTS/1_m_glasses.vue";
import m_glassLens from "../views/mobile/PRODUCTS/2_m_glassLens.vue";
import m_contactLens from "../views/mobile/PRODUCTS/3_m_contactLens.vue";
import m_hearingAid from "../views/mobile/PRODUCTS/4_m_hearingAid.vue";

// PARTNERSHIP
import m_franchise from "../views/mobile/PARTNERSHIP/1_m_franchise.vue";
import m_howto from "../views/mobile/PARTNERSHIP/2_m_howto.vue";
import m_inquiryFranchise from "../views/mobile/PARTNERSHIP/3_m_inquiryFranchise.vue";
import m_inquiryPartnership from "../views/mobile/PARTNERSHIP/4_m_inquiryPartnership.vue";

// EDUCATION
import m_researchCenter from "../views/mobile/EDUCATION/1_m_researchCenter.vue";
import m_officer from "../views/mobile/EDUCATION/2_m_officer.vue";
import m_recruit from "../views/mobile/EDUCATION/3_m_recruit.vue";
import m_recruitDetail from "../views/mobile/EDUCATION/3_2_m_recruitDetail.vue";

// WHATSNEW
import m_cf from "../views/mobile/WHATSNEW/1_m_cf.vue";
import m_davichNews from "../views/mobile/WHATSNEW/2_m_davichNews.vue";
import m_davichNewsDetail from "../views/mobile/WHATSNEW/2_2_m_davichNewsDetail.vue";
import m_event from "../views/mobile/WHATSNEW/3_m_event.vue";

// SUPPORT
import m_ask from "../views/mobile/SUPPORT/1_m_ask.vue";
import m_faq from "../views/mobile/SUPPORT/2_m_faq.vue";
import m_giftCard from "../views/mobile/SUPPORT/3_m_giftCard.vue";
import m_findStore from "../views/mobile/SUPPORT/4_m_findStore.vue";

import m_privacyPolicy from "../views/mobile/PRIVACY/1_m_privacyPolicy.vue";
import m_emailProtect from "../views/mobile/PRIVACY/2_m_emailProtect.vue";

const m_router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
  routes: [
    {
      path: "/",
      name: "m_index",
      component: m_index,
    },

    //PRIVACY
    {
      path: "/privacy/m_privacyPolicy",
      name: "m_privacyPolicy",
      component: m_privacyPolicy,
    },
    {
      path: "/privacy/m_emailProtect",
      name: "m_emailProtect",
      component: m_emailProtect,
    },

    //ABOUT
    {
      path: "/about/m_greetings",
      name: "m_greetings",
      component: m_greetings,
    },
    {
      path: "/about/m_information",
      name: "m_information",
      component: m_information,
    },
    {
      path: "/about/m_identity",
      name: "m_identity",
      component: m_identity,
    },
    {
      path: "/about/m_contribution",
      name: "m_contribution",
      component: m_contribution,
    },
    {
      path: "/about/m_contributionPostList",
      name: "m_contributionPostList",
      component: m_contributionPostList,
      props: true,
    },
    {
      path: "/about/m_contributionPostDetail/:boardid",
      name: "m_contributionPostDetail",
      component: m_contributionPostDetail,
      props: true,
    },
    {
      path: "/about/m_location",
      name: "m_location",
      component: m_location,
    },

    // PRODUCTS
    {
      path: "/products/m_glasses",
      name: "m_glasses",
      component: m_glasses,
    },
    {
      path: "/products/m_glassLens",
      name: "m_glassLens",
      component: m_glassLens,
    },
    {
      path: "/products/m_contactLens",
      name: "m_contactLens",
      component: m_contactLens,
    },
    {
      path: "/products/m_hearingAid",
      name: "m_hearingAid",
      component: m_hearingAid,
    },

    // PARTNERSHIP
    {
      path: "/partnership/m_franchise",
      name: "m_franchise",
      component: m_franchise,
    },
    {
      path: "/partnership/m_howto",
      name: "m_howto",
      component: m_howto,
    },
    {
      path: "/partnership/m_inquiryFranchise",
      name: "m_inquiryFranchise",
      component: m_inquiryFranchise,
    },
    {
      path: "/partnership/m_inquiryPartnership",
      name: "m_inquiryPartnership",
      component: m_inquiryPartnership,
    },

    // EDUCATION
    {
      path: "/education/m_researchCenter",
      name: "m_researchCenter",
      component: m_researchCenter,
    },
    {
      path: "/education/m_officer",
      name: "m_officer",
      component: m_officer,
    },
    {
      path: "/education/m_recruit",
      name: "m_recruit",
      component: m_recruit,
      props: true,
    },
    {
      path: "/education/m_recruitDetail/:boardid",
      name: "m_recruitDetail",
      component: m_recruitDetail,
      props: true,
    },

    // WHATSNEW
    {
      path: "/whatsnew/m_cf",
      name: "m_cf",
      component: m_cf,
    },
    {
      path: "/whatsnew/m_davichNews",
      name: "m_davichNews",
      component: m_davichNews,
    },
    {
      path: "/whatsnew/m_davichNewsDetail/:boardid",
      name: "m_davichNewsDetail",
      component: m_davichNewsDetail,
      props: true,
    },
    {
      path: "/whatsnew/m_event",
      name: "m_event",
      component: m_event,
    },

    // SUPPORT
    {
      path: "/support/m_ask",
      name: "m_ask",
      component: m_ask,
    },
    {
      path: "/support/m_faq",
      name: "m_faq",
      component: m_faq,
    },
    {
      path: "/support/m_giftCard",
      name: "m_giftCard",
      component: m_giftCard,
      props: true,
    },
    {
      path: "/support/m_findStore",
      name: "m_findStore",
      component: m_findStore,
    },

    // 모바일 기기에서 pc url로도 사이트 들어갈 수 있도록 하기 위해 추가(23.10.17)
    //PRIVACY
    {
      path: "/privacy/privacyPolicy",
      name: "m_privacyPolicy",
      component: m_privacyPolicy,
    },
    {
      path: "/privacy/emailProtect",
      name: "m_emailProtect",
      component: m_emailProtect,
    },

    //ABOUT
    {
      path: "/about/greetings",
      name: "m_greetings",
      component: m_greetings,
    },
    {
      path: "/about/information",
      name: "m_information",
      component: m_information,
    },
    {
      path: "/about/identity",
      name: "m_identity",
      component: m_identity,
    },
    {
      path: "/about/contribution",
      name: "m_contribution",
      component: m_contribution,
    },
    {
      path: "/about/contributionPostList",
      name: "m_contributionPostList",
      component: m_contributionPostList,
      props: true,
    },
    {
      path: "/about/contributionPostDetail/:boardid",
      name: "m_contributionPostDetail",
      component: m_contributionPostDetail,
      props: true,
    },
    {
      path: "/about/location",
      name: "m_location",
      component: m_location,
    },

    // PRODUCTS
    {
      path: "/products/glasses",
      name: "m_glasses",
      component: m_glasses,
    },
    {
      path: "/products/glassLens",
      name: "m_glassLens",
      component: m_glassLens,
    },
    {
      path: "/products/contactLens",
      name: "m_contactLens",
      component: m_contactLens,
    },
    {
      path: "/products/hearingAid",
      name: "m_hearingAid",
      component: m_hearingAid,
    },

    // PARTNERSHIP
    {
      path: "/partnership/franchise",
      name: "m_franchise",
      component: m_franchise,
    },
    {
      path: "/partnership/howto",
      name: "m_howto",
      component: m_howto,
    },
    {
      path: "/partnership/inquiryFranchise",
      name: "m_inquiryFranchise",
      component: m_inquiryFranchise,
    },
    {
      path: "/partnership/inquiryPartnership",
      name: "m_inquiryPartnership",
      component: m_inquiryPartnership,
    },

    // EDUCATION
    {
      path: "/education/researchCenter",
      name: "m_researchCenter",
      component: m_researchCenter,
    },
    {
      path: "/education/officer",
      name: "m_officer",
      component: m_officer,
    },
    {
      path: "/education/recruit",
      name: "m_recruit",
      component: m_recruit,
      props: true,
    },
    {
      path: "/education/recruitDetail/:boardid",
      name: "m_recruitDetail",
      component: m_recruitDetail,
      props: true,
    },

    // WHATSNEW
    {
      path: "/whatsnew/cf",
      name: "m_cf",
      component: m_cf,
    },
    {
      path: "/whatsnew/davichNews",
      name: "m_davichNews",
      component: m_davichNews,
    },
    {
      path: "/whatsnew/davichNewsDetail/:boardid",
      name: "m_davichNewsDetail",
      component: m_davichNewsDetail,
      props: true,
    },
    {
      path: "/whatsnew/event",
      name: "m_event",
      component: m_event,
    },

    // SUPPORT
    {
      path: "/support/ask",
      name: "m_ask",
      component: m_ask,
    },
    {
      path: "/support/faq",
      name: "m_faq",
      component: m_faq,
    },
    {
      path: "/support/giftCard",
      name: "m_giftCard",
      component: m_giftCard,
      props: true,
    },
    {
      path: "/support/findStore",
      name: "m_findStore",
      component: m_findStore,
    },

    // 모바일 notfoundpage
    {
      path: "/m_notFound",
      name: "m_notFoundPage",
      component: m_notFoundPage,
    },
    {
      path:"/:pathMatch(.*)*",
      redirect:"/m_notFound",
    },
  ],
});

function detectMobileDevice(agent) {
  const mobileRegex = [/Android/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

  return mobileRegex.some((mobile) => agent.match(mobile));
}

const isMobile = detectMobileDevice(window.navigator.userAgent);

//웹타이틀
w_router.afterEach((to, from) => {
  const title = to.meta.title === undefined ? "DAVICH" : to.meta.title;
  Vue.nextTick(() => {
    document.title = title;
  });
});

m_router.afterEach((to, from) => {
  const title = to.meta.title === undefined ? "DAVICH" : to.meta.title;
  Vue.nextTick(() => {
    document.title = title;
  });
});

let router;
if (isMobile) {
  router = m_router;
  console.log("current device is mobile");
} else {
  router = w_router;
  console.log("current device is not mobile");
}

//구글 애널리틱스 적용
Vue.use(
  VueGtag,
  {
    config: { id: "G-JR5148JL5F" },
  },
  w_router
);
Vue.use(
  VueGtag,
  {
    config: { id: "G-JR5148JL5F" },
  },
  m_router
);

export default router;
