<template>
  <div class="ly-w1400">
    <div class="ly-wrap">
      <div class="contribution-post" v-bind="contentToShow">
        <h2 class="post-ttl">
          {{ contentToShow[0].title }}
        </h2>
        <p class="post-date">
          <em>마감일 &nbsp;</em>
          <span class="post-date-num">{{ contentToShow[0].due_date }}</span>
        </p>
        <div v-if="contentToShow[0].imgSrc">
          <div class="post-img" v-for="(src, index) in contentToShow[0].imgSrc" :key="index">
            <img :src="src" alt="post sample image" />
          </div>
        </div>
        <p class="post-txt" v-html="contentToShow[0].contents"></p>
        <!-- <button>
          <a class="download application-download-btn" :href="contentToShow[0].formUrl" download="form_sample"
            >지원서 다운로드</a
          >
        </button> -->
        <!-- @click="download(item.name,item.formUrl)" -->
        <div v-if="contentToShow[0].formInfo">
          <div v-for="(item, index) in contentToShow[0].formInfo" :key="index">
          <button class="download application-download-btn" @click="saveas(item.name,item.formUrl)" 
            >{{item.name}} [다운로드]</button>
          </div>
        </div>
        <router-link :to="{ path: '/education/recruit', query: { index: 1 } }" class="go application-go-btn"
          >지원하러 가기</router-link
        >
      </div>
    </div>
    <div class="next-prev-post">
      <p>
        <em><img src="https://img.davich.com/image/sub/about/icon-arrow-up.png" alt="arrow top icon" /> 이전글</em>
        <router-link :to="`/education/recruitDetail/${prevData[0].id}`" class="prev-post" v-if="prevData.length != 0">{{
          prevData[0].title
        }}</router-link>
        <a class="prev-post" v-else> 이전 게시물이 없습니다. </a>
      </p>
      <p>
        <em><img src="https://img.davich.com/image/sub/about/icon-arrow-down.png" alt="arrow top icon" />다음글</em>
        <router-link :to="`/education/recruitDetail/${nextData[0].id}`" class="next-post" v-if="nextData.length != 0">{{
          nextData[0].title
        }}</router-link>
        <a class="next-post" v-else> 다음 게시물이 없습니다. </a>
      </p>
    </div>

    <div class="post-list-btn">
      <router-link class="el-btn" to="/education/recruit">목록보기</router-link>
    </div>
  </div>
  <!-- 사관∙공채 공고 상세페이지 -->
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
  props: ["boardid"],
  data() {
    return {};
  },

  computed: {
    ...mapState("education", {
      boardData: (state) => state.boardData,
    }),

    contentToShow() {
      const self = this;
      let result;
      if(this.boardData != '' && this.boardData != null){
      result = self.boardData.filter((item) => {
        let filtered = true;
        filtered = self.boardid == item.id;
        return filtered;
      });
      return result;
      }
    },

    prevData() {
      const self = this;

      let result;
      result = self.boardData.filter((item) => {
        let thisData = true;
        thisData = self.contentToShow[0].index - 1 == item.index;
        return thisData;
      });

      return result;
    },

    nextData() {
      const self = this;

      let result;
      result = self.boardData.filter((item) => {
        let thisData = true;
        thisData = self.contentToShow[0].index + 1 == item.index;
        return thisData;
      });

      return result;
    },
  },
  
  created(){
    if (this.boardData == '' || this.boardData == null) {
      this.$store.dispatch("education/FETCH_RECRUIT");
    }
  },
  methods: {
    forceFileDownload(response, realfilename) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', realfilename)
      document.body.appendChild(link)
      link.click()
    },

    async saveas(name, fileurl) {
      console.log("fileurl : ", fileurl); 
      const reqFileUrl = fileurl ; 


    //   let xhr = new XMLHttpRequest()
    //   xhr.open('GET', reqFileUrl, true)
    //   // console.log("GET :", fileurl); 
    //   xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded")
    //   // xhr.responseType = 'arraybuffer
    //   xhr.send()
    //   xhr.onload = function(e) {
    //     console.log("onload =====");

    //   if (this.status === 200) {
    //       let blob = new Blob([this.response] )
    //       let link = document.createElement('a')
    //       link.href = window.URL.createObjectURL(blob)
    //       link.download = name
    //       link.click()
    //   }
    //   else {
    //     console.log("error");
    //     console.log(e);
    //   }
    // } 

      const file = await fetch(reqFileUrl); //
      console.log("filfileurl :aaaaa ", file); 
      const blob = await file.blob();
      const url = window.URL.createObjectURL(blob);

      console.log("file to local url : ", url); 

      var link = document.createElement("a");
      link.href = url;
      link.download = name;
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      link.remove();

    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.contribution-post {
  width: 700px;
  margin: auto;
  padding: 150px 0 40px 0;
  .post-ttl {
    font-size: 25px;
    font-weight: bold;
    padding: 30px 0;
    border-top: 1px solid $color-black;
    border-bottom: 1px solid #cccccc;
    letter-spacing: -0.5px;
  }
  // 마감일
  .post-date {
    padding: 30px 0;
    font-size: 18px;
    text-align: right;
  }
  // 본문이미지
  .post-img {
    width: 700px;
    height: auto;
    img {
      width: 100%;
    }
  }
  //본문 내용
  .post-txt {
    padding: 70px 0;
    @include font-main;
  }
}
// 이전글 다음글
.next-prev-post {
  width: 700px;
  margin: auto;
  border-top: 1px solid $color-black;
  border-bottom: 1px solid $color-black;
  p {
    @include font-main;
    padding: 20px;
    display: flex;
    align-items: center;
    &:first-child {
      border-bottom: 1px solid #ccc;
    }
    em {
      display: flex;
      align-items: center;
      font-weight: 500;
      margin-right: 34px;
      img {
        margin-right: 10px;
      }
    }
  }
}

// 목록보기 버튼
.post-list-btn {
  margin-top: 60px;
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
}

// 지원서 다운로드 버튼
.download {
  display: block;
  width: fit-content;
  text-align: left;
  line-height: 30px;
  font-weight: 500;
  font-size: 17px;
  color: $color-blue;
}
.download:hover {
  text-decoration: underline;
}

// 지원하러 가기 버튼
.go {
  margin-top: 20px;
  width: 700px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-weight: 400;
  font-size: 17px;
  color: #fff;
  background-color: $color-blue;
}
</style>
