<template>
  <div class="ly-w1400" :class="{ blur: isUploading }">
    <footer class="footer to-index full-page-scroll">
      <div class="ly-footer-inner footer-inner">
        <div class="footer-top">
          <ul class="fnb">
            <li>
              <router-link to="/privacy/privacyPolicy" style="font-weight: bold;">개인정보처리방침</router-link>
            </li>
            <li>
              <router-link to="/privacy/emailProtect">이메일주소 무단수집거부</router-link>
            </li>
            <li><router-link to="/support/faq">자주하는 질문</router-link></li>
            <li><router-link to="/about/location">오시는 길</router-link></li>
            <!-- <li>
              <a :href="downloadUrl" download="daivch-brochure"
                >브로슈어 다운로드</a
              >
            </li> -->
            <li>
              <a href="https://939.co.kr/davich/" target="_blank">원격지원</a>
            </li>
          </ul>
          <div class="footer-sns">
            <a href="https://www.instagram.com/davich.official/" target="_blank" class="instagram">
              <img src="https://img.davich.com/image/main/icon-instagram-01.png" alt="instagram icon" />
            </a>
            <a href="https://facebook.com/davichoptical" target="_blank" class="facebook">
              <img src="https://img.davich.com/image/main/icon-facebook-01.png" alt="facebook icon" />
            </a>
            <a href="https://www.youtube.com/channel/UCJoAvzt6GNBZLuXYY50F3Yw" target="_blank" class="youtube">
              <img src="https://img.davich.com/image/main/icon-youtube-01.png" alt="youtube icon" />
            </a>
          </div>
        </div>
        <div class="footer-body">
          <div class="footer-info">
            <h2>(주)다비치안경체인</h2>
            <p>
              <span>대표이사 : 김인규, 김봉건 ㅣ 주소 : 서울 용산구 만리재로 190-1 (서계동)</span>
              <span
                >사업자 등록번호 : 764-81-01136 ㅣ 의료기기 판매업 신고번호 : 제 3192호 ㅣ 의료기기 광고 사전심의번호 :
                2016GN1350095</span
              >
              <span>대표번호 : 1668-1407 ㅣ<!-- 연말정산 문의 : 070-7432-0957 ㅣ--> Fax : 02-752-6176</span>
            </p>
            <small>COPYRIGHT&copy; DAVICH OPTICAL. ALL RIGHTS RESERVED.</small>
          </div>
          <div class="footer-familysite">
            <button class="familysite-btn">
              <span>Family Site</span>
              <img src="https://img.davich.com/image/main/select-arrow.png" alt="arrow" />
            </button>
            <div class="familysite-link">
              <a href="https://www.davichmarket.com/" target="_blank">다비치마켓</a>
              <a href="https://www.davichhearing.com/" target="_blank">다비치보청기</a>
              <a href="https://www.kvisionoptical.com/" target="_blank">K비젼안경</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import axios from "@/api/axios-auth";
import { mapState } from "vuex";

export default {
  data() {
    return {
      downloadUrl: "",
    };
  },

  computed: {
    ...mapState({
      isUploading: (state) => state.isUploading,
    }),
  },

  created() {
    axios
      .get("/about/brochure")
      .then((res) => {
        this.downloadUrl = res.data.download_url;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  mounted() {
    let familySiteBtn = document.querySelector(".familysite-btn");
    familySiteBtn.addEventListener("click", function () {
      this.classList.toggle("on");
    });
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.ly-footer-inner {
  width: 1400px;
  margin: auto;
  padding: 70px 0;
}

.footer {
  width: 100%;
  background: #f9f9f9;
  line-height: 26px;
  .footer-inner {
    .footer-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .fnb {
        display: flex;
        font-weight: 500;
        li {
          margin-right: 38px;
          a {
            letter-spacing: -0.48px;
            &:hover {
              font-weight: 700;
              text-decoration: underline;
            }
          }
        }
      }
      .footer-sns {
        display: flex;
        align-items: center;
        a {
          margin-right: 28px;
          overflow: hidden;
          position: relative;
          overflow: hidden;
          display: flex;
          img {
            // position: absolute;
            // top: 0;
          }
          &.instagram {
            width: 23px;
            height: 23px;
            img {
              width: 100%;
              height: 100%;
              left: 0;
            }
          }
          &.facebook {
            width: 24px;
            height: 24px;
            img {
              width: 100%;
              height: 100%;
              left: -31px;
            }
          }
          &.youtube {
            margin-right: 0;
            width: 25px;
            height: 21px;
            img {
              width: 100%;
              height: 100%;
              left: -63px;
            }
          }
        }
      }
    }
    .footer-body {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .footer-info {
        margin-top: 60px;
        h2 {
          font-size: 25px;
          font-weight: 700;
          letter-spacing: -0.75px;
        }
        p {
          margin: 32px 0;
          line-height: 26px;
          letter-spacing: -0.48px;
          span {
            display: block;
            color: #555555;
          }
        }
        small {
          color: #888888;
          letter-spacing: -0.42px;
          font-size: 14px;
        }
      }
      .footer-familysite {
        box-sizing: border-box;
        width: 250px;
        height: auto;
        letter-spacing: -0.48px;
        font-size: 16px;
        position: relative;
        .familysite-btn {
          color: #555555;
          border: 1px solid #dedede;
          width: 100%;
          height: 50px;
          line-height: 50px;
          text-align: left;
          padding: 0 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 16px;
          span {
            display: inline-block;
            width: 100%;
          }
        }
        .familysite-link {
          position: absolute;
          width: 100%;
          bottom: 100%;
          left: 0;
          border: 1px solid #dedede;
          border-bottom: none;
          box-sizing: border-box;
          opacity: 0;
          visibility: hidden;
          transition: 0.3s;
          a {
            width: 100%;
            color: #555555;
            box-sizing: border-box;
            padding: 8px 20px;
          }
        }
        //클릭했을 경우
        .familysite-btn.on {
          border-top: none;
          background-color: $color-white;
          img {
            transform: rotateX(180deg);
          }
          & + .familysite-link {
            background-color: $color-white;
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}
</style>
