<template>
  <div id="index ly-w1400">
    <play-video :videoData="videoData"></play-video>

    <count-year></count-year>

    <card-col-4></card-col-4>

    <main-full-banner></main-full-banner>

    <quick-menu></quick-menu>
    <my-feed-insta></my-feed-insta>
    <main-notice></main-notice>
    <pop-up></pop-up>

    <top-button v-on:initNum="initNum" class="top-btn-main pc-btn"></top-button>
  </div>
</template>

<script>
import PlayVideo from "../components/PlayVideo.vue";
import CountYear from "../components/CountYear.vue";
import CardCol4 from "../components/CardCol4.vue";
import MainFullBanner from "../components/MainFullBanner.vue";
import QuickMenu from "../components/QuickMenu.vue";
import MyFeedInsta from "../components/MyFeedInsta.vue";
import MainNotice from "../components/MainNotice.vue";
import TopButton from "../components/TopButton.vue";
import PopUp from "../components/PopUp.vue";

import { mapState } from "vuex";

import { scrollIntoView } from "seamless-scroll-polyfill";

export default {
  components: {
    PlayVideo,
    CountYear,
    CardCol4,
    MainFullBanner,
    QuickMenu,
    MyFeedInsta,
    MainNotice,
    TopButton,
    PopUp,
  },
  data() {
    return {
      inMove: false,
      activeSection: 0,
      offsets: [],
      // touchStartY: 0,   모바일 작업시 사용
    };
  },

  computed: {
    ...mapState({
      videoData: (state) => state.videoData,
    }),
  },

  methods: {
    //각 섹션의 오프셋top값 저장하는 함수
    calculateSectionOffsets() {
      let section = document.getElementsByClassName("to-index");

      for (let i = 0; i < section.length; i++) {
        let sectionOffset = section[i].offsetTop;
        this.offsets.push(sectionOffset);
      }
    },

    handleMouseWheel: function (e) {
      if (e.deltaY > 0 && !this.inMove) {
        this.moveDown();
      } else if (e.deltaY < 0 && !this.inMove) {
        this.moveUp();
      }

      e.preventDefault();
      return false;
    },

    moveDown() {
      this.inMove = true;
      this.activeSection++;
      if (this.activeSection > this.offsets.length - 1) {
        this.activeSection = this.offsets.length - 1;
      }
      this.scrollToSection(this.activeSection, true);
    },
    moveUp() {
      this.inMove = true;
      this.activeSection--;
      if (this.activeSection < 0) {
        this.activeSection = 0;
      }
      this.scrollToSection(this.activeSection, true);
    },

    //해당 컴포넌트로 스크롤
    scrollToSection(id, force = false) {
      if (this.inMove && !force) return false;

      this.activeSection = id;
      this.inMove = true;

      setTimeout(() => {
        scrollIntoView(document.getElementsByClassName("to-index")[id], {
          block: "end",
          behavior: "smooth",
        });
      }, 150);

      if (this.activeSection != 0 || this.activeSection != 7) {
        setTimeout(() => {
          this.inMove = false;
        }, 151);
      }
    },

    // top button
    initNum() {
      const self = this;
      self.activeSection = 0;
    },
  },

  mounted() {
    this.calculateSectionOffsets();

    window.addEventListener("wheel", this.handleMouseWheel, {
      passive: false,
    });
  },

  destroyed() {
    window.removeEventListener("wheel", this.handleMouseWheel);
  },
};
</script>

<style lang="scss" scoped>
.top-btn-main {
  z-index: 99999999999;
  .top-btn {
    z-index: 99999999999;
  }
}
</style>
