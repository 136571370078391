<template>
  <div class="search-comp">
    <input type="text" placeholder="검색어를 입력하세요" v-model="searchText" />
    <b-button @click="searchContents()"
      ><img
        class="img-icon-search"
        src="https://img.davich.com/mobile/image/sub/about/icon-search.png"
        alt=""
    /></b-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchText: "",
    };
  },
  props: ["mutationName", "boardData"],

  methods: {
    searchContents() {
      const mutationName = this.mutationName;
      const boardData = this.boardData;
      let searchText = this.searchText;

      if (searchText.length != 0) {
        this.$store.commit(mutationName, [boardData, searchText]);
        this.$emit("switchVar", true);
      } else {
        this.$emit("switchVar", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.search-comp {
  position: relative;
  width: 85vw;
  margin: 0 auto;
  height: 40px;
  border-bottom: 1px solid #dedede;
  margin-top: 50px;
  input {
    width: 100%;
    @include font-main;
    height: 40px;
    padding-right: 36px;
    box-sizing: border-box;
    &::placeholder {
      color: #ccc;
    }
  }
  .img-icon-search {
    width: 28px;
    height: 28px;
  }
  .btn-secondary {
    padding: 0;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
</style>
