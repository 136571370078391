<template>
  <div class="support-ask ly-w1400">
    <div class="content-wrap" :class="{ blur: isUploading }">
      <sub-title :title="title" :content="content"> </sub-title>
      <section class="ask">
        <article class="ars ly-cont scroll-fade-in-f">
          <h2 class="hidden">대표전화</h2>
          <p class="ars-main-num">
            <!-- <em>02-752-6177</em> -->
            <em>1668-1407</em>
            <span>
              평일 10:00~18:00 <br />
              (점심시간 12:00~13:00)
            </span>
          </p>
          <ul class="ars-sub-num">
            <li>
              <p>ARS 1번</p>
              <!-- <span>마켓/홈페이지 문의</span> -->
              <span>고객 상담</span>
            </li>
            <li>
              <p>ARS 2번</p>
              <!-- <span>마켓 쿠폰 문의</span> -->
              <span>CS팀 연결</span>
            </li>
            <!-- <li>
              <p>ARS 3번</p>
              <span>개인정보 확인</span>
            </li>
            <li>
              <p>ARS 4번</p>
              <span>CS팀 연결</span>
            </li> -->
          </ul>
        </article>
        <!--ars-->
        <article class="inquiry online-inquiry ly-cont">
          <div class="online-inquiry-ttl scroll-fade-in-f">
            <h3>온라인 문의</h3>
            <p>전화 문의가 불편하신가요? 온라인으로 편리하게 문의하세요. 신속하게 처리 해 드리겠습니다.</p>
          </div>
          <div id="ly-form" class="ly-form scroll-fade-in-f">
            <div class="ly-form-cont">
              <h3>문의 내용</h3>
              <div class="form-cont">
                <p>
                  <label for="classify-online">구분<span>&#42;</span></label>
                  <select name="classify-online" id="classify-online" v-model="category_id">
                    <option value="1">칭찬 접수</option>
                    <option value="2">불만 접수</option>
                    <option value="3">단순 문의</option>
                  </select>
                </p>
                <p class="name-form">
                  <label for="name">성함<span>&#42;</span></label>
                  <input id="name" type="text" placeholder="성함을 입력해주세요." v-model="name" maxlength="20" />
                </p>
                <p class="number-form">
                  <label for="phonenumber">휴대폰<span>&#42;</span></label>
                  <span>
                    <!--01 연락처 입력부분-->
                    <span class="certifi-num-wrap">
                      <input
                        id="phonenumber"
                        type="text"
                        placeholder="'-'제외하고 숫자만입력"
                        v-model="mobile_no"
                        maxlength="11"
                      />
                      <!--참고_인증번호 받기 버튼 활성화 시 클래스 active 추가-->
                      <!--버튼(인증번호 받기 전)-->
                      <button id="get-authnum-btn" class="certifi-num-btn" @click="certifyMobile">인증번호 받기</button>
                      <!--버튼(인증번호 받은 후)-->
                      <!-- <button class="certifi-num-btn reissuance">재발급</button> -->
                      <!--연락처 올바르지 않을 시 나타나는 문구-->
                      <span class="caution hidden">올바른 휴대폰 번호를 입력해주세요.</span>
                    </span>
                    <!--02 인증번호 입력부분-->
                    <span class="certifi-num-wrap">
                      <!-- <label for="certification" class="">인증번호 입력</label> -->
                      <input
                        class="hidden"
                        id="certifi-input"
                        type="text"
                        placeholder="인증번호를 입력해주세요."
                        v-model="authNumber"
                        maxlength="6"
                      />
                      <!--문구(인증번호 받은 후)-->
                      <span class="caution hidden" id="timer"
                        >인증번호가 발송되었습니다. <em> 유효시간 {{ timeStr }}초</em></span
                      >
                      <!--문구(인증번호 틀린 후)-->
                      <span class="caution hidden">인증번호가 일치하지 않습니다. <em> 유효시간 : 3:00초</em></span>
                      <!--문구(인증번호 유효시간 초과 시)-->
                      <span class="caution hidden">인증번호를 재발급 해주세요. 유효시간 초과</span>
                      <!--문구(인증번호 인증 완료 후)-->
                      <span class="caution confirm hidden">인증이 완료되었습니다.</span>
                      <!--참고_인증하기 버튼 활성화 시 클래스 active 추가-->
                      <button class="certifi-num-btn hidden" id="certifi-btn" @click="confirmMobile">
                        인증번호 확인
                      </button>
                    </span>
                  </span>
                </p>
                <p class="email-form">
                  <label for="email">이메일<span>&#42;</span></label>
                  <input
                    id="email"
                    type="email"
                    placeholder="이메일 주소를 입력해주세요."
                    v-model="tempEmail"
                    @blur="sumDomain"
                    required
                  />
                  <span class="">&#64;</span>
                  <label for="input-domain" class="hidden">이메일 도메인 주소창</label>
                  <input id="input-domain" type="text" v-model="inputDomain" @blur="sumDomain" />
                  <label for="select-domain" class="hidden">이메일 도메인 주소 선택</label>
                  <select id="select-domain" v-model="domain" @change="sumDomain">
                    <option :value="0">직접입력</option>
                    <option :value="'naver.com'">naver.com</option>
                    <option :value="'hanmail.net'">hanmail.net</option>
                    <option :value="'gmail.com'">gmail.com</option>
                    <option :value="'daum.net'">daum.net</option>
                    <option :value="'nate.com'">nate.com</option>
                  </select>
                </p>
                <p class="reason-form">
                  <label>내용<span>&#42;</span></label>
                  <textarea
                    name="reason"
                    id="reason"
                    cols="30"
                    rows="10"
                    placeholder="문의 내용을 입력해주세요."
                    v-model="question"
                    maxlength="2500"
                  >
                  </textarea>
                </p>
                <p class="file-form">
                  <label for="upload-name">파일첨부</label>
                  <span>
                    <input id="upload-name" class="upload-name" :value="file.name" placeholder="첨부파일" />
                    <div class="file-btn-wrap">
                      <label for="avatar">찾아보기</label>
                      <span id="delete-file">
                        <button>
                          <img src="https://img.davich.com/image/sub/partnership/icon-x-square.png" alt="x icon" />
                          파일삭제
                        </button></span
                      >
                    </div>
                    <input
                      type="file"
                      id="avatar"
                      name="avatar"
                      accept="PDF, DOC, DOCX, PPT, PPTX, HWP, GUL,
                  GIF, JPG, PNG, ZIP, TXT"
                    />

                    <em
                      >1GB를 초과 할 수 없으며 PDF, DOC, DOCX, PPT, PPTX, HWP, GUL, GIF, JPG, PNG, ZIP, TXT 파일만
                      가능합니다.</em
                    >
                  </span>
                </p>
              </div>
            </div>
            <!-- 문의 내용 -->
            <div class="ly-form-agree">
              <h3>개인정보 수집동의</h3>
              <div class="agree-cont">
                <div class="agree-txt" v-html="contents"></div>
                <div class="agree-chk">
                  <input id="agree-chk" type="checkbox" v-model="isAgree" />
                  <label for="agree-chk"
                    >수집하는 개인정보 항목, 수집/이용목적, 개인정보 보유기간에 동의합니다.<span>&#42;</span></label
                  >
                  <div class="chk-icon-wrap">
                    <img
                      class="chk-icon"
                      src="https://img.davich.com/image/sub/icon-form-checkbox.jpg"
                      alt="icon checkbox"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- 개인정보 수집동의 -->
            <button class="el-btn question-btn question-btn-left" @click="postFormData">문의하기</button>
          </div>
        </article>
        <!--online inquire-->
      </section>
    </div>
    <!--content wrap-->
    <div class="upload" :class="{ active: this.$store.state.isUploading }">
      <div class="img-wrap">
        <img src="https://img.davich.com/image/sub/about/symbol-mark.png" alt="" />
      </div>
      <p class="upload-percent">{{ this.$store.state.uploadPercent }}%</p>
      <span class="upload-bar">
        <span class="upload-bar-inner" :style="{ left: this.$store.state.uploadPercent + '%' }"></span>
      </span>
      <p>파일을 업로드중입니다.</p>
      <button class="el-btn upload-btn" @click="cancelPost()">취소하기</button>
    </div>
    <!--upload window-->
  </div>
</template>

<script>
import SubTitle from "../../components/SubTitle1.vue";

import axios from "@/api/axios-auth";
import axiosOrigin from "axios";
import { mapState } from "vuex";

export default {
  components: {
    SubTitle,
  },
  data() {
    return {
      title: "고객의 소리",
      content: "고객님의 소리를 귀담아 듣고, 개선을 위해 최선을 다하겠습니다.",

      //formData
      category_id: "1",
      name: "",
      mobile_no: "",
      email: "",
      domain: 0,
      question: "",

      tempEmail: "",
      inputDomain: "",

      authnumber_id: 0,
      authNumber: null,

      file: "",

      isCertified: false,
      isAgree: false,

      timer: null,
      timeCounter: 180,
      timeStr: "03:00",

      cancelSource: null,
    };
  },

  computed: {
    ...mapState({
      contents: (state) => state.others.privacy[0].contents,
      isUploading: (state) => state.isUploading,
    }),
  },

  watch: {
    name(value) {
      const self = this;

      //공백인것,특수문자인것 제거
      const result = value.replace(/\s/g, "").replace(/[/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]/g, "");
      self.$nextTick(() => (self.name = result));
    },

    mobile_no(value) {
      const self = this;
      const btn = document.getElementById("get-authnum-btn");
      if (self.mobile_no.length == 11) {
        btn.classList.add("active");
      } else {
        btn.classList.remove("active");
      }

      //숫자아닌것 제거
      const result = value.replace(/\D/g, "");
      self.$nextTick(() => (self.mobile_no = result));
    },

    authNumber(value) {
      const self = this;
      const btn = document.getElementById("certifi-btn");
      if (self.authNumber.length == 6) {
        btn.classList.add("active");
      } else {
        btn.classList.remove("active");
      }

      //숫자아닌것 제거
      const result = value.replace(/\D/g, "");
      self.$nextTick(() => (self.authNumber = result));
    },

    tempEmail(value) {
      const self = this;

      const result = value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/, "").replace(/[@]/g, "");
      self.$nextTick(() => (self.tempEmail = result));
    },

    domain() {
      const self = this;
      const domainInput = document.getElementById("input-domain");

      if (self.domain == "0") {
        domainInput.removeAttribute("disabled");
        domainInput.setAttribute("type", "text");
      } else {
        domainInput.setAttribute("disabled", true);
        domainInput.setAttribute("type", "hidden");
        self.inputDomain = self.domain;
      }
    },
  },

  methods: {
    certifyMobile() {
      if (this.mobile_no == "") {
        alert("휴대폰 번호를 입력하세요");
        return 0;
      }
      const getAuthnumBtn = document.getElementById("get-authnum-btn");
      const certifyInput = document.getElementById("certifi-input");
      const certifyBtn = document.getElementById("certifi-btn");
      const countTimer = document.getElementById("timer");
      getAuthnumBtn.classList.add("active");
      certifyInput.classList.remove("hidden");
      certifyBtn.classList.remove("hidden");
      countTimer.classList.remove("hidden");
      this.setTimer();

      if (getAuthnumBtn.innerText != "재발급") {
        getAuthnumBtn.innerText = "재발급";
      } else {
        alert("인증번호가 재발송되었습니다.");
        //타이머 초기화
        this.timerStop(this.timer);
        this.timeCounter = 180;
        this.timeStr = "03:00";
        this.timer = null;
        this.timeStart();
      }

      axios
        .post("/message/authnumber", {
          board_name: "customer_voice",
          mobile_no: this.mobile_no,
        })
        .then((res) => {
          this.authnumber_id = res.data.id;
        })
        .catch((err) => console.log(err));
    },

    confirmMobile() {
      axios
        .post("/message/authnumber/check", {
          mobile_no: this.mobile_no,
          authnumber_id: this.authnumber_id,
          input_data: this.authNumber,
        })
        .then((res) => {
          if (res.status == 200) {
            alert("인증이 완료되었습니다.");
            this.isCertified = true;
            this.timerStop(this.timer);

            document.getElementById("get-authnum-btn").classList.add("hidden");
            document.getElementById("certifi-input").classList.add("hidden");
            document.getElementById("certifi-btn").classList.add("hidden");
            document.getElementById("timer").classList.add("hidden");

            document.getElementById("phonenumber").setAttribute("disabled", true);
            document.getElementById("get-authnum-btn").setAttribute("disabled", true);

            const confirm = document.getElementById("auth-confirm");
            confirm.classList.remove("hidden");
          }
        })
        .catch((err) => {
          switch (err.response.data.error_code) {
            case 1:
              alert("유효시간이 초과되었습니다.");
              break;
            case 2:
              alert("입력하신 번호가 일치하지 않습니다.");
              break;
          }
        });
    },

    postFormData() {
      const self = this;

      //예외처리
      if (self.name.length == 0 || self.mobile_no.length == 0 || self.email.length == 0 || self.question.length == 0) {
        alert("필수항목을 기입해 주세요");
        return 1;
      }

      if (self.isAgree == false) {
        alert("개인정보 수집동의에 체크해주세요.");
        return 1;
      }
      if (self.isCertified == false) {
        alert("휴대폰 인증을 완료해주세요.");
        return 1;
      }

      if (self.isAgree == true && self.isCertified == true) {
        const frm = new FormData();
        frm.append("category_id", self.category_id);
        frm.append("name", self.name);
        frm.append("mobile_no", self.mobile_no);
        frm.append("email", self.email);
        frm.append("question", self.question);
        frm.append("file", self.file);

        if (self.file) {
          this.$store.state.isUploading = true;
        }

        const CancelToken = axiosOrigin.CancelToken;
        self.cancelSource = CancelToken.source();

        axios
          .post(`/support/customervoice`, frm, {
            onUploadProgress: (progressEvent) => {
              let percentage = (progressEvent.loaded * 100) / progressEvent.total;
              let precentCompleted = Math.round(percentage);
              this.$store.commit("SET_UPLOAD_PERCENT", precentCompleted);
            },
            headers: {
              "Content-Type": `multipart/form-data`,
            },
            cancelToken: self.cancelSource.token,
          })
          .then(() => {
            this.$store.state.isUploading = false;
            self.cancelSource = null;
            alert("문의가 등록되었습니다. 문의해 주셔서 감사합니다.");
            this.$router.go(this.$router.currentRoute);
          })
          .catch((err) => {
            console.log(err);
            if (err.message == "userCancel") {
              this.$store.state.isUploading = false;
              this.$store.state.uploadPercent = 0;
              self.cancelSource = null;
              alert("취소되었습니다");
            } else {
              alert("에러발생 다시 시도해주세요");
            }
          });
      }
    },

    cancelPost() {
      const self = this;
      if (self.cancelSource) {
        self.cancelSource.cancel("userCancel");
        this.$store.state.isUploading = false;
        this.$store.state.uploadPercent = 0;
        self.cancelSource = null;
      }
    },

    //타이머
    timeStart() {
      this.TimeCounter = 180;
      let interval = setInterval(() => {
        this.timeCounter--;
        this.timeStr = this.prettyTime();
        if (this.timeCounter <= 0) {
          this.timerStop(interval);
        }
      }, 1000);
      return interval;
    },
    timerStop(timer) {
      clearInterval(timer);
      this.timeCounter = 0;
    },
    prettyTime() {
      let time = this.timeCounter / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      return minutes.toString().padStart(2, "0") + ":" + secondes.toString().padStart(2, "0");
    },
    setTimer() {
      if (this.timer != null) {
        this.timerStop(this.timer);
        this.timer = null;
      }
      this.timer = this.timeStart();
    },

    //직접입력 이메일 합치기
    sumDomain() {
      const self = this;
      if (self.domain == "0") {
        let fullEmail = self.tempEmail + "@" + self.inputDomain;
        self.email = fullEmail;
      } else {
        self.email = self.tempEmail + "@" + self.domain;
      }
    },
  },

  mounted() {
    const self = this;

    let typeOfFile = typeof self.file;
    let delBtn = document.querySelector("#delete-file");

    //파일 첨부 시 경로 알려주기
    const inputEl = document.querySelector("#avatar");
    inputEl.addEventListener("change", function () {
      let fileList = inputEl.files;
      let file = fileList[0];

      //파일용량체크
      let maxSize = 1024 * 1024 * 1024;
      if (file.size >= maxSize) {
        alert("첨부파일 사이즈는 1GB 이내로 등록 가능합니다.");
        inputEl.value = "";
        return false;
      }

      self.file = file;
      typeOfFile = typeof self.file;
      if (typeOfFile == "object") {
        delBtn.style.display = "block";
        //display block
      }
    });

    //파일 삭제 기능
    delBtn.addEventListener("click", function () {
      let fileList = inputEl.files;
      if (fileList.length > 0) {
        self.file = "";
        inputEl.value = "";
        // display none
        delBtn.style.display = "none";
      }
    });
  },

  beforeRouteLeave(to, from, next) {
    //파일 전송 중 라우팅 시 전송 취소
    const self = this;
    if (self.cancelSource) {
      self.cancelSource.cancel("userCancel");
      next();
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.support-ask {
  .sub-title {
    background-image: url("https://img.davich.com/image/sub/subtitle/sub-support-bg-01.png");
  }
  .ask {
    margin-top: 90px;
    .ars {
      display: flex;
      justify-content: space-between;
      margin-bottom: 120px;
      .ars-main-num {
        text-align: center;
        width: 350px;
        height: 350px;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $color-blue;
        color: $color-white;
        position: relative;
        em {
          font-size: 40px;
          letter-spacing: -1.2px;
          font-weight: bold;
          margin-bottom: 20px;
        }
        span {
          @include font-main;
          color: $color-white;
        }
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -280px;
          width: 280px;
          height: 0px;
          border: 1px dashed $color-blue;
        }
      }
      .ars-sub-num {
        width: 670px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        li {
          width: 100%;
          display: flex;
          &:last-child {
            p {
              border-bottom: 1px solid $color-blue;
            }
            span {
              border-bottom: 1px solid #dedede;
            }
          }
          p {
            padding: 35.5px 0;
            width: 150px;
            border-top: 1px solid $color-blue;
            @include font-h-22;
            color: $color-blue;
          }
          span {
            width: 100%;
            padding: 35.5px 0;
            border-top: 1px solid #dedede;
            @include font-main;
          }
        }
      }
    }
    //온라인 문의 > 개인정보 수집동의
    .inquiry {
      .online-inquiry-ttl {
        h3 {
          @include font-h-35;
          margin-bottom: 11px;
        }
        p {
          @include font-main;
          margin-bottom: 70px;
        }
      }
    }
  }
}
</style>
