<template>
  <div class="sub-title">
    <div class="sub-title-txt ly-cont">
      <h2>{{ this.title }}</h2>
      <p>{{ this.content }}</p>
      <p>{{ this.content2 }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "content", "content2"],
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.sub-title {
  width: 100%;
  height: 600px;
  overflow: hidden;
  background-attachment: fixed;
  background-position: top;
  position: relative;
  .sub-title-txt {
    color: white;
    position: absolute;
    bottom: 67px;
    left: 50%;
    transform: translateX(-50%);
    h2 {
      font-size: 60px;
      font-weight: bold;
      line-height: normal;
      margin-bottom: 12px;
      letter-spacing: -1.8px;
    }
    p {
      @include font-main;
    }
  }
}
</style>
