import {
  fetchRecruit,
  fetchPicAndVideo,
  fetchSuccess,
  fetchDepartment,
  fetchEmployee,
} from "@/api/index";

export const actions = {
  FETCH_RECRUIT: function (context) {
    fetchRecruit()
      .then((res) => {
        context.commit("SET_RECRUIT", res.data);
      })
      .catch((err) => console.log(err));
  },

  FETCH_PIC_VIDEO: function (context) {
    fetchPicAndVideo()
      .then((res) => {
        context.commit("SET_PIC_VIDEO", res.data);
      })
      .catch((err) => console.log(err));
  },

  FETCH_SUCCESS: function (context) {
    fetchSuccess()
      .then((res) => {
        context.commit("SET_SUCCESS", res.data);
      })
      .catch((err) => console.log(err));
  },

  FETCH_DEPARTMENT: function (context) {
    fetchDepartment()
      .then((departmentRes) => {
        fetchEmployee()
          .then((employeeRes) => {
            context.commit("SET_DEPARTMENT", [
              departmentRes.data,
              employeeRes.data,
            ]);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  },
};
