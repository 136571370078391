<template>
  <div class="ly-w1400">
    <sub-title :title="title" :content="content"> </sub-title>
    <section class="inquiry ly-cont">
      <h2 class="hidden">가맹점 개설 문의하기 및 담당자 연락처</h2>
      <div id="ly-form" class="ly-form scroll-fade-in-f">
        <div class="ly-form-cont">
          <h3>개설 문의 내용</h3>
          <div class="form-cont">
            <p>
              <label for="country">희망지역<span>&#42;</span></label>
              <select name="country" id="country" v-model="hope_area_name">
                <option value="지역선택" selected disabled>지역선택</option>
                <option value="서울">서울</option>
                <option value="부산">부산</option>
                <option value="대구">대구</option>
                <option value="인천">인천</option>
                <option value="광주">광주</option>
                <option value="대전">대전</option>
                <option value="울산">울산</option>
                <option value="세종">세종</option>
                <option value="경기">경기</option>
                <option value="강원">강원</option>
                <option value="충북">충북</option>
                <option value="충남">충남</option>
                <option value="전북">전북</option>
                <option value="전남">전남</option>
                <option value="경북">경북</option>
                <option value="경남">경남</option>
                <option value="제주">제주</option>
              </select>
            </p>
            <p class="career-form">
              <label for="career">안경사 경력<span>&#42;</span></label>
              <input id="career" type="text" v-model="experienced_years" maxlength="3" />
            </p>
            <p class="price-form">
              <label for="price">가용가능 금액<span>&#42;</span></label>
              <input id="price" type="text" v-model="moneyToShow" maxlength="14" />
            </p>
            <p class="name-form">
              <label for="name">성함<span>&#42;</span></label>
              <input id="name" type="text" placeholder="성함을 입력해주세요." v-model="name" maxlength="20" />
            </p>

            <p class="number-form">
              <label for="phonenumber">휴대폰<span>&#42;</span></label>
              <span>
                <!--01 연락처 입력부분-->
                <span class="certifi-num-wrap">
                  <input
                    id="phonenumber"
                    type="text"
                    placeholder="'-'제외하고 숫자만입력"
                    v-model="mobile_no"
                    maxlength="11"
                  />
                  <!--참고_인증번호 받기 버튼 활성화 시 클래스 active 추가-->
                  <!--버튼(인증번호 받기 전)-->
                  <button id="get-authnum-btn" class="certifi-num-btn" @click="certifyMobile">인증번호 받기</button>
                  <!--버튼(인증번호 받은 후)-->
                  <!-- <button class="certifi-num-btn reissuance">재발급</button> -->
                  <!--연락처 올바르지 않을 시 나타나는 문구-->
                  <span class="caution hidden">올바른 휴대폰 번호를 입력해주세요.</span>
                </span>
                <!--02 인증번호 입력부분-->
                <span class="certifi-num-wrap">
                  <!-- <label for="certification" class="">인증번호 입력</label> -->
                  <input
                    class="hidden"
                    id="certifi-input"
                    type="text"
                    placeholder="인증번호를 입력해주세요."
                    v-model="authNumber"
                    maxlength="6"
                  />
                  <!--문구(인증번호 받은 후)-->
                  <span class="caution hidden" id="timer"
                    >인증번호가 발송되었습니다. <em> 유효시간 {{ timeStr }}초</em></span
                  >
                  <!--문구(인증번호 틀린 후)-->
                  <span class="caution hidden">인증번호가 일치하지 않습니다. <em> 유효시간 : 3:00초</em></span>
                  <!--문구(인증번호 유효시간 초과 시)-->
                  <span class="caution hidden">인증번호를 재발급 해주세요. 유효시간 초과</span>
                  <!--문구(인증번호 인증 완료 후)-->
                  <span id="auth-confirm" class="caution confirm hidden">인증이 완료되었습니다.</span>
                  <!--참고_인증하기 버튼 활성화 시 클래스 active 추가-->
                  <button class="certifi-num-btn hidden" id="certifi-btn" @click="confirmMobile">인증번호 확인</button>
                </span>
              </span>
            </p>
            <p class="email-form">
              <label for="email">이메일<span>&#42;</span></label>
              <input
                id="email"
                type="email"
                placeholder="이메일 주소를 입력해주세요."
                v-model="tempEmail"
                @blur="sumDomain"
                required
              />
              <span class="">&#64;</span>
              <label for="input-domain" class="hidden">이메일 도메인 주소창</label>
              <input id="input-domain" type="text" v-model="inputDomain" @blur="sumDomain" />
              <label for="select-domain" class="hidden">이메일 도메인 주소 선택</label>
              <select id="select-domain" v-model="domain" @change="sumDomain">
                <option :value="0">직접입력</option>
                <option :value="'naver.com'">naver.com</option>
                <option :value="'hanmail.net'">hanmail.net</option>
                <option :value="'gmail.com'">gmail.com</option>
                <option :value="'daum.net'">daum.net</option>
                <option :value="'nate.com'">nate.com</option>
              </select>
            </p>
            <p class="reason-form">
              <label>개설 희망 이유<span>&#42;</span></label>
              <textarea
                name="reason"
                id="reason"
                cols="30"
                rows="10"
                placeholder="다비치 가맹점 개설을 희망하는 이유를 기입해주세요."
                v-model="open_reasons"
                maxlength="2500"
              >
              </textarea>
            </p>
          </div>
        </div>
        <div class="ly-form-agree">
          <h3>개인정보 수집동의</h3>
          <div class="agree-cont">
            <div class="agree-txt" v-html="contents"></div>
            <div class="agree-chk">
              <input id="agree-chk" type="checkbox" v-model="isAgree" />
              <label for="agree-chk"
                >수집하는 개인정보 항목, 수집/이용목적, 개인정보 보유기간에 동의합니다.<span>&#42;</span></label
              >
              <div class="chk-icon-wrap">
                <img
                  class="chk-icon"
                  src="https://img.davich.com/image/sub/icon-form-checkbox.jpg"
                  alt="icon checkbox"
                />
              </div>
            </div>
          </div>
        </div>
        <button class="el-btn question-btn" @click="postFormData">문의하기</button>
      </div>
      <!--/.inquiry inquiry from-->
      <div class="inquiry-num scroll-fade-in-f">
        <figure>
          <figcaption>
            <h3>개설 담당자 연락처</h3>
            <p>대표번호 070-7433-0144</p>
            <table>
              <colgroup>
                <col class="col1" />
                <col class="col2" />
                <col class="col3" />
                <col class="col4" />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">담당자</th>
                  <th scope="col">연락처</th>
                  <th scope="col">이메일</th>
                  <th scope="col">담당지역</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in getManager" :key="item.id">
                  <td>{{ item.name }}</td>
                  <td>{{ item.mobile_no }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.details }}</td>
                </tr>
              </tbody>
            </table>
          </figcaption>
        </figure>
      </div>
      <!--/.inquiry inquiry contact number-->
    </section>
  </div>
</template>

<script>
import SubTitle from "../../components/SubTitle1.vue";
import axios from "@/api/axios-auth";

import { mapGetters, mapState } from "vuex";

export default {
  beforeCreate() {
    this.$store.dispatch("partnership/FETCH_MANAGER");
  },

  components: {
    SubTitle,
  },
  data() {
    return {
      title: "가맹점 개설문의",
      content: "다비치와 함께 성장을 도모하고, 시너지 효과를 창출할 소중한 연락을 기다립니다.​",

      hope_area_name: "지역선택",
      experienced_years: "",
      investable_money: null,
      name: "",
      email: "",
      domain: 0,

      mobile_no: "",
      open_reasons: "",

      tempEmail: "",
      inputDomain: "",

      authnumber_id: 0,
      authNumber: null,

      isCertified: false,
      isAgree: false,

      timer: null,
      timeCounter: 180,
      timeStr: "03:00",

      moneyToShow: null,
    };
  },

  computed: {
    ...mapGetters("partnership", ["getManager"]),
    ...mapState({
      contents: (state) => state.others.privacy[0].contents,
    }),
  },

  watch: {
    experienced_years(value) {
      const self = this;

      //숫자아닌건 제거
      const result = value.replace(/\D/g, "");
      self.$nextTick(() => (self.experienced_years = result));
    },

    name(value) {
      const self = this;

      //공백인것,특수문자인것 제거
      const result = value.replace(/\s/g, "").replace(/[/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]/g, "");
      self.$nextTick(() => (self.name = result));
    },

    mobile_no(value) {
      const self = this;
      const btn = document.getElementById("get-authnum-btn");
      if (self.mobile_no.length == 11) {
        btn.classList.add("active");
      } else {
        btn.classList.remove("active");
      }

      //숫자아닌것 제거
      const result = value.replace(/\D/g, "");
      self.$nextTick(() => (self.mobile_no = result));
    },

    authNumber(value) {
      const self = this;
      const btn = document.getElementById("certifi-btn");
      if (self.authNumber.length == 6) {
        btn.classList.add("active");
      } else {
        btn.classList.remove("active");
      }

      //숫자아닌것 제거
      const result = value.replace(/\D/g, "");
      self.$nextTick(() => (self.authNumber = result));
    },

    tempEmail(value) {
      const self = this;

      const result = value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/, "").replace(/[@]/g, "");
      self.$nextTick(() => (self.tempEmail = result));
    },

    //렌더링에 콤마찍어서 보여줄 변수
    moneyToShow(newValue) {
      //쉼표넣기
      const result = newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (this.moneyToShow = result));

      //쉼표들어간 문자열 쉼표삭제
      let numValue = newValue.replaceAll(",", "");
      this.investable_money = numValue;
    },

    domain() {
      const self = this;
      const domainInput = document.getElementById("input-domain");

      if (self.domain == "0") {
        domainInput.removeAttribute("disabled");
        domainInput.setAttribute("type", "text");
      } else {
        domainInput.setAttribute("disabled", true);
        domainInput.setAttribute("type", "hidden");
        self.inputDomain = self.domain;
      }
    },
  },

  methods: {
    certifyMobile() {
      if (this.mobile_no == "") {
        alert("휴대폰 번호를 입력하세요");
        return 0;
      }
      const getAuthnumBtn = document.getElementById("get-authnum-btn");
      const certifyInput = document.getElementById("certifi-input");
      const certifyBtn = document.getElementById("certifi-btn");
      const countTimer = document.getElementById("timer");
      getAuthnumBtn.classList.add("active");
      certifyInput.classList.remove("hidden");
      certifyBtn.classList.remove("hidden");
      countTimer.classList.remove("hidden");
      this.setTimer();

      if (getAuthnumBtn.innerText != "재발급") {
        getAuthnumBtn.innerText = "재발급";
      } else {
        alert("인증번호가 재발송되었습니다.");
        //타이머 초기화
        this.timerStop(this.timer);
        this.timeCounter = 180;
        this.timeStr = "03:00";
        this.timer = null;
        this.timeStart();
      }

      axios
        .post("/message/authnumber", {
          board_name: "customer_voice",
          mobile_no: this.mobile_no,
        })
        .then((res) => {
          this.authnumber_id = res.data.id;
        })
        .catch((err) => console.log(err));
    },

    confirmMobile() {
      axios
        .post("/message/authnumber/check", {
          mobile_no: this.mobile_no,
          authnumber_id: this.authnumber_id,
          input_data: this.authNumber,
        })
        .then((res) => {
          if (res.status == 200) {
            alert("인증이 완료되었습니다.");
            this.isCertified = true;
            this.timerStop(this.timer);
            document.getElementById("get-authnum-btn").classList.add("hidden");
            document.getElementById("certifi-input").classList.add("hidden");
            document.getElementById("certifi-btn").classList.add("hidden");
            document.getElementById("timer").classList.add("hidden");
            document.getElementById("phonenumber").setAttribute("disabled", true);
            document.getElementById("get-authnum-btn").setAttribute("disabled", true);

            const confirm = document.getElementById("auth-confirm");
            confirm.classList.remove("hidden");
          }
        })
        .catch((err) => {
          switch (err.response.data.error_code) {
            case 1:
              alert("유효시간이 초과되었습니다.");
              break;
            case 2:
              alert("입력하신 번호가 일치하지 않습니다.");
              break;
          }
        });
    },

    postFormData() {
      const self = this;

      //예외처리
      if (
        self.hope_area_name.length == 0 ||
        self.experienced_years.length == 0 ||
        self.investable_money.length == 0 ||
        self.name.length == 0 ||
        self.email.length == 0 ||
        self.mobile_no.length == 0 ||
        self.open_reasons.length == 0
      ) {
        alert("필수항목을 기입해 주세요");
        return 1;
      }

      if (self.isAgree == false) {
        alert("개인정보 수집동의에 체크해주세요.");
        return 1;
      }
      if (self.isCertified == false) {
        alert("휴대폰 인증을 완료해주세요.");
        return 1;
      }
      if (self.isAgree == true && self.isCertified == true) {
        // this.$store.dispatch("partnership/POST_OPEN_INQUIRE", dataToPost);
        axios
          .post(
            `/partnership/store/openinquire`,
            {
              hope_area_name: self.hope_area_name,
              experienced_years: self.experienced_years,
              investable_money: self.investable_money,
              name: self.name,
              email: self.email,
              mobile_no: self.mobile_no,
              open_reasons: self.open_reasons,
            },
            {
              headers: {
                "Content-Type": `application/json`,
              },
            }
          )
          .then(() => {
            alert("문의가 등록되었습니다. 문의해 주셔서 감사합니다.");
            this.$router.go(this.$router.currentRoute);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    //타이머
    timeStart() {
      this.TimeCounter = 180;
      let interval = setInterval(() => {
        this.timeCounter--;
        this.timeStr = this.prettyTime();
        if (this.timeCounter <= 0) {
          this.timerStop(interval);
        }
      }, 1000);
      return interval;
    },
    timerStop(timer) {
      clearInterval(timer);
      this.timeCounter = 0;
    },
    prettyTime() {
      let time = this.timeCounter / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      return minutes.toString().padStart(2, "0") + ":" + secondes.toString().padStart(2, "0");
    },
    setTimer() {
      if (this.timer != null) {
        this.timerStop(this.timer);
        this.timer = null;
      }
      this.timer = this.timeStart();
    },

    //직접입력 이메일 합치기
    sumDomain() {
      const self = this;
      if (self.domain == "0") {
        let fullEmail = self.tempEmail + "@" + self.inputDomain;
        self.email = fullEmail;
      } else {
        self.email = self.tempEmail + "@" + self.domain;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.sub-title {
  background-image: url("https://img.davich.com/image/sub/subtitle/sub-partnership-bg.png");
}

.inquiry {
  padding: 20px 0 200px 0;
}

/* 개설 담당자 연락처 */
.inquiry-num {
  figure {
    position: relative;
    figcaption {
      display: flex;
      h3 {
        width: 250px !important;
        @include font-h-25;
        line-height: 37px;
      }
      p {
        top: -37px;
        right: 0;
        position: absolute;
        font-size: 18px;
        line-height: 30px;
        font-weight: 500;
      }
    }
    table {
      width: 1150px;
      border-top: 1px solid $color-black;
      colgroup {
        .col1 {
          width: 270px;
        }
        .col2 {
          width: 270px;
        }
        .col3 {
          width: 270px;
        }
        .col4 {
          width: 340px;
        }
      }
      tr {
        border-bottom: 1px solid #ccc;
        th {
          text-align: left;
          font-weight: 500;
          padding: 18px 0 17px 0;
          background-color: #f9f9f9;
          &:first-child {
            padding-left: 20px;
          }
        }
        td {
          padding: 23px 0;
          &:first-child {
            padding-left: 20px;
          }
        }
      }
    }
  }
}
</style>
