<template>
  <div class="ly-w1400">
    <main class="contribution ly-wrap">
      <section class="contribution-main">
        <div class="contribution-desc">
          <div class="main-txt">
            <h2 class="scroll-fade-in-f">눈건강 시력 지킴이</h2>
            <p class="scroll-fade-in-f scroll-delay-1">
              <span>
                눈건강 시력 지킴이는 소외된 어려운 이웃까지 맑고 밝게
                비춰주는<br />
                봉사단으로 전국 사회봉사시설을 방문해 시력검사 및 돋보기,
                교정용<br />
                안경을 무료로 제공합니다.
              </span>
              다비치는 모두가 함께하는 더 나은 사회를 만들기 위해
              기여하겠습니다.
            </p>
            <router-link
              class="el-btn scroll-fade-in-f scroll-delay-2"
              to="/about/contributionPostList"
              >히스토리 보기​​</router-link
            >
          </div>
          <div class="main-counting">
            <div class="counting-volun">
              <span>봉사활동</span>
              <p>
                <em
                  ><Roller
                    :text="this.getDavichNumbers.volunteer_count"
                    :isNumberFormat="isNumberFormat"
                    :transition="transition"
                  ></Roller></em
                ><span>회</span>
              </p>
            </div>
            <div class="counting-volun">
              <span>시력검사 및 안경기부</span>
              <p>
                <em
                  ><Roller
                    :text="this.getDavichNumbers.glasses_give_count"
                    :isNumberFormat="isNumberFormat"
                    :transition="transition"
                  ></Roller></em
                ><span>명</span>
              </p>
            </div>
          </div>
        </div>
        <!--contribution-desc-->
        <div class="contribution-img">
          <img
            src="https://img.davich.com/image/sub/about/img-contribution-01.png"
            alt="시력측정 이미지"
          />
        </div>
        <!--contribution-img-->
        <div class="contribution-items ly-cont">
          <router-link
            :to="`/about/contributionPostDetail/${item.id}`"
            class="contribution-item"
            v-for="item in listToShow"
            :key="item.id"
          >
            <div class="item-show">
              <img
                src="https://img.davich.com/image/sub/about/icon-plus.png"
                alt="plus icon"
              />
              <p v-html="item.title"></p>
            </div>
            <div class="item-hover">
              <img :src="item.main_image" alt="" />
            </div>
          </router-link>
        </div>
        <!--contribution-items-->
      </section>
      <!--눈건강시력지킴이-->
      <section class="contribution-volun">
        <img
          class="contribution-icon-bg"
          src="https://img.davich.com/image/sub/about/icon-contribution.png"
          alt="icon"
        />
        <div class="contribution-img">
          <img
            src="https://img.davich.com/image/sub/about/img-contribution-02.png"
            alt="children pic"
          />
        </div>
        <div class="contribution-txt">
          <h2 class="scroll-fade-in-f">해외봉사</h2>
          <p class="scroll-fade-in-f scroll-delay-1">
            눈건강 시력 지킴이는 베트남과 캄보디아에서 봉사활동을 하고 있습니다.
            전국 다비치안경을 통해 모아진 헌 안경은 어려운 국가의 이웃들에게
            전달하고 있습니다.
          </p>
        </div>
      </section>
      <!--해외봉사-->
      <section class="contribution-campaign">
        <div class="campaign-item">
          <span class="slogan-1">봉사</span>
          <span class="slogan-2">배려</span>
          <span class="slogan-3">미래</span>
        </div>
        <div>
          <div class="campaign-item-show">
            <div class="campaign-ttl">
              <h2>BIBIEM</h2>
              <p>
                봉사, 배려, 미래를 뜻하는 안경테 ‘비비엠’ 라인은 판매 제품 한
                개당 한 개씩 전 세계의 안경이 필요한 소외된 이웃에게 기부하는
                사회공헌 캠페인입니다.
              </p>
            </div>
          </div>
        </div>
      </section>
      <!--캠페인-->
      <span class="empty"></span>
    </main>
  </div>
</template>

<script>
import Roller from "vue-roller";

import { mapGetters } from "vuex";

export default {
  beforeCreate() {
    this.$store.dispatch("FETCH_CONTRIBUTION_BOARD");
    this.$store.dispatch("FETCH_DAVICH_NUMBERS");

  },
  components: {
    Roller,
  },

  data() {
    return {
      isNumberFormat: true,
      transition: 2,
    };
  },

  computed: {
    ...mapGetters("about", ["getBoardData", "getDavichNumbers"]),

    listToShow() {
      return this.getBoardData.slice(0, 3);
    },
  },

  methods: {
    fixed() {
      var campaign = document.querySelector(".contribution-campaign");
      var campaignH = campaign.getBoundingClientRect().top; // 높이

      if (campaignH < -30 && !campaign.classList.contains("animation")) {
        campaign.classList.add("fixed"); //고정
      }
    },
    fixedSroll(e) {
      var campaign = document.querySelector(".contribution-campaign"); // 실제 값
      var contributionVolun = document.querySelector(".contribution-volun"); // 형제 요소
      // 높이 값(실제 애니메이션이 들어가는 부분은 fixed되어 위치값을 잃어버리기 때문에 바로위 형제요소의 top 위치 + 형제요소의 높이값)
      var campaignTop =
        window.pageYOffset +
        contributionVolun.getBoundingClientRect().top +
        contributionVolun.offsetHeight;

      if (e.deltaY < 0) {
        // 위로 스크롤 할 경우
        //fixed 된 상태에서 위로 스크롤할때 fixed 해제
        if (window.scrollY < campaignTop) {
          campaign.classList.remove("fixed");
        }
      } else if (e.deltaY > 0) {
        // 아래로 스크롤 할경우
        if (
          campaign.classList.contains("fixed") &&
          !campaign.classList.contains("animation")
        ) {
          setTimeout(function () {
            campaign.classList.add("animation"); //애니메이션 추가
          }, 500);
          setTimeout(function () {
            window.scrollTo(0, campaignTop); // 위치 이동
          }, 800);
          setTimeout(function () {
            campaign.classList.remove("fixed"); //fixed 해제
            let empty = document.querySelector(".empty"); //빈공간 삭제
            empty.style.height = "0";
          }, 1000);
        }
      }
    },
  },
  mounted() {
    //특정위치에 가면 fixed된다
    window.addEventListener("scroll", this.fixed);

    // fixed 된 후 스크롤 할 때
    window.addEventListener("mousewheel", this.fixedSroll);
  },

  destroyed() {
    window.removeEventListener("scroll", this.fixed);
    window.removeEventListener("mousewheel", this.fixedSroll);
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

//contribution main
.contribution ::v-deep {
  .contribution-main {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: calc(100vh - 80px);
    position: relative;
    .contribution-desc {
      width: 1400px;
      position: absolute;
      top: 111px;
      left: 50%;
      transform: translateX(-50%);
      .main-txt {
        margin-bottom: 10vh;
        h2 {
          font-size: 50px;
          line-height: 70px;
          letter-spacing: -1.5px;
          margin-bottom: 20px;
          font-weight: bold;
        }
        p {
          @include font-main;
          margin-bottom: 60px;
          width: 600px;
          letter-spacing: -0.54px;
          span {
            display: block;
            margin-bottom: 25px;
          }
        }
      }
      .main-counting {
        .counting-volun {
          p {
            display: flex;
            color: #6cc24a;
            font-size: 25px;
            font-weight: 900;
            em {
              font-size: 70px;
              font-weight: 800;
              .roller {
                .roller__wrapper {
                  mask-image: none !important;
                  -webkit-mask-image: none !important;
                }
              }
            }
            span {
              margin-top: 60px;
              margin-left: 10px;
            }
          }
          &:nth-child(2) {
            margin-top: 20px;
            p {
              em,
              span {
                color: $color-skyblue;
              }
            }
          }
        }
      }
    }
    .contribution-img {
      width: 50%;
      min-width: 600px;
      height: 100%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .contribution-items {
      position: absolute;
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);
      width: 1400px;
      display: flex;
      justify-content: flex-end;
      .contribution-item {
        width: 250px;
        height: 165px;
        margin-left: 30px;
        position: relative;
        .item-hover {
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        &:hover {
          .item-hover {
            opacity: 1;
          }
        }
        .item-show {
          background-color: $color-blue;
          width: 100%;
          height: 100%;
          img {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 15px;
            height: 15px;
          }
          p {
            width: 220px;
            padding: 0 15px;
            line-height: 22px;
            font-size: 14px;
            text-align: center;
            color: $color-white;
            @include box-center;
            @include ellipsis(2, 22px);
          }
        }
        .item-hover {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          width: 100%;
          height: 100%;
          opacity: 0;
          overflow: hidden;
          transition: 0.4s;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        &:hover {
          .item-hover {
            opacity: 1;
          }
        }
      }
    }
  }
  .contribution-volun {
    position: relative;
    overflow: hidden;
    .contribution-icon-bg {
      position: absolute;
      top: 90px;
      left: 1463px;
      animation: rotate 8s linear infinite;
    }
    padding: 245px 0;
    display: flex;
    position: relative;
    align-items: center;
    .contribution-img {
      margin-right: 30px;
      background-color: $color-blue;
      height: 554px;
      width: 2900px;
      margin-left: -2000px;
      transform: skewX(-30deg);
      img {
        position: absolute;
        z-index: 7;
        top: 45px;
        left: 2134px;
        transform: skewX(30deg);
      }
    }
    .contribution-txt {
      margin-left: 200px;
      width: 520px;
      h2 {
        font-size: 50px;
        line-height: 70px;
        letter-spacing: -1.5px;
        margin-bottom: 20px;
        font-weight: bold;
      }
      p {
        @include font-main;
        margin-bottom: 60px;
        width: 520px;
        letter-spacing: -0.54px;
      }
    }
  }
  .empty {
    height: 300vh;
    width: 100%;
    display: block;
  }
  .contribution-campaign {
    width: 100%;
    background-color: $color-white;
    position: relative;
    height: 100vh;
    &.fixed {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
    }
    &.animation {
      .slogan-1 {
        // 정중앙으로 위치
        // 위치하면서 사라지기
        //
        animation: circleMove 0.8s linear both;
      }
      .slogan-2 {
        animation: circleMove 0.8s linear both;
      }
      .slogan-3 {
        animation: circleMove 0.8s linear both;
      }
      .campaign-item-show {
        opacity: 1;
        .campaign-ttl {
          h2 {
            animation: downtoup-f 0.6s linear both;
            animation-delay: 0.8s;
          }
          p {
            animation: downtoup-f 0.6s linear both;
            animation-delay: 1.2s;
          }
        }
      }
    }
    .campaign-item {
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        position: absolute;
        width: 247px;
        height: 247px;
        text-align: center;
        line-height: 247px;
        font-size: 20px;
        border: 1px dashed $color-black;
        border-radius: 50%;
        &.slogan-1 {
          left: 0;
        }
        &.slogan-2 {
          left: calc(50% - 123.5px);
        }
        &.slogan-3 {
          left: calc(100% - 249px);
        }
      }
    }
    .campaign-item-show {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      background: center / cover no-repeat
        url("https://img.davich.com/image/sub/about/img-contribution-03.jpg");
      transition: 2s;
      transition-delay: 1s;
      .campaign-ttl {
        text-align: center;
        h2 {
          font-family: $en-font;
          font-size: 60px;
          line-height: 70px;
          letter-spacing: -1.8px;
          margin-bottom: 20px;
          font-weight: 800;
        }
        p {
          @include font-main;
          margin-bottom: 60px;
          width: 550px;
          letter-spacing: -0.54px;
        }
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes circleMove {
  0% {
    opacity: 1;
  }
  100% {
    left: calc(50% - 123.5px);
    opacity: 0;
  }
}
</style>
