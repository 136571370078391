<template>
  <div class="ly-wrap ly-cont-m">
    <div class="contribution-post" v-bind="contentToShow">
      <h2 class="post-ttl">
        {{ contentToShow[0].title }}
      </h2>
      <p class="post-date">
        <em>작성일 &nbsp;</em>
        <span class="post-date-num">{{ contentToShow[0].created_dttm }}</span>
      </p>
      <div class="arrow">
        <a class="prevlink" href="javascript:void(0);">
          <button class="prev">
            <img
              src="https://img.davich.com/mobile/image/sub/about/caret-down.png"
              alt="arrow icon"
            />
          </button>
        </a>
        <a class="nextlink" href="javascript:void(0);">
          <button class="next">
            <img
              src="https://img.davich.com/mobile/image/sub/about/caret-down2.png"
              alt="arrow icon"
            />
          </button>
        </a>
      </div>
      <div class="post-img">
        <img :src="contentToShow[0].main_image" alt="post sample image" />
      </div>
      <p class="post-txt" v-html="contentToShow[0].contents"></p>
      <div class="post-list-btn ly-cont-m">
        <router-link
          class="el-btn el-btn-w100"
          to="/about/m_contributionPostList"
          >목록보기</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["contentToShow"],
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.contribution-post {
  margin: auto;
  padding: 50px 0;
  .post-ttl {
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
    padding: 20px 0;
    border-top: 1px solid $color-black;
    border-bottom: 1px solid #cccccc;
    letter-spacing: -0.5px;
  }
  .post-date {
    padding: 20px 0;
    font-size: 15px;
    text-align: left;
    float: left;
  }
  .arrow {
    text-align: right;
    float: right;
    padding: 10px 0;
    .prevlink {
      .prev img {
        transform: rotate(360deg);
        border: 1px solid #ccc;
        padding: 5px;
      }
    }
    .nextlink {
      .next img {
        transform: rotate(360deg);
        border: 1px solid #ccc;
        padding: 5px;
      }
    }
  }

  .post-img {
    display: inline-block;
    width: 100%;
    max-width: 700px;
    margin-top: -5px;
    img {
      width: 100%;
    }
  }
  .post-txt {
    width: 100%;
    padding: 30px 0;
    line-height: 25px;
    font-size: 14px;
    letter-spacing: -0.75px;
  }
  .post-list-btn {
    // max-width: 350px;
    // text-align: center;
    // margin: 60px auto 30px auto;
    // padding-top: 20px;
    // padding-bottom: 20px;
    // border: 1px solid #222;
    // font-size: 15px;
    // display: block;
    margin-bottom: 40px;
    margin-top: 40px;
  }
}
</style>
