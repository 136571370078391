<template>
  <div>
    <section class="ly-wrap">
      <div class="ly-cont-m">
        <img
          class="notfound"
          src="https://img.davich.com/mobile/image/m_icon_notfound.png"
          alt="notfound"
        />
        <h2>이용에 불편을 드려 죄송합니다.</h2>
        <h1>
          페이지의 경로가 잘못 입력되었거나 변경 혹은 삭제되어 요청하신 페이지를
          찾을 수 없습니다.<br />
          입력하신 경로가 정확한지 다시 한 번 확인해주시기 바랍니다. 감사합니다.
        </h1>

        <div class="btnbox">
          <button
            class="el-btn question-btn question-btn-left btn1"
            onclick="history.back(-1);"
          >
            이전페이지로 돌아가기
          </button>

          <router-link
            class="el-btn question-btn question-btn-left btn2"
            to="/"
          >
            메인페이지로 이동
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Meta from 'vue-meta';
export default {
  components: {},
  name: 'm_notFoundPage',
  metaInfo: {
    title: 'Mobile Error Page',
    meta:[
      {name:'robots',content: 'noindex,nofollow'}
    ]
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.notfound {
  display: block;
  margin: 80px auto 0;
}

h2 {
  @include font-h-25;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;
}
h1 {
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 90px;
}
.btnbox {
  width: 100%;
  height: 250px;
  margin: 0 auto;
  position: relative;
  .btn1 {
    display: block;
    margin: 0 auto 20px;
  }
  .btn2 {
    display: block;
    margin: 0 auto;
  }
}

p {
  @include font-h-35;
  // color: $color-darkblue;
}
</style>
