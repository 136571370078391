<template>
  <div class="ly-w1400">
    <div class="ly-wrap">
      <div class="contribution-post" v-bind="contentToShow">
        <h2 class="post-ttl">
          {{ contentToShow[0].title }}
        </h2>
        <!--제목-->
        <p class="post-date">
          <em>작성일 &nbsp;</em>
          <span class="post-date-num">{{ contentToShow[0].created_dttm }}</span>
        </p>
        <!--작성일-->
        <div
          class="post-img"
          v-for="(item, index) of contentToShow[0].img_url"
          :key="index"
        >
          <img
            v-if="item"
            :src="item"
            alt="post sample image"
          />
        </div>
        <!--이미지-->
        <p class="post-txt" v-html="contentToShow[0].contents"></p>
        <!--내용-->
      </div>
      <!--포스트 내용-->
    </div>
    <div class="next-prev-post">
      <p>
        <em
          ><img
            src="https://img.davich.com/image/sub/about/icon-arrow-up.png"
            alt="arrow top icon"
          />
          이전글</em
        >
        <router-link
          :to="`/about/contributionPostDetail/${prevData[0].id}`"
          class="prev-post"
          v-if="prevData.length != 0"
          >{{ prevData[0].title }}</router-link
        >
        <a class="prev-post" v-else> 이전 게시물이 없습니다. </a>
      </p>
      <!--이전글-->
      <p>
        <em
          ><img
            src="https://img.davich.com/image/sub/about/icon-arrow-down.png"
            alt="arrow top icon"
          />다음글</em
        >
        <router-link
          :to="`/about/contributionPostDetail/${nextData[0].id}`"
          class="next-post"
          v-if="nextData.length != 0"
          >{{ nextData[0].title }}</router-link
        >
        <a class="next-post" v-else> 다음 게시물이 없습니다. </a>
      </p>
      <!--다음글-->
    </div>
    <!--이전글 다음글-->
    <div class="post-list-btn">
      <router-link class="el-btn" to="/about/contributionPostList"
        >목록보기</router-link
      >
    </div>
    <!--목록버튼-->
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["boardid"],

  computed: {
    ...mapState("about", {
      boardData: (state) => state.boardData,
    }),

    contentToShow() {
      const self = this;
      let result;
      if (this.boardData != '' && this.boardData != null){
      result = self.boardData.filter((item) => {
        let filtered = true;
        filtered = self.boardid == item.id;
        return filtered;
      });
      return result;
      }
    },

    prevData() {
      const self = this;

      let result;
      result = self.boardData.filter((item) => {
        let thisData = true;
        thisData = self.contentToShow[0].index - 1 == item.index;
        return thisData;
      });

      return result;
    },

    nextData() {
      const self = this;

      let result;
      result = self.boardData.filter((item) => {
        let thisData = true;
        thisData = self.contentToShow[0].index + 1 == item.index;
        return thisData;
      });

      return result;
    },
  },

  //사회공헌 게시판 벗어날 시 로컬스토리지 currentPage 초기화
  beforeRouteLeave(to, from, next) {
    if (
      to.name == "contributionPostList" ||
      to.name == "contributionPostDetail"
    ) {
      next();
    } else {
      localStorage.currentPage = 1;
      next();
    }
  },

  created() {
    if( this.boardData == '' || this.boardData == null ){
      this.$store.dispatch("FETCH_CONTRIBUTION_BOARD")
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

/*포스트 내용*/
.contribution-post {
  width: 700px;
  margin: auto;
  padding: 150px 0;
  .post-ttl {
    font-size: 25px;
    font-weight: bold;
    padding: 30px 0;
    border-top: 1px solid $color-black;
    border-bottom: 1px solid #cccccc;
    letter-spacing: -0.5px;
  }
  .post-date {
    padding: 30px 0;
    font-size: 18px;
    text-align: right;
  }
  .post-img {
    width: 700px;
    height: auto;
    img {
      width: 100%;
    }
  }
  .post-txt {
    padding: 70px 0;
    @include font-main;
  }
}

/*이전글, 다음글*/
.next-prev-post {
  width: 700px;
  margin: auto;
  border-top: 1px solid $color-black;
  border-bottom: 1px solid $color-black;
  p {
    @include font-main;
    padding: 20px;
    display: flex;
    align-items: center;
    &:first-child {
      border-bottom: 1px solid #ccc;
    }
    em {
      display: flex;
      align-items: center;
      font-weight: 500;
      margin-right: 34px;
      img {
        margin-right: 10px;
      }
    }
  }
}

/*문의하기 버튼 */
.post-list-btn {
  margin-top: 60px;
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
}
</style>
