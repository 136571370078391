export const getters = {
  getRecruit: function (state) {
    return state.boardData;
  },

  getPicVideo: function (state) {
    return state.picVideoData;
  },

  getSuccess: function (state) {
    return state.successStory;
  },

  getDepartment: function (state) {
    return state.departmentData;
  },
};
