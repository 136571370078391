<template>
  <div class="info-content" id="info-content">
    <section class="full-page-scroll">
      <sub-title :title="title" :content="content" :content2="content2"> </sub-title>
    </section>
    <section class="thought-item full-page-scroll info-section reliability">
      <div class="thought-desc">
        <div class="thought-ttl">
          <h2>Reliability</h2>
          <p>신뢰할 수 있는 브랜드</p>
        </div>
        <p class="thought-txt">
          <span>
            보이지 않았지만 우리는 항상 지켜왔습니다. <br />
            언제나 고객이 필요할 때 있어야 할 곳에서 묵묵히 해야 할 <br />
            일을 했습니다. 신뢰는 보이지 않는 작은 곳에서부터 시작<br />되는 것을 우리는 잘 알고 있기 때문입니다. <br />
          </span>
          <span class="scroll-fade-in-f">
            보이지 않는 곳에서 손을 내밀어 도움을 주는, 여러분이 믿고 <br />의지 할 수 있는 우리는 다비치입니다. <br />
          </span>
        </p>
      </div>
    </section>
    <!-- /.reliability -->
    <section class="thought-item full-page-scroll empathy">
      <div class="thought-desc-Emp">
        <div class="thought-ttl">
          <h2 class="scroll-fade-in-f">Empathy</h2>
          <p class="scroll-fade-in-f scroll-delay-1">함께 공감하는 브랜드</p>
        </div>
        <p class="thought-txt">
          <span class="scroll-fade-in-f scroll-delay-2">
            세상을 보는 관점을 바꾸는 것은 매우 어려운 일이었습니다. <br />
          </span>
          <span class="scroll-fade-in-f scroll-delay-3">
            노력은 항상 어려운 일에 필요합니다. <br />
            고객의 시선으로 세상을 이해하는 것은 말로는 쉽게 할 수
            <br />있습니다. 그 일이 어려운 일인 것을 알고 있기에 우리는 <br />노력하고 있습니다. <br />마음으로부터
            다가가기 위해 노력하고 있습니다.<br />
            진실된 경험을 전달하기 위해 노력하고 있습니다. <br />
            당신과 같은 곳을 바라보는 우리는 다비치입니다. <br />
          </span>
        </p>
      </div>
    </section>
    <!-- /.empathy -->
    <section class="thought-item full-page-scroll attunement">
      <div class="thought-desc-Att">
        <div class="thought-ttl">
          <h2 class="scroll-fade-in-f">Attunement</h2>
          <p class="scroll-fade-in-f scroll-delay-1">항상 조율하는 브랜드</p>
        </div>
        <p class="thought-txt">
          <span class="scroll-fade-in-f scroll-delay-2">
            하루가 다르게 세상은 빠르게 변하고 있습니다. <br />우리를 불안하게 하는 많은 것들이 나타났다 사라지는 일을
            <br />반복합니다. 불안한 많은 일들을 마주했을 때 균형을 잡으<br />면 한쪽으로 치우치지 않습니다. 기술이
            발전하고 시대가 바 <br />뀌어도 변하지 말아야 할 것들이 있습니다. <br />
          </span>
          <span class="scroll-fade-in-f scroll-delay-3">
            복잡한 세상의 중심에서 당신을 위해 균형을 잡고 있겠습니 <br />다. 당신과 함께하는 우리는 다비치입니다.<br />
          </span>
        </p>
      </div>
    </section>
    <!-- /.attunement -->
    <section class="thought-item full-page-scroll be-like">
      <div class="be-like-cont">
        <div class="be-like-cont-inner">
          <div class="be-like-item scroll-fade-in-f">
            <p>
              <strong>Reliability</strong>
              신뢰할 수 있는
              <span>머리(의식)</span>
            </p>
          </div>
          <div class="be-like-item scroll-fade-in-f scroll-delay-1">
            <p>
              <strong>Empathy</strong>
              함께 공감하는
              <span>가슴(마음)</span>
            </p>
          </div>
          <div class="be-like-item scroll-fade-in-f scroll-delay-2">
            <p>
              <strong>Attunement</strong>
              항상 조율하는
              <span>손(표현)</span>
            </p>
          </div>
          <div class="be-like-arrow scroll-fade-in-f scroll-delay-3">
            <img src="https://img.davich.com/mobile/image/sub/about/icon-arrow.png" alt="right arrow icon" />
          </div>
          <div class="be-like-item be-like-main scroll-fade-in-f scroll-delay-4">
            <h2>다비치다움</h2>
          </div>
        </div>
      </div>
      <p class="thought-txt">
        <span class="scroll-fade-in-f">
          다비치다움이란 다비치의 기본 원칙입니다. <br />이것은 모두에게 변하지 않는 신뢰를 만들어가는 과정이며, <br />
          이를 묵묵히 지켜가고자 하는 정신입니다. <br />
        </span>

        <span class="scroll-fade-in-f scroll-delay-1">
          우리만의 전문성을 바탕으로 한<br />
          고객 응대 프로세스를 만들어 왔으며,<br />
          전국 공통의 보편적 서비스로 추구하기 위해<br />
          우리의 모든 경험을 체계화 하기위한 노력을 <br />지속하고 있습니다.
          <br />
        </span>
        <span class="scroll-fade-in-f scroll-delay-2">
          우리의 노력은 궁극적으로 고객을 향한 봉사 정신과 활동이며,<br />
          이러한 묵묵한 발걸음을 통해 고객에게 감동을 드릴 수 <br />있다는 사명감을 최선을 다해 지켜가고 있습니다.<br />
        </span>
      </p>
    </section>
    <!-- /.be like -->
    <section class="info-history full-page-scroll info-section">
      <div class="history-cont">
        <div class="history-img scroll-fade-in-f">
          <img src="https://img.davich.com/mobile/image/sub/about/img-history.png" alt="right arrow icon" />
          <!-- <h2>HISTORY</h2> -->
        </div>
        <div class="history-desc scroll-fade-in-f scroll-delay-1">
          <p v-for="item in getHistory" :key="item.id">
            <em v-html="item.yyyy"></em><span v-html="item.description"></span>
          </p>
        </div>
      </div>
    </section>
    <!-- /.history -->
  </div>
</template>

<script>
import SubTitle from "../../../components/mobile/MSubTitle1.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    SubTitle,
  },
  data() {
    return {
      title: "기업소개",
      content: "전문성과 신뢰를 바탕으로 한 ",
      content2: "최고의 안경전문기업, 다비치입니다.",
    };
  },

  computed: {
    ...mapGetters("about", ["getHistory"]),
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.sub-title {
  background-image: url("https://img.davich.com/mobile/image/sub/subtitle/sub-about-bg-01.png");
  background-position: bottom;
}

/*경영이념*/
.thought-item {
  width: 100vw;
  height: 100vh;
  min-height: 927px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  &.reliability {
    color: $color-white;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("https://img.davich.com/mobile/image/sub/about/img-thought-bg-01.png");
  }
  &.empathy {
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("https://img.davich.com/mobile/image/sub/about/img-thought-bg-02.png");
  }
  &.attunement {
    background-position-x: center;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("https://img.davich.com/mobile/image/sub/about/img-thought-bg-03.png");
  }
  .thought-desc {
    position: relative;
    margin-top: -40px;
    &:before {
      content: "";
      position: absolute;
      top: -310px;
      left: 50%;
      transform: translateX(-50%);
      width: 2px;
      height: 30px;
      background-color: $color-white;
      opacity: 0.3;
    }
    .thought-ttl {
      margin-bottom: 50px;
      margin-top: -270px;
      h2 {
        margin-top: 10px;
        line-height: normal;
        font-family: $en-font;
        font-size: 35px;
        font-weight: 700;
        letter-spacing: -1.8px;
      }
      p {
        line-height: 40px;
        font-size: 18px;
        letter-spacing: -0.75px;
        font-weight: 600;
      }
    }
  }
  .thought-desc-Emp {
    position: relative;
    margin-top: 60px;
    &:before {
      content: "";
      position: absolute;
      top: -310px;
      left: 50%;
      transform: translateX(-50%);
      width: 2px;
      height: 30px;
      background-color: $color-black;
      opacity: 0.3;
    }
    .thought-ttl {
      margin-bottom: 50px;
      margin-top: -270px;
      h2 {
        margin-top: 10px;
        line-height: normal;
        font-family: $en-font;
        font-size: 35px;
        font-weight: 700;
        letter-spacing: -1.8px;
      }
      p {
        line-height: 40px;
        font-size: 18px;
        letter-spacing: -0.75px;
        font-weight: 600;
      }
    }
  }
  .thought-desc-Att {
    position: relative;
    margin-top: 160px;
    &:before {
      content: "";
      position: absolute;
      top: -310px;
      left: 50%;
      transform: translateX(-50%);
      width: 2px;
      height: 30px;
      background-color: $color-black;
      opacity: 0.3;
    }
    .thought-ttl {
      margin-bottom: 50px;
      margin-top: -270px;
      h2 {
        margin-top: 10px;
        line-height: normal;
        font-family: $en-font;
        font-size: 35px;
        font-weight: 700;
        letter-spacing: -1.8px;
      }
      p {
        line-height: 40px;
        font-size: 18px;
        letter-spacing: -0.75px;
        font-weight: 600;
      }
    }
  }
}

//경영이념 본문
.thought-txt {
  line-height: 27px;
  letter-spacing: -0.54px;
  margin-top: -20px;
  max-width: 100%;
  span {
    font-size: 13.5px;
    font-weight: 300;
    display: block;
    margin: 0 auto 20px auto;
    &:last-child {
      margin-bottom: 110px;
    }
  }
}

//다비치다움
.be-like {
  height: auto !important;
  display: block;
  padding-top: 120px;
  box-sizing: border-box;
  .be-like-cont {
    margin-bottom: 90px;
    .be-like-cont-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      .be-like-item {
        p,
        h2 {
          width: 248px;
          height: 248px;
          border-radius: 50%;
          border: 1px solid $color-blue;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          justify-content: center;
          margin-top: -30px;
          font-size: 16px;
          line-height: 9px;
          letter-spacing: -0.54px;
          letter-spacing: -0.54px;
          strong {
            display: inline-block;
            font-size: 24px;
            letter-spacing: -1.05px;
            font-weight: 700;
            color: $color-blue;
            line-height: normal;
            margin-bottom: 10.5px;
          }
        }
        h2 {
          background-color: $color-blue;
          color: $color-white;
          font-size: 25px;
          font-weight: 500;
          margin-bottom: 10.5px;
          margin-top: 0px;
        }
        span {
          @include font-main;
          letter-spacing: -0.54px;
          display: inline-block;
          font-size: 15px;
          color: #ccc;
          margin-top: 30px;
        }
        &:nth-child(2) {
          margin: 0 -42px;
        }
      }
      .be-like-arrow {
        height: 50px;
        display: flex;
        align-items: center;
        margin-top: 0px;
        img {
          //화살표
          width: 20px;
          height: 50px;
        }
      }
    }
  }
}

/*회사연혁*/
.info-history {
  width: 100vw;
  .history-cont {
    width: 100vw;
    .history-img {
      position: relative;
      width: 100vw;
      height: 500px;
      display: inline-block;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .history-desc {
      padding-top: 50px;
      width: 85vw;
      margin: auto;
      font-size: 16px;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: #dedede;
      }
      &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
      }
      p {
        padding: 17.5px 0;
        display: flex;
        em {
          font-family: $en-font;
          font-weight: 600;
          margin-right: 37px;
          line-height: 35px;
        }
        span {
          line-height: 35px;
          span {
            display: block;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
</style>
