<template>
  <div>
    <div class="popup" v-for="item in popupData" :key="item.id">
      <a :href="item.url" target="_blank" class="img-wrap">
        <img :src="item.attachment.download_url" alt="popup image" />
      </a>
      <div class="popup-btn-wrap">
        <button class="today-btn">오늘 하루 보지 않기</button>
        <button class="close-btn" @click="close()">닫기</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  beforeCreate() {
    this.$store.dispatch("FETCH_POPUP");
  },

  computed: {
    ...mapState({
      popupData: (state) => state.popupData,
    }),
  },

  methods: {
    close() {
      let popUp = document.querySelector(".popup");
      popUp.style.display = "none";
    },
  },

  updated() {
    // 24시간동안 안보이게
    var popUp = document.querySelector(".popup");

    // setCookie
    function setCookie2(name, value, expirehours) {
      var todayDate = new Date();
      todayDate.setHours(todayDate.getHours() + expirehours); //현재시간 + 변수
      document.cookie =
        name +
        "=" +
        escape(value) +
        "; path=/; expires=" +
        todayDate.toGMTString() +
        ";";
    }

    //click시 setCookie 실행 code
    function todaycloseWin() {
      let todayBtn = document.querySelector(".today-btn");
      todayBtn.addEventListener("click", function () {
        setCookie2("qcookie", "done", 24); //setCookie(name,how,time)
        popUp.style.display = "none";
      });
    }
    todaycloseWin();

    //쿠키 유무 확인 (없을경우 팝업창 띄워준다)
    var cookiedata2 = document.cookie;
    if (cookiedata2.indexOf("qcookie=done") < 0) {
      popUp.style.display = "flex";
    } else {
      popUp.style.display = "none";
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.popup {
  position: fixed;
  width: 85vw;
  @include box-center;
  z-index: 99999;
  flex-direction: column;
  max-width: 400px;

  .img-wrap {
    width: 100%;
    max-width: 400px;
    height: 105.5vw;
    max-height: 500px;
    //background-color: $color-white;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }
  .popup-btn-wrap {
    display: table;
    width: 100%;
    height: 50px;
    button {
      display: table-cell;
      width: 50%;
      line-height: 50px;
      text-align: center;
      &.today-btn {
        background-color: $color-white;
      }
      &.close-btn {
        background-color: $color-blue;
        color: $color-white;
      }
    }
  }
}
</style>
