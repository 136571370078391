<template>
  <div class="cf-main-wrap">
    <sub-title :title="title" :content="content"> </sub-title>
    <section class="cf-main scroll-fade-in-f">
      <iframe
        id="play-frame"
        :src="`https://www.youtube.com/embed/${noticeData.uniqueUrl}?autoplay=1&mute=1&controls=0&loop=1$playlist=${noticeData.uniqueUrl}`"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture muted;"
        allowfullscreen
      ></iframe>
      <div class="cf-ttl">
        <em>{{ noticeData.title }}</em>
        <h2>{{ noticeData.content }}</h2>
      </div>
      <!--비디오-->
    </section>
    <section class="cf-list scroll-fade-in-f">
      <h2 class="hidden">davich cf list</h2>
      <div class="m-menu-wrap">
        <div class="menu">
          <button @click="[setClass(''), menuColor(0)]" class="cf-btn active">전체</button>
          <button @click="[setClass('TV CF'), menuColor(1)]" class="cf-btn">TV CF</button>
          <button @click="[setClass('DIGITAL CF'), menuColor(2)]" class="cf-btn">DIGITAL CF</button>
          <!-- <tab-menu-2 :tabTitle="tabTitle"></tab-menu-2> -->
        </div>
      </div>
      <!-- <tab-menu-2 :tabTitle="tabTitle"></tab-menu-2> -->
      <search-input :mutationName="'whatsnew/SEARCH_CF'" :boardData="getCfData" v-on:switchVar="switchVar"></search-input>
    </section>
    <!-- 필터링 X -->
    <div class="m-cf-cont scroll-fade-in-f" v-if="isFiltered == false">
      <div class="m-cf-items">
        <a class="m-cf-item" v-for="item in listToShow" :key="item.id">
          <div class="img-wrap">
            <img :src="item.imgurl" alt="davich cf" @click="showModal(item.id)" />
          </div>
          <h2>{{ item.type }}</h2>
          <h3>{{ item.title }}</h3>
          <p>
            {{ item.content }}
          </p>
          <span>{{ item.date }}</span>
        </a>
      </div>
    </div>

    <!-- 필터링 O -->
    <div class="m-cf-cont scroll-fade-in-f" v-else-if="isFiltered == true">
      <div class="m-cf-items">
        <a class="m-cf-item" v-for="item in filteredToShow" :key="item.id">
          <div class="img-wrap">
            <img :src="item.imgurl" alt="davich cf" @click="showModal(item.id)" />
          </div>
          <h2>{{ item.type }}</h2>
          <h3>{{ item.title }}</h3>
          <p>
            {{ item.content }}
          </p>
          <span>{{ item.date }}</span>
        </a>
      </div>
    </div>

    <b-pagination id="pagination" :total-rows="rows" v-model="currentPage" :per-page="perPage"></b-pagination>

    <div class="m-cf-btn ly-cont-m">
      <button class="el-btn-m" id="btnMoreData" @click="getMoreData">더보기</button>
    </div>
  </div>
</template>

<script>
import SubTitle from "../../../components/mobile/MSubTitle1.vue";
import SearchInput from "../../../components/mobile/MSearchInput.vue";
import PlayYoutube from "../../../components/mobile/EDUCATION/MPlayYoutube.vue";

import { mapGetters, mapState } from "vuex";

export default {
  beforeCreate() {
    this.$store.dispatch("whatsnew/FETCH_CF");
  },

  components: {
    SubTitle,
    SearchInput,
  },

  data() {
    return {
      title: "광고/홍보영상",
      content: "다비치가 준비한 새로운 CF를 마음껏 즐겨보세요.",

      playId: 0,

      isFiltered: false,
      selectedClass: "",
      isSearched: false,

      //pagination
      perPage: 4,
      currentPage: 1,

      lengthListToShow: 0,
      lengthFilteredToShow: 0,
    };
  },

  computed: {
    ...mapGetters("whatsnew", ["getCfData"]),
    ...mapState("whatsnew", {
      searchedData: (state) => state.searchedCf,
    }),

    listToShow() {
      const self = this;
      let result;
      let boardData = [];
      if (self.isSearched == false) {
        boardData = self.getCfData;
      } else {
        boardData = self.searchedData;
      }

      self.lengthListToShow = boardData.length;

      result = boardData.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
      return result;
    },

    filteredToShow() {
      const self = this;
      let selectedClass = this.selectedClass;
      let result;
      let boardData = this.getCfData.filter((item) => {
        let filtered = true;
        if (selectedClass && selectedClass.length > 0) {
          filtered = item.type == selectedClass;
        }
        return filtered;
      });

      self.lengthFilteredToShow = boardData.length;

      result = boardData.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);

      return result;
    },

    //공지설정된 데이터중 첫번째데이터 가져오기
    noticeData() {
      let noticeList = this.getCfData.filter((item) => {
        let filtered = true;
        filtered = item.notice == 1;
        return filtered;
      });

      return noticeList[0];
    },

    rows() {
      const self = this;
      if (self.isFiltered == false) {
        return self.lengthListToShow;
      } else {
        return self.lengthFilteredToShow;
      }
    },
  },

  watch: {
    //게시물 4개 안될 때 더보기 숨김
    filteredToShow() {
      const btn = document.getElementById("btnMoreData");
      if (this.perPage >= this.rows) {
        btn.style.display = "none";
      } else {
        btn.style.display = "block";
      }
    },

    listToShow() {
      const btn = document.getElementById("btnMoreData");
      if (this.perPage >= this.rows) {
        btn.style.display = "none";
      } else {
        btn.style.display = "block";
      }
    },
  },

  methods: {
    //선택한 class(분류)에 따라서 필터링,필터링변수 true
    setClass(selectedClass) {
      this.perPage = 4;
      const btn = document.getElementById("btnMoreData");
      btn.style.display = "block";

      if (this.isFiltered == false) {
        this.isFiltered = true;
      }

      this.selectedClass = selectedClass;
      if (selectedClass == "" || selectedClass.length == 0) {
        this.isFiltered = false;
        this.isSearched = false;
      }
    },

    showModal(playId) {
      this.playId = playId;

      this.$modal.show(
        PlayYoutube,
        {
          playId: this.playId,
          youtubeUrl: this.getCfData,
        },
        {
          adaptive: true,
          minWidth: 320,
          minHeight: 180,
        },
        {
          "before-open": this.beforeOpen,
        }
      );
    },

    //더보기 버튼 클릭 시 데이터 추가로 표시.
    getMoreData() {
      const btn = document.getElementById("btnMoreData");
      this.perPage += 4;

      //마지막 데이터에서 더보기버튼 숨김
      if (this.perPage >= this.rows) {
        btn.style.display = "none";
      }
    },

    switchVar(param) {
      if (param == true) {
        this.isSearched = true;
        this.isFiltered = false;
        this.perPage = 10;
        const btn = document.getElementById("btnMoreData");
        btn.style.display = "block";
      } else {
        this.isSearched = false;
      }
    },

    //button color
    menuColor(index) {
      const tabBtn = document.querySelectorAll(".cf-btn");
      for (let i = 0; i < tabBtn.length; i++) {
        tabBtn[i].classList.remove("active");
      }
      tabBtn[index].classList.add("active");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.sub-title {
  background-image: url("https://img.davich.com/mobile/image/sub/subtitle/sub-what-bg-01.png");
  background-size: cover;
}

.cf-main-wrap {
  margin-bottom: 50px;
  .cf-list {
    .search-comp {
      margin: 50px auto 100px;
    }
  }
  .cf-main {
    margin: 70px 0;
    position: relative;
    #play-frame {
      width: 100%;
      height: 56.25vw;
    }
    img {
      width: 100vw;
    }
    // cf title
    .cf-ttl {
      position: absolute;
      bottom: 30px;
      left: 30px;
      color: #fff;
      em {
        font-weight: 200;
        font-size: 15px;
        line-height: 20px;
      }
      h2,
      h3 {
        margin-top: 7px;
        font-size: 18px;
        line-height: 15px;
        font-weight: 600;
      }
    }
  }
  //콘텐츠 목록
  .m-cf-cont {
    width: 90vw;
    margin: -50px auto 0 auto !important;
    .m-cf-items {
      width: 100%;
      font-family: $ko-font;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      flex-direction: row;
      height: 100%;
      margin: 0 auto;
      margin-bottom: 0px;
      .m-cf-item {
        margin-bottom: 50px;
        width: 40vw;
        margin-right: 2.5vw;
        margin-left: 2.5vw;
        min-width: 40vw;
        display: flex;
        flex-direction: column;
        display: block;
        .img-wrap {
          @include img-wrap;
          width: 100%;
          height: 59vw;
          img {
            height: 151%;
            @include box-center;
          }
        }
        h2 {
          width: 100%;
          margin-top: 15px;
          font-size: 13px;
          letter-spacing: -0.75px;
          color: #003a70;
          font-weight: 400;
        }
        h3 {
          width: 100%;
          margin-top: 8px;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: -0.75px;
        }
        p {
          width: 100%;
          margin-top: 16px;
          margin-bottom: 14px;
          line-height: 20px;
          font-size: 14px;
          letter-spacing: -0.75px;
          color: #888;
          text-align: justify;
        }
        span {
          font-size: 13px;
          color: #aaa;
        }
      }
    }
  }
  .el-btn-m {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

#pagination {
  display: none;
}
</style>
