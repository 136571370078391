<template>
  <div class="sub-title">
    <div class="sub-title-txt">
      <h2 v-html="title"></h2>
      <p>
        <span>{{ this.content }}</span>
        <span>{{ this.content2 }}</span>
        <span>{{ this.content3 }}</span>
        <span>{{ this.content4 }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "content", "content2", "content3", "content4"],
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.sub-title {
  width: 100%;
  //height: 600px;
  height: max(600px, 110vw);
  overflow: hidden;
  background-size: cover;
  background-position: center;
  position: relative;
  .sub-title-txt {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    width: 100vw;
    h2 {
      font-size: 40px;
      font-weight: bold;
      line-height: normal;
      margin-bottom: 10px;
      letter-spacing: -1.2px;
    }
    p {
      font-size: 17px;
      letter-spacing: -0.51px;
      line-height: normal;
      opacity: 0.8;
      span {
        display: block;
      }
    }
  }
}
</style>
