<template>
  <div>
    <section class="myfeed-insta ly-cont to-index">
      <h2 class="scroll-fade-in">instagram</h2>
      <div class="instafeed scroll-fade-in scroll-delay-1">
        <div
          class="instafeed-inner"
          v-for="item in latestInstaData"
          :key="item.id"
        >
          <a class="instafeed-cont" :href="item.permalink" target="_blank">
            <img
              v-if="item.thumbnail_url"
              class="instafeed-img"
              :src="item.thumbnail_url"
              alt="instaimg"
            />
            <img
              v-else
              class="instafeed-img"
              :src="item.media_url"
              alt="instaimg"
            />
            <img
              class="instafeed-type"
              src="https://img.davich.com/image/main/icon-instagram-carousel.png"
              alt="instagram carousel type icon"
            />
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  computed: {
    latestInstaData() {
      var state = this.$store.state.instaItem;
      return state;
    },
  },
  data() {
    return {
      acvite: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.myfeed-insta {
  padding-top: 40px;
  height: calc(100vh - 80px);
  h2 {
    font-size: 60px;
    font-weight: 800;
    line-height: 71px;
    margin-bottom: 30px;
    font-family: $en-font;
  }

  .instafeed {
    margin-bottom: -40px;
    .instafeed-inner {
      width: 320px;
      height: 320px;
      position: relative;
      display: inline-block;
      overflow: hidden;
      margin-right: 40px;
      margin-bottom: 40px;
      &:nth-child(4n) {
        margin-right: 0;
      }
      .instafeed-cont {
        display: inline-block;
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        .instafeed-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: 0.3s;
        }
        .instafeed-type {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
      &:hover {
        .instafeed-img {
          transform: scale(1.2);
        }
        // .instafeed-txt {
        //   opacity: 1;
        //   visibility: visible;

        //   &:before {
        //     transform: scale(1);
        //   }
        // }
      }
      // .instafeed-txt {
      //   pointer-events: none;
      //   position: absolute;
      //   width: 100%;
      //   height: 100%;
      //   top: 0;
      //   left: 0;
      //   color: $color-white;

      //   &:before {
      //     content: "";
      //     width: 100%;
      //     height: 100%;
      //     position: absolute;
      //     background-color: $color-blue;
      //     opacity: 0.8;
      //     z-index: 0;
      //     transform: scale(0.8);
      //     transition: 0.3s;
      //   }
      //   opacity: 0;
      //   visibility: hidden;

      //   .instafeed-txt-inner {
      //     padding: 55px 50px;
      //     z-index: 1;
      //     position: relative;
      //     display: flex;
      //     flex-direction: column;
      //     height: 100%;
      //     box-sizing: border-box;
      //     text-overflow: ellipsis;

      //     .comment-like {
      //       display: flex;
      //       align-items: center;
      //       justify-content: center;
      //       flex: 1;
      //       > span {
      //         display: flex;
      //         align-items: center;
      //         justify-content: center;
      //         margin-right: 44px;
      //         font-size: 20px;
      //         img {
      //           display: inline-block;
      //           margin-right: 10px;
      //         }
      //       }
      //     }
      //     .txt {
      //       height: 70px;
      //       overflow: hidden;
      //     }
      //   }
      // }
    }
  }
}
</style>
