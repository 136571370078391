<template>
  <div class="ly-w1400 identity" id="identity">
    <section class="full-page-scroll">
      <sub-title-1 :title="title" :content="content"> </sub-title-1>
    </section>
    <aside class="info-sidebar sidebar">
      <button
        class="sidebar-btn sidebar-btn1"
        :class="{ sidebarActive: activeSection - 1 == 0 }"
        v-on:click="scrollToSection(1)"
      >
        <span>워드마크</span>
      </button>
      <button
        class="sidebar-btn sidebar-btn2"
        :class="{ sidebarActive: activeSection - 1 == 1 }"
        v-on:click="scrollToSection(2)"
      >
        <span>슬로건</span>
      </button>
      <button
        class="sidebar-btn sidebar-btn2"
        :class="{
          sidebarActive: activeSection - 1 == 2 || activeSection - 1 == 3,
        }"
        v-on:click="scrollToSection(3)"
      >
        <span>시그니처</span>
      </button>
      <!-- <button
        class="sidebar-btn sidebar-btn2"
        :class="{ sidebarActive: activeSection - 1 == 4 }"
        v-on:click="scrollToSection(5)"
      >
        <span>지정서체</span>
      </button> -->
    </aside>
    <!--오른쪽 버튼 -->
    <section class="word-mark full-page-scroll identity-section ly-cont">
      <h2 class="identity-heading scroll-fade-in-f">
        Word Mark<br />
        워드마크
      </h2>
      <p class="scroll-fade-in-f scroll-delay-1">
        다비치는 세상을 맑고 밝게 비춘다는 순 우리말로 소외된 어려운 이웃까지 맑고 밝게 다 비춘다는 의미입니다.<br />
        워드마크는 다비치 브랜드의 아이덴티티와 핵심가치를 함축적으로 담고 있습니다.<br />
        다비치와 고객이 신뢰로 함께 만들어온 전통과 새롭게 펼쳐질 미래를 밝게 비추어 가는 아름다운 빛을 시각적으로
        표현하였습니다.
      </p>
      <div class="wordmark-logo">
        <div class="scroll-fade-in-f">
          <div class="img">
            <img src="https://img.davich.com/image/sub/about/logo-wordmark.png" alt="davich wordmark english" />
          </div>
          <span>워드마크 (영문)</span>
        </div>
        <div class="scroll-fade-in-f scroll-delay-1">
          <div class="img">
            <img src="https://img.davich.com/image/sub/about/logo-wordmark-ko.png" alt="davich wordmark" />
          </div>
          <span>워드마크 (국문)</span>
        </div>
      </div>
    </section>
    <!--/.wordmark -->
    <section class="slogan full-page-scroll identity-section ly-cont">
      <h2 class="identity-heading scroll-fade-in-f">
        Brand Slogan<br />
        슬로건
      </h2>
      <p class="scroll-fade-in-f scroll-delay-1">
        고객의 가치있는 미래를 열어가는 다비치의 브랜드 미션을 기준으로<br />
        신뢰, 조율, 공감의 브랜드 핵심가치가 새로운 다비치 브랜드를 구체화 할 수 있도록 구성되어 있으며, <br />
        Light Up Your Life 이라는 슬로건을 중심으로 다비치가 나아가야 할 방향을 설정하였습니다.<br />
      </p>
      <div class="slogan-logo scroll-fade-in-f">
        <img src="https://img.davich.com/image/sub/about/slogan.png" alt="davich slogan" />
      </div>
    </section>
    <!-- /.slogan -->
    <section class="signature identity-section ly-cont">
      <h2 class="hidden">시그니처</h2>
      <article class="symbol full-page-scroll ly-cont">
        <h3 class="identity-heading scroll-fade-in-f">
          Signature<br />
          시그니처
        </h3>
        <div class="symbol-cont">
          <div class="symbol-motif symbol-item scroll-fade-in-f">
            <h4>Symbol motif</h4>
            <p>
              빛이 어둠에 은은하게 비추어 들어와 표면에 맺혀 부드럽고 따스하게 스며들어가는 형상을 바탕으로 신뢰하고
              공감하고 조율하는 다비치다움을 모던하고 심플하게 해석하여 시각적으로 표현했습니다.
            </p>
            <div class="img">
              <img src="https://img.davich.com/image/sub/about/symbol-motif.png" alt="davich symbol motif picture" />
            </div>
          </div>
          <div class="symbol-mark symbol-item scroll-fade-in-f scroll-delay-1">
            <h4>Symbol Mark</h4>
            <p>
              다비치의 심볼의 12가지 빛의 흐름은 고객과 사회를 향한 봉사정신과 고객을 위해 묵묵히 노력하는 발걸음,
              그리고 고객에게 감동을 전달하고자 하는 다비치의 사명감을 나타냅니다. 다비치의 새로운 심볼은 “어둠을 뚫고
              나가는 빛의 형상이 새롭고 조화로운 질서로 맺혀 하나가 되어가는 과정을 의미합니다.”
            </p>
            <div class="img">
              <img src="https://img.davich.com/image/sub/about/symbol-mark.png" alt="davich symbol motif picture" />
            </div>
          </div>
        </div>
      </article>
    </section>
    <!-- /.signature 01 -->
    <section class="signature identity-section ly-cont">
      <article class="signature-combine full-page-scroll ly-cont">
        <div class="combine-ttl">
          <h3 class="identity-heading scroll-fade-in-f">
            Signature<br />
            시그니처
          </h3>
          <p class="scroll-fade-in-f scroll-delay-1">
            다비치의 심볼마크와 워드마크 그리고 슬로건의 조합은 각 형태에 따라 비례를 조정한 것이므로 <br />
            사용의 오류를 방지하기 위해 모든 원고의 사용은 매뉴얼 데이터에 의한 확대, 축소, 복사가 되어야 합니다.
          </p>
        </div>
        <div class="combine-content">
          <div class="combine-updown scroll-fade-in-f scroll-delay-2">
            <img src="@/assets/image/using/sub/about/logo-signature.png" alt="davich signature logo" />
            <p>시그니처 상하 조합</p>
          </div>
          <div class="combine-leftright scroll-fade-in-f scroll-delay-3">
            <div class="combine-item">
              <p>
                시그니처 안경 <br />
                좌우조합(국문)
              </p>
              <div class="img">
                <img src="@/assets/image/using/sub/about/logo-gl.png" alt="davich glasses signature logo" />
              </div>
            </div>
            <div class="combine-item">
              <p>
                시그니처 안경 <br />
                좌우조합(영문)
              </p>
              <div class="img">
                <img src="@/assets/image/using/sub/about/logo-gl-en.png" alt="davich glasses signature logo" />
              </div>
            </div>
            <div class="combine-item">
              <p>
                시그니처 보청기 <br />
                좌우조합(국문)
              </p>
              <div class="img">
                <img src="@/assets/image/using/sub/about/logo-hearing.png" alt="davich hearing signature logo" />
              </div>
            </div>
            <div class="combine-item">
              <p>
                시그니처 보청기 <br />
                좌우조합(영문)
              </p>
              <div class="img">
                <img src="@/assets/image/using/sub/about/logo-hearing-en.png" alt="davich hearing signature logo" />
              </div>
            </div>
            <!-- <div class="combine-item">
              <p>
                시그니처 마켓 <br />
                좌우조합(국문)
              </p>
              <div class="img">
                <img
                  src="https://img.davich.com/image/sub/about/logo-market.png"
                  alt="davich glasses signature logo"
                />
              </div>
            </div>
            <div class="combine-item">
              <p>
                시그니처 마켓 <br />
                좌우조합(영문)
              </p>
              <div class="img">
                <img
                  src="https://img.davich.com/image/sub/about/logo-market-en.png"
                  alt="davich glasses signature logo"
                />
              </div>
            </div> -->
            <div class="combine-item">
              <p>
                시그니처 렌즈 <br />
                좌우조합(국문)
              </p>
              <div class="img">
                <img src="@/assets/image/using/sub/about/logo-lens.png" alt="davich lens signature logo" />
              </div>
            </div>
            <div class="combine-item">
              <p>
                시그니처 렌즈 <br />
                좌우조합(영문)
              </p>
              <div class="img">
                <img src="@/assets/image/using/sub/about/logo-lens-en.png" alt="davich lens signature logo" />
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
    <!-- /.signature 02 -->
    <top-button v-on:initNum="initNum" class="top-btn-main pc-btn"></top-button>
  </div>
</template>

<script>
import SubTitle1 from "../../components/SubTitle1.vue";
import TopButton from "../../components/TopButton.vue";

import { scrollIntoView } from "seamless-scroll-polyfill";

export default {
  components: {
    SubTitle1,
    TopButton,
  },
  data() {
    return {
      title: "비주얼 아이덴티티",
      content: "전문성과 신뢰를 바탕으로 한 최고의 안경전문기업, 다비치입니다.",

      inMove: false,
      activeSection: 0,
      offsets: [],
    };
  },

  methods: {
    //각 섹션의 오프셋top값 저장하는 함수
    calculateSectionOffsets() {
      let section = document.getElementsByClassName("full-page-scroll");
      for (let i = 0; i < section.length; i++) {
        let sectionOffset = section[i].offsetTop;
        this.offsets.push(sectionOffset);
      }
    },

    handleMouseWheel: function (e) {
      if (e.deltaY > 0 && !this.inMove) {
        this.moveDown();
      } else if (e.deltaY < 0 && !this.inMove) {
        this.moveUp();
      }

      e.preventDefault();
      return false;
    },

    moveDown() {
      this.inMove = true;
      this.activeSection++;
      if (this.activeSection > this.offsets.length - 1) {
        this.activeSection = this.offsets.length - 1;
      }
      this.scrollToSection(this.activeSection, true);
    },
    moveUp() {
      this.inMove = true;
      this.activeSection--;
      if (this.activeSection < 0) {
        this.activeSection = 0;
      }
      this.scrollToSection(this.activeSection, true);
    },

    //해당 컴포넌트로 스크롤
    scrollToSection(id, force = false) {
      if (this.inMove && !force) return false;

      this.activeSection = id;
      this.inMove = true;

      setTimeout(() => {
        scrollIntoView(document.getElementsByClassName("full-page-scroll")[id], {
          block: "end",
          behavior: "smooth",
        });
      }, 150);

      if (this.activeSection != 0 || this.activeSection != 5) {
        setTimeout(() => {
          this.inMove = false;
        }, 151);
      }
    },

    //top button
    initNum() {
      const self = this;
      self.activeSection = 0;
    },
  },

  mounted() {
    ///////////////풀스크린 스크롤
    this.calculateSectionOffsets();

    const thisPage = document.getElementById("identity");
    thisPage.addEventListener("wheel", this.handleMouseWheel, {
      passive: false,
    });
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.identity {
  .sub-title {
    background-image: url("https://img.davich.com/image/sub/subtitle/sub-about-bg-02.png");
  }
  //identity layout
  .identity-heading {
    @include font-h-35;
    font-family: $en-font;
    margin-bottom: 50px;
    letter-spacing: -1.05px;
  }
  p {
    @include font-main;
    letter-spacing: -0.54px;
  }
  .word-mark,
  .slogan,
  .symbol,
  .signature-combine,
  .typo {
    height: 100vh;
    box-sizing: border-box;
    padding-top: 120px;
    position: relative;
  }
  //word-mark
  .word-mark {
    .wordmark-logo {
      margin-top: 200px;
      display: flex;
      > div {
        height: 215px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        &:nth-child(1) {
          padding: 0 361px 0 141px;
        }
      }
    }
  }
  //slogan
  .slogan {
    .slogan-logo {
      margin-top: 244.8px;
      @include box-center-flex;
    }
  }
  //signature
  .signature {
    //symbol
    .symbol {
      .symbol-cont {
        display: flex;
        .symbol-item {
          h4 {
            font-size: 25px;
            font-weight: bold;
            margin-bottom: 10px;
          }
          .img {
            margin-top: 30px;
          }
          &.symbol-motif {
            width: 549px;
            margin-right: 71px;
            img {
              height: 40vh;
            }
          }
          &.symbol-mark {
            flex: 1;
            .img {
              padding-top: 50px;
              display: flex;
              width: 100%;
              @include box-center-flex;
              height: auto;
            }
          }
        }
      }
    }
    //signature
    .signature-combine {
      .combine-ttl {
        display: flex;
        margin-bottom: 50px;
        h3 {
          width: 325px;
        }
      }
      .combine-content {
        display: flex;
        .combine-updown {
          margin-right: 142px;
          text-align: center;
          width: 185px;
          flex-shrink: 0;
          img {
            width: 100%;
          }
          p {
            margin-top: 50px;
          }
        }
        .combine-leftright {
          display: flex;
          flex-wrap: wrap;
          .combine-item {
            margin-bottom: 7.4vh;
            display: flex;
            p {
              width: 155px;
            }
            .img {
              height: 60px;
              img {
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}

/* 사이드 bar */
.info-sidebar {
  z-index: 3;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  .sidebar-btn {
    @include font-main;
    display: block;
    width: 50px;
    height: 145px;
    border: 1px solid $color-blue;
    transform: skewY(40deg);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-blue;
    background-color: $color-white;
    span {
      transform: skewY(-40deg);
      writing-mode: vertical-rl;
      text-orientation: upright;
    }
    &.sidebarActive {
      background-color: $color-blue;
      border: 1px solid $color-blue;
      color: $color-white;
    }
  }
}

.top-btn-main {
  z-index: 99999999999;
  .top-btn {
    z-index: 99999999999;
  }
}
</style>
