<template>
  <section class="product_a">
    <div class="a_txt ly-cont-m" :class="txtClass">
      <img class="product_line scroll-fade-in-f scroll-delay-1" src="https://img.davich.com/mobile/image/sub/product/icon-product-02.png" alt="product_line" />
      <img class="product_logo scroll-fade-in-f scroll-delay-1" :src="logoSrc" alt="brand_logo" />
      <p class="scroll-fade-in-f scroll-delay-2" v-html="content"></p>
      <a :href="outlinkUrl" target="_blank" class="el-btn scroll-fade-in-f scroll-delay-3">자세히 보기</a>
    </div>
    <div class="a_img" :class="imgClass">
      <img v-for="(src, index) in imgSrc" :key="index" class="scroll-fade-in-f" :src="src" alt="brand_img" />
    </div>
  </section>
</template>

<script>
export default {
  props: ["companyData"],
  data() {
    return {
      imgClass: "",
      txtClass: "",
      imgSrc: [],
      logoSrc: "",
      lineSrc: "",
      content: "",
      outlinkUrl: "",

      i: 0,
    };
  },

  created() {
    const self = this;
    self.imgClass = this.companyData.name + "img";
    self.txtClass = this.companyData.name + "txt";
    self.imgSrc = this.companyData.imgSrc;
    self.logoSrc = this.companyData.logoSrc;
    self.lineSrc = this.companyData.lineSrc;
    self.content = this.companyData.content;
    self.outlinkUrl = this.companyData.outlinkUrl;
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

// 브랜드A - (이미지 왼쪽정렬 형태)

.product_a {
  .a_img {
    width: 100vw;
    display: block;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      display: block;
    }
  }
  .a_txt {
    margin-top: 70px;
    .product_line {
      width: 154px;
    }
    .product_logo {
      margin: 50px 0px;
      max-width: 230px;
      display: block;
    }
    p {
      line-height: 25px;
    }
    .el-btn {
      margin: 60px 0;
    }
  }
}
</style>
