<template>
  <div class="txt-bg">
    <p class="txt-bg-d">DAVICH</p>
    <p class="txt-bg-h">{{ this.text }}</p>
  </div>
</template>

<script>
export default {
  props: ["text"],
  methods: {
    txt() {
      {
        let top = window.pageYOffset; // 현재 스크롤 위치값
        let top3 = top % 1000; // 스크롤의 백의자리수까지의 값
        let davichbg = document.querySelector(".txt-bg"); // 다비치 bg 박스

        let davichTxt = document.querySelector(".txt-bg-d");
        let brandTxt = document.querySelector(".txt-bg-h");

        if (2000 > top && top > 1000) {
          davichbg.style.marginTop = 1000 + "px";
          davichTxt.style.left = top3 * 1 + "px";
          brandTxt.style.right = top3 * 1 + "px";
        }
        if (4000 > top && top > 3000) {
          davichbg.style.marginTop = 3000 + "px";
          davichTxt.style.left = top3 * 1 + "px";
          brandTxt.style.right = top3 * 1 + "px";
        }
        if (6000 > top && top > 5000) {
          davichbg.style.marginTop = 5000 + "px";
          davichTxt.style.left = top3 * 1 + "px";
          brandTxt.style.right = top3 * 1 + "px";
        }
        if (8000 > top && top > 7000) {
          davichbg.style.marginTop = 7000 + "px";
          davichTxt.style.left = top3 * 1 + "px";
          brandTxt.style.right = top3 * 1 + "px";
        }
        if (10000 > top && top > 9000) {
          davichbg.style.marginTop = 9000 + "px";
          davichTxt.style.left = top3 * 1 + "px";
          brandTxt.style.right = top3 * 1 + "px";
        }
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.txt);
  },
  destroyed() {
    window.removeEventListener("scroll", this.txt);
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.txt-bg {
  width: 100%;
  height: 100%;
  z-index: -10;
  position: absolute;
  font-size: 250px;
  line-height: 250px;
  font-weight: 900;
  margin-top: 3000px;
  color: #f9f9f9;
  font-family: $en-font;
  top: 0px;
  .txt-bg-d {
    position: absolute;
    bottom: 250px;
    left: 0px;
  }
  .txt-bg-h {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
}
</style>
