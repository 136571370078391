<template>
  <div class="ly-w1400">
    <section class="main-brand to-index">
      <h2 class="hidden">DAVICH BRAND</h2>
      <div class="brand-items scroll-fade-in">
        <router-link to="/products/glasses" class="brand-item brand-gl">
          <span class="el-btn el-btn-b">안경테 	&#38; 선글라스</span>
          <div class="brand-img -img">
            <img
              src="https://img.davich.com/image/main/img-product-gl.png"
              alt=""
            />
          </div>
        </router-link>
        <router-link
          to="/products/glassLens"
          class="brand-item scroll-fade-in scroll-delay-1 brand-gl-lens"
        >
          <span class="el-btn el-btn-b">안경렌즈</span>
          <div class="brand-img -img">
            <img
              src="https://img.davich.com/image/main/img-product-lens.png"
              alt=""
            />
          </div>
        </router-link>
        <router-link
          to="/products/contactLens"
          class="brand-item scroll-fade-in scroll-delay-2 brand-lens"
        >
          <span href="#" class="el-btn el-btn-b">콘택트렌즈</span>
          <div class="brand-img">
            <img
              src="https://img.davich.com/image/main/img-product-contact.png"
              alt=""
            />
          </div>
        </router-link>
        <router-link
          to="/products/hearingAid"
          class="brand-item scroll-fade-in scroll-delay-3 brand-hearing"
        >
          <span href="#" class="el-btn el-btn-b">보청기</span>
          <div class="brand-img">
            <img
              src="https://img.davich.com/image/main/img-product-bo.png"
              alt=""
            />
          </div>
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.main-brand {
  height: calc(100vh - 80px);
  .brand-items {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    .brand-item {
      width: 25%;
      height: 100%;
      position: relative;
      overflow: hidden;
      .brand-img {
        overflow: hidden;
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: 0.3s;
        }
      }

      &:hover {
        img {
          -webkit-transform: scale(1.1);
          -moz-transform: scale(1.1);
          -o-transform: scale(1.1);
          -ms-transform: scale(1.1);
          transform: scale(1.1);
        }
        span {
          color: $color-white;
          border: 1px solid $color-blue;
          &:before {
            transform: skewX(-40deg) translateX(-20px);
          }
        }
      }

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        font-size: 20px;
      }
    }
  }
}
</style>
