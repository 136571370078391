<template>
  <div class="support-faq">
    <sub-title :title="title" :content="content" :content2="content2"> </sub-title>
    <section class="faq scroll-fade-in-f">
      <div class="faq-btn-wrap">
        <b-button class="active faq-btn" @click="[setClass(''), menuColor(0)]">전체</b-button>
        <b-button @click="[setClass('개인정보'), menuColor(1)]" class="faq-btn">개인정보</b-button>
        <b-button @click="[setClass('멤버십'), menuColor(2)]" class="faq-btn">멤버십</b-button>
        <b-button @click="[setClass('상품'), menuColor(3)]" class="faq-btn">상품</b-button>
        <b-button @click="[setClass('매장'), menuColor(4)]" class="faq-btn">매장</b-button>
        <b-button @click="[setClass('일반'), menuColor(5)]">일반</b-button>
      </div>
      <!--전체 tab menu-->
      <search-input
        :mutationName="'support/SEARCH_FAQ'"
        :boardData="getFaqData"
        v-on:switchVar="switchVar"
      ></search-input>
      <!-- 필터링 X -->
      <div class="faq-cont ly-cont-m" v-if="isFiltered == false">
        <b-card v-for="item in listToShow" :key="item.id">
          <b-card-header>
            <b-button v-b-toggle="item.id">
              <span>Q.</span>
              <div>
                <em>{{ item.class }}</em>
                <p v-html="item.title"></p>
              </div>
            </b-button>
          </b-card-header>
          <b-collapse :id="item.id">
            <b-card-body>
              <b-card-text>
                <span>A.</span>
                <div>
                  <div class="img-wrap" v-for="(src, index) in item.imgSrc" :key="index">
                    <img :src="src" alt="faq이미지" />
                  </div>
                  <p v-html="item.content"></p>
                </div>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>

      <!-- 필터링 O -->
      <div class="faq-cont ly-cont-m" v-else-if="isFiltered == true">
        <b-card v-for="item in filteredToShow" :key="item.id">
          <b-card-header>
            <b-button v-b-toggle="item.id">
              <span>Q.</span>
              <div>
                <em>{{ item.class }}</em>
                <p v-html="item.title"></p>
              </div>
            </b-button>
          </b-card-header>
          <b-collapse :id="item.id">
            <b-card-body>
              <b-card-text>
                <span>A.</span>
                <div>
                  <div class="img-wrap" v-for="(src, index) in item.imgSrc" :key="index">
                    <img :src="src" alt="faq이미지" />
                  </div>
                  <p v-html="item.content"></p>
                </div>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>

      <b-pagination id="pagination" :total-rows="rows" v-model="currentPage" :per-page="perPage"></b-pagination>

      <button class="el-btn-m" id="btnMoreData" @click="getMoreData">더보기</button>
    </section>
  </div>
</template>

<script>
import SubTitle from "../../../components/mobile/MSubTitle1.vue";
import SearchInput from "../../../components/mobile/MSearchInput.vue";

import { mapGetters, mapState } from "vuex";

export default {
  beforeCreate() {
    this.$store.dispatch("support/FETCH_FAQ");
  },

  components: {
    SubTitle,
    SearchInput,
  },
  data() {
    return {
      //subtitle
      title: "자주하는 질문",
      content: "고객님의 문의사항을",
      content2: " 바로 해결할 수 있게 도와드립니다.",

      isFiltered: false,
      selectedClass: "",
      isSearched: false,

      currentPage: 1,
      perPage: 10,
      lengthListToShow: 0,
      lengthFilteredToShow: 0,
    };
  },

  computed: {
    ...mapGetters("support", ["getFaqData"]),
    ...mapState("support", {
      searchedData: (state) => state.searchedData,
    }),

    listToShow() {
      const self = this;
      let result;
      let boardData = [];
      if (self.isSearched == false) {
        boardData = self.getFaqData;
      } else {
        boardData = self.searchedData;
      }

      self.lengthListToShow = boardData.length;

      result = boardData.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
      return result;
    },

    filteredToShow() {
      const self = this;
      let selectedClass = this.selectedClass;
      let result;
      let boardData = this.getFaqData.filter((item) => {
        let filtered = true;
        if (selectedClass && selectedClass.length > 0) {
          filtered = item.class == selectedClass;
        }
        return filtered;
      });

      self.lengthFilteredToShow = boardData.length;

      result = boardData.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);

      return result;
    },

    rows() {
      const self = this;
      if (self.isFiltered == false) {
        return self.lengthListToShow;
      } else {
        return self.lengthFilteredToShow;
      }
    },
  },

  watch: {
    //게시물 10개 안될 때 더보기 숨김
    filteredToShow() {
      const btn = document.getElementById("btnMoreData");
      if (this.perPage >= this.rows) {
        btn.style.display = "none";
      } else {
        btn.style.display = "block";
      }
    },

    listToShow() {
      const btn = document.getElementById("btnMoreData");
      if (this.perPage >= this.rows) {
        btn.style.display = "none";
      } else {
        btn.style.display = "block";
      }
    },
  },

  methods: {
    //선택한 class(분류)에 따라서 필터링,필터링변수 true
    setClass(selectedClass) {
      this.perPage = 10;
      const btn = document.getElementById("btnMoreData");
      btn.style.display = "block";

      if (this.isFiltered == false) {
        this.isFiltered = true;
      }
      this.selectedClass = selectedClass;
      if (selectedClass == "" || selectedClass.length == 0) {
        this.isFiltered = false;
        this.isSearched = false;
      }
    },

    getMoreData() {
      const btn = document.getElementById("btnMoreData");
      this.perPage += 5;

      //마지막 데이터에서 더보기버튼 숨김
      if (this.perPage >= this.rows) {
        btn.style.display = "none";
      }
    },

    switchVar(param) {
      if (param == true) {
        this.isSearched = true;
        this.isFiltered = false;
        this.perPage = 10;
        const btn = document.getElementById("btnMoreData");
        btn.style.display = "block";
      } else {
        this.isSearched = false;
      }
    },

    //button color
    menuColor(index) {
      const tabBtn = document.querySelectorAll(".faq-btn");
      for (let i = 0; i < tabBtn.length; i++) {
        tabBtn[i].classList.remove("active");
      }
      tabBtn[index].classList.add("active");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.support-faq {
  .sub-title {
    background-image: url("https://img.davich.com/mobile/image/sub/subtitle/sub-support-bg-01.png");
    background-size: cover;
  }
  .faq {
    margin: 50px auto 100px auto;
    position: relative;
    overflow: hidden;
    // 전체 tab menu 버튼
    .faq-btn-wrap {
      border-bottom: 1px solid #dedede;
      display: flex;
      height: 60px;
      flex-wrap: nowrap;
      overflow-x: auto;
      // scroll bar hidden
      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
      .btn-secondary {
        display: inline-block;
        height: 100%;
        flex: 0 0 auto;
        width: auto;
        @include font-tab-16;
        margin-right: 30px;
        position: relative;
        &:first-child {
          margin-left: 7.5vw;
        }
        &.active {
          color: $color-blue;
          border-bottom: 2px solid $color-blue;
        }
      }
    }
    .faq-cont {
      margin-top: 50px;
      border-top: 1px solid #dedede;
      .card {
        width: 100%;
        border-bottom: 1px solid #dedede;
        > .card-body {
          width: 100%;
          //header 부분
          .card-header {
            width: 100%;
            .btn-secondary {
              width: 100%;
              padding: 20px;
              display: flex;
              @include font-main-m;
              > div,
              > span {
                text-align: left;
              }
              position: relative;
              &.not-collapsed {
                border-bottom: 1px solid #dedede;
                background-color: #f9f9f9;
              }
              span {
                font-weight: bold;
                color: $color-blue;
                width: 29px;
              }
              em {
                font-weight: bold;
                line-height: 30px;
              }
            }
          }
          // content 부분
          .collapse {
            @include font-main-m;
            //질문&답변창
            .card-body {
              padding: 20px;
              background-color: #f9f9f9;
              width: 100%;
              display: flex;
              line-height: 30px;
              .card-text {
                display: block;
                display: flex;
                span {
                  width: 29px;
                  font-weight: bold;
                  flex-shrink: 0;
                }
                p {
                  margin-top: 10px;
                }
                .img-wrap {
                  width: 68vw;
                  height: auto;
                  img {
                    width: 100%;
                    height: auto;
                    margin-bottom: 15px;
                  }
                }
              }
            }
          }
        }
      }
    }
    //더보기 버튼
    .el-btn-m {
      display: block;
      margin: 100px auto auto;
    }
  }
}

#pagination {
  display: none;
}
</style>
