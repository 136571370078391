/**
 *
 * Author mrwang 2022.03.07
 *
 */

import axios from "axios";
import dotenv from "dotenv";

dotenv.config();

const apiServerUrl = process.env.VUE_APP_API_SERVER_URL;

const instance = axios.create({
  baseURL: apiServerUrl,
  headers: { "Accept-Language": "ko" },
});

instance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("authToken");
    const lang = localStorage.getItem("LANGUAGE");
    if (token) {
      config.headers.common["Authentication"] = `Bearer ${token}`;
      config.headers.common["Content-Type"] = "application/json;charset=UTF-8";
    }
    config.headers["Accept-Language"] = lang;

    return config;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error.response);
  }
);

export default instance;
