export const mutations = {
  //개인정보처리방침
  SET_PRIVACY(state, resData) {
    let sorted;

    sorted = resData.sort(function (a, b) {
      return b.id - a.id;
    });

    state.privacy = sorted;
  },
};
