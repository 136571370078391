<template>
  <div class="find-result-item-wrap">
    <div
      class="find-result-item"
      v-bind="mapDataItem"
      v-on:click="moveLocation(mapDataItem)"
    >
      <a href="#"
        ><h3>{{ mapDataItem.name }}</h3></a
      >
      <div class="find-result-item-desc">
        <p>
          <span>
            {{ mapDataItem.addr }}
          </span>

          <!-- <button @click="copyToClipboard()">복사</button> -->
        </p>
        <p>{{ mapDataItem.tel }}</p>
        <div class="classify-items">
          <em
            class="classify-item"
            v-for="(tag, index) in mapDataItem.tag"
            :key="index"
          >
            <div
              class="store-icon-wrap"
              :class="{
                wifiIcon: tag.includes('wifi'),
                parkingIcon: tag.includes('parking'),
                earaidIcon: tag.includes('earaid'),
                lensIcon: tag.includes('lens'),
                fittingIcon: tag.includes('fitting'),
              }"
            >
              <img :src="iconSrc" alt="icon" />
            </div>
            <span v-if="tag.includes('wifi')">와이파이</span>
            <span v-if="tag.includes('parking')">주차장</span>
            <span v-if="tag.includes('earaid')">보청기</span>
            <span v-if="tag.includes('lens')">렌즈샵</span>
            <span v-if="tag.includes('fitting')">누진피팅센터</span>
          </em>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["mapDataItem"],

  data() {
    return {
      iconSrc:
        "https://img.davich.com/image/sub/support/map/icon-store-all.png",
    };
  },

  methods: {
    moveLocation(mapDataItem) {
      this.$emit("moveLocation", mapDataItem);
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";
.find-store.ly-w1400 {
  // 이미지 모음
  .store-icon-wrap {
    position: relative;
    overflow: hidden;
    margin-right: 5px;
    display: inline-block;
    img {
      position: absolute;
    }
    &.wifiIcon {
      width: 21px;
      height: 21px;
      img {
        left: 0px;
        top: 0px;
      }
    }
    &.addressIcon {
      width: 21px;
      height: 21px;
      img {
        left: 0px;
        top: -22px;
      }
    }
    &.phoneIcon {
      width: 21px;
      height: 21px;
      img {
        left: -22px;
        top: 0px;
      }
    }
    &.siteIcon {
      width: 21px;
      height: 21px;
      img {
        left: -21px;
        top: -22px;
      }
    }
    &.earaidIcon {
      width: 21px;
      height: 21px;
      img {
        left: -43px;
        top: 0;
      }
    }
    &.locationIcon {
      width: 22px;
      height: 21px;
      img {
        left: -42px;
        top: -22px;
      }
    }
    &.saleIcon {
      width: 21px;
      height: 21px;
      img {
        left: -64px;
        top: 0;
      }
    }
    &.fittingIcon {
      width: 22px;
      height: 21px;
      img {
        left: -64px;
        top: -22px;
      }
    }
    &.lensIcon {
      width: 23px;
      height: 21px;
      img {
        left: -85px;
        top: 0px;
      }
    }
    &.parkingIcon {
      width: 23px;
      height: 21px;
      img {
        left: -85px;
        top: -22px;
      }
    }
    &.kakaoIcon {
      width: 26px;
      height: 21px;
      img {
        left: -109px;
        top: 0px;
      }
    }
    &.reservIcon {
      width: 16px;
      height: 21px;
      img {
        left: -109px;
        top: -22px;
      }
    }
  }

  // 분류결과
  .find-result-items {
    flex: 1;
    overflow-y: scroll;
    @include scroll-bar;
    .find-result-item-wrap {
      .find-result-item {
        border-bottom: 1px solid #dedede;
        cursor: pointer;
        padding: 30px;

        &:hover {
          background-color: #f0f8ff;
        }
        a.focused {
          background-color: #f0f8ff;
        }
        h3 {
          font-weight: bold;
          font-size: 20px;
          line-height: 45px;
          letter-spacing: 0.6px;
        }
        .find-result-item-desc {
          p {
            @include font-main;
            color: #777;
            &:nth-of-type(2) {
              margin-bottom: 30px;
            }
          }
          button {
            display: inline-block;
            width: 50px;
            height: 30px;
            border: 1px solid #dedede;
            font-size: 13px;
            line-height: 30px;

            color: #777;
            border-radius: 15px;
            background-color: $color-white;
          }
        }
      }
    }
  }
  // 분류
  .classify-items {
    display: flex;
    .classify-item {
      margin-right: 40px;
      position: relative;
      color: #aaa;
      display: flex;
      align-items: center;

      &:before {
        content: "";
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        right: -20px;
        background-color: #aaa;
      }
      &:last-child:before {
        display: none;
      }
    }
  }
}
</style>
