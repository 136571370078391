import { render, staticRenderFns } from "./3_m_event.vue?vue&type=template&id=125b258a&scoped=true&"
import script from "./3_m_event.vue?vue&type=script&lang=js&"
export * from "./3_m_event.vue?vue&type=script&lang=js&"
import style0 from "./3_m_event.vue?vue&type=style&index=0&id=125b258a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "125b258a",
  null
  
)

export default component.exports