<template>
  <div class="how-to ly-w1400">
    <sub-title :title="title" :content="content"> </sub-title>
    <section class="know-how">
      <div class="know-how-inner">
        <h2 class="scroll-fade-in-f">다비치만의 성공전략 노하우</h2>
        <div class="know-how-items">
          <div class="know-how-item scroll-fade-in-f scroll-delay-1">
            <p>지역 핵심상권의 대표</p>
            <span
              >전문적인 상권분석을 통한<br />
              지역 최고의 1등 매장오픈</span
            >
          </div>
          <div class="know-how-item scroll-fade-in-f scroll-delay-2">
            <p>
              전략정책을 바탕으로 한<br />
              운영 노하우 접목
            </p>
            <span
              >최고의 컨설팅 제공으로<br />
              다비치만의 차별화 정책</span
            >
          </div>
          <div class="know-how-item scroll-fade-in-f scroll-delay-3">
            <p>
              지속적인<br />
              신규고객 창출 확보
            </p>
            <span>다비치 고객만족 시스템</span>
          </div>
        </div>
      </div>
    </section>
    <!-- /.know-how -->
    <section class="store-model">
      <div class="model-main">
        <div class="model-desc">
          <div class="model-desc-inner">
            <h2 class="scroll-fade-in-f">점포모델</h2>
            <p class="scroll-fade-in-f scroll-delay-1">
              다비치안경점은 대로변에 위치하고 전면 간판 12m 노출,<br />
              60~80평 이상의 대형 매장에서 성공적으로 운영되고 있습니다.
            </p>
            <div class="model-btns">
              <button class="active" data-alt="https://img.davich.com/image/sub/partnership/img-partnership-store.png">
                1층 모델
              </button>
              <button data-alt="https://img.davich.com/image/sub/partnership/img-partnership-store-02.png">
                2층 모델
              </button>
            </div>
          </div>
        </div>
        <div class="model-img">
          <img src="https://img.davich.com/image/sub/partnership/img-partnership-store.png" alt="map icon" />
        </div>
      </div>
      <div class="model-corner-slider">
        <div class="model-corner-slider-inner">
          <div class="corner-slide slide-1">
            <div class="img-wrap">
              <img
                src="https://img.davich.com/image/sub/partnership/img-partnership-slide-01.png"
                alt="13579 정찰코너"
              />
            </div>
            <p>13579 정찰코너</p>
          </div>
          <div class="corner-slide slide-2">
            <div class="img-wrap">
              <img
                src="https://img.davich.com/image/sub/partnership/img-partnership-slide-02.png"
                alt="콘텍트렌즈 코너"
              />
            </div>
            <p>콘택트렌즈 코너</p>
          </div>
          <div class="corner-slide slide-3">
            <div class="img-wrap">
              <img src="https://img.davich.com/image/sub/partnership/img-partnership-slide-03.png" alt="비비엠 코너" />
            </div>
            <p>비비엠 코너</p>
          </div>
          <div class="corner-slide slide-4">
            <div class="img-wrap">
              <img
                src="https://img.davich.com/image/sub/partnership/img-partnership-slide-04.png"
                alt="수입안경 코너"
              />
            </div>
            <p>수입안경 코너</p>
          </div>
          <div class="corner-slide slide-5">
            <div class="img-wrap">
              <img src="https://img.davich.com/image/sub/partnership/img-partnership-slide-05.png" alt="매장 전경" />
            </div>
            <p>매장 전경</p>
          </div>
          <div class="corner-slide slide-6">
            <div class="img-wrap">
              <img src="https://img.davich.com/image/sub/partnership/img-partnership-slide-06.png" alt="다비치 카페" />
            </div>
            <p>다비치 카페</p>
          </div>
          <div class="corner-slide slide-7">
            <div class="img-wrap">
              <img src="https://img.davich.com/image/sub/partnership/img-partnership-slide-07.png" alt="트루뷰존" />
            </div>
            <p>트루뷰존</p>
          </div>
          <div class="corner-slide slide-8">
            <div class="img-wrap">
              <img src="https://img.davich.com/image/sub/partnership/img-partnership-slide-08.png" alt="비전케어 LAB" />
            </div>
            <p>비전케어 LAB</p>
          </div>
          <div class="corner-slide slide-9">
            <div class="img-wrap">
              <img src="https://img.davich.com/image/sub/partnership/img-partnership-slide-09.png" alt="검안실" />
            </div>
            <p>검안실</p>
          </div>
          <div class="corner-slide slide-10">
            <div class="img-wrap">
              <img src="https://img.davich.com/image/sub/partnership/img-partnership-slide-10.png" alt="키오스크" />
            </div>
            <p>키오스크</p>
          </div>
          <div class="corner-slide slide-11">
            <div class="img-wrap">
              <img
                src="https://img.davich.com/image/sub/partnership/img-partnership-slide-11.png"
                alt="난시멀티포컬피팅센터"
              />
            </div>
            <p>난시멀티포컬피팅센터</p>
          </div>
        </div>
      </div>
    </section>
    <!-- /.store-model -->
    <section class="procedure">
      <div class="procedure-inner ly-cont">
        <h2 class="scroll-fade-in-f">개설 절차</h2>
        <div class="procedure-info">
          <div class="info-desc scroll-fade-in-f scroll-delay-1">
            <h3>Counseling</h3>
            <p>신규 오픈 희망 시 담당자와 가맹상담 진행 &#40;상담 후 예비점주 등록 필요 &#41;</p>
          </div>
          <div class="info-desc scroll-fade-in-f scroll-delay-2">
            <h3>Process Period</h3>
            <p>
              점포 확보에 소요되는 시간에 따라 오픈까지의 전체기간 차이 발생<br />
              &#40;점포 계약 후 오픈까지 평균 1-2개월 소요 &#41;
            </p>
          </div>
        </div>
        <div class="opening-procedure">
          <div class="procedure-items">
            <div class="procedure-item scroll-fade-in-f">
              <p>01&#46; 접수</p>
              <span>본사방문(창업설명회 참석) <br />또는 창업센터 전화상담</span>
            </div>
            <div class="procedure-arrow scroll-fade-in-f">
              <img src="https://img.davich.com/image/sub/about/icon-arrow.png" alt="right arrow icon" />
            </div>
            <div class="procedure-item scroll-fade-in-f">
              <p>02&#46; 상담</p>
              <span
                >지역별 가맹 개발자와 상담<br />
                &#40;점포 입점 가능여부, 다비치<br />
                전략 정책 설명, 정보공개서 &#41;</span
              >
            </div>
            <div class="procedure-arrow scroll-fade-in-f">
              <img src="https://img.davich.com/image/sub/about/icon-arrow.png" alt="right arrow icon" />
            </div>
            <div class="procedure-item scroll-fade-in-f">
              <p>
                03&#46; 상권조사 및<br />
                현장답사
              </p>
              <span
                >해당 지역 실사를 통한
                <br />
                점포 후보 매장 조사</span
              >
            </div>
            <div class="procedure-arrow scroll-fade-in-f">
              <img src="https://img.davich.com/image/sub/about/icon-arrow.png" alt="right arrow icon" />
            </div>
            <div class="procedure-item scroll-fade-in-f">
              <p>04&#46; 점포계약</p>
              <span>점포 임대차 계약 (건물주)</span>
            </div>
            <div class="procedure-arrow scroll-fade-in-f">
              <img src="https://img.davich.com/image/sub/about/icon-arrow.png" alt="right arrow icon" />
            </div>
            <div class="procedure-item scroll-fade-in-f">
              <p>05&#46; 계약</p>
              <span>가맹 계약 (다비치 점주)</span>
            </div>
            <div class="procedure-item scroll-fade-in-f">
              <p>06&#46; 시공</p>
              <span
                >인테리어 시공 25일<br />
                (변동사항 있음)</span
              >
            </div>
            <div class="procedure-arrow scroll-fade-in-f">
              <img src="https://img.davich.com/image/sub/about/icon-arrow.png" alt="right arrow icon" />
            </div>
            <div class="procedure-item scroll-fade-in-f">
              <p>07&#46; 점주교육</p>
              <span
                >(신규자 교육 5박 6일,<br />
                파견근무 2주)</span
              >
            </div>
            <div class="procedure-arrow scroll-fade-in-f">
              <img src="https://img.davich.com/image/sub/about/icon-arrow.png" alt="right arrow icon" />
            </div>
            <div class="procedure-item scroll-fade-in-f">
              <p>08&#46; 오픈</p>
              <span>다비치 안경점 오픈</span>
            </div>
            <div class="procedure-arrow scroll-fade-in-f">
              <img src="https://img.davich.com/image/sub/about/icon-arrow.png" alt="right arrow icon" />
            </div>
            <div class="procedure-item scroll-fade-in-f">
              <p>09&#46; 사후관리</p>
              <span>30일 과정 및 지속적인 컨설팅</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /.procedure -->
    <section class="available">
      <h2 class="hidden">다비치 오픈 지역</h2>
      <article class="available-store ly-cont">
        <h3 class="scroll-fade-in-f">오픈 가능 지역</h3>
        <div class="pas scroll-fade-in-f scroll-delay-1">
          <p>해당지역 안경사님께 오픈 우선권을 드립니다!</p>
          <div class="pas-items">
            <div class="pas-item scroll-fade-in-f">
              <div class="img-wrap">
                <img src="https://img.davich.com/image/sub/partnership/icon-partnership-01.png" alt="medal icon" />
              </div>
              <p>
                지역 내 1등이 가능한<br />
                상권 및 점포
              </p>
            </div>

            <div class="pas-item scroll-fade-in-f scroll-delay-1">
              <div class="img-wrap">
                <img src="https://img.davich.com/image/sub/partnership/icon-partnership-02.png" alt="medal icon" />
              </div>
              <p>
                전국 시단위 이상 지역
                <span
                  >(단, 읍단위의 경우<br />
                  시 수준 상권 형성 지역)</span
                >
              </p>
            </div>
            <div class="pas-item scroll-fade-in-f scroll-delay-2">
              <div class="img-wrap">
                <img src="https://img.davich.com/image/sub/partnership/icon-partnership-03.png" alt="medal icon" />
              </div>
              <p>
                시가지 핵심상권, 역세권,<br />
                대규모 역사, 핵심지역
              </p>
            </div>
            <div class="pas-item scroll-fade-in-f scroll-delay-3">
              <div class="img-wrap">
                <img src="https://img.davich.com/image/sub/partnership/icon-partnership-04.png" alt="medal icon" />
              </div>
              <p>
                대단위 아파트 단지 내 상가
                <span
                  >(단, 기존 가맹점 상권 내<br />
                  오픈 불가능)</span
                >
              </p>
            </div>
          </div>
        </div>
        <div class="qualify">
          <div class="qualify-desc scroll-fade-in-f">
            <h4>가입자격</h4>
            <p>
              <span>&#45; 현재 안경원 운영 또는 신규 개설 희망자 </span>
              <span>&#45; 체인 가족 공동체로서 한마음이 될 수 있는 안경사</span>
            </p>
          </div>
          <div class="qualify-desc scroll-fade-in-f scroll-delay-1">
            <h4>가입조건</h4>
            <p>
              <span>&#45; 가맹점 가입비 : 2,500만원(VAT별도)</span>
              <span>&#45; 정기 지급금 : 월 100만원(VAT별도)</span>
            </p>
          </div>
        </div>
      </article>
      <article class="place">
        <partner-list></partner-list>
      </article>
    </section>
    <!-- /.available -->
  </div>
</template>

<script>
import PartnerList from "../../components/PARTNERSHIP/PartnerList.vue";
import SubTitle from "../../components/SubTitle1.vue";

export default {
  components: {
    PartnerList,
    SubTitle,
  },
  data() {
    return {
      title: "가맹점 개설안내",
      content: "우리지역 1등 매장! No.1 브랜드 다비치와 함께하세요.",
    };
  },

  mounted() {
    /*01 점포모델 - 버튼 클릭 시 색상/이미지 링크 변경*/
    let modelBtn = document.querySelectorAll(".model-btns button");
    for (var i = 0; i < modelBtn.length; i++) {
      let modelImgLink = modelBtn[i].dataset.alt;
      modelBtn[i].addEventListener("click", function () {
        // 버튼 색상변경
        for (var z = 0; z < modelBtn.length; z++) {
          modelBtn[z].classList.remove("active");
        }
        this.classList.add("active");
        //src 변경
        let modelImg = document.querySelector(".model-img img");
        modelImg.src = modelImgLink;
      });
    }

    /*02 점포모델 - 슬라이드*/
    const slideList = document.querySelector(".model-corner-slider-inner"); // Slide parent dom
    const slideContents = document.querySelectorAll(".corner-slide"); // each slide dom
    // var slideListTop = slideList.offsetTop;
    // var winH = window.innerHeight;
    const slideLen = slideContents.length; // slide length
    const slideWidth = 480; // slide width
    const slideSpeed = 300; // slide speed
    const startNum = 0; // initial slide index (0 ~ 4)
    slideList.style.width = slideWidth * (slideLen + 3) + "px";

    // Copy first and last slide
    let firstChild = slideList.firstElementChild;
    let secondChild = document.querySelector(".slide-2");
    let thirdChild = document.querySelector(".slide-3");
    let forthChild = document.querySelector(".slide-4");
    let clonedSecond = secondChild.cloneNode(true);
    let clonedThird = thirdChild.cloneNode(true);
    let clonedForth = forthChild.cloneNode(true);
    let clonedFirst = firstChild.cloneNode(true);

    // Add copied Slides
    slideList.appendChild(clonedFirst);
    slideList.appendChild(clonedSecond);
    slideList.appendChild(clonedThird);
    slideList.appendChild(clonedForth);

    slideList.style.transform = "translate3d(0px, 0px, 0px)";
    let curIndex = startNum; // current slide index (except copied slide)
    let curSlide = slideContents[curIndex]; // current slide dom
    curSlide.classList.add("slide_active");

    setInterval(function () {
      if (curIndex <= slideLen - 1) {
        slideList.style.transition = slideSpeed + "ms";
        slideList.style.transform = "translate3d(-" + slideWidth * (curIndex + 1) + "px, 0px, 0px)";
      }
      if (curIndex === slideLen - 1) {
        setTimeout(function () {
          slideList.style.transition = "0ms";
          slideList.style.transform = "translate3d(0px, 0px, 0px)";
        }, slideSpeed);
        curIndex = -1;
      }

      curSlide = slideContents[++curIndex];
    }, 2500);
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.how-to {
  .sub-title {
    background-image: url("https://img.davich.com/image/sub/subtitle/sub-partnership-bg.png");
  }
  h2,
  .available h3 {
    @include font-h-35;
  }
  /*다비치 만의 성공전략 노하우*/
  .know-how {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 92px 0 200px 0;
    .know-how-inner {
      h2 {
        margin-bottom: 90px;
      }
      .know-how-items {
        display: flex;
        .know-how-item {
          p {
            @include font-h-25;
            color: $color-blue;
            text-align: center;
            width: 350px;
            height: 350px;
            margin-bottom: 167px;
            color: $color-blue;
            border-radius: 50%;
            border: 1px solid $color-blue;
            position: relative;
            @include box-center-flex;
            &:after {
              content: "";
              width: 1px;
              height: 100px;
              position: absolute;
              bottom: -150px;
              left: 50%;
              transform: translateY(-50%);
              background-color: $color-blue;
            }
          }
          &:nth-child(2) {
            margin: 0 -20px 0 -20px;
          }
          > span {
            @include font-main;
          }
        }
      }
    }
  }
  /*점포모델*/
  .store-model {
    max-width: 100%;
    overflow: hidden;
    margin-bottom: 150px;
    .model-main {
      margin: 0 auto;
      width: 1400px;
      display: flex;
      position: relative;
      margin-bottom: 165px;
      // 점포모델 desc
      .model-desc {
        margin-right: 400px;
        display: flex;
        align-items: center;
        .model-desc-inner {
          h2 {
            @include font-h-35;
            margin-bottom: 40px;
          }
          > p {
            @include font-main;
            margin-bottom: 102px;
          }
          .model-btns {
            button {
              @include font-main;
              font-weight: 400;
              margin-right: 30px;
              position: relative;
              &.active {
                color: $color-blue;
              }
              &:last-child {
                &:before {
                  display: none;
                }
              }
              &:before {
                content: "";
                position: absolute;
                top: 7px;
                right: -15px;
                width: 1px;
                height: 17px;
                background-color: #dedede;
              }
            }
          }
        }
      }
      // 점포모델 이미지
      .model-img {
        background-color: $color-blue;
        height: 554px;
        width: 1100px;
        margin-right: -900px;
        transform: skewX(30deg);
        position: relative;
        img {
          width: 960px;
          position: absolute;
          z-index: 9999999;
          top: 45px;
          right: 370px;
          transform: skewX(-30deg);
        }
      }
    }
    // 점포모델 이미지 슬라이드
    .model-corner-slider {
      position: relative;
      overflow: hidden;
      width: calc(100% - 260px);
      height: auto;
      margin-left: 260px;
      text-align: center;
      font-size: 22px;
      line-height: 30px;
      font-weight: 500;
      letter-spacing: -0.66px;
      .model-corner-slider-inner {
        display: flex;
        .corner-slide {
          margin-right: 40px;
          .img-wrap {
            width: 440px;
            height: 440px;
            margin-bottom: 20px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  /*개설절차*/
  .procedure {
    padding: 100px 0;
    background-color: #f9f9f9;
    h2 {
      @include font-h-35;
      margin-bottom: 71px;
    }
    .procedure-info {
      display: flex;
      .info-desc {
        width: 700px;
        h3 {
          @include font-h-25;
          margin-bottom: 20px;
        }
        p {
          @include font-main;
          margin-bottom: 70px;
        }
      }
    }
    .opening-procedure {
      .procedure-items {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -70px;
        .procedure-item {
          margin-bottom: 70px;
          position: relative;
          p {
            width: 220px;
            height: 220px;
            border-radius: 50%;
            margin-bottom: 30px;
            font-size: 22px;
            font-weight: bold;
            line-height: 30px;
            letter-spacing: -0.54px;
            border: 1px solid $color-blue;
            color: $color-blue;
            @include box-center-flex;
            text-align: center;
          }
          span {
            display: inline-block;
            @include font-main;
            text-align: center;
            width: 100%;
          }
        }
        .procedure-arrow {
          height: 220px;
          display: flex;
          align-items: center;
          img {
            //화살표
            width: 75px;
            height: 20px;
          }
        }
      }
    }
  }

  /*오픈가능지역*/
  .available {
    padding: 100px 0 180px 0;
    /*자격 안내*/
    .available-store {
      h3 {
        text-align: center;
        margin-bottom: 116px;
      }
      .pas {
        margin-bottom: 100px;
        > p {
          text-align: center;
          @include font-h-25;
          margin-bottom: 70px;
        }
        .pas-items {
          display: flex;
          .pas-item {
            width: 350px;
            display: flex;
            align-items: center;
            flex-direction: column;
            text-align: center;
            .img-wrap {
              margin-bottom: 20px;
              height: 91.5px;
              img {
                height: 100%;
              }
            }
            p {
              font-size: 22px;
              font-weight: bold;
              line-height: normal;
              letter-spacing: -0.66px;
              color: $color-blue;
              span {
                display: block;
                color: $color-black;
                @include font-main;
                font-weight: 400;
                margin-top: 20px;
              }
            }
          }
        }
      }
      .qualify {
        display: flex;
        justify-content: space-between;
        margin-bottom: 130px;
        .qualify-desc {
          width: 680px;
          height: 210px;
          border: 1px solid #dedede;
          padding: 44px 50px 0 50px;
          h4 {
            @include font-h-25;
            margin-bottom: 19px;
          }
          p {
            span {
              display: block;
              @include font-main;
            }
          }
        }
      }
    }
  }
}
</style>
