import { fetchMainVideo, fetchInstaToken, fetchInstaItem, fetchCount, fetchPopup } from "../api/index";

export default {
  FETCH_POPUP(context) {
    fetchPopup()
      .then((res) => {
        context.commit("SET_POPUP", res.data);
      })
      .catch((err) => console.log(err));
  },

  FETCH_MAIN_VIDEO: function (context) {
    fetchMainVideo()
      .then((res) => {
        context.commit("SET_MAIN_VIDEO", res.data);
      })
      .catch((err) => console.log(err));
  },

  FETCH_INSTA: function (context) {
    fetchInstaToken()
      .then((res) => {
        const token = res.data.access_token;
        fetchInstaItem(token)
          .then((res) => {
            context.commit("SET_INSTA_ITEM", res.data.data);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  },
  FETCH_INSTA_M: function (context) {
    fetchInstaItem()
      .then((res) => {
        context.commit("SET_INSTA_ITEM_M", res.data.data);
      })
      .catch((err) => console.log(err));
  },
  FETCH_COUNT: function (context) {
    fetchCount().then((res) => {
      context.commit("SET_COUNT_ITEM", res.data);
    });
  },
};
