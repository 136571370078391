<template>
  <div>
    <sub-title :title="title" :content="content" :content2="content2"> </sub-title>
    <section class="davich-news scroll-fade-in-f">
      <h2 class="hidden">다비치 소식 게시판</h2>
      <div class="m-menu-wrap">
        <div class="menu">
          <button @click="[setClass(''), menuColor(0)]" class="news-btn cf-btn active">전체</button>
          <button @click="[setClass('공지'), menuColor(1)]" class="news-btn cf-btn">공지</button>
          <button @click="[setClass('뉴스'), menuColor(2)]" class="news-btn cf-btn">뉴스</button>
        </div>
      </div>
      <search-input
        :mutationName="'whatsnew/SEARCH_NEWS_BOARD'"
        :boardData="getNewsData"
        v-on:switchVar="switchVar"
      ></search-input>

      <!-- 필터링 O -->
      <div class="news-board board-items ly-cont-m scroll-fade-in-f" v-if="isFiltered == true">
        <div class="board-item" v-for="item in filteredToShow" :key="item.id">
          <em
            class="badge"
            :class="{
              noticeBadge: item.class == '공지',
              newsBadge: item.class == '뉴스',
            }"
            >{{ item.class }}</em
          >
          <router-link :to="`/whatsnew/m_davichNewsDetail/${item.id}`">{{ item.title }}</router-link>
          <span>{{ item.created_dttm }}</span>
        </div>
      </div>

      <div class="news-board board-items ly-cont-m scroll-fade-in-f" v-if="isFiltered == false">
        <div class="board-item" v-for="item in listToShow" :key="item.id">
          <em
            class="badge"
            :class="{
              noticeBadge: item.class == '공지',
              newsBadge: item.class == '뉴스',
            }"
            >{{ item.class }}</em
          >
          <router-link :to="`/whatsnew/m_davichNewsDetail/${item.id}`">{{ item.title }}</router-link>
          <span>{{ item.created_dttm }}</span>
        </div>
      </div>

      <b-pagination id="pagination" v-model="currentPage" :per-page="perPage"></b-pagination>

      <button class="el-btn-m" id="btnMoreData" @click="getMoreData">더보기</button>
    </section>
  </div>
</template>

<script>
import SubTitle from "../../../components/mobile/MSubTitle1.vue";
import SearchInput from "../../../components/mobile/MSearchInput.vue";
import { mapGetters, mapState } from "vuex";

export default {
  beforeCreate() {
    this.$store.dispatch("whatsnew/FETCH_NEWS");
  },

  components: {
    SubTitle,
    SearchInput,
  },
  data() {
    return {
      title: "다비치 소식",
      content: "다비치의 공지, 뉴스",
      content2: "새로운 소식을 전해드립니다.",

      isFiltered: false,
      selectedClass: "",
      isSearched: false,

      currentPage: 1,
      perPage: 10,

      lengthListToShow: 0,
      lengthFilteredToShow: 0,
    };
  },

  computed: {
    ...mapGetters("whatsnew", ["getNewsData"]),
    ...mapState("whatsnew", {
      searchedData: (state) => state.searchedData,
    }),

    listToShow() {
      const self = this;
      let result;
      let boardData = [];
      if (self.isSearched == false) {
        boardData = self.getNewsData;
      } else {
        boardData = self.searchedData;
      }

      self.lengthListToShow = boardData.length;

      result = boardData.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
      return result;
    },

    filteredToShow() {
      const self = this;
      let selectedClass = this.selectedClass;
      let result;
      let boardData = this.getNewsData.filter((item) => {
        let filtered = true;
        if (selectedClass && selectedClass.length > 0) {
          filtered = item.class == selectedClass;
        }
        return filtered;
      });

      self.lengthFilteredToShow = boardData.length;

      result = boardData.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);

      return result;
    },

    rows() {
      const self = this;
      if (self.isFiltered == false) {
        return self.lengthListToShow;
      } else {
        return self.lengthFilteredToShow;
      }
    },
  },

  watch: {
    //게시물 10개 안될 때 더보기 숨김
    filteredToShow() {
      const btn = document.getElementById("btnMoreData");
      if (this.perPage >= this.rows) {
        btn.style.display = "none";
      } else {
        btn.style.display = "block";
      }
    },

    listToShow() {
      const btn = document.getElementById("btnMoreData");
      if (this.perPage >= this.rows) {
        btn.style.display = "none";
      } else {
        btn.style.display = "block";
      }
    },
  },

  methods: {
    //선택한 class(분류)에 따라서 필터링,필터링변수 true
    setClass(selectedClass) {
      this.perPage = 10;
      const btn = document.getElementById("btnMoreData");
      btn.style.display = "block";

      if (this.isFiltered == false) {
        this.isFiltered = true;
      }
      this.selectedClass = selectedClass;
      if (selectedClass == "" || selectedClass.length == 0) {
        this.isFiltered = false;
        this.isSearched = false;
      }
    },

    getMoreData() {
      const btn = document.getElementById("btnMoreData");
      this.perPage += 5;

      //마지막 데이터에서 더보기버튼 숨김
      if (this.perPage >= this.rows) {
        btn.style.display = "none";
      }
    },

    switchVar(param) {
      if (param == true) {
        this.isSearched = true;
        this.isFiltered = false;
        this.perPage = 10;
        const btn = document.getElementById("btnMoreData");
        btn.style.display = "block";
      } else {
        this.isSearched = false;
      }
    },

    //button color
    menuColor(index) {
      const tabBtn = document.querySelectorAll(".news-btn");
      for (let i = 0; i < tabBtn.length; i++) {
        tabBtn[i].classList.remove("active");
      }
      tabBtn[index].classList.add("active");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.sub-title {
  background-image: url("https://img.davich.com/mobile/image/sub/subtitle/sub-what-bg-02.png");
  background-size: cover;
}

.davich-news {
  margin-top: 50px;
  margin-bottom: 100px;
  .news-menu {
    display: flex;
    margin-top: 40px;
  }
  //콘텐츠 목록
  .board-items {
    margin-top: 40px;
    border-top: 1px solid $color-black;
    .board-item {
      padding-bottom: 20px;
      border-bottom: 1px solid #dedede;
      position: relative;
      .badge {
        line-height: 20px;
        letter-spacing: -0.54px;
        display: inline-block;
        padding: 8px 9px;
        &.noticeBadge {
          background-color: #dedede;
        }
        &.newsBadge {
          background-color: #f0f8ff;
        }
      }
      a {
        margin: 15px 12px;
        width: 65vw;
        font-size: 16px;
        font-weight: 500;
        line-height: 30px;
        display: inline-block;
      }
      span {
        color: #aaa;
        letter-spacing: -0.54px;
        display: inline-block;
        margin-left: 55px;
      }
    }
  }
  .el-btn-m {
    width: 85vw !important;
    margin: 100px 7.5vw 0 !important;
  }
}

#pagination {
  display: none;
}
</style>
