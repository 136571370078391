<template>
  <div id="index">
    <play-video></play-video>
    <count-year></count-year>
    <card-col-4></card-col-4>
    <main-full-banner></main-full-banner>
    <quick-menu></quick-menu>
    <my-feed-insta></my-feed-insta>
    <pop-up></pop-up>
    <main-notice></main-notice>
  </div>
</template>

<script>
import PlayVideo from "../../components/mobile/MPlayVideo.vue";
import CountYear from "../../components/mobile/MCountYear.vue";
import CardCol4 from "../../components/mobile/MCardCol4.vue";
import MainFullBanner from "../../components/mobile/MMainFullBanner.vue";
import QuickMenu from "../../components/mobile/MQuickMenu.vue";
import MyFeedInsta from "../../components/mobile/MMyFeedInsta.vue";
import MainNotice from "../../components/mobile/MMainNotice.vue";
import TopButton from "../../components/mobile/MTopButton.vue";
import PopUp from "../../components/mobile/MPopUp.vue";

export default {
  components: {
    PlayVideo,
    CountYear,
    CardCol4,
    MainFullBanner,
    QuickMenu,
    MyFeedInsta,
    MainNotice,
    TopButton,
    PopUp,
  },
  data() {
    return {
      inMove: false,
      activeSection: 0,
      offsets: [],
      // touchStartY: 0,   모바일 작업시 사용
    };
  },
};
</script>

<style lang="scss" scoped>
body {
}
#index {
  max-width: 100vw;
  overflow: hidden;
}
.tpp {
  background-color: red;
}
</style>
