<template>
  <div>
    <!-- <div id="root"></div> -->
    <div class="count-year to-index" id="count-year">
      <h2 class="hidden">숫자로 보는 다비치</h2>
      <div class="ly-cont-m count-item-num">
        <span class="franchisee-num scroll-fade-in">
          <em>
            <Roller
              class="roller"
              :text="mainCountItem.franchise_count.toString()"
              :isNumberFormat="true"
              v-if="scroll < 500"
              :transition="1"
              defaultChar="0"
            ></Roller
            ><span>개</span>
          </em>
          <p class="franchisee-txt scroll-fade-in">
            다비치 안경체인의<br />
            가맹점 수
          </p>
        </span>

        <span class="graduated-num scroll-fade-in scroll-delay-1">
          <em>
            <Roller
              :text="mainCountItem.graduate_count.toString()"
              :isNumberFormat="true"
              v-if="scroll < 500"
              :transition="2"
              defaultChar="0"
            ></Roller
            ><span>명</span>
          </em>
          <!-- <animated-number
            :value="mainCountItem.graduate_count"
            round="1"
            :delay="delay2"
            v-if="scroll > 400"
          /> -->
          <p class="graduated-txt scroll-fade-in">
            다비치가 배출한<br />
            사관&middot;공채 졸업생
          </p>
        </span>

        <span class="donation-num scroll-fade-in scroll-delay-2">
          <em>
            <Roller
              :text="mainCountItem.glasses_give_count.toString()"
              :isNumberFormat="true"
              v-if="scroll < 500"
              :transition="2.5"
              defaultChar="0"
            ></Roller
            ><span>개</span>
          </em>
          <!-- <animated-number
            :value="mainCountItem.glasses_give_count"
            round="1"
            :delay="delay3"
            v-if="scroll > 400"
          /> -->
          <p class="donation-txt scroll-fade-in">
            다비치 눈건강시력지킴이<br />
            안경기부
          </p>
        </span>
        <span class="customer-num scroll-fade-in scroll-delay-3">
          <em>
            <Roller
              :text="mainCountItem.customer_count.toString()"
              :isNumberFormat="true"
              v-if="scroll < 500"
              :transition="3"
              defaultChar="0"
            ></Roller
            ><span class="ten-thousand">만</span>
            <!-- <animated-number
            :value="mainCountItem.glasses_give_count"
            round="1"
            :delay="delay3"
            v-if="scroll > 400"
          /> -->
          </em>
          <p class="customer-txt scroll-fade-in scroll-delay-3">
            다비치와 함께한<br />고객 수
          </p>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Roller from "vue-roller";

export default {
  components: {
    Roller,
  },
  computed: {
    mainCountItem() {
      let state = this.$store.state.mainCountItem;
      return state;
    },
  },
  data() {
    return {
      scroll: 500,
      delay1: 300,
      delay2: 600,
      delay3: 900,
      isActive: false,
    };
  },
  created() {
    document.addEventListener("scroll", this.handleScroll);
    this.$store.dispatch("FETCH_COUNT");
  },
  destroyed() {
    document.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // let scroll = document.documentElement.scrollTop;
      const countYear = document.getElementById("count-year");
      let scroll = countYear.getBoundingClientRect().top;
      this.scroll = scroll;
      if (scroll <= 500) {
        document.removeEventListener("scroll", this.handleScroll);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.count-year {
  position: relative;
  width: 100%;
  padding: 79px 0;
  background-color: $color-blue;
  color: $color-white;

  .count-item-num {
    @include box-center-flex;
    flex-direction: column;
    > span {
      display: flex;
      align-items: center;
      margin-bottom: 56px;
      &.customer-num {
        position: relative;
        margin-bottom: 0;
        &:before {
          position: absolute;
          content: "";
          width: 4px;
          height: 20px;
          background-color: $color-white;
          top: 19px;
          left: 18px;
        }
        &:after {
          position: absolute;
          content: "";
          top: 26px;
          left: 10px;
          width: 20px;
          height: 4px;
          background-color: $color-white;
        }
      }
      em {
        min-width: 180px;
        margin-right: 7vw;
        // margin-right: 30px;
        display: flex;
        justify-content: flex-end;
        .roller {
          font-family: $en-font;
          font-weight: 600;
          font-size: 37px;
        }
        span {
          font-size: 18px;
          font-weight: 500;
          margin-top: 25px;
          margin-left: 2px;
          // font-size: 30px;
          // font-weight: 400;
          // padding-top: 52px;
          // padding-left: 8px;
          // height: 120px;
          &.ten-thousand {
            // font-size: 60px;
            // padding-top: 23px;

            font-size: 32px;
            margin: 10px 0 0 2px;
          }
        }
      }
      &.franchisee-num {
        animation-delay: 0.2s;
      }
      &.graduated-num {
        animation-delay: 0.4s;
      }
      &.donation-num {
        animation-delay: 0.6s;
      }
      p {
        font-size: 15px;
        display: inline-block;
        line-height: normal;
        min-width: 156px;
        // margin-top: 20px;
        // width: 170px;
        // line-height: 30px;
        // text-align: center;
        // font-size: 18px;
        // font-weight: 300;
      }
    }
  }
}
.roller .rollerBlock {
  .roller__wrapper {
  }
}
.roller .roller__wrapper[data-v-3d2b3d3a] {
  // height: 120px !important;
  // font-size: 75px !important;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
