<template>
  <div class="ly-w1400" id="show-brand">
    <sub-title :title="title" :content="content"> </sub-title>
    <div class="product-ttl">
      <h2>Hope To Someone Eyewear</h2>
    </div>
    <div class="ly-cont">
      <show-left :companyData="getGlassesFrame[0]"></show-left>
    </div>
    <div class="product-ttl">
      <h2 class="eyewear">DAVICH EYEWEAR</h2>
    </div>
    <div class="ly-cont" v-for="(item, index) in getGlassesFrame" :key="item.id">
      <show-right v-if="index > 0 && index % 2 == 1" :companyData="item"></show-right>
      <show-left v-else-if="index > 0 && index % 2 == 0" :companyData="item"></show-left>
    </div>
    <davichbrand-txt-bg :text="text"></davichbrand-txt-bg>
  </div>
</template>

<script>
import SubTitle from "../../components/SubTitle1.vue";
import ShowLeft from "../../components/PRODUCTS/ShowLeft.vue";
import ShowRight from "../../components/PRODUCTS/ShowRight.vue";
import DavichbrandTxtBg from "../../components/PRODUCTS/DavichbrandTxtBg.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    SubTitle,
    ShowLeft,
    ShowRight,
    DavichbrandTxtBg,
  },

  data() {
    return {
      // sub title
      title: "안경테 & 선글라스",
      content: "다비치 브랜드에는 신뢰, 조율, 공감의 핵심가치가 담겨 있습니다.",
      // background text
      text: "BRAND",
    };
  },

  computed: {
    ...mapGetters("products", ["getGlassesFrame"]),
  },

  beforeCreate() {
    this.$store.dispatch("products/FETCH_GLASSES_FRAME");
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.sub-title {
  background-image: url("https://img.davich.com/image/sub/subtitle/sub-product-gl-bg.png");
}
.product-ttl {
  padding: 150px 0 206px 0;
  h2 {
    text-transform: uppercase;
    margin-bottom: -50px;
    text-align: center;
    font-size: 40px;
    line-height: 109px;
    font-weight: bold;
    font-family: $en-font;
    color: $color-black;
    position: relative;
    &:before,
    &:after {
      content: "";
      position: absolute;
      height: 1px;
    }
    &:before {
      top: 95px;
      left: 0;
      z-index: -1;
      width: 100%;
      background-color: #dedede;
    }
    &:after {
      width: 600px;
      top: 95px;
      left: 50%;
      transform: translateX(-50%);
      background-color: $color-black;
    }
    &.eyewear {
      &:after {
        width: 362px;
      }
    }
  }
}
</style>
