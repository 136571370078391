<template>
  <div class="close-wrap">
    <div class="close-icon"></div>
    <iframe
      id="play-frame"
      width="960"
      height="540"
      :src="srcData"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      srcData: "",
    };
  },
  props: ["playId", "youtubeUrl"],
  mounted() {
    const youtubeUrl = this.youtubeUrl;

    let filteredUrl = youtubeUrl.filter((item) => {
      let filtered = true;
      filtered = item.id == this.playId;
      return filtered;
    });

    const newUniqueUrl = filteredUrl[0].uniqueUrl;
    const newSrc = `https://www.youtube.com/embed/${newUniqueUrl}?autoplay=1&mute=1`;

    this.srcData = newSrc;
  },
};
</script>

<style lang="scss">
body > #modals-container > .vm--container {
  background-color: transparent;
  overflow: visible;
  height: auto;
}
body > #modals-container > .vm--container > .vm--modal {
  background-color: transparent;
  overflow: visible !important;
  height: auto !important;
}

body > #modals-container > .vm--container .vm--overlay {
  background: rgba(0, 0, 0, 0.6);
}

.close-icon {
  display: block;
  z-index: 99999999;
  position: absolute;
  right: 10px;
  top: -30px;
  background-color: rgba(255, 255, 255, 0);
  pointer-events: none;
  &:after {
    width: 100%;
    height: 100%;
    display: inline-block;
    content: "CLOSE \00d7";
    font-size: 15px;
    color: #fff;
    background-color: rgba(255, 255, 255, 0);
    pointer-events: none;
    font-size: 20px !important;
  }
}
</style>
