export default {
  //메인페이지 팝업
  SET_POPUP(state, resData) {
    let usePopupList = [];

    //사용여부 1인 데이터만 필터링
    for (let item of resData) {
      if (item.status == 1) {
        usePopupList.push(item);
      }
    }

    let usePopup = usePopupList.slice(0, 1);

    state.popupData = usePopup;
  },

  //메인페이지 비디오
  SET_MAIN_VIDEO: function (state, resData) {
    let result = [];

    for (let item of resData) {
      let obj = {};

      if (result.length < 5 && item.status == 1) {
        obj.id = item.id;
        obj.title = item.title;
        obj.subtitle = item.subtitle;

        for (let attc of item.attachments) {
          if (attc.type == "pc") {
            obj.pcUrl = attc.download_url;
          } else {
            obj.mobileUrl = attc.download_url;
          }
        }
        result.push(obj);
      }
    }

    state.videoData = result;
  },
  //인스타그램 데이터(PC)
  SET_INSTA_ITEM: function (state, resData) {
    let latestData = [];

    for (let i = 0; i < 8; i++) {
      latestData[i] = resData[i];
    }
    state.instaItem = latestData;
  },
  //인스타그램 데이터(모바일)
  SET_INSTA_ITEM_M: function (state, resData) {
    let latestData = [];

    for (let i = 0; i < 9; i++) {
      latestData[i] = resData[i];
    }
    state.instaItem = latestData;
  },
  //메인페이지 숫자카운팅 데이터
  SET_COUNT_ITEM: function (state, resData) {
    state.mainCountItem = resData;
  },
  //FAQ 데이터
  SET_FAQ_DATA: function (state, resData) {
    state.faqData = resData;
  },
  //대용량파일 업로드 퍼센트
  SET_UPLOAD_PERCENT: function (state, data) {
    state.uploadPercent = data;
  },
};
