<template>
  <div class="ly-wrap ly-cont-m">
    <div class="contribution-post" v-bind="contentToShow">
      <h2 class="post-ttl">
        {{ contentToShow[0].title }}
      </h2>
      <div class="datearrowParents">
        <p class="post-date">
          <!-- <em>작성일 &nbsp;</em> -->
          <span class="post-date-num">{{ contentToShow[0].created_dttm }}</span>
        </p>
        <!-- 이전글 다음글 -->
        <div class="arrow">
          <router-link
            v-if="prevData.length != 0"
            class="prevlink"
            :to="`/whatsnew/m_davichNewsDetail/${prevData[0].id}`"
          >
            <button class="prev">
              <img
                src="https://img.davich.com/mobile/image/sub/about/caret-down.png"
                alt="arrow icon"
              />
            </button>
          </router-link>
          <router-link
            v-if="nextData.length != 0"
            class="nextlink"
            :to="`/whatsnew/m_davichNewsDetail/${nextData[0].id}`"
          >
            <button class="next">
              <img
                src="https://img.davich.com/mobile/image/sub/about/caret-down2.png"
                alt="arrow icon"
              />
            </button>
          </router-link>
        </div>
      </div>
      <div
        class="post-img"
        v-for="(src, index) in contentToShow[0].imgSrc"
        :key="index"
      >
        <img :src="src" alt="post sample image" />
      </div>
      <p class="post-txt" v-html="contentToShow[0].contents"></p>
      <div class="post-list-btn ly-cont-m">
        <router-link class="el-btn-m" to="/whatsnew/m_davichNews"
          >목록보기</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["boardid"],

  computed: {
    ...mapState("whatsnew", {
      boardData: (state) => state.boardData,
    }),

    contentToShow() {
      const self = this;
      let result;
      if(this.boardData != '' && this.boardData != null){
      result = self.boardData.filter((item) => {
        let filtered = true;
        filtered = self.boardid == item.id;
        return filtered;
      });
      return result;
      }
    },

    prevData() {
      const self = this;

      let result;
      result = self.boardData.filter((item) => {
        let thisData = true;
        thisData = self.contentToShow[0].index - 1 == item.index;
        return thisData;
      });

      return result;
    },

    nextData() {
      const self = this;

      let result;
      result = self.boardData.filter((item) => {
        let thisData = true;
        thisData = self.contentToShow[0].index + 1 == item.index;
        return thisData;
      });

      return result;
    },
  },
  created() {
    if (this.boardData == '' || this.boardData == null){
      this.$store.dispatch("whatsnew/FETCH_NEWS");
    }
  },
  mounted() {
    // texteditor에 불러오는 이미지 사이즈 고정
    let img = document.querySelectorAll(".post-txt img");
    for (var i = 0; i < img.length; i++) {
      img[i].style.maxWidth = "100%";
      img[i].style.height = "auto";
    }
  },
  updated() {
    // texteditor에 불러오는 이미지 사이즈 고정
    let img = document.querySelectorAll(".post-txt img");
    for (var i = 0; i < img.length; i++) {
      img[i].style.maxWidth = "100%";
      img[i].style.height = "auto";
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.contribution-post {
  margin: auto;
  padding: 50px 0 0 0;
  .post-ttl {
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
    padding: 20px 0;
    border-top: 1px solid $color-black;
    border-bottom: 1px solid #cccccc;
    letter-spacing: -0.5px;
  }
  .datearrowParents {
    display: flex;
    position: relative;
    .post-date {
      display: flex;
      padding: 20px 0 90px;
      font-size: 15px;
      text-align: left;
      float: left;
    }
    //이전글 다음글 화살표
    .arrow {
      display: flex;
      position: absolute;
      right: 0;
      text-align: right;
      float: right;
      padding: 10px 0 90px;
      .prevlink {
        .prev img {
          transform: rotate(360deg);
          border: 1px solid #ccc;
          padding: 5px;
        }
      }
      .nextlink {
        .next img {
          transform: rotate(360deg);
          border: 1px solid #ccc;
          padding: 5px;
        }
      }
    }
  }
  //본문 내용
  .post-img img {
    max-width: 100%;
  }
  // .post-img {
  //   display: inline-block;
  //   width: 100%;
  //   margin-top: -5px;
  //   img {
  //     width: 100%;
  //     margin-bottom: 20px;
  //   }
  // }
  .post-txt {
    width: 100%;
    line-height: 25px;
    font-size: 14px;
    letter-spacing: -0.75px;
  }
  .post-list-btn {
    margin-bottom: 100px;
    margin-top: 100px;
  }
}
</style>
