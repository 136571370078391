<template>
  <div class="ly-w1400">
    <sub-title :title="title" :content="content"> </sub-title>
    <div class="ly-cont" v-for="(item, index) in getContactLens" :key="item.id">
      <show-left v-if="index % 2 == 0" :companyData="item"></show-left>
      <show-right v-else :companyData="item"></show-right>
    </div>
    <davichbrand-txt-bg :text="text"></davichbrand-txt-bg>
  </div>
</template>

<script>
import SubTitle from "../../components/SubTitle1.vue";
import ShowLeft from "../../components/PRODUCTS/ShowLeft.vue";
import ShowRight from "../../components/PRODUCTS/ShowRight.vue";
import DavichbrandTxtBg from "../../components/PRODUCTS/DavichbrandTxtBg.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    SubTitle,
    ShowLeft,
    ShowRight,
    DavichbrandTxtBg,
  },
    name: '3_contactLens',
  metaInfo: {
    title: '다비치렌즈',
    meta:[
      {name:'keywords',content:'다비치렌즈'}
    ]
  },
  computed: {
    ...mapGetters("products", ["getContactLens"]),
  },

  data() {
    return {
      //sub title
      title: "콘택트렌즈",
      content: "다비치 브랜드에는 신뢰, 조율, 공감의 핵심가치가 담겨 있습니다.",
      // background text
      text: "BRAND",
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.sub-title {
  background-image: url("https://img.davich.com/image/sub/subtitle/sub-product-contact-bg.png");
}
</style>
