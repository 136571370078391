<template>
  <div class="tab-btn-wrap">
    <button
      v-for="(item, index) in this.tabTitle"
      :key="index"
      class="tab-btn tab-btn-underline"
      @click="tabItem(index)"
    >
      {{ item }}
    </button>
    <!-- <button class="tab-btn tab-btn-underline" @click="tabItem(1)">
        {{ this.menu2 }}
      </button>
      <button class="tab-btn tab-btn-underline" @click="tabItem(2)">
        {{ this.menu3 }}
      </button>
      <button class="tab-btn tab-btn-underline" @click="tabItem(3)">
        {{ this.menu4 }}
      </button> -->
  </div>
</template>

<script>
export default {
  props: ["tabTitle"],
  // data() {
  //   return {
  //     tabTitle: [],
  //   };
  // },
  // created() {
  //   const self = this;
  // },
  methods: {
    tabItem(index) {
      const tabBtn = document.querySelectorAll(".tab-btn");
      const tabCont = document.querySelectorAll(".tab-item");
      // button color
      for (let i = 0; i < tabBtn.length; i++) {
        tabBtn[i].classList.remove("active");
      }
      tabBtn[index].classList.add("active");
      // gift card show
      for (let i = 0; i < tabCont.length; i++) {
        tabCont[i].classList.remove("display");
      }
      tabCont[index].classList.add("display");
    },
  },
  mounted() {
    const tabBtn = document.querySelectorAll(".tab-btn");
    tabBtn[0].classList.add("active");
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

// tab button
.tab-btn-wrap {
  border-bottom: 1px solid #edeff5;
  display: flex;
  justify-content: center;
  &.tab-btn-wrap-underline {
    border-bottom: 1px solid #dedede;
  }
  .tab-btn {
    @include font-tab-20;
    padding: 26px 19px 25px 19px;
    min-width: 200px;
    text-align: center;
    position: relative;
    &.active {
      color: $color-blue;
      &.tab-btn-underline {
        border-bottom: 2px solid $color-blue;
      }
    }
    &:before {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 30px;
      background-color: $color-gray;
    }
    &:last-child {
      &:before {
        display: none;
      }
    }
  }
}

/*tab content*/
.tab-items {
  .tab-item {
    display: none;
    &.display {
      display: block;
    }
  }
}
</style>
