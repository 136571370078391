export const getters = {
  getOpenArea: function (state) {
    return state.openArea;
  },

  getOpened: function (state) {
    return state.opened;
  },

  getNewOpen: function (state) {
    return state.newOpen;
  },

  getManager(state) {
    return state.manager;
  },
};
