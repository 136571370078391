<template>
  <div>
    <sub-title :title="title" :content="content"> </sub-title>
    <section class="contribution-post-list ly-cont ly-w1400">
      <h2 class="hidden">눈 건강시력 지킴이 게시글 리스트</h2>
      <search-input :mutationName="'about/SEARCH_CONTRIBUTION_BOARD'" :boardData="getBoardData" v-on:switchVar="switchVar"></search-input>
      <!--/검색창-->
      <ul class="post-list-cont">
        <contribution-post-list v-for="item in listToShow" :key="item.id" :boardData="item" class="scroll-active"></contribution-post-list>
      </ul>
      <!--/게시물 리스트-->
      <b-pagination id="pagination" v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="post-list-cont"></b-pagination>
      <!--/페이징-->
    </section>
  </div>
</template>

<script>
import SubTitle from "../../components/SubTitle1.vue";
import SearchInput from "../../components/SearchInput.vue";
import ContributionPostList from "../../components/ABOUT/ContributionPostList.vue";
import { mapGetters, mapState } from "vuex";

export default {
  beforeCreate() {
    this.$store.dispatch("FETCH_CONTRIBUTION_BOARD");
  },

  components: {
    SubTitle,
    SearchInput,
    ContributionPostList,
  },

  data() {
    return {
      perPage: 9,
      currentPage: 1,

      //서브타이틀 텍스트
      title: "눈 건강 시력 지킴이",
      content: "전문성과 신뢰를 바탕으로 한 최고의 안경전문기업, 다비치입니다.",
      // search option
      option1: "전체",
      option2: "제목",
      option3: "내용",

      isSearched: false,
    };
  },

  computed: {
    ...mapGetters("about", ["getBoardData"]),
    ...mapState("about", {
      searchedData: (state) => state.searchedData,
    }),

    rows() {
      return this.getBoardData.length;
    },

    listToShow() {
      const self = this;
      let result;
      let boardData = [];
      if (self.isSearched == false) {
        boardData = self.getBoardData;
      } else {
        boardData = self.searchedData;
      }
      result = boardData.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
      return result;
    },
  },

  created() {
    const self = this;
    if (localStorage.currentPage) {
      self.currentPage = localStorage.currentPage;
    }
  },

  watch: {
    currentPage() {
      const self = this;
      localStorage.currentPage = self.currentPage;
    },
  },

  methods: {
    switchVar(param) {
      if (param == true) {
        this.isSearched = true;
      } else {
        this.isSearched = false;
      }
    },
  },

  //사회공헌 게시판 벗어날 시 로컬스토리지 currentPage 초기화
  beforeRouteLeave(to, from, next) {
    if (to.name == "contributionPostDetail") {
      next();
    } else {
      localStorage.currentPage = 1;
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.sub-title {
  background-image: url("https://img.davich.com/image/sub/subtitle/sub-about-bg-03.png");
}

.ly-w1400 ::v-deep {
  #pagination {
    display: flex;
    justify-content: center;
    li:first-child,
    li:last-child {
      display: none;
    }
    .page-item {
      display: inline-block;
      width: 36px;
      height: 40px;
      .page-link {
        text-align: center;
        line-height: 40px !important;
        border: none !important;
        display: inline-block;
        width: 36px;
        height: 40px;
        font-size: 20px;
        letter-spacing: -0.6px;
        color: #777;
        @include font-main;
      }
      &:hover,
      &.active {
        .page-link {
          color: $color-skyblue;
          text-decoration: underline;
        }
      }
    }
  }
  padding-top: 100px;
}
.contribution-post-list {
  padding: 70px 0 150px 0;
  //검색창
  .search-comp {
    margin-left: auto;
  }
  // 게시물 리스트
  .post-list-cont {
    padding: 70px 0 150px 0;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -100px;
  }
}
</style>
