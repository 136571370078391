<template>
  <div>
    <div class="content-wrap" :class="{ blur: isUploading }">
      <sub-title :title="title" :content="content" :content2="content2" :content3="content3" :content4="content4">
      </sub-title>
      <section class="inquiry-wrap scroll-fade-in-f">
        <h2 class="hidden">제휴문의 내용, 개인정보 수집 동의</h2>
        <div class="ly-form-m partnership-form">
          <div class="ly-form-cont">
            <h3>제휴문의 내용</h3>
            <div class="form-cont ly-cont-m">
              <p class="partnership-category-form">
                <label for="partnership-category">제휴유형<span>&#42;</span></label>
                <select name="partenrship-category" id="partenrship-category" v-model="type_id">
                  <option v-for="item in type" :key="item.id" :value="item.id">{{ item.name }}</option>
                </select>
              </p>
              <p class="company-form">
                <label for="company-name">회사(기관)명<span>&#42;</span></label>
                <input
                  autofocus
                  id="company-name"
                  type="text"
                  placeholder="회사(기관)명을 입력해주세요."
                  v-model="company_name"
                  maxlength="30"
                />
              </p>
              <p class="name-form">
                <label for="name">제안자명<span>&#42;</span></label>
                <input id="name" type="text" placeholder="담당자명을 입력해주세요." v-model="name" maxlength="20" />
              </p>
              <p class="email-form">
                <label for="email">이메일<span>&#42;</span></label>
                <input id="email" type="email" placeholder="" v-model="tempEmail" @blur="sumDomain" required />
                <span class="">&#64;</span>
                <label for="input-domain" class="hidden">이메일 도메인 주소창</label>
                <input id="input-domain" type="text" v-model="inputDomain" @blur="sumDomain" />
                <label for="select-domain" class="hidden">이메일 도메인 주소 선택</label>
                <select id="select-domain" v-model="domain" @change="sumDomain">
                  <option :value="''" selected disabled>선택</option>
                  <option :value="'naver.com'">naver.com</option>
                  <option :value="'hanmail.net'">hanmail.net</option>
                  <option :value="'gmail.com'">gmail.com</option>
                  <option :value="'daum.net'">daum.net</option>
                  <option :value="'nate.com'">nate.com</option>
                  <option :value="0">직접입력</option>
                </select>
              </p>
              <p class="number-form">
                <label for="phonenumber">휴대폰<span>&#42;</span></label>
                <span>
                  <!--01 연락처 입력부분-->
                  <span class="certifi-num-wrap">
                    <input
                      id="phonenumber"
                      type="text"
                      placeholder="'-'제외하고 숫자만입력"
                      v-model="mobile_no"
                      maxlength="11"
                    />
                    <!--참고_인증번호 받기 버튼 활성화 시 클래스 active 추가-->
                    <!--버튼(인증번호 받기 전)-->
                    <button id="get-authnum-btn" class="certifi-num-btn" @click="certifyMobile">인증번호 받기</button>
                    <!--버튼(인증번호 받은 후)-->
                    <!-- <button class="certifi-num-btn reissuance">재발급</button> -->
                    <!--연락처 올바르지 않을 시 나타나는 문구-->
                    <span class="caution hidden">올바른 휴대폰 번호를 입력해주세요.</span>
                  </span>
                  <!--02 인증번호 입력부분-->
                  <span class="certifi-num-wrap">
                    <!-- <label for="certification" class="">인증번호 입력</label> -->
                    <input
                      class="hidden"
                      id="certifi-input"
                      type="text"
                      placeholder="인증번호를 입력해주세요."
                      v-model="authNumber"
                      maxlength="6"
                    />
                    <!--문구(인증번호 받은 후)-->
                    <span class="caution hidden" id="timer"
                      >인증번호가 발송되었습니다. <em> 유효시간 {{ timeStr }}초</em></span
                    >
                    <!--문구(인증번호 틀린 후)-->
                    <span class="caution hidden">인증번호가 일치하지 않습니다. <em> 유효시간 : 3:00초</em></span>
                    <!--문구(인증번호 유효시간 초과 시)-->
                    <span class="caution hidden">인증번호를 재발급 해주세요. 유효시간 초과</span>
                    <!--문구(인증번호 인증 완료 후)-->
                    <span class="caution confirm hidden">인증이 완료되었습니다.</span>
                    <!--참고_인증하기 버튼 활성화 시 클래스 active 추가-->
                    <button class="certifi-num-btn hidden" id="certifi-btn" @click="confirmMobile">
                      인증번호 확인
                    </button>
                  </span>
                </span>
              </p>
              <p class="name-form">
                <label for="title">제목<span>&#42;</span></label>
                <input id="title" type="text" placeholder="제목을 입력해주세요." v-model="titleForm" maxlength="70" />
              </p>
              <p class="reason-form">
                <label>문의 내용<span>&#42;</span></label>
                <textarea
                  name="reason"
                  id="reason"
                  cols="30"
                  rows="10"
                  placeholder="제휴문의 내용을 입력해주세요."
                  v-model="questions"
                  maxlength="2500"
                >
                </textarea>
              </p>
              <p class="file-form">
                <label for="upload-name">파일첨부</label>
                <span>
                  <input id="upload-name" class="upload-name" :value="file.name" placeholder="첨부파일" />

                  <div class="file-btn-wrap">
                    <label for="avatar">찾아보기</label>
                    <span id="delete-file">
                      <button>
                        <img src="https://img.davich.com/image/sub/partnership/icon-x-square.png" alt="x icon" />
                        파일삭제
                      </button></span
                    >
                  </div>
                  <input
                    type="file"
                    id="avatar"
                    name="avatar"
                    accept="PDF, DOC, DOCX, PPT, PPTX, HWP, GUL,
                  GIF, JPG, PNG, ZIP, TXT"
                  />
                  <em
                    >1GB를 초과 할 수 없으며 PDF, DOC, DOCX, PPT, PPTX, HWP, GUL, GIF, JPG, PNG, ZIP, TXT 파일만
                    가능합니다.</em
                  >
                </span>
              </p>
            </div>
          </div>
          <div class="ly-form-agree">
            <h3>개인정보 수집동의</h3>
            <div class="agree-cont ly-cont-m">
              <div class="agree-txt" v-html="contents"></div>
              <div class="agree-chk">
                <input id="agree-chk" type="checkbox" v-model="isAgree" />
                <label for="agree-chk"
                  >수집하는 개인정보 항목, 수집/이용목적, 개인정보 보유기간에 동의합니다.<span>&#42;</span></label
                >
                <div class="chk-icon-wrap">
                  <img
                    class="chk-icon"
                    src="https://img.davich.com/image/sub/icon-form-checkbox.jpg"
                    alt="icon checkbox"
                  />
                </div>
              </div>
            </div>
          </div>
          <button class="el-btn-m question-btn" @click="postFormData">문의하기</button>
        </div>
      </section>
    </div>
    <div class="upload" :class="{ active: this.$store.state.isUploading }">
      <div class="img-wrap">
        <img src="https://img.davich.com/image/sub/about/symbol-mark.png" alt="" />
      </div>
      <p class="upload-percent">{{ this.$store.state.uploadPercent }}%</p>
      <span class="upload-bar">
        <span class="upload-bar-inner" :style="{ left: this.$store.state.uploadPercent + '%' }"></span>
      </span>
      <p>파일을 업로드중입니다.</p>
      <button class="el-btn upload-btn" @click="cancelPost()">취소하기</button>
    </div>
  </div>
</template>

<script>
import SubTitle from "../../../components/mobile/MSubTitle1.vue";
import axios from "@/api/axios-auth";
import axiosOrigin from "axios";

import { mapState } from "vuex";

export default {
  beforeCreate() {
    this.$store.dispatch("partnership/FETCH_TYPE");
  },
  components: {
    SubTitle,
  },
  data() {
    return {
      title: "제휴문의",
      content: `다비치는 원활한 비즈니스를 위한`,
      content2: " 다양한 사업 제휴의 기회를 제공하고 있습니다.",
      content3: `다비치와 함께 성장을 도모하고, 시너지 효과를 창출할`,
      content4: `많은 기업들의 소중한 연락을 기다립니다.`,

      //formData
      type_id: null,
      type_name: "",
      company_name: "",
      titleForm: "",
      name: "",
      email: "",
      domain: "",

      mobile_no: "",
      questions: "",
      file: "",

      tempEmail: "",
      inputDomain: "",

      authnumber_id: 0,
      authNumber: null,

      isCertified: false,
      isAgree: false,

      timer: null,
      timeCounter: 180,
      timeStr: "03:00",

      cancelSource: null,
    };
  },

  computed: {
    ...mapState({
      contents: (state) => state.others.privacy[0].contents,
      type: (state) => state.partnership.partnershipType,
      isUploading: (state) => state.isUploading,
    }),
  },

  watch: {
    company_name(value) {
      const self = this;

      //공백인것,특수문자인것 제거
      const result = value.replace(/\s/g, "").replace(/[/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]/g, "");
      self.$nextTick(() => (self.company_name = result));
    },

    name(value) {
      const self = this;

      //공백인것,특수문자인것 제거
      const result = value.replace(/\s/g, "").replace(/[/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]/g, "");
      self.$nextTick(() => (self.name = result));
    },

    tempEmail(value) {
      const self = this;

      const result = value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/, "").replace(/[@]/g, "");
      self.$nextTick(() => (self.tempEmail = result));
    },

    mobile_no(value) {
      const self = this;
      const btn = document.getElementById("get-authnum-btn");
      if (self.mobile_no.length == 11) {
        btn.classList.add("active");
      } else {
        btn.classList.remove("active");
      }

      //숫자아닌것 제거
      const result = value.replace(/\D/g, "");
      self.$nextTick(() => (self.mobile_no = result));
    },

    authNumber(value) {
      const self = this;
      const btn = document.getElementById("certifi-btn");
      if (self.authNumber.length == 6) {
        btn.classList.add("active");
      } else {
        btn.classList.remove("active");
      }

      //숫자아닌것 제거
      const result = value.replace(/\D/g, "");
      self.$nextTick(() => (self.authNumber = result));
    },
    domain() {
      const self = this;
      const domainInput = document.getElementById("input-domain");
      const domainSelect = document.getElementById("select-domain");
      if (self.domain == "0") {
        domainInput.removeAttribute("disabled");
        domainInput.style.display = "block";
        domainSelect.style.display = "none";
        domainInput.focus();
      } else {
        domainInput.setAttribute("disabled", true);
        self.inputDomain = self.domain;
      }
    },

    type_id(value) {
      const self = this;

      let filteredType = self.type.filter((item) => {
        let filtered;
        filtered = item.id === value;
        return filtered;
      });
      self.type_name = filteredType[0].name;
    },
  },

  methods: {
    //타이머
    timeStart() {
      this.TimeCounter = 180;
      let interval = setInterval(() => {
        this.timeCounter--;
        this.timeStr = this.prettyTime();
        if (this.timeCounter <= 0) {
          this.timerStop(interval);
        }
      }, 1000);
      return interval;
    },
    timerStop(timer) {
      clearInterval(timer);
      this.timeCounter = 0;
    },
    prettyTime() {
      let time = this.timeCounter / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      return minutes.toString().padStart(2, "0") + ":" + secondes.toString().padStart(2, "0");
    },
    setTimer() {
      if (this.timer != null) {
        this.timerStop(this.timer);
        this.timer = null;
      }
      this.timer = this.timeStart();
    },

    //휴대폰인증
    certifyMobile() {
      if (this.mobile_no == "") {
        alert("휴대폰 번호를 입력하세요");
        return 0;
      }
      const getAuthnumBtn = document.getElementById("get-authnum-btn");
      const certifyInput = document.getElementById("certifi-input");
      const certifyBtn = document.getElementById("certifi-btn");
      const countTimer = document.getElementById("timer");
      getAuthnumBtn.classList.add("active");
      certifyInput.classList.remove("hidden");
      certifyBtn.classList.remove("hidden");
      countTimer.classList.remove("hidden");
      this.setTimer();

      if (getAuthnumBtn.innerText != "재발급") {
        getAuthnumBtn.innerText = "재발급";
      } else {
        alert("인증번호가 재발송되었습니다.");
        //타이머 초기화
        this.timerStop(this.timer);
        this.timeCounter = 180;
        this.timeStr = "03:00";
        this.timer = null;
        this.timeStart();
      }

      axios
        .post("/message/authnumber", {
          board_name: "customer_voice",
          mobile_no: this.mobile_no,
        })
        .then((res) => {
          this.authnumber_id = res.data.id;
        })
        .catch((err) => console.log(err));
    },

    confirmMobile() {
      axios
        .post("/message/authnumber/check", {
          mobile_no: this.mobile_no,
          authnumber_id: this.authnumber_id,
          input_data: this.authNumber,
        })
        .then((res) => {
          if (res.status == 200) {
            alert("인증이 완료되었습니다.");
            this.isCertified = true;
            this.timerStop(this.timer);

            document.getElementById("get-authnum-btn").classList.add("hidden");
            document.getElementById("certifi-input").classList.add("hidden");
            document.getElementById("certifi-btn").classList.add("hidden");
            document.getElementById("timer").classList.add("hidden");

            document.getElementById("phonenumber").setAttribute("disabled", true);
            document.getElementById("get-authnum-btn").setAttribute("disabled", true);

            const confirm = document.getElementById("auth-confirm");
            confirm.classList.remove("hidden");
          }
        })
        .catch((err) => {
          switch (err.response.data.error_code) {
            case 1:
              alert("유효시간이 초과되었습니다.");
              break;
            case 2:
              alert("입력하신 번호가 일치하지 않습니다.");
              break;
          }
        });
    },

    postFormData() {
      const self = this;

      //예외처리
      if (
        self.type_id == null ||
        self.company_name.length == 0 ||
        self.titleForm.length == 0 ||
        self.name.length == 0 ||
        self.email.length == 0 ||
        self.mobile_no.length == 0 ||
        self.questions.length == 0
      ) {
        alert("필수항목을 기입해 주세요");
        return 1;
      }

      if (self.isAgree == false) {
        alert("개인정보 수집동의에 체크해주세요.");
        return 1;
      }
      if (self.isCertified == false) {
        alert("휴대폰 인증을 완료해주세요.");
        return 1;
      }
      if (self.isAgree == true && self.isCertified == true) {
        const frm = new FormData();
        frm.append("type_id", self.type_id);
        frm.append("type_name", self.type_name);
        frm.append("company_name", self.company_name);
        frm.append("title", self.titleForm);
        frm.append("name", self.name);
        frm.append("email", self.email);
        frm.append("mobile_no", self.mobile_no);
        frm.append("questions", self.questions);
        frm.append("file", self.file);

        if (self.file) {
          this.$store.state.isUploading = true;
        }

        const CancelToken = axiosOrigin.CancelToken;
        self.cancelSource = CancelToken.source();

        axios
          .post(`/partnership/partnership/inquire`, frm, {
            onUploadProgress: (progressEvent) => {
              let percentage = (progressEvent.loaded * 100) / progressEvent.total;
              let precentCompleted = Math.round(percentage);
              this.$store.commit("SET_UPLOAD_PERCENT", precentCompleted);
            },
            headers: {
              "Content-Type": `multipart/form-data`,
            },
            cancelToken: self.cancelSource.token,
          })
          .then(() => {
            this.$store.state.isUploading = false;
            self.cancelSource = null;
            alert("문의가 등록되었습니다. 문의해 주셔서 감사합니다.");
            this.$router.go(this.$router.currentRoute);
          })
          .catch((err) => {
            console.log(err);
            if (err.message == "userCancel") {
              this.$store.state.isUploading = false;
              this.$store.state.uploadPercent = 0;
              self.cancelSource = null;
              alert("취소되었습니다");
            } else {
              alert("에러발생 다시 시도해주세요");
            }
          });
      }
    },

    cancelPost() {
      const self = this;
      if (self.cancelSource) {
        self.cancelSource.cancel("userCancel");
        this.isLoading = false;
        self.cancelSource = null;
      }
    },

    //직접입력 이메일 합치기
    sumDomain() {
      const self = this;
      if (self.domain == "0") {
        let fullEmail = self.tempEmail + "@" + self.inputDomain;
        self.email = fullEmail;
      } else {
        self.email = self.tempEmail + "@" + self.domain;
      }
    },
  },

  mounted() {
    const self = this;

    // 셀렉트박스 디폴트 셋팅
    self.type_id = self.type[0].id;

    let typeOfFile = typeof self.file;
    let delBtn = document.querySelector("#delete-file");

    //파일 첨부 시 경로 알려주기
    const inputEl = document.querySelector("#avatar");
    inputEl.addEventListener("change", function () {
      let fileList = inputEl.files;
      let file = fileList[0];

      //파일용량체크
      let maxSize = 1024 * 1024 * 1024;
      if (file.size >= maxSize) {
        alert("첨부파일 사이즈는 1GB 이내로 등록 가능합니다.");
        inputEl.value = "";
        return false;
      }

      self.file = file;
      typeOfFile = typeof self.file;
      if (typeOfFile == "object") {
        delBtn.style.display = "block";
        //display block
      }
    });

    //파일 삭제 기능
    delBtn.addEventListener("click", function () {
      let fileList = inputEl.files;
      if (fileList.length > 0) {
        self.file = "";
        inputEl.value = "";
        // display none
        delBtn.style.display = "none";
      }
    });
  },

  beforeRouteLeave(to, from, next) {
    //파일 전송 중 라우팅 시 전송 취소
    const self = this;
    if (self.cancelSource) {
      self.cancelSource.cancel("userCancel");
      next();
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.sub-title {
  background-image: url("https://img.davich.com/mobile/image/sub/subtitle/sub-partnership-bg.png");
  background-size: cover;
}

.inquiry-wrap {
  padding: 0 0 100px 0 !important;
  .ly-form-m {
    .ly-form-cont {
      margin-top: 50px;
    }
  }
}
</style>
