<template>
  <section class="product_b" :class="uniqSection">
    <div class="b_img" :class="imgClass">
      <img v-for="(src, index) in imgSrc" :key="index" class="scroll-fade-in-f" :src="src" alt="brand_img" />
    </div>

    <div class="b_txt" :class="txtClass">
      <img src="https://img.davich.com/image/sub/product/icon-product-02.png" alt="product_line_b" />
      <img class="product_logo scroll-fade-in-f scroll-delay-1" :src="logoSrc" alt="brand_logo" />
      <p class="scroll-fade-in-f scroll-delay-2" v-html="content"></p>
      <a :href="outlinkUrl" target="_blank" class="el-btn scroll-fade-in-f scroll-delay-3">자세히 보기</a>
    </div>
  </section>
</template>

<script>
export default {
  props: ["companyData"],
  data() {
    return {
      uniqSection: "",
      imgClass: "",
      txtClass: "",
      imgSrc: [],
      logoSrc: "",
      content: "",
      outlinkUrl: "",

      ib: 0,
    };
  },

  methods: {
    // 우측용 B 구간 고정 모션---------------------------------------------------------------------
    scrollEvent() {
      const uniqSection = this.uniqSection;
      const imgClass = this.imgClass;
      const txtClass = this.txtClass;

      var pds = document.documentElement.scrollTop; // 현재 스크롤 위치값

      var contB = document.querySelector(`.${imgClass}`); // 이미지 B 구역
      var contBw = contB.offsetWidth; // A구역의 width 값 저장
      var txtB = document.querySelector(`.${txtClass}`); // 텍스트 B 구역

      var tB = contB.getBoundingClientRect().top + window.scrollY; // 이미지의 시작점 Y값
      var bB = contB.getBoundingClientRect().bottom + window.scrollY - contBw; // 이미지의 끝점 Y값 - 이미지 Width 값

      var hH = document.querySelector("header").offsetHeight; // 헤드의 높이를 저장 / 포지션 top 용
      var txtBX = txtB.getBoundingClientRect().left + window.scrollX; // 텍스트 A 구역의 좌측 여백값 저장 / 포지션 left 용

      var contBlength = contB.getElementsByTagName("img").length; // 이미지 A 구역의 이미지 갯수 값 저장
      var contBH = (contBlength - 1) * contBw; // 이미지 최대 갯수-1, 곱하기 이미지 폭

      //---------------------------------------------------------------------
      var totalBH = contBlength * contBw; // 총 높이, 높이 계산용
      var totalB = document.querySelector(`.${uniqSection}`); // 전체 B구역
      totalB.setAttribute("style", "height:" + totalBH + "px"); // B구역 높이 입력
      //---------------------------------------------------------------------

      if (pds >= tB && pds < bB) {
        if (this.ib == 0) {
          // 텍스트박스가 스크롤 영역에 있을때
          txtB.setAttribute("style", "position:fixed; top:" + hH + "px; left:" + txtBX + "px"); // fixed
          this.ib++;
        }
      }
      if (pds > bB) {
        if (this.ib > 0) {
          // 텍스트박스가 스크롤 영역 하단으로 벗어날때
          txtB.setAttribute("style", "position:relative");
          txtB.setAttribute("style", "transform:translate(0," + contBH + "px)");
          this.ib = 0;
        }
      }
      if (pds < tB) {
        if (this.ib > 0) {
          // 텍스트박스가 스크롤 영역 상단으로 벗어날때
          txtB.setAttribute("style", "position:relative");
          txtB.setAttribute("style", "transform:translate(0,0)");
          this.ib = 0;
        }
      }
    },
  },

  created() {
    const self = this;
    self.uniqSection = "a" + this.companyData.id + "sec";
    self.imgClass = "a" + this.companyData.id + "img";
    self.txtClass = "a" + this.companyData.id + "txt";
    self.imgSrc = this.companyData.imgSrc;
    self.logoSrc = this.companyData.logoSrc;
    self.content = this.companyData.content;
    self.outlinkUrl = this.companyData.outlinkUrl;
  },
  mounted() {
    document.addEventListener("scroll", this.scrollEvent);
  },

  destroyed() {
    document.removeEventListener("scroll", this.scrollEvent);
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.product_b {
  padding-top: 70px;
  padding-bottom: 220px;
  display: block;
  box-sizing: content-box;
  //overflow: auto;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
  .b_img {
    float: right;
    width: 600px;
    display: block;
    img {
      width: 100%;
      display: block;
    }
  }
  .b_txt {
    float: left;
    width: 600px;
    img {
      display: block;
    }
    .product_logo {
      margin: 50px 0;
    }
    p {
      line-height: 30px;
      font-size: 18px;
      letter-spacing: -0.54px;
      margin-bottom: 150px;
    }
  }
}
</style>
