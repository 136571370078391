<template>
  <div>
    <sub-title :title="title" :content="content"> </sub-title>
    <section
      class="cf-main ly-cont scroll-fade-in-f ly-w1400"
      v-bind="getCfData"
    >
      <div class="iframe-wrap">
        <iframe
          id="play-frame"
          :src="`https://www.youtube.com/embed/${noticeData.uniqueUrl}?autoplay=1&mute=1&controls=0&loop=1&playlist=${noticeData.uniqueUrl}`"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div class="cf-ttl">
        <em>{{ noticeData.title }}</em>
        <h2>{{ noticeData.content }}</h2>
      </div>
      <!--비디오-->
    </section>
    <section class="cf-list ly-cont scroll-fade-in-f">
      <h2 class="hidden">davich cf list</h2>
      <search-input
        :mutationName="'whatsnew/SEARCH_CF'"
        :boardData="getCfData"
        v-on:switchVar="switchVar"
      ></search-input>
      <!--검색-->
      <div class="cf-menu">
        <button @click="[setClass(''), menuColor(0)]" class="cf-btn active">
          전체
        </button>
        <button @click="[setClass('TV CF'), menuColor(1)]" class="cf-btn">
          TV CF
        </button>
        <button @click="[setClass('DIGITAL CF'), menuColor(2)]" class="cf-btn">
          DIGITAL CF
        </button>
      </div>
      <div class="tab-items cf-items">
        <div class="tab-item cf-item display">
          <!-- 필터링 X -->
          <div class="cf-cards" v-if="isFiltered == false">
            <div v-for="item in listToShow" :key="item.id" class="cf-card">
              <a class="cf-video">
                <div class="img-wrap">
                  <img
                    :src="item.imgurl"
                    alt="davich cf"
                    @click="showModal(item.id)"
                  />
                </div>
                <div class="hover-icon">
                  <img
                    src="https://img.davich.com/image/sub/btn-play-b.png"
                    alt="start btn"
                  />
                </div>
              </a>
              <div class="cf-desc">
                <div class="cf-ttl">
                  <em>{{ item.type }}</em>
                  <h3>{{ item.title }}</h3>
                </div>
                <p>{{ item.content }}</p>
                <span>{{ item.date }}</span>
              </div>
            </div>
          </div>
          <!-- 필터링 O -->
          <div class="cf-cards" v-else-if="isFiltered == true">
            <div v-for="item in filteredToShow" :key="item.id" class="cf-card">
              <a class="cf-video">
                <div class="img-wrap">
                  <img
                    :src="item.imgurl"
                    alt="davich cf"
                    @click="showModal(item.id)"
                  />
                </div>
                <div class="hover-icon">
                  <img
                    src="https://img.davich.com/image/sub/btn-play-b.png"
                    alt="start btn"
                  />
                </div>
              </a>
              <div class="cf-desc">
                <div class="cf-ttl">
                  <em>{{ item.type }}</em>
                  <h3>{{ item.title }}</h3>
                </div>
                <p>{{ item.content }}</p>
                <span>{{ item.date }}</span>
              </div>
            </div>
          </div>
          <b-pagination
            id="pagination"
            :total-rows="rows"
            v-model="currentPage"
            :per-page="perPage"
          ></b-pagination>
          <button class="el-btn el-btn-c" id="btnMoreData" @click="getMoreData">
            더보기
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SubTitle from "../../components/SubTitle1.vue";
import SearchInput from "../../components/SearchInput.vue";
import PlayYoutube from "../../components/EDUCATION/PlayYoutube.vue";

import { mapGetters, mapState } from "vuex";

export default {
  beforeCreate() {
    this.$store.dispatch("whatsnew/FETCH_CF");
  },

  components: {
    SubTitle,
    SearchInput,
  },
  data() {
    return {
      title: "광고/홍보영상",
      content: "다비치가 준비한 새로운 CF를 마음껏 즐겨보세요.",

      playId: 0,

      isFiltered: false,
      selectedClass: "",
      isSearched: false,

      lengthListToShow: 0,
      lengthFilteredToShow: 0,

      //pagination
      perPage: 4,
      currentPage: 1,
    };
  },

  computed: {
    ...mapGetters("whatsnew", ["getCfData"]),
    ...mapState("whatsnew", {
      searchedData: (state) => state.searchedCf,
    }),

    listToShow() {
      const self = this;
      let result;
      let boardData = [];
      if (self.isSearched == false) {
        boardData = self.getCfData;
      } else {
        boardData = self.searchedData;
      }

      self.lengthListToShow = boardData.length;

      result = boardData.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
      return result;
    },

    filteredToShow() {
      const self = this;
      let selectedClass = this.selectedClass;
      let result;
      let boardData = this.getCfData.filter((item) => {
        let filtered = true;
        if (selectedClass && selectedClass.length > 0) {
          filtered = item.type == selectedClass;
        }
        return filtered;
      });

      self.lengthFilteredToShow = boardData.length;

      result = boardData.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );

      return result;
    },

    //공지설정된 데이터중 첫번째데이터 가져오기
    noticeData() {
      let noticeList = this.getCfData.filter((item) => {
        let filtered = true;
        filtered = item.notice == 1;
        return filtered;
      });

      return noticeList[0];
    },

    rows() {
      const self = this;
      if (self.isFiltered == false) {
        return self.lengthListToShow;
      } else {
        return self.lengthFilteredToShow;
      }
    },
  },

  watch: {
    //게시물 4개 안될 때 더보기 숨김
    filteredToShow() {
      const btn = document.getElementById("btnMoreData");
      if (this.perPage >= this.rows) {
        btn.style.display = "none";
      } else {
        btn.style.display = "block";
      }
    },

    listToShow() {
      const btn = document.getElementById("btnMoreData");
      if (this.perPage >= this.rows) {
        btn.style.display = "none";
      } else {
        btn.style.display = "block";
      }
    },
  },

  methods: {
    //선택한 class(분류)에 따라서 필터링,필터링변수 true
    setClass(selectedClass) {
      this.perPage = 4;
      const btn = document.getElementById("btnMoreData");
      btn.style.display = "block";

      if (this.isFiltered == false) {
        this.isFiltered = true;
      }

      this.selectedClass = selectedClass;
      if (selectedClass == "" || selectedClass.length == 0) {
        this.isFiltered = false;
        this.isSearched = false;
      }
    },

    // switchClassify(classifyNum) {
    //   this.classify = classifyNum;
    // },

    showModal(playId) {
      this.playId = playId;

      this.$modal.show(
        PlayYoutube,
        {
          playId: this.playId,
          youtubeUrl: this.getCfData,
        },
        {
          width: 960,
          height: 540,
        }
      );
    },

    //더보기 버튼 클릭 시 데이터 추가로 표시.
    getMoreData() {
      const btn = document.getElementById("btnMoreData");
      this.perPage += 4;

      //마지막 데이터에서 더보기버튼 숨김
      if (this.perPage >= this.rows) {
        btn.style.display = "none";
      }
    },

    switchVar(param) {
      if (param == true) {
        this.isSearched = true;
        this.isFiltered = false;
        this.perPage = 4;
        const btn = document.getElementById("btnMoreData");
        btn.style.display = "block";
      } else {
        this.isSearched = false;
      }
    },

    //button color
    menuColor(index) {
      const tabBtn = document.querySelectorAll(".cf-btn");
      for (let i = 0; i < tabBtn.length; i++) {
        tabBtn[i].classList.remove("active");
      }
      tabBtn[index].classList.add("active");
    },
  },

  // watch: {
  //   //마지막 데이터에서 더보기버튼 숨김
  //   perPage: function () {
  //     const btn = document.getElementById("btnMoreData");

  //     if (this.perPage >= this.listLength) {
  //       btn.style.display = "none";
  //     }
  //   },
  // },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.sub-title {
  background-image: url("https://img.davich.com/image/sub/subtitle/sub-what-bg-01.png");
}

.iframe-wrap {
  width: 1400px;
  height: 787.5px;
  overflow: hidden;
  @include img-wrap;
  iframe {
    width: 1400px;
    height: 790px;
    @include img;
  }
}

// cf title
.cf-ttl {
  em {
    font-size: 15px;
    line-height: 29px;
  }
  h2,
  h3 {
    margin-top: 7px;
    font-size: 25px;
    line-height: 29px;
    font-weight: bold;
  }
}

// main cf
.cf-main {
  position: relative;
  margin: 70px auto 140px auto;
  .cf-ttl {
    position: absolute;
    bottom: 61px;
    left: 60px;
    color: $color-white;
  }
}

// cf list
.cf-list {
  margin-bottom: 150px;
  .cf-menu {
    margin-bottom: 103px;
    .cf-btn {
      padding: 0 30px;
      font-size: 20px;
      font-weight: 500;
      line-height: 45px;
      letter-spacing: -0.6px;
      position: relative;
      &:first-child {
        padding-left: 0;
      }
      &.active {
        color: $color-blue;
      }
      &:before {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 30px;
        background-color: #dedede;
      }
      &:last-child {
        &:before {
          display: none;
        }
      }
    }
  }
  .search-comp {
    margin-right: 10px;
    margin-top: -2px;
    float: right;
  }
  .cf-items {
    .cf-item {
      .cf-cards {
        margin-right: 75px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: -120px;
        .cf-card {
          display: flex;
          align-items: flex-start;
          margin-bottom: 120px;
          height: 465px;
          &:before {
            content: "";
          }
          .cf-video {
            position: relative;
            margin-right: 44px;
            &:hover {
              .hover-icon {
                opacity: 1;
                visibility: visible;
                pointer-events: none;
              }
              box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.4);
            }
            .img-wrap {
              width: 315px;
              height: 465px;
              @include img-wrap;
              img {
                height: 151%;
                @include box-center;
              }
            }
            .hover-icon {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background-color: #0055aac0;
              z-index: 1;
              opacity: 0;
              visibility: hidden;
              transition: 0.3s;
              img {
                @include box-center;
                width: 30px;
              }
            }
            .cf-ttl {
              position: absolute;
              z-index: 2;
              bottom: 43px;
              left: 33px;
              color: $color-white;
            }
          }
          .cf-desc {
            width: 265px;
            .cf-ttl {
              em {
                color: $color-blue;
              }
            }
            p {
              @include font-main;
              color: #777;
              margin-top: 30px;
              margin-bottom: 32px;
            }
            span {
              font-size: 16px;
              color: #aaa;
              line-height: 30px;
            }
          }
        }
      }
      .el-btn {
        margin-top: 150px;
      }
    }
  }
}

#pagination {
  display: none;
}
</style>
