<template>
  <div class="tab-btn-wrap">
    <button
      v-for="(item, index) in this.tabTitle"
      :key="index"
      class="tab-btn-m"
      @click="tabItem(index)"
    >
      {{ item }}
    </button>
  </div>
</template>

<script>
export default {
  props: ["tabTitle"],
  // data() {
  //   return {
  //     tabTitle: [],
  //   };
  // },
  // created() {
  //   const self = this;
  // },
  methods: {
    tabItem(index) {
      const tabBtn = document.querySelectorAll(".tab-btn-m");
      const tabCont = document.querySelectorAll(".tab-item");
      // button color
      for (let i = 0; i < tabBtn.length; i++) {
        tabBtn[i].classList.remove("active");
      }
      tabBtn[index].classList.add("active");
      // gift card show
      for (let i = 0; i < tabCont.length; i++) {
        tabCont[i].classList.remove("display");
      }
      tabCont[index].classList.add("display");
    },
  },
  mounted() {
    const tabBtn = document.querySelectorAll(".tab-btn-m");
    tabBtn[0].classList.add("active");
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

// tab button
.tab-btn-wrap {
  width: 100vw;
  display: flex;
  border-bottom: 1px solid #edeff5;
  justify-content: center;
  // display: table;
  // border-collapse: collapse;
  // table-layout: fixed;
  &.tab-btn-wrap-underline {
    border-bottom: 1px solid #dedede;
  }
  .tab-btn-m {
    font-size: 15px;
    font-weight: 500;
    height: 60px;
    line-height: 60px;
    flex: 1;
    border-right: 1px solid #dedede;
    text-align: center;
    position: relative;
    // display: table-cell;
    // vertical-align: middle;
    &:last-child,
    &.active {
      border-right: 0;
    }
    &.active {
      color: $color-white;
      background-color: $color-blue;
    }
  }
}

/*tab content*/
.tab-items {
  .tab-item {
    display: none;
    &.display {
      display: block;
    }
  }
}
</style>
