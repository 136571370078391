import { mutations } from "./mutations";
import { actions } from "./actions";
import { getters } from "./getters";

const state = {
  faqData: [],
  searchedData: [],
  storeData: [],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
