<template>
  <div>
    <div class="detail-img">
      <div class="main-img-wrap">
        <img v-if="storeProps.imgSrc" class="main-img" :src="storeProps.imgSrc[0]" alt="" />
        <img v-else class="main-img" src="https://img.davich.com/image/sub/education/education_team_ready.png" alt="" />
      </div>
      <div class="sub-imgs">
        <button class="sub-img-wrap" v-for="(src, index) in storeProps.imgSrc" :key="index">
          <img :data-alt="index + 1" class="sub-img" :src="src" alt="" />
        </button>
      </div>
    </div>
    <div class="detail-ttl">
      <h3>{{ storeProps.name }}</h3>
      <div class="classify-items">
        <em class="classify-item" v-for="(tag, index) in storeProps.tag" :key="index">
          <div
            class="store-icon-wrap"
            :class="{
              wifiIcon: tag.includes('wifi'),
              parkingIcon: tag.includes('parking'),
              earaidIcon: tag.includes('earaid'),
              lensIcon: tag.includes('lens'),
              fittingIcon: tag.includes('fitting'),
            }"
          >
            <img :src="iconSrc" alt="icon" />
          </div>
          <span v-if="tag.includes('wifi')">와이파이</span>
          <span v-if="tag.includes('parking')">주차장</span>
          <span v-if="tag.includes('earaid')">보청기</span>
          <span v-if="tag.includes('lens')">렌즈샵</span>
          <span v-if="tag.includes('fitting')">누진피팅센터</span>
        </em>
      </div>
    </div>
    <div class="detail-txt-wrap">
      <div class="detail-txt">
        <p class="detail-address">
          <span class="store-icon-wrap detail-store-icon-wrap address-icon-wrap addressIcon">
            <img src="https://img.davich.com/image/sub/support/map/icon-store-all.png" alt="address icon" />
          </span>
          <span class="address">
            <!-- eslint-disable-next-line vue/no-textarea-mustache -->
            <input
              id="address-data"
              name="ourtext"
              class="hidden"
              type="hidden
              "
              v-model="storeProps.addr"
            />
            <div class="address-detail">
              {{ storeProps.addr }}
              <button @click="copyToClipboard">주소복사</button>
            </div>
          </span>
        </p>
        <p class="detail-number" v-if="storeProps.tel">
          <span class="store-icon-wrap detail-store-icon-wrap phone-icon-wrap phoneIcon">
            <img src="https://img.davich.com/image/sub/support/map/icon-store-all.png" alt="phone icon" />
          </span>
          <span>{{ storeProps.tel }}</span>
        </p>
        <p class="detail-site" v-if="storeProps.blogUrl">
          <span class="store-icon-wrap detail-store-icon-wrap site-icon-wrap siteIcon">
            <img src="https://img.davich.com/image/sub/support/map/icon-store-all.png" alt="site icon" />
          </span>
          <span
            ><a :href="storeProps.blogUrl" target="_blank">{{ storeProps.blogUrl }}</a></span
          >
        </p>
        <p class="detail-location" v-if="storeProps.locationInfo">
          <span class="store-icon-wrap detail-store-icon-wrap location-icon-wrap locationIcon">
            <img src="https://img.davich.com/image/sub/support/map/icon-store-all.png" alt="location icon" />
          </span>
          <span>{{ storeProps.locationInfo }}</span>
        </p>
        <p class="detail-sale">
          <span class="store-icon-wrap detail-store-icon-wrap sale-icon-wrap saleIcon">
            <img src="https://img.davich.com/image/sub/support/map/icon-store-all.png" alt="sale icon" />
          </span>
          <span
            ><span v-for="dcItem in storeProps.discount" :key="dcItem.id"
              ><strong>{{ dcItem.dc_rate }}% 할인</strong>{{ dcItem.ven_name }}</span
            >
          </span>
        </p>
      </div>
    </div>
    <div class="kakao-reserv-btns">
      <a :href="storeProps.kakaoUrl" target="_blank">
        <div class="store-icon-wrap kakao-icon-wrap kakaoIcon">
          <img src="https://img.davich.com/image/sub/support/map/icon-store-all.png" alt="kakao icon" />
        </div>
        <span>카카오톡 채널</span>
      </a>
      <a href="http://www.davichmarket.com/front/visit/visit-welcome" target="_blank" class="visit-btn"
        ><div class="store-icon-wrap reserv-icon-wrap reservIcon">
          <img src="https://img.davich.com/image/sub/support/map/icon-store-all-02.png" alt="reservation icon" />
        </div>
        <span>방문예약</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["storeProps"],

  data() {
    return {
      iconSrc: "https://img.davich.com/image/sub/support/map/icon-store-all.png",
    };
  },

  mounted() {},

  updated() {
    // 상세 내용 없을경우 아이콘 제거
    let iconWrap = document.querySelectorAll(".detail-store-icon-wrap");
    for (var i = 0; i < iconWrap.length; i++) {
      var textNode = iconWrap[i].nextSibling;
      if (textNode.innerText == "") {
        iconWrap[i].style.display = "none";
      }
    }
  },

  methods: {
    copyToClipboard() {
      let copySpan = document.getElementById("address-data");
      copySpan.select();
      document.execCommand("copy");
      alert("복사되었습니다, 감사합니다.");
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";
.find-store.ly-w1400 {
  .detail-img {
    .main-img-wrap {
      width: 100%;
      height: 300px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .sub-imgs {
      margin-top: 20px;
      display: flex;
      .sub-img-wrap {
        // flex: 0 0 auto;
        width: 92px;
        height: 60px;
        margin-left: 15px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .detail-ttl {
    padding: 35px 20px;
    h3 {
      font-weight: bold;
      font-size: 20px;
      line-height: 45px;
      letter-spacing: 0.6px;
    }
  }
  .detail-txt-wrap {
    height: calc(100vh - 131px - 380px - 50px - 80px);
    .detail-txt {
      overflow: scroll;
      padding: 0 20px;
      @include scroll-bar;
      color: #777;
      height: inherit;
      margin: 0 auto;
      p {
        display: flex;
        margin-bottom: 20px;
        .store-icon-wrap {
          margin-top: 5px;
        }
        span {
          &:nth-child(1) {
            flex-shrink: 0;
          }
          @include font-main;
          a {
            color: #777;
          }
          span {
            color: $color-blue;
            display: block;
            line-height: 41px;

            strong {
              font-size: 15px;
              border: 1px solid #0056aa;
              display: inline-block;
              min-width: 80px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              margin-right: 10px;
            }
          }
          button {
            display: inline-block;
            width: 65px;
            height: 30px;
            border: 1px solid #dedede;
            font-size: 13px;
            line-height: 30px;

            color: #777;
            border-radius: 15px;
          }
        }
      }
    }
  }
  .kakao-reserv-btns {
    position: absolute;
    bottom: 20px;
    left: 0;
    display: flex;
    padding: 0 20px;
    background-color: #fff;

    a {
      width: 220px;
      height: 50px;
      border: 1px solid $color-black;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 5px;
      }
      &.visit-btn {
        margin-left: 20px;
        background-color: $color-blue;
        color: $color-white;
        .reservIcon {
          width: 22px;
          height: 22px;
          margin-bottom: -4px;

          img {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }
}

.store-icon-wrap {
  position: relative;
  overflow: hidden;
  margin-right: 5px;
  display: inline-block;
  img {
    position: absolute;
  }
  &.wifiIcon {
    width: 21px;
    height: 21px;
    img {
      left: 0px;
      top: 0px;
    }
  }
  &.addressIcon {
    width: 21px;
    height: 21px;
    img {
      left: 0px;
      top: -22px;
    }
  }
  &.phoneIcon {
    width: 21px;
    height: 21px;
    img {
      left: -22px;
      top: 0px;
    }
  }
  &.siteIcon {
    width: 21px;
    height: 21px;
    img {
      left: -21px;
      top: -22px;
    }
  }
  &.earaidIcon {
    width: 21px;
    height: 21px;
    img {
      left: -43px;
      top: 0;
    }
  }
  &.locationIcon {
    width: 22px;
    height: 21px;
    img {
      left: -42px;
      top: -22px;
    }
  }
  &.saleIcon {
    width: 21px;
    height: 21px;
    img {
      left: -64px;
      top: 0;
    }
  }
  &.fittingIcon {
    width: 22px;
    height: 21px;
    img {
      left: -64px;
      top: -22px;
    }
  }
  &.lensIcon {
    width: 23px;
    height: 21px;
    img {
      left: -85px;
      top: 0px;
    }
  }
  &.parkingIcon {
    width: 23px;
    height: 21px;
    img {
      left: -85px;
      top: -22px;
    }
  }
  &.kakaoIcon {
    width: 26px;
    height: 21px;
    img {
      left: -109px;
      top: 0px;
    }
  }
}

.address {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  .address-detail {
    display: inline-block;
    flex-shrink: 0;
    font-size: 18px;
    color: #777;
    margin-right: 10px;
    max-width: 100%;
  }
}
</style>
