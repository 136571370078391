<template>
  <div class="ly-w1400">
    <!-- <div id="root"></div> -->
    <div class="count-year to-index">
      <h2 class="hidden">숫자로 보는 다비치</h2>
      <div class="ly-cont count-item-num">
        <span class="franchisee-num scroll-fade-in">
          <em>
            <Roller
              class="roller"
              :text="mainCountItem.franchise_count.toString()"
              :isNumberFormat="true"
              v-if="scroll > 400"
              :transition="1"
              defaultChar="0"
            ></Roller
            ><span>개</span>
          </em>
          <p class="franchisee-txt scroll-fade-in">
            다비치 안경체인의<br />
            가맹점 수
          </p>
        </span>

        <span class="graduated-num scroll-fade-in scroll-delay-1">
          <em>
            <Roller
              :text="mainCountItem.graduate_count.toString()"
              :isNumberFormat="true"
              v-if="scroll > 400"
              :transition="2"
              defaultChar="0"
            ></Roller
            ><span>명</span>
          </em>
          <!-- <animated-number
            :value="mainCountItem.graduate_count"
            round="1"
            :delay="delay2"
            v-if="scroll > 400"
          /> -->
          <p class="graduated-txt scroll-fade-in">
            다비치가 배출한<br />
            사관&middot;공채 졸업생
          </p>
        </span>

        <span class="donation-num scroll-fade-in scroll-delay-2">
          <em>
            <Roller
              :text="mainCountItem.glasses_give_count.toString()"
              :isNumberFormat="true"
              v-if="scroll > 400"
              :transition="2.5"
              defaultChar="0"
            ></Roller
            ><span>개</span>
          </em>
          <!-- <animated-number
            :value="mainCountItem.glasses_give_count"
            round="1"
            :delay="delay3"
            v-if="scroll > 400"
          /> -->
          <p class="donation-txt scroll-fade-in">
            다비치 눈건강시력<br />지킴이 안경기부
          </p>
        </span>
        <span class="customer-num scroll-fade-in scroll-delay-3">
          <em>
            <Roller
              :text="mainCountItem.customer_count.toString()"
              :isNumberFormat="true"
              v-if="scroll > 400"
              :transition="3"
              defaultChar="0"
            ></Roller
            ><span class="ten-thousand">만</span>
            <!-- <animated-number
            :value="mainCountItem.glasses_give_count"
            round="1"
            :delay="delay3"
            v-if="scroll > 400"
          /> -->
          </em>
          <p class="customer-txt scroll-fade-in scroll-delay-3">
            다비치와 함께한<br />고객 수
          </p>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Roller from "vue-roller";

export default {
  components: {
    Roller,
  },
  computed: {
    mainCountItem() {
      let state = this.$store.state.mainCountItem;
      return state;
    },
  },
  data() {
    return {
      scroll: 0,
      isActive: false,
    };
  },
  created() {
    document.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      let scroll = document.documentElement.scrollTop;
      this.scroll = scroll;
      if (scroll >= 400) {
        document.removeEventListener("scroll", this.handleScroll);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.roller .roller__wrapper ::v-deep {
  mask-image: none !important;
}

.count-year {
  position: relative;
  &:after {
    content: "";
    width: 1px;
    height: 100px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: $color-white;
  }
  width: 100%;
  height: 540px;
  background-color: $color-blue;
  color: $color-white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .count-item-num {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > span {
      display: flex;

      text-align: center;
      align-items: center;
      flex-direction: column;

      &.customer-num {
        position: relative;
        &:before {
          position: absolute;
          content: "";
          width: 4px;
          height: 40px;
          background-color: $color-white;
          top: -60px;
        }
        &:after {
          position: absolute;
          content: "";
          top: -42px;
          width: 40px;
          height: 4px;
          background-color: $color-white;
        }
      }
      em {
        display: flex;
        .roller {
          font-family: $en-font;
          font-weight: 600;
          font-size: 75px;
        }
        span {
          font-size: 30px;
          font-weight: 400;
          padding-top: 52px;
          padding-left: 8px;
          height: 120px;
          &.ten-thousand {
            font-size: 60px;
            padding-top: 23px;
          }
        }
      }
      &.franchisee-num {
        animation-delay: 0.2s;
      }
      &.graduated-num {
        animation-delay: 0.4s;
      }
      &.donation-num {
        animation-delay: 0.6s;
      }
      p {
        margin-top: 20px;
        width: 170px;
        line-height: 30px;
        text-align: center;
        font-size: 18px;
        font-weight: 300;
      }
    }
  }
}
// .roller .rollerBlock {
//   .roller__wrapper {
//     height: 120px !important;
//     font-size: 75px !important;
//   }
// }
// .roller .roller__wrapper[data-v-3d2b3d3a] {
//   height: 150px !important;
//   font-size: 75px !important;
//   mask-image: none !important;
//   -webkit-mask-image: none !important;
// }

// .roller[data-v-42164fec] {
//   height: 120px !important;
//   font-size: 75px !important;
// }

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
