<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div :class="{ blur: isUploading }">
    <footer class="footer to-index full-page-scroll">
      <ul class="fnb ly-footer-inner">
        <li class="footer-main">
          <div class="footer-main-inner ly-cont-m">
            <h2>(주)다비치안경체인</h2>
            <div class="footer-sns">
              <a href="https://www.instagram.com/davich.official/" target="_blank" class="instagram">
                <img src="https://img.davich.com/image/main/icon-instagram-01.png" alt="instagram icon" />
              </a>
              <a href="https://facebook.com/davichoptical" target="_blank" class="facebook">
                <img src="https://img.davich.com/image/main/icon-facebook-01.png" alt="facebook icon" />
              </a>
              <a href="https://www.youtube.com/channel/UCJoAvzt6GNBZLuXYY50F3Yw" target="_blank" class="youtube">
                <img src="https://img.davich.com/image/main/icon-youtube-01.png" alt="youtube icon" />
              </a>
            </div>
          </div>
        </li>
        <li>
          <router-link to="/privacy/m_privacyPolicy" style="font-weight: bold;">개인정보처리방침​</router-link>
        </li>
        <li>
          <router-link to="/privacy/m_emailProtect">이메일주소 무단수집거부</router-link>
        </li>
        <li><router-link to="/support/m_faq">자주하는 질문</router-link></li>
        <li><router-link to="/about/m_location">오시는 길</router-link></li>
        <!-- <li>
          <a :href="downloadUrl" download="daivch-brochure"
            >브로슈어 다운로드</a
          >
        </li> -->
        <li class="footer-familysite">
          <button class="familysite-btn">
            Family Site
            <img src="https://img.davich.com/mobile/image/main/icon-caret-down.png" alt="arrow" />
          </button>
          <ul class="familysite-link sub-menu">
            <li>
              <a href="https://www.davichmarket.com/" target="_blank">다비치마켓</a>
            </li>
            <li>
              <a href="https://www.davichhearing.com/" target="_blank">다비치보청기</a>
            </li>
            <li>
              <a href="https://www.kvisionoptical.com/" target="_blank">K비젼안경</a>
            </li>
          </ul>
        </li>
        <li class="footer-info">
          <button class="active">
            COPYRIGHT&copy; DAVICH OPTICAL. ALL RIGHTS RESERVED.
            <img src="https://img.davich.com/mobile/image/main/icon-caret-down.png" alt="arrow" />
          </button>

          <p class="sub-menu">
            <span>대표이사 : 김인규, 김봉건</span>
            <span>주소 : 서울 용산구 만리재로 190-1 (서계동)</span>
            <span>사업자 등록번호 : 764-81-01136</span>
            <span>의료기기 판매업 신고번호 : 제 3192호</span>
            <span>의료기기 광고 사전심의번호 : 2016GN1350095</span>
            <!-- <span>대표번호 : 02-752-6177</span> -->
            <span>대표번호 : 1668-1407</span>
            <!-- <span>연말정산 문의 : 02-752-6177 (ARS 1번)</span> -->
            <!--<span>연말정산 문의 : 070-7432-0957</span>-->
            <span>Fax : 02-752-6176</span>
          </p>
        </li>
      </ul>
    </footer>
  </div>
</template>
<script>
import axios from "@/api/axios-auth";
import { mapState } from "vuex";

export default {
  data() {
    return {
      downloadUrl: "",
    };
  },

  computed: {
    ...mapState({
      isUploading: (state) => state.isUploading,
    }),
  },

  created() {
    axios
      .get("/about/brochure")
      .then((res) => {
        this.downloadUrl = res.data.download_url;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  mounted() {
    let footerBtn = document.querySelectorAll(".fnb > li > button");

    for (var i = 0; i < footerBtn.length; i++) {
      footerBtn[i].addEventListener("click", function () {
        this.classList.toggle("active");
      });
    }
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.footer {
  width: 100vw;
  .fnb {
    li {
      border-top: 1px solid #dedede;
      &.footer-main {
        .footer-main-inner {
          padding: 27px 0 23px 0;
          display: flex;
          justify-content: space-between;
          h2 {
            font-size: 18px;
            line-height: 25px;
            font-weight: bold;
          }
          .footer-sns {
            display: flex;
            align-items: center;
            a {
              margin-right: 27px;
              overflow: hidden;
              position: relative;
              overflow: hidden;
              img {
              }
              &.instagram {
                width: 23px;
                height: 23px;
                img {
                  width: 100%;
                  height: 100%;
                  left: 0;
                }
              }
              &.facebook {
                width: 24px;
                height: 24px;
                img {
                  width: 100%;
                  height: 100%;
                  left: -31px;
                }
              }
              &.youtube {
                margin-right: 0;
                width: 25px;
                height: 21px;
                img {
                  width: 100%;
                  height: 100%;
                  left: -63px;
                }
              }
            }
          }
        }
      }
      > a,
      button {
        display: block;
        width: 85vw;
        padding: 17px 0;
        margin: auto;
        font-size: 15px;
        line-height: 25px;
        &.active {
          img {
            transform: rotate(180deg);
          }
          & + .sub-menu {
            display: block;
          }
        }
        & + .sub-menu {
          display: none;
        }
        img {
          width: 15px;
          height: 15px;
          float: right;
          margin-top: 5px;
          transform: rotate(0deg);
        }
      }
      &.footer-familysite {
        button {
          text-align: left;
        }
        img {
        }
        .familysite-link {
          background-color: #f9f9f9;
        }
      }
      &.footer-info {
        button {
          font-size: 13px;
          text-align: left;
        }
        p {
          padding: 30px 7.5vw;
          background-color: #f9f9f9;
          span {
            display: block;
            font-size: 15px;
            line-height: 25px;
          }
        }
      }
    }
  }
}
</style>
