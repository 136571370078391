<template>
  <div class="ly-wrap ly-cont-m">
    <div class="contribution-post" v-bind="contentToShow">
      <h2 class="post-ttl">
        {{ contentToShow[0].title }}
      </h2>
      <div class="datearrowParents">
        <p class="post-date">
          <em>마감일 &nbsp;</em>
          <span class="post-date-num">{{ contentToShow[0].due_date }}</span>
        </p>
        <div class="arrow">
          <router-link
            v-if="prevData.length != 0"
            class="prevlink"
            :to="`/education/m_recruitDetail/${prevData[0].id}`"
          >
            <button class="prev">
              <img
                src="https://img.davich.com/mobile/image/sub/about/caret-down.png"
                alt="arrow icon"
              />
            </button>
          </router-link>
          <router-link
            v-if="nextData.length != 0"
            class="nextlink"
            :to="`/education/m_recruitDetail/${nextData[0].id}`"
          >
            <button class="next">
              <img
                src="https://img.davich.com/mobile/image/sub/about/caret-down2.png"
                alt="arrow icon"
              />
            </button>
          </router-link>
        </div>
      </div>
      <div v-if="contentToShow[0].imgSrc">
        <div
          class="post-img"
          v-for="(src, index) in contentToShow[0].imgSrc"
          :key="index"
        >
          <img :src="src" alt="post sample image" />
        </div>
      </div>
      <p class="post-txt" v-html="contentToShow[0].contents"></p>
     <div v-if="contentToShow[0].formInfo">
        <div v-for="(item, index) in contentToShow[0].formInfo" :key="index">
        <button class="download application-download-btn" @click="saveas(item.name,item.formUrl)" 
          >{{item.name}} [다운로드]</button>
        </div>
      </div>

      <router-link
        :to="{ path: '/education/m_recruit', query: { index: 1 } }"
        class="go application-go-btn"
        >지원하러 가기</router-link
      >
      <div class="post-list-btn ly-cont-m">
        <router-link class="el-btn-m" to="/education/m_recruit"
          >목록보기</router-link
        >
      </div>
    </div>
  </div>
  <!-- 사관∙공채 공고 상세페이지 -->
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["boardid"],

  computed: {
    ...mapState("education", {
      boardData: (state) => state.boardData,
    }),

    contentToShow() {
      const self = this;
      let result;
      if(this.boardData != '' && this.boardData != null){
      result = self.boardData.filter((item) => {
        let filtered = true;
        filtered = self.boardid == item.id;
        return filtered;
      });
      return result;
      }
    },

    prevData() {
      const self = this;

      let result;
      result = self.boardData.filter((item) => {
        let thisData = true;
        thisData = self.contentToShow[0].index - 1 == item.index;
        return thisData;
      });

      return result;
    },

    nextData() {
      const self = this;

      let result;
      result = self.boardData.filter((item) => {
        let thisData = true;
        thisData = self.contentToShow[0].index + 1 == item.index;
        return thisData;
      });

      return result;
    },
  },
  
  created(){
    if (this.boardData == '' || this.boardData == null) {
      this.$store.dispatch("education/FETCH_RECRUIT");
    }
  },
    methods: {
    forceFileDownload(response, realfilename) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', realfilename)
      document.body.appendChild(link)
      link.click()
    },

    async saveas(name, fileurl) {
      const reqFileUrl = fileurl ; 
      const file = await fetch(reqFileUrl); 
      const blob = await file.blob();
      const url = window.URL.createObjectURL(blob);


      var link = document.createElement("a");
      link.href = url;
      link.download = name;
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      link.remove();

    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.contribution-post {
  margin: auto;
  padding: 50px 0 0 0;
  .post-ttl {
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
    padding: 20px 0;
    border-top: 1px solid $color-black;
    border-bottom: 1px solid #cccccc;
    letter-spacing: -0.5px;
  }
  .datearrowParents {
    display: flex;
    position: relative;
    //마감일
    .post-date {
      padding: 20px 0 90px;
      font-size: 15px;
      text-align: left;
      display: flex;
    }
    .arrow {
      position: absolute;
      right: 0;
      text-align: right;
      display: flex;
      padding: 10px 0 90px;
      .prevlink {
        .prev img {
          transform: rotate(360deg);
          border: 1px solid #ccc;
          padding: 5px;
        }
      }
      .nextlink {
        .next img {
          transform: rotate(360deg);
          border: 1px solid #ccc;
          padding: 5px;
        }
      }
    }
  }
  //본문 이미지
  .post-img {
    display: inline-block;
    width: 100%;
    margin-top: -5px;
    img {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  //본문 내용
  .post-txt {
    position: relative;
    width: 100%;
    line-height: 25px;
    font-size: 14px;
    letter-spacing: -0.75px;
    img {
      width: 100%;
    }
  }
  // 목록보기 버튼
  .post-list-btn {
    margin-bottom: 100px;
    margin-top: 100px;
  }
}
// 지원서 다운로드 버튼
.download {
   display: block;
  width: fit-content;
  text-align: left;
  margin-bottom: 7px;
  font-weight: 500;
  font-size: 15px;
  color: $color-blue;
}
.download:hover {
  text-decoration: underline;
}
// 지원하러 가기 버튼
.go {
  margin-top: 20px;
  width: 100%;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-weight: 400;
  font-size: 17px;
  color: #fff;
  background-color: $color-blue;
}
</style>
