<template>
  <div id="bootstrap-overrides">
    <li class="post-list-item" v-bind="boardData">
      <router-link :to="`/about/contributionPostDetail/${boardData.id}`">
        <div class="post-list-img">
          <img class="post-img" :src="boardData.main_image" alt="search icon" />
        </div>
        <div class="post-list-desc">
          <h3 class="post-list-ttl">
            {{ boardData.title }}
          </h3>
          <span class="post-list-date">{{ boardData.created_dttm }}</span>
        </div>
      </router-link>
    </li>
  </div>
</template>

<script>
export default {
  props: ["boardData"],
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

// 게시물 리스트
#bootstrap-overrides {
  width: 440px;
  margin-right: 40px;
  margin-bottom: 100px;
  &:nth-child(3n) {
    margin-right: 0;
  }
  .post-list-item {
    a {
      width: 100%;

      text-decoration: initial;
      &:hover {
        .post-list-img {
          box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.4);
          position: relative;
          &:before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            background-color: $color-blue;
            opacity: 0.8;
            z-index: 2;
          }
          &:after {
            content: "";
            width: 40px;
            height: 36px;
            background: url("https://img.davich.com/image/sub/about/icon-plus.png");
            @include box-center;
            z-index: 3;
          }
        }
      }
      .post-list-img {
        width: 100%;
        height: 300px;
        overflow: hidden;
        transition: 0.4s;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .post-list-desc {
        margin-top: 30px;

        .post-list-ttl {
          font-size: 25px;
          font-weight: bold;
          line-height: 40px;
          margin-bottom: 30px;
        }
        .post-list-date {
          color: #aaa;
        }
      }
    }
  }
}
</style>
