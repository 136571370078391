<template>
  <!-- eslint-disable -->
  <div>
    <header class="header">
      <div class="ly-header-inner header-inner">
        <button class="toggle-btn">
          <span></span>
          <span></span>
          <span></span>
        </button>
        <nav>
          <ul class="gnb">
            <li class="main-menu">
              <button>
                ABOUT​
                <img src="https://img.davich.com/mobile/image/main/icon-caret-down-g.png" alt="arrow" />
              </button>
              <ul class="sub-menu sub-menu-1">
                <li>
                  <router-link to="/about/m_greetings">대표 인사말​</router-link>
                </li>
                <li>
                  <router-link to="/about/m_information">기업소개</router-link>
                </li>
                <li>
                  <router-link to="/about/m_identity">비주얼 아이덴티티​​</router-link>
                </li>
                <li>
                  <router-link to="/about/m_contribution">사회공헌​</router-link>
                </li>
                <li>
                  <router-link to="/about/m_location">오시는 길</router-link>
                </li>
              </ul>
            </li>
            <li class="main-menu">
              <button>
                PRODUCTS​
                <img src="https://img.davich.com/mobile/image/main/icon-caret-down-g.png" alt="arrow" />
              </button>
              <ul class="sub-menu">
                <li>
                  <router-link to="/products/m_glasses">안경테 	&#38; 선글라스</router-link>
                </li>
                <li>
                  <router-link to="/products/m_glassLens">안경렌즈​</router-link>
                </li>
                <li>
                  <router-link to="/products/m_contactLens">콘택트렌즈</router-link>
                </li>
                <li>
                  <router-link to="/products/m_hearingAid">보청기​</router-link>
                </li>
              </ul>
            </li>
            <li class="main-menu">
              <button>
                PARTNERSHIP
                <img src="https://img.davich.com/mobile/image/main/icon-caret-down-g.png" alt="arrow" />
                ​
              </button>
              <ul class="sub-menu">
                <li>
                  <router-link to="/partnership/m_franchise">가맹점현황​</router-link>
                </li>
                <li>
                  <router-link to="/partnership/m_howto">가맹점 개설안내</router-link>
                </li>
                <li>
                  <router-link to="/partnership/m_inquiryFranchise">가맹점 개설문의​</router-link>
                </li>
                <li>
                  <router-link to="/partnership/m_inquiryPartnership">제휴문의</router-link>
                </li>
              </ul>
            </li>
            <li class="main-menu">
              <button>
                EDUCATION​
                <img src="https://img.davich.com/mobile/image/main/icon-caret-down-g.png" alt="arrow" />
              </button>
              <ul class="sub-menu">
                <li>
                  <router-link to="/education/m_researchCenter">고객가치경영연구원</router-link>
                </li>
                <li>
                  <router-link to="/education/m_officer">다비치 사관&middot;공채</router-link>
                </li>
                <li>
                  <router-link to="/education/m_recruit">사관&middot;공채&middot;코디네이터 모집</router-link>
                </li>
              </ul>
            </li>
            <li class="main-menu">
              <button>
                WHAT'S NEW
                <img src="https://img.davich.com/mobile/image/main/icon-caret-down-g.png" alt="arrow" />
              </button>
              <ul class="sub-menu sub-menu-4">
                <li>
                  <router-link to="/whatsnew/m_cf">광고/홍보영상​</router-link>
                </li>
                <li>
                  <router-link to="/whatsnew/m_davichNews">다비치 소식</router-link>
                </li>
                <li>
                  <router-link to="/whatsnew/m_event">이벤트</router-link>
                </li>
              </ul>
            </li>
            <li class="main-menu">
              <button>
                SUPPORT
                <img src="https://img.davich.com/mobile/image/main/icon-caret-down-g.png" alt="arrow" />
              </button>
              <ul class="sub-menu sub-menu-5">
                <li>
                  <router-link to="/support/m_ask">고객의 소리​</router-link>
                </li>
                <li>
                  <router-link to="/support/m_faq">자주하는 질문​</router-link>
                </li>
                <li>
                  <router-link to="/support/m_giftCard">상품권 구매</router-link>
                </li>
                <li>
                  <router-link to="/support/m_findStore">매장찾기​</router-link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
        <h1>
          <router-link to="/"
            ><img class="header-logo" src="https://img.davich.com/mobile/image/main/logo-all.png" alt="davich logo"
          /></router-link>
        </h1>

        <div class="header-utils">
          <router-link to="/support/m_findStore" class="header-search">
            <img
              class="header-cart-icon"
              src="https://img.davich.com/mobile/image/main/icon-find-store.png"
              alt="store icon"
            />
          </router-link>

          <a class="el-btn-icon header-market" href="https://www.davichmarket.com" target="_blank">
            <img
              class="header-cart-icon"
              src="https://img.davich.com/mobile/image/main/icon-market.png"
              alt="cart icon"
            />
          </a>
        </div>
      </div>
      <hr />
    </header>
  </div>
</template>
<script>
/*eslint-disable */

export default {
  data() {
    return {
      opacityList: [
        //opacity1로 헤더설정할 페이지의 route name을 입력

        //about
        // "m_greetings",
        // "m_information",
        "m_contribution",
        "m_contributionPostDetail",

        //partnership
        // "m_inquiryFranchise",
        // "m_inquiryPartnership",

        //education
        "m_recruitDetail",

        //whatnew
        // "m_cf",
        "m_davichNewsDetail",

        //support
        // "m_giftCard",
        "m_findStore",

        //privacy
        "m_privacyPolicy",
        "m_emailProtect",

        // notFoundPage
        "m_notFoundPage",
      ],
    };
  },
  watch: {
    //라우트 변경 감지하여 헤더 opacity 결정
    //라우트 변경 감지하여 헤더 하위 메뉴 안보이게
    $route(to, from) {
      const header = document.querySelector("header");
      const opacityList = this.opacityList;

      if (opacityList.includes(to.name)) {
        console.log(to.name);
        header.classList.remove("active");
        header.classList.add("opacity1");
      } else {
        console.log(to.name);
        header.classList.remove("active");
        header.classList.remove("opacity1");
      }
    },
  },
  mounted() {
    var header = document.querySelector("header");

    //마운트할 때 헤더 opacity 결정
    const opacityList = this.opacityList;
    if (opacityList.includes(this.$route.name)) {
      header.classList.add("opacity1");
    } else {
      header.classList.remove("opacity1");
    }

    //toggle button
    var toggleBtn = document.querySelector(".toggle-btn");
    toggleBtn.addEventListener("click", function () {
      if (header.classList.contains("active")) {
        header.classList.remove("active");
      } else {
        header.classList.add("active");
      }
    });

    //scroll
    window.addEventListener("scroll", function () {
      if (window.scrollY !== 0) {
        header.classList.add("scroll");
      } else {
        header.classList.remove("scroll");
      }
    });

    //sub menu
    var mainMenu = document.querySelectorAll(".main-menu > button");
    var result = false;
    for (var i = 0; i < mainMenu.length; i++) {
      mainMenu[i].addEventListener("click", function (e) {
        e.preventDefault;
        // 모든것 없앰
        if (e.target.classList.contains("active")) {
          e.target.classList.remove("active");
        } else {
          for (var z = 0; z < mainMenu.length; z++) {
            if (mainMenu[z].classList.contains("active")) {
              mainMenu[z].classList.remove("active");
            }
          }
          e.target.classList.add("active");
        }
      });
    }

    // 같은 버튼 클릭 시에도 nav 없애기(active 사라지게)
    let menuLink = document.querySelectorAll(".sub-menu li a");
    for (var i = 0; i < menuLink.length; i++) {
      menuLink[i].addEventListener("click", function () {
        header.classList.remove("active");
      });
    }
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.ly-header-inner {
  width: 85vw;
}

header {
  width: 100%;
  height: 64.5px;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 9999999999;
  @include box-center-flex;
  &.active,
  &.scroll,
  &.opacity1 {
    background-color: $color-white;
    border-bottom: 1px solid #ccc;
    .header-inner {
      .toggle-btn {
        span {
          background-color: $color-black;
        }
      }
      h1 {
        a {
          img {
            transform: translateX(0);
          }
        }
      }

      .header-utils {
        a {
          img {
            transform: translateX(-50%);
          }
          &:nth-of-type(1) {
            margin-right: 17px;
          }
        }
      }
    }
    hr {
      display: none;
    }
  }
  &.scroll {
    position: fixed;
  }
  &.active {
    .toggle-btn {
      span {
        &:nth-child(1) {
          top: calc(50% - 1px);
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          transform: rotate(-45deg);
        }
        &:last-child {
          display: none;
        }
      }
    }
    .header-inner {
      nav {
        left: 0;
      }
    }
  }
  .header-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .toggle-btn {
      display: block;
      width: 27px;
      height: 20px;
      position: relative;
      span {
        position: absolute;
        width: 100%;
        height: 2px;
        top: 0;
        left: 0;
        background-color: $color-white;
        &:nth-child(2) {
          top: calc(50% - 1px);
          left: 0;
        }
        &:last-child {
          top: calc(100% - 2px);
          left: 0;
        }
      }
    }
    nav {
      .gnb {
        .main-menu {
          > button,
          .sub-menu > li > a,
          .sub-sub-menu > li > a {
            display: block;
            color: $color-black;
            border-bottom: 1px solid #dedede;
            line-height: 46px;
          }
        }
      }
    }
    nav {
      position: fixed;
      top: 64.5px;
      left: -100%;
      width: 100vw;
      height: calc(100vh - 64.5px);
      overflow-y: scroll;
      background-color: white;
      transition: 0.3s;
      z-index: 9999999999;
      padding-top: 39px;
      margin: 0 auto;
      .gnb {
        width: 90vw;
        margin: 0 auto;
        .main-menu {
          > button {
            text-align: left;
            font-size: 18px;
            font-weight: bold;
            font-family: $en-font;
            display: block;
            width: 100%;
            img {
              width: 15px;
              height: 15px;
              float: right;
              margin-top: 18px;
              transform: rotate(0deg);
            }

            & + .sub-menu {
              display: none;
              a {
                font-size: 16px;
                font-weight: 500;
                padding-left: 12px;
              }
            }
          }
          > button.active {
            img {
              transform: rotate(180deg);
            }
            & + .sub-menu {
              display: block;
              > li > a {
                & + .sub-sub-menu {
                  display: none;

                  background-color: #f9f9f9;
                  a {
                    padding-left: 27px;
                    font-size: 16px;
                    color: #777;
                  }
                }
              }
              > li > a.active {
                & + .sub-sub-menu {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
    h1 {
      width: 123.4px;
      height: 29.7px;
      overflow: hidden;
      @include box-center;
      left: 48%;
      a {
        display: inline-block;
        width: 100%;
        height: 100%;
        img {
          height: 29.7px;
          transform: translateX(-50%);
        }
      }
    }
    .header-utils {
      display: flex;
      align-items: center;
      a {
        width: 25px;
        height: 25px;
        overflow: hidden;
        img {
          height: 100%;
        }
        &:nth-of-type(1) {
          margin-right: 17px;
        }
      }
    }
  }
  hr {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: $color-white;
    z-index: 99999;
  }
}
</style>
