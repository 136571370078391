export const mutations = {
  SET_FAQ: function (state, resData) {
    let result = [];

    for (let item of resData) {
      let obj = {};
      obj.id = item.id.toString();
      obj.title = item.question;
      obj.content = item.answer;

      switch (item.category_id) {
        case 1:
          obj.class = "개인정보";
          break;
        case 2:
          obj.class = "멤버십";
          break;
        case 3:
          obj.class = "상품";
          break;
        case 4:
          obj.class = "매장";
          break;
        case 5:
          obj.class = "일반";
          break;
      }

      obj.imgSrc = [];

      item.attachments.map((attc) => {
        obj.imgSrc.push(attc.download_url);
      });

      result.push(obj);
    }

    state.faqData = result;
  },

  SEARCH_FAQ: function (state, payload) {
    let boardData = payload[0];
    let searchText = payload[1];

    let result;
    result = boardData.filter((item) => {
      let filtered = true;
      filtered =
        item.title.includes(searchText) || item.content.includes(searchText);
      return filtered;
    });

    state.searchedData = result;
  },

  SET_STORE: function (state, resData) {
    let result = [];

    for (let store of resData) {
      let obj = {};

      obj.id = store.id;
      obj.name = store.str_name;
      obj.lat = store.latitude;
      obj.lng = store.longitude;
      obj.addr = `${store.addr1} ${store.addr2}`;
      obj.tel = store.tel_no;
      obj.tag = [];
      if (store.wifi_yn == "Y") {
        obj.tag.push("wifi");
      }
      if (store.parking_yn == "Y") {
        obj.tag.push("parking");
      }
      if (store.hearing_aid == "Y") {
        obj.tag.push("earaid");
      }
      obj.blogUrl = store.blog_add;
      obj.kakaoUrl = store.kakao_add;
      obj.locationInfo = store.location_information;

      //매장 이미지
      obj.imgSrc = [];
      if (
        store.store_image_masters != "" ||
        store.store_image_masters.length != 0
      ) {
        for (let imgAttc of store.store_image_masters) {
          obj.imgSrc.push(imgAttc.store_photo_url);
        }
      }

      //제휴할인
      if (
        store.store_affiliate_masters != "" ||
        store.store_affiliate_masters.length != 0
      ) {
        obj.discount = store.store_affiliate_masters;
      }

      if (store.end_ind == 1) {
        result.push(obj);
      }
    }

    state.storeData = result;
  },
};
