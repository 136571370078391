import axios from "@/api/axios-auth";

//메인페이지 영상데이터  get요청
function fetchMainVideo() {
  return axios.get(`/others/mainvisualcontents`);
}

//popup 데이터 get 요청
function fetchPopup() {
  return axios.get(`/others/popup`);
}

//개인정보 처리방침
function fetchPrivacy() {
  return axios.get(`/others/privacypolicies`);
}

//인스타그램 token요청
function fetchInstaToken() {
  return axios.get(`/instagramtoken/1`);
}

//인스타그램 데이터 get요청
function fetchInstaItem(token) {
  return axios.get(
    `https://graph.instagram.com/17841406373382597/media?fields=id,media_type,media_url,permalink,thumbnail_url,username,caption&access_token=${token}`
  );
}

//메인페이지 숫자로보는 다비치 get요청
function fetchCount() {
  return axios.get(`/about`);
}

//about페이지 회사연혁 get요청
function fetchHistory() {
  return axios.get(`/about/history`);
}

//about페이지 눈건강시력시킴이 게시판 get 요청
function fetchContributionBoard() {
  return axios.get(`/about/eyesight`);
}

function fetchDavichNumbers() {
  return axios.get(`/others/davichnumbers`);
}


//products 페이지 안경테 데이터 get 요청
function fetchGlassesFrame() {
  return axios.get(`/products/brands/glassesframe`);
}

//products 페이지 안경렌즈 get 요청
function fetchGlassesLens() {
  return axios.get(`/products/brands/glasseslens`);
}

//products 페이지 콘텍트렌즈 get 요청
function fetchContactLens() {
  return axios.get(`/products/brands/contactlens`);
}

//products 페이지 보청기 get 요청
function fetchHearingAid() {
  return axios.get(`/products/brands/hearingaid`);
}

//partnership 가맹점 현황
function fetchOpened() {
  return axios.get(`/partnership/store/open`);
}

//partnership 가맹점 개설안내 - 오픈가능지역 get 요청
function fetchOpenArea() {
  return axios.get(`/partnership/store/area`);
}

//partnetship 가맹점 개설담당자 연락처
function fetchManager() {
  return axios.get(`/managers?taskpart=open`);
}

//partnetship 제휴문의 제휴유형
function fetchPartnershipType() {
  return axios.get(`/partnership/partnership/type`);
}

//education 사관공채공고 게시판데이터 get
function fetchRecruit() {
  return axios.get(`/education/officeropenrecruitment/announce`);
}

//education 다비치사관공채 성공사례
function fetchSuccess() {
  return axios.get(`/education/successstory`);
}

//education 다비치사관공채 사진 및 영상 - 사진 및 졸업동영상 get
function fetchPicAndVideo() {
  return axios.get(`/education/pictureandvideo`);
}

//education 고객가치경영연구원 - 조직 소개 get
function fetchDepartment() {
  return axios.get(`/education/departments`);
}
function fetchEmployee() {
  return axios.get(`/education/employee`);
}

//whatsnew 광고/홍보영상 Tv cf
function fetchCf() {
  return axios.get(`/whatsnew/adcontents`);
}

//whatsnew 다비치소식 공지 get
function fetchNotice() {
  return axios.get(`/whatsnew/newsandnotice?type=1`);
}

//whatsnew 다비치소식 뉴스 get
function fetchNews() {
  return axios.get(`/whatsnew/newsandnotice?type=2`);
}

//whatsnew 이벤트 get
function fetchEvent() {
  return axios.get(`/whatsnew/event`);
}

//support 자주하는 질문 get
function fetchFaq() {
  return axios.get(`/support/faq`);
}

//support 매장찾기데이터 get
function fetchStoreData() {
  return axios.get(`/support/findstore`);
}

export {
  fetchPopup,
  fetchPrivacy,
  fetchMainVideo,
  fetchInstaToken,
  fetchInstaItem,
  fetchCount,
  fetchHistory,
  fetchContributionBoard,
  fetchGlassesFrame,
  fetchGlassesLens,
  fetchContactLens,
  fetchHearingAid,
  fetchOpenArea,
  fetchRecruit,
  fetchSuccess,
  fetchPicAndVideo,
  fetchDepartment,
  fetchEmployee,
  fetchOpened,
  fetchFaq,
  fetchStoreData,
  fetchNotice,
  fetchNews,
  fetchCf,
  fetchManager,
  fetchPartnershipType,
  fetchEvent,
  fetchDavichNumbers,
};
