<template>
  <div class="main-wrapper ly-w1400">
    <sub-title-1 :title="title" :content="content" :content2="content2"> </sub-title-1>
    <section>
      <tab-menu :tabTitle="tabTitle"></tab-menu>
      <div class="tab-items">
        <div class="tab-item tab_a display">
          <!--사관∙공채란?-->
          <div class="ly-cont">
            <div class="videobox scroll-active">
              <video
                class="autoplay-video"
                playsinline
                width="1400px"
                height="788px"
                src="https://img.davich.com/video/education_officer_video01.mp4"
                type="video/mp4"
                muted
                autoplay
              ></video>
            </div>
          </div>
          <div class="intro">
            <div class="intro_box">
              <div class="intro_bg">
                <div class="intro_img">
                  <img src="https://img.davich.com/image/sub/education/education_officer_01.jpg" alt="intro_img" />
                </div>
              </div>
              <h2 class="title01 scroll-fade-in-f">사관∙공채란?</h2>
              <p class="scroll-fade-in-f">
                <span>매장의 중간관리자</span>로서 그와 맞는 <span>다비치다움</span>을 교육받아, 전략정책을<br />
                수행하고 실무능력을 향상 시켜 고객 행복 실천을 위해 매장에 변화와 혁신을 전도하는 인재
              </p>
              <div class="txt scroll-fade-in-f">
                <h3>17년간 우수 인재 발굴 지속하여<br />현재까지 양성한 졸업생</h3>
                <div class="line01">
                  <span>사관 졸업생</span>
                  <h4>1,012</h4>
                </div>
                <div class="line02">
                  <span>공채 졸업생</span>
                  <h4>612</h4>
                </div>
              </div>
            </div>
          </div>
          <!-- 사관∙공채란? -->
          <div class="ability">
            <div class="ability-inner ly-cont">
              <h2>사관∙공채 선발 기준</h2>
              <div class="txt">
                <div class="txt_left scroll-fade-in-f">
                  <div class="line01 scroll-fade-in-f">
                    <p>모집</p>
                    <h5>매년 12월 모집 접수</h5>
                    <span>
                      - 전국 안경광학과 졸업생
                    </span>
                  </div>
                  <article>
                    <h6>준비사항</h6>
                    <span>
                      - 지원서 (필수)<br/>
                      - 이력서 (선택)
                    </span>
                  </article>
                  <div class="line_x"></div>
                </div>
                <div class="txt_right scroll-fade-in-f">
                  <div class="line02 scroll-fade-in-f">
                    <p>면접</p>
                    <h5>1월 초 면접진행</h5>
                    <span>
                      - 지원자 중 선발 과정<br />
                      - 대표이사를 포함한 약 6명의 심사단
                    </span>
                  </div>
                  <article class="scroll-fade-in-f">
                    <h6>준비사항</h6>
                    <span class="last">
                      - 구두 면접<br />
                      - 한국식 실무 예비검사 실기평가
                    </span>
                  </article>
                </div>
              </div>
              <div class="ab_slider">
                <ul class="slider-inner">
                  <li class="slide slide-0">
                    <p>
                      다비치에서는<br />
                      이러한 역량을 가진<br />
                      인재를 발굴합니다.
                    </p>
                  </li>
                  <li class="slide slide-1">
                    <img src="https://img.davich.com/image/sub/education/icon_ability01.png" alt="icon_ability01" />
                    <p>셀프리더쉽</p>
                  </li>
                  <li class="slide slide-2">
                    <img src="https://img.davich.com/image/sub/education/icon_ability02.png" alt="icon_ability02" />
                    <p>변화관리 능력</p>
                  </li>
                  <li class="slide slide-3">
                    <img src="https://img.davich.com/image/sub/education/icon_ability03.png" alt="icon_ability03" />
                    <p>업무 추진력</p>
                  </li>
                  <li class="slide slide-4">
                    <img src="https://img.davich.com/image/sub/education/icon_ability04.png" alt="icon_ability04" />
                    <p>갈등관리 능력</p>
                  </li>
                  <li class="slide slide-5">
                    <img src="https://img.davich.com/image/sub/education/icon_ability05.png" alt="icon_ability05" />
                    <p>고객중심 마인드</p>
                  </li>
                  <li class="slide slide-6">
                    <img src="https://img.davich.com/image/sub/education/icon_ability06.png" alt="icon_ability06" />
                    <p>문제해결 능력</p>
                  </li>
                  <li class="slide slide-7">
                    <img src="https://img.davich.com/image/sub/education/icon_ability07.png" alt="icon_ability07" />
                    <p>자기관리 능력</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- 사관∙공채 선발 기준 -->
          <div class="roadmap ly-cont">
            <h2 class="scroll-fade-in-f">인재 양성 로드맵(전문성)</h2>
            <p class="scroll-fade-in-f">
              다비치의 인재 양성 프로그램은 단 기간에 여러분을 최고의 전문가로 만들어 드립니다.
            </p>
            <img
              src="https://img.davich.com/image/sub/education/roadmap_01.png"
              alt="roadmap01"
              class="scroll-fade-in-f scroll-delay-1"
            />
            <img
              src="https://img.davich.com/image/sub/education/roadmap_02.png"
              alt="roadmap02"
              class="scroll-fade-in-f scroll-delay-2"
            />
            <a href="#" @click="goToCurriculum()" class="el-btn scroll-fade-in-f scroll-delay-3"
              >특전 및 교육과정 바로가기</a
            >
          </div>
          <!-- 인재 양성 로드맵(전문성) -->
        </div>
        <div class="tab-item tab_b">
          <!--특전 및 교육과정-->
          <section class="perks">
            <img
              src="https://img.davich.com/image/sub/education/process_bg.png"
              alt="process_bg"
              class="scroll-fade-in-f scroll-delay-1"
            />
            <div class="txt">
              <h2 class="scroll-fade-in-f scroll-delay-2">사관∙공채 특전</h2>
              <ul>
                <li class="line1 scroll-fade-in-f scroll-delay-2">
                  <p>사관<br /><span>최대 4,000만원</span></p>
                </li>
                <li class="line2 scroll-fade-in-f scroll-delay-2">
                  <p>교육 수료 후<br />전국 다비치안경체인<br />매장 근무 시 혜택</p>
                </li>
                <li class="line3 scroll-fade-in-f scroll-delay-2">
                  <p>공채<br /><span>2,900만원</span></p>
                </li>
              </ul>
              <p class="scroll-fade-in-f scroll-delay-2">+ 1년 근무 시 퇴직금 별도</p>
              <span class="box scroll-fade-in-f scroll-delay-3">
                - 2~3년제 졸업 시 학점은행제 병행 가능(3+1 학사과정)<br />
                - 학사과정 및 4년제 졸업 시 석사과정 병행 가능<br />
                - 4대 보험 가입(근로기준법 적용)<br />
                - 대학 안경광학과 산업체 실무 교육 강의 기회 및 각종 학술 세미나 우선 참여권 부여<br />
                - 교육 기간 중 소정의 훈련수당 지급
              </span>
            </div>
          </section>
          <!-- 사관∙공채 특전 -->
          <section class="table ly-cont">
            <h2 class="scroll-fade-in-f scroll-delay-1">하루 일과표</h2>
            <img
              class="scroll-fade-in-f scroll-delay-2"
              src="@/assets/image/sub/education/process_table.png"
              alt="process_table"
            />
          </section>
          <!-- 하루 일과표 -->
          <section class="subject">
            <article>
              <div class="subject_a">
                <h2 class="scroll-fade-in-f scroll-delay-1">교육과목</h2>
                <ul class="abox">
                  <li class="scroll-fade-in-f scroll-delay-2">A. 전략정책</li>
                  <li class="scroll-fade-in-f scroll-delay-2">B. 전문성</li>
                  <li class="scroll-fade-in-f scroll-delay-2">C. 상품지식</li>
                  <li class="scroll-fade-in-f scroll-delay-2">D. 양안시</li>
                </ul>
                <ul class="atxt">
                  <li class="scroll-fade-in-f scroll-delay-3">
                    - 다비치다움<br />
                    - 30가지 전략정책<br />
                    - 다비치 차별화 시스템<br />
                    - 고객감동 CS교육
                  </li>
                  <li class="scroll-fade-in-f scroll-delay-3">
                    - 한국식 실무 예비검사<br />
                    - 다비치 표준화 검사<br />
                    - 기능성/사무용 검사<br />
                    - 누진/난시 피팅센터를 활용한 <br />&nbsp;&nbsp;고객응대교육
                  </li>
                  <li class="scroll-fade-in-f scroll-delay-3">
                    - 안경테 상품지식(국내, 수입)<br />
                    - 안경렌즈별 상품지식<br />
                    &nbsp;&nbsp;블루컷, 사무용, 누진, 기능성 콘택트 <br />
                    &nbsp;&nbsp;상품지식(난시안, 멀티포컬 등)
                  </li>
                </ul>
              </div>
              <!-- 교육과목 -->
              <div class="subject_b">
                <h2 class="scroll-fade-in-f scroll-delay-1">평가내용</h2>
                <ul class="bbox">
                  <li>
                    <img src="https://img.davich.com/image/sub/education/process_icon_01.png" alt="icon_01.png" />
                    <p>전략정책 평가</p>
                  </li>
                  <li>
                    <img src="https://img.davich.com/image/sub/education/process_icon_02.png" alt="icon_02.png" />
                    <p>고객응대 시뮬레이션</p>
                  </li>
                  <li>
                    <img src="https://img.davich.com/image/sub/education/process_icon_03.png" alt="icon_03.png" />
                    <p>모의상권 분석</p>
                  </li>
                  <li>
                    <img src="https://img.davich.com/image/sub/education/process_icon_04.png" alt="icon_04.png" />
                    <p>재고교육</p>
                  </li>
                </ul>
                <ul class="btxt">
                  <li>현장투입전 고객응대가 가능토록<br />시뮬레이션 평가진행</li>
                  <li>나의 매장을 오픈한다면?<br />직접 상권을 분석하여 매장을 오픈해보자</li>
                  <li>중간관리자로써 매장의<br />전반적인 재고관리 능력 평가</li>
                </ul>
              </div>
            </article>
          </section>
          <!-- 평가내용 -->
          <section class="gallery">
            <h2 class="scroll-fade-in-f scroll-delay-1">교육장 소개</h2>
            <div class="tab_a">
              <div class="a_detail scroll-fade-in-f scroll-delay-2">
                <p class="bigap">본관</p>
                <img class="biga" src="https://img.davich.com/image/sub/education/process_img_01.png" alt="본관" />
              </div>
              <div class="a_list scroll-fade-in-f scroll-delay-3">
                <li class="smallboxa active">
                  <img
                    class="smalla"
                    src="https://img.davich.com/image/sub/education/process_img_01.png"
                    alt="본관"
                    name="0"
                  />
                </li>
                <li class="smallboxa">
                  <img
                    class="smalla"
                    src="https://img.davich.com/image/sub/education/process_img_02.png"
                    alt="대강의실(연회장, 워크숍 진행)"
                    name="1"
                  />
                </li>
                <li class="smallboxa">
                  <img
                    class="smalla"
                    src="https://img.davich.com/image/sub/education/process_img_03.png"
                    alt="1강의실(이론 및 컴퓨터 수업)"
                    name="2"
                  />
                </li>
                <li class="smallboxa">
                  <img
                    class="smalla"
                    src="https://img.davich.com/image/sub/education/process_img_04.png"
                    alt="소회의실(미팅룸, 귀빈실)"
                    name="3"
                  />
                </li>
                <li class="smallboxa">
                  <img
                    class="smalla"
                    src="https://img.davich.com/image/sub/education/process_img_05.png"
                    alt="지하 1강의실(실습 및 컴퓨터 수업)"
                    name="4"
                  />
                </li>
                <li class="smallboxa">
                  <img
                    class="smalla"
                    src="https://img.davich.com/image/sub/education/process_img_06.png"
                    alt="지하 2강의실(실습 및 컴퓨터 수업)"
                    name="5"
                  />
                </li>
              </div>
            </div>
            <div class="tab_b">
              <div class="b_detail scroll-fade-in-f scroll-delay-2">
                <p class="bigbp">신관</p>
                <img class="bigb" src="https://img.davich.com/image/sub/education/process_img_07.png" alt="신관" />
              </div>
              <div class="b_list scroll-fade-in-f scroll-delay-3">
                <li class="smallboxb active">
                  <img
                    class="smallb"
                    src="https://img.davich.com/image/sub/education/process_img_07.png"
                    alt="신관"
                    name="0"
                  />
                </li>
                <li class="smallboxb">
                  <img
                    class="smallb"
                    src="https://img.davich.com/image/sub/education/process_img_08.png"
                    alt="1층 양안시 센터(양안시 이론 및 실습)"
                    name="1"
                  />
                </li>
                <li class="smallboxb">
                  <img
                    class="smallb"
                    src="https://img.davich.com/image/sub/education/process_img_09.png"
                    alt="1층 미디어실(다용도 목적)"
                    name="2"
                  />
                </li>
                <li class="smallboxb">
                  <img
                    class="smallb"
                    src="https://img.davich.com/image/sub/education/process_img_10.png"
                    alt="2층 굴절검사(실습전용공간)"
                    name="3"
                  />
                </li>
                <li class="smallboxb">
                  <img
                    class="smallb"
                    src="https://img.davich.com/image/sub/education/process_img_11.png"
                    alt="2층 콘택트렌즈실(컴퓨터 수업 가능)"
                    name="4"
                  />
                </li>
                <li class="smallboxb">
                  <img
                    class="smallb"
                    src="https://img.davich.com/image/sub/education/process_img_12.png"
                    alt="2층 안경렌즈실(컴퓨터 수업가능)"
                    name="5"
                  />
                </li>
                <li class="smallboxb">
                  <img
                    class="smallb"
                    src="https://img.davich.com/image/sub/education/process_img_13.png"
                    alt="3층 시뮬레이션장(매장과 동일한 구성)"
                    name="6"
                  />
                </li>
              </div>
            </div>
          </section>
        </div>
        <!-- 교육장 소개 -->
        <div class="tab-item tab_c ly-cont">
          <!--사진 및 영상-->
          <article class="success-video">
            <h4 class="scroll-fade-in-f">성공사례</h4>
            <div class="success-video-items">
              <div v-for="item in getSuccess" :key="item.id" class="success-video-item">
                <div v-if="item.id % 2 == 0" class="right">
                  <div class="img-wrap scroll-fade-in-f">
                    <img :src="item.imgurl" alt="유투브썸네일" @click="showModal(item.id)" />
                    <img src="https://img.davich.com/image/sub/btn-play-b.png" alt="play-button" class="play-btn" />
                  </div>
                  <div class="desc scroll-fade-in-f scroll-delay-1">
                    <h5>{{ item.title }}</h5>
                    <em>{{ item.subtitle }}</em>
                    <p v-html="item.story"></p>
                  </div>
                </div>
                <div v-else>
                  <div class="img-wrap scroll-fade-in-f">
                    <img :src="item.imgurl" alt="유투브썸네일" @click="showModal(item.id)" />
                    <img src="https://img.davich.com/image/sub/btn-play-b.png" alt="play-button" class="play-btn" />
                  </div>
                  <div class="desc scroll-fade-in-f scroll-delay-1">
                    <h5>{{ item.title }}</h5>
                    <em>{{ item.subtitle }}</em>
                    <p v-html="item.story"></p>
                  </div>
                </div>
              </div>
            </div>
          </article>
          <!-- 성공사례 -->
          <article class="graduate-video">
            <h4 class="scroll-fade-in-f">사진 및 졸업동영상</h4>
            <div class="graduate-video-items scroll-fade-in-f">
              <div v-for="item in graduateItemsForList" :key="item.id" class="graduate-video-item">
                <div class="img-wrap">
                  <img :src="item.imgurl" alt="유투브썸네일" @click="showModal2(item.id)" />
                  <div class="hover-icon">
                    <img src="https://img.davich.com/image/sub/btn-play-b.png" alt="play-button" class="play-btn" />
                  </div>
                </div>
                <h5 @click="showModal2(item.id)">{{ item.title }}</h5>
              </div>
            </div>
            <b-pagination id="pagination" v-model="currentPage" :per-page="perPage"></b-pagination>
            <button class="el-btn el-btn-c scroll-fade-in-f" id="btnMoreData" @click="getMoreData">더보기</button>
          </article>
          <!-- 사진 및 졸업동영상 -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SubTitle1 from "../../components/SubTitle1.vue";
import PlayYoutube from "../../components/EDUCATION/PlayYoutube.vue";
import TabMenu from "../../components/TabMenu.vue";

import { mapGetters } from "vuex";

export default {
  beforeCreate() {
    this.$store.dispatch("education/FETCH_PIC_VIDEO");
    this.$store.dispatch("education/FETCH_SUCCESS");
  },

  components: {
    SubTitle1,
    TabMenu,
  },
  data() {
    return {
      // subtitle
      title: "다비치 사관∙공채",
      content: "안경업계 최초로 자체 훈련기관으로",
      content2: "서울지방노동청의 승인을 받은 전문 교육기관에서 여러분의 꿈을 이루세요.",
      //tab menu
      tabTitle: ["사관∙공채란?", "특전 및 교육과정", "사진 및 영상"],
      playId: 0,

      lengthGraduateItemsForList: 0,

      //pagination
      perPage: 6,
      currentPage: 1,
    };
  },

  computed: {
    ...mapGetters("education", ["getPicVideo", "getSuccess"]),

    graduateItemsForList() {
      const self = this;
      self.lengthGraduateItemsForList = self.getPicVideo.length;
      return this.getPicVideo.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
    },

    rows() {
      const self = this;
      return self.lengthGraduateItemsForList;
    },
  },

  watch: {
    //게시물 6개 안될 때 더보기 숨김
    graduateItemsForList() {
      const btn = document.getElementById("btnMoreData");
      if (this.perPage >= this.rows) {
        btn.style.display = "none";
      } else {
        btn.style.display = "block";
      }
    },
  },

  methods: {
    showModal(playId) {
      this.playId = playId;

      this.$modal.show(
        PlayYoutube,
        {
          playId: this.playId,
          youtubeUrl: this.getSuccess,
        },
        {
          width: 960,
          height: 540,
        },
        {
          "before-open": this.beforeOpen,
        }
      );
    },
    showModal2(playId) {
      this.playId = playId;

      this.$modal.show(
        PlayYoutube,
        {
          playId: this.playId,
          youtubeUrl: this.getPicVideo,
        },
        {
          width: 960,
          height: 540,
        }
      );
    },

    //더보기 버튼 클릭 시 데이터 추가로 표사.
    getMoreData() {
      const btn = document.getElementById("btnMoreData");
      this.perPage += 3;

      //마지막 데이터에서 더보기버튼 숨김
      if (this.perPage >= this.getPicVideo.length) {
        btn.style.display = "none";
      }
    },

    // 특전 및 교육과정 바로가기 링크 이동
    goToCurriculum() {
      const tabBtn = document.querySelectorAll(".tab-btn");
      const tabCont = document.querySelectorAll(".tab-item");
      tabBtn[0].classList.remove("active");
      tabBtn[1].classList.add("active");
      tabCont[0].classList.remove("display");
      tabCont[1].classList.add("display");
    },
  },

  // 자동 슬라이드 배너
  mounted() {
    // 교육장 소개 탭 갤러리 AAAAAAAAAAAAAAA
    var bigPicA = document.querySelector(".biga"); // 디테일 이미지 경로
    var bigPicAp = document.querySelector(".bigap"); // p 태그 경로
    var smallPicsA = document.querySelectorAll(".smalla"); // 리스트 이미지들
    var smallBoxA = document.querySelectorAll(".smallboxa"); // 리스트 박스들

    for (var p = 0; p < smallPicsA.length; p++) {
      smallPicsA[p].addEventListener("click", changepic);
    }

    function changepic() {
      // 교체 함수
      var smallPicAttribute = this.getAttribute("src"); // 선택 이미지 주소 저장
      var smallPicAtxt = this.getAttribute("alt"); // 선택 제목 저장
      var smallIndexA = this.getAttribute("name"); // 인덱스 구하기
      let smallClassA = smallBoxA[smallIndexA]; // 해당 박스 클래스 선택

      smallBoxA[0].classList.remove("active"); // 전체 액티브 클래스 삭제
      smallBoxA[1].classList.remove("active"); // 전체 액티브 클래스 삭제
      smallBoxA[2].classList.remove("active"); // 전체 액티브 클래스 삭제
      smallBoxA[3].classList.remove("active"); // 전체 액티브 클래스 삭제
      smallBoxA[4].classList.remove("active"); // 전체 액티브 클래스 삭제
      smallBoxA[5].classList.remove("active"); // 전체 액티브 클래스 삭제

      smallClassA.classList.add("active"); // 액티브 클래스 추가

      bigPicA.setAttribute("src", smallPicAttribute); // 이미지 교체
      bigPicAp.innerHTML = smallPicAtxt; // 텍스트 교체
    }

    // 교육장 소개 탭 갤러리 BBBBBBBB
    var bigPicB = document.querySelector(".bigb"); // 디테일 이미지 경로
    var bigPicBp = document.querySelector(".bigbp"); // p 태그 경로
    var smallPicsB = document.querySelectorAll(".smallb"); // 리스트 이미지들
    var smallBoxB = document.querySelectorAll(".smallboxb"); // 리스트 박스들

    for (var b = 0; b < smallPicsB.length; b++) {
      smallPicsB[b].addEventListener("click", changepicB);
    }

    function changepicB() {
      // 교체 함수
      var smallPicBttribute = this.getAttribute("src"); // 선택 이미지 주소 저장
      var smallPicBtxt = this.getAttribute("alt"); // 선택 제목 저장
      var smallIndexB = this.getAttribute("name"); // 인덱스 구하기
      let smallClassB = smallBoxB[smallIndexB]; // 해당 박스 클래스 선택

      smallBoxB[0].classList.remove("active"); // 전체 액티브 클래스 삭제
      smallBoxB[1].classList.remove("active"); // 전체 액티브 클래스 삭제
      smallBoxB[2].classList.remove("active"); // 전체 액티브 클래스 삭제
      smallBoxB[3].classList.remove("active"); // 전체 액티브 클래스 삭제
      smallBoxB[4].classList.remove("active"); // 전체 액티브 클래스 삭제
      smallBoxB[5].classList.remove("active"); // 전체 액티브 클래스 삭제
      smallBoxB[6].classList.remove("active"); // 전체 액티브 클래스 삭제

      smallClassB.classList.add("active"); // 액티브 클래스 추가

      bigPicB.setAttribute("src", smallPicBttribute); // 이미지 교체
      bigPicBp.innerHTML = smallPicBtxt; // 텍스트 교체
    }

    // video pause
    function videoPause() {
      let vid = document.querySelector(".autoplay-video");
      let tabMenu = document.querySelectorAll(".tab-btn");
      for (var i = 0; i < tabMenu.length; i++) {
        tabMenu[i].addEventListener("click", function () {
          if (tabMenu[0].classList.contains("active")) {
            vid.play();
          } else {
            vid.pause();
          }
        });
      }
    }
    videoPause();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.sub-title {
  background-image: url("https://img.davich.com/image/sub/subtitle/sub-edu-bg-02.png");
}

.waiting {
  font-size: 40px;
  color: $color-blue;
  font-weight: bold;
  text-align: center;
  line-height: 50px;
  padding: 100px 0;
}

h2 {
  @include font-h-35;
}
p {
  @include font-main;
}

// 사관∙공채란? tab_a
.tab_a {
  .videobox {
    margin-top: 70px;
    width: 1400px;
    height: 788px;
    overflow: hidden;
  }
  // 사관 공채란???
  .intro {
    margin-top: 200px;
    margin-bottom: 200px;
    max-width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    .intro_box {
      width: 1400px;
      margin: 0 auto;
      position: relative;
      .intro_bg {
        right: 0;
        background-color: $color-blue;
        height: 554px;
        width: 1100px;
        margin-right: -500px;
        transform: skewX(30deg);
        position: absolute;
        .intro_img {
          position: absolute;
          z-index: 9999999;
          top: 50px;
          left: 70px;
          width: 800px;
          height: 640px;
          overflow: hidden;
          img {
            margin-left: -25%;
            width: 150%;
            height: 100%;
            object-fit: cover;
            transform: skew(-30deg);
          }
        }
      }
    }
    .title01 {
      padding-top: 130px;
      margin-bottom: 30px;
    }
    p {
      span {
        font-weight: bold;
      }
    }
    .txt {
      width: 460px;
      height: 340px;
      position: relative;
      text-align: center;
      h3 {
        @include font-h-20;
        font-weight: 500;
        margin-top: 90px;
      }
      h4 {
        font-family: $en-font;
        font-size: 60px;
        font-weight: bold;
        color: $color-blue;
      }
      span {
        display: block;
        margin-top: 70px;
        margin-bottom: 10px;
      }
      .line01 {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 250px;
        height: 250px;
        border: 1px solid $color-blue;
        border-radius: 250px;
      }
      .line02 {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 250px;
        height: 250px;
        border: 1px solid $color-blue;
        border-radius: 250px;
      }
    }
  }
  // 사관 공채 선발 기준
  .ability {
    background: #f0f8ff;
    padding-bottom: 80px;
    position: relative;
    h2 {
      text-align: center;
      padding-top: 80px;
      padding-bottom: 80px;
    }
    h5 {
      @include font-h-25;
      color: $color-blue;
    }
    .txt {
      width: 1100px;
      height: 600px;
      margin: 0 auto;
      position: relative;
      p {
        @include font-h-22;
        font-weight: 500;
        margin-top: 108px;
        margin-bottom: 10px;
      }
      span {
        @include font-main;
      }
      h6 {
        @include font-h-20;
      }
      .txt_left {
        position: absolute;
        left: 0;
        width: 500px;
        height: 600px;
        .line01 {
          position: absolute;
          top: 0;
          left: 100px;
          width: 300px;
          height: 300px;
          border: 1px dashed $color-blue;
          border-radius: 150px;
          text-align: center;
          background: #f0f8ff;
          z-index: 2;
          span {
            width: 210px;
            display: block;
            text-align: left;
            margin: 125px auto 0;
          }
        }
      }
      .txt_right {
        position: absolute;
        right: 0;
        width: 500px;
        height: 600px;
        .line02 {
          position: absolute;
          top: 0;
          left: 100px;
          width: 300px;
          height: 300px;
          border-radius: 150px;
          text-align: center;
          background: $color-blue;
          p,
          h5 {
            color: $color-white;
          }
          span {
            width: 280px;
            display: block;
            text-align: left;
            margin: 125px auto 0;
            color: $color-black;
          }
        }
      }
    }
    .line_x {
      width: 500px;
      height: 20px;
      border-top: 1px dashed $color-blue;
      position: absolute;
      left: 300px;
      top: 150px;
      z-index: 1;
    }
    article {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 160px;
      border-top: 1px solid $color-black;
      border-bottom: 1px solid #cccccc;
      h6 {
        margin: 20px 0 20px 20px;
      }
      span {
        float: left;
        width: 230px;
        display: block;
        margin-left: 20px;
      }
      .last {
        width: 300px;
      }
    }

    //슬라이드
    .ab_slider {
      margin-top: 120px;
      width: 100%;
      margin-bottom: -50px;
      ul.slider-inner {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        li.slide {
          width: 300px;
          height: 300px;
          margin-right: 50px;
          border-radius: 150px;
          background: $color-white;
          margin-bottom: 50px;
          &.slide-0 {
            background: $color-blue;
            color: $color-white;
            font-weight: $color-white;
            line-height: 40px;
            p {
              margin-top: 80px;
            }
          }
          &:nth-child(4n) {
            margin-right: 0;
          }
          img {
            display: block;
            margin: 90px auto 10px;
          }
          p {
            @include font-h-25;
            text-align: center;
          }
        }
      }
    }
  }
  // 인재 양성 로드맵(전문성)
  .roadmap {
    margin-top: 140px;
    margin-bottom: 200px;
    p {
      margin-top: 30px;
      margin-bottom: 80px;
    }
    img {
      margin-bottom: 80px;
    }
    // 버튼 넓게 css 변경
    .el-btn {
      margin-left: 450px;
      width: 500px;
      height: 60px;
      line-height: 60px;
      &:before {
        width: 550px;
        transform: skewX(-40deg) translateX(-580px);
      }
      &:hover {
        &:before {
          transform: skewX(-40deg) translateX(-25px);
        }
      }
    }
  }
}

// 특전 및 교육과정 ///////////
.tab_b {
  .perks {
    width: 92.8%;
    height: 1080px;
    margin: 70px auto 0;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .txt {
      top: 160px;
      right: 190px;
      position: absolute;
      width: 700px;
      height: 650px;
      color: $color-white;
      text-align: center;
      ul {
        margin-top: 56px;
        width: 100%;
        height: 254px;
        position: relative;
      }
      li {
        position: absolute;
        width: 254px;
        height: 254px;
        border: 1px solid $color-white;
        border-radius: 150px;
      }
      .line1 {
        left: 0;
        p {
          margin-top: 90px;
          @include font-h-22;
          font-weight: 300;
          span {
            @include font-h-35;
            font-size: 30px;
          }
        }
      }
      .line2 {
        left: 223px;
        p {
          margin-top: 80px;
        }
      }
      .line3 {
        right: 0;
        p {
          margin-top: 90px;
          @include font-h-22;
          font-weight: 300;
          span {
            @include font-h-35;
            font-size: 30px;
          }
        }
      }
      p {
        margin: 24px 0 30px 0;
      }
      .box {
        display: block;
        font-size: 16px;
        font-weight: 200;
        text-align: left;
        line-height: 35px;
        padding: 20px;
        border-top: 1px solid $color-white;
        border-bottom: 1px solid $color-white;
      }
    }
  }
  .table {
    height: 650px;
    h2 {
      padding: 85px 0 40px;
    }
  }
  // 하루일과표
  .subject {
    width: 100%;
    height: auto;
    background: #f0f8ff;
    article {
      width: 1400px;
      margin: 0 auto;
      h2 {
        padding: 100px 0 40px;
      }
      //교육과목
      .subject_a {
        width: 1400px;
        height: 450px;
        position: relative;
        .abox {
          li {
            @include font-h-22;
            color: $color-blue;
            width: 375px;
            height: 80px;
            line-height: 80px;
            position: absolute;
            text-align: center;
            border: 1px solid $color-blue;
            border-radius: 50px;
            top: 191px;
            &:nth-child(2) {
              left: 340px;
            }
            &:nth-child(3) {
              right: 340px;
            }
            &:nth-child(4) {
              right: 0;
            }
          }
        }
        .atxt {
          li {
            @include font-main;
            width: 375px;
            position: absolute;
            border-radius: 50px;
            top: 301px;
            padding-left: 30px;
            padding-right: 30px;
            &:nth-child(2) {
              left: 340px;
            }
            &:nth-child(3) {
              right: 340px;
            }
          }
        }
      }
      // 평가내용
      .subject_b {
        width: 1400px;
        height: 650px;
        position: relative;
        .bbox {
          li {
            width: 300px;
            height: 300px;
            background: $color-white;
            border-radius: 150px;
            position: absolute;
            text-align: center;
            top: 187px;
            &:nth-child(2) {
              left: 367px;
            }
            &:nth-child(3) {
              right: 367px;
            }
            &:nth-child(4) {
              right: 0;
            }
            img {
              margin-top: 50px;
            }
            p {
              @include font-h-22;
              color: $color-blue;
            }
          }
        }
        .btxt {
          li {
            @include font-main;
            width: 320px;
            position: absolute;
            top: 510px;
            text-align: center;
            &:nth-child(1) {
              left: 357px;
            }
            &:nth-child(2) {
              right: 357px;
            }
            &:nth-child(3) {
              right: -10px;
            }
          }
        }
      }
    }
  }
  // 교육장 소개
  .gallery {
    width: 100%;
    overflow: hidden;
    h2 {
      width: 1400px;
      margin: 120px auto 70px;
    }
    .tab_a {
      width: 1400px;
      height: 960px;
      margin: 0 auto;
      position: relative;
      .a_detail {
        position: absolute;
        width: 1440px;
        height: 960px;
        top: 0;
        right: -260px;
        z-index: -999;
        &:before {
          content: "";
          background-image: url("https://img.davich.com/image/sub/education/gradient.png");
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        p {
          right: 260px;
          bottom: 82px;
          position: absolute;
          @include font-h-25;
          font-weight: 500;
          color: $color-white;
          text-align: right;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .a_list {
        width: 960px;
        height: 120px;
        position: absolute;
        bottom: 40px;
        overflow: hidden;
        li {
          display: block;
          width: 120px;
          height: 120px;
          margin-right: 40px;
          float: left;
          border: 3px solid transparent;
          &.active {
            border: 3px solid $color-blue;
          }
          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer;
          }
        }
      }
    }
    // 신관 이미지
    .tab_b {
      width: 1400px;
      height: 960px;
      margin: 120px auto 150px;
      position: relative;
      .b_detail {
        position: absolute;
        width: 1440px;
        height: 960px;
        top: 0;
        left: -260px;
        z-index: -999;
        &:before {
          content: "";
          background-image: url("https://img.davich.com/image/sub/education/gradient.png");
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        p {
          left: 260px;
          bottom: 82px;
          position: absolute;
          @include font-h-25;
          font-weight: 500;
          color: $color-white;
          text-align: left;
          max-width: 270px;
          line-height: 35px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .b_list {
        width: 1080px;
        height: 120px;
        position: absolute;
        bottom: 40px;
        right: 0;
        overflow: hidden;
        li {
          display: block;
          width: 120px;
          height: 120px;
          margin-right: 40px;
          float: left;
          border: 3px solid transparent;
          &.active {
            border: 3px solid $color-blue;
          }
          &:last-child {
            margin-right: 0;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer;
          }
        }
      }
    }
  }
}

// 사진 및 영상
.tab_c {
  margin-top: 62px;
  margin-bottom: 150px;
  h4 {
    @include font-h-35;
    margin-bottom: 40px;
  }
  // 성공사례
  .success-video {
    margin-bottom: 180px;
    .success-video-items {
      .success-video-item {
        &:last-child {
          > div {
            .desc {
              position: relative;
              margin-top: 30px;
              top: 0;
            }
          }
        }
        > div {
          width: 680px;
          position: relative;

          .img-wrap {
            position: relative;
            width: 100%;
            height: 382.5px;
            cursor: pointer;
            margin-bottom: 30px;
            overflow: hidden;
            img {
              //  width: 100%;
              height: 135%;
              object-fit: cover;
              @include box-center;
            }
            .play-btn {
              width: 55px;
              height: 62px;
              @include box-center;
              pointer-events: none;
            }
          }
          .desc {
            position: absolute;
            top: calc(382.5px + 30px);
            width: 680px;
            h5 {
              @include font-h-25;
              margin-bottom: 10px;
            }
            em {
              @include font-main;
              color: #777;
            }
            p {
              @include font-main;
              margin-top: 30px;
            }
          }
          &.right {
            margin-left: auto;
            text-align: right;
          }
        }
      }
    }
  }
  // 사진 및 졸업동영상
  .graduate-video {
    .graduate-video-items {
      margin-bottom: calc(-80px + 150px);
      display: flex;
      flex-wrap: wrap;

      .graduate-video-item {
        width: 440px;
        margin-right: 40px;
        margin-bottom: 80px;
        &:nth-child(3n) {
          margin-right: 0;
        }
        .img-wrap {
          width: 100%;
          height: 246px;
          cursor: pointer;
          position: relative;
          &:hover {
            .hover-icon {
              opacity: 1;
              visibility: visible;
              pointer-events: none;
            }
            box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.4);
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .hover-icon {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: #0055aac0;
            z-index: 1;
            opacity: 0;
            visibility: hidden;
            transition: 0.3s;
            img {
              @include box-center;
              width: 30px;
              height: 33px;
            }
          }
        }
        h5 {
          margin-top: 30px;
          @include font-h-25;
        }
      }
    }
  }
}

//pagination
#pagination {
  display: none;
}
</style>
