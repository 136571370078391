<template>
  <div>
    <section class="main-notice">
      <h2 class="hidden">다비치 회사 소식</h2>
      <article class="notice-cont notice-davich ly-cont-m scroll-fade-in">
        <h3 class="main-ttl-v1-m">다비치 소식</h3>
        <div class="notice-desc-wrap">
          <router-link
            :to="`/whatsnew/m_davichNewsDetail/${item.id}`"
            class="notice-desc"
            v-for="item in listToShow"
            :key="item.id"
          >
            <h4 class="notice-ttl">
              {{ item.title }}
            </h4>
          </router-link>
        </div>
      </article>
      <!-- 다비치 소식 베너 -->
      <article class="notice-cont notice-store scroll-fade-in scroll-delay-1">
        <h3 class="main-ttl-v1-m">신규 오픈 매장</h3>
        <div class="store-slider">
          <router-link to="/partnership/m_franchise" class="store-slider-inner">
            <div
              class="store-slide active"
              v-for="item in newOpen"
              :key="item.id"
            >
              <img
                src="https://img.davich.com/mobile/image/main/img-grand-open-m.png"
                alt="grand open bg"
              />
              <div class="store-desc">
                <h4>{{ item.name }}</h4>
                <p>{{ item.openDate }}</p>
              </div>
            </div>
          </router-link>
        </div>
        <ul class="slide-pagination"></ul>
      </article>
      <!-- 신규오픈매장 베너 -->
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  beforeCreate() {
    this.$store.dispatch("whatsnew/FETCH_NEWS");
  },

  data() {
    return {
      isUpdated: false,
      interval: null,
      timeout: null,
    };
  },

  computed: {
    ...mapGetters("whatsnew", ["getNewsData"]),
    ...mapState("partnership", {
      newOpen: (state) => state.newOpen,
    }),

    listToShow() {
      const self = this;
      return self.getNewsData.slice(0, 3);
    },
  },

  updated() {
    const self = this;
    let slideList = document.querySelector(".store-slider-inner");
    let slideContents = document.querySelectorAll(".store-slide");
    var slideListTop = slideList.offsetTop;
    var winH = window.innerHeight;
    const pagination = document.querySelector(".slide-pagination"); // dot
    const slideLen = slideContents.length; // slide length
    const slideWidth = window.innerWidth; // slide width
    const slideSpeed = 3000; // slide speed
    const startNum = 0; // initial slide index (0 ~ 4)

    // Copy first and last slide
    let firstChild = slideList.firstElementChild;
    let clonedFirst = firstChild.cloneNode(true);
    slideList.appendChild(clonedFirst);
    slideList.style.transform = "translate3d(0px, 0px, 0px)";
    let curIndex = startNum; // current slide index (except copied slide)

    // dot button 추가
    let pageChild = "";
    for (let z = 0; z < slideLen - 1; z++) {
      pageChild += '<li class="dot';
      // pageChild += z === startNum ? " dot-active" : "";

      pageChild += '" data-index="' + z + '"><a href="#"></a></li>';
    }
    pagination.innerHTML = pageChild;

    //dot button css
    var pageDots = document.querySelectorAll(".dot"); // each dot from pagination
    for (let i = 0; i < pageDots.length; i++) {
      // 버튼 css
      if (pageDots[i].classList.contains("dot-active")) {
        pageDots[i].style.backgroundColor = "#0056aa";
        pageDots[i].style.width = "20px";
        pageDots[i].style.height = "8px";
        pageDots[i].style.borderRadius = "8px";
        pageDots[i].style.marginRight = "8px";
      } else {
        pageDots[i].style.backgroundColor = "#ccc";
        pageDots[i].style.width = "8px";
        pageDots[i].style.height = "8px";
        pageDots[i].style.marginRight = "8px";
        pageDots[i].style.borderRadius = "50%";
      }
    }

    // 자동 슬라이드
    if (window.scrollY >= slideListTop - winH) {
      if (self.isUpdated == false) {
        self.interval = setInterval(function () {
          if (curIndex <= slideLen - 1) {
            slideList.style.transition = 1000 + "ms";
            slideList.style.transform =
              "translate3d(-" + slideWidth * (curIndex + 1) + "px, 0px, 0px)";

            let curDot = document.getElementsByClassName("dot");
            for (var i = 0; i < curDot.length; i++) {
              curDot[i].classList.remove("dot-active");
              curDot[i].style.backgroundColor = "#ccc";
              curDot[i].style.width = "8px";
              curDot[i].style.height = "8px";
              curDot[i].style.marginRight = "8px";
              curDot[i].style.borderRadius = "50%";
            }
            curDot[curIndex].classList.add("dot-active");
            curDot[curIndex].style.backgroundColor = "#0056aa";
            curDot[curIndex].style.width = "20px";
            curDot[curIndex].style.height = "8px";
            curDot[curIndex].style.borderRadius = "8px";
            curDot[curIndex].style.marginRight = "8px";
          }
          if (curIndex === slideLen - 1) {
            self.timeout = setTimeout(function () {
              slideList.style.transition = "0ms";
              slideList.style.transform = "translate3d(0px, 0px, 0px)";
            }, slideSpeed);
            curIndex = -1;
          }
          var curSlide = slideContents[++curIndex];
          curIndex = curIndex++;
        }, 4000);
        self.isUpdated = true;
      }
    }
  },
  destroyed() {
    const self = this;
    clearInterval(self.interval);
    clearTimeout(self.timeout);
  },
  //dot button 클릭 기능
  //   let curDot;
  //   Array.prototype.forEach.call(pageDots, function (dot, i) {
  //     //버튼 중에 클릭한 버튼
  //     dot.addEventListener("click", function (e) {
  //       e.preventDefault();
  //       curDot = document.querySelector(".dot-active");
  //       curDot.classList.remove("dot-active"); // active 있는것 삭제
  //       curDot = this;
  //       this.classList.add("dot-active"); // 클릭된 곳 dot-active;

  //       curSlide.classList.remove("slide-active");
  //       curIndex = Number(this.getAttribute("data-index"));
  //       curSlide = slideContents[curIndex];
  //       curSlide.classList.add("slide-active");
  //       slideList.style.transition = slideSpeed + "ms";
  //       slideList.style.transform =
  //         "translate3d(-" + slideWidth * (curIndex + 1) + "px, 0px, 0px)";
  //     });
  //   });
  // },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.dot.dot-active {
  width: 400px;
  background-color: red !important;
}

.main-notice {
  margin-bottom: 122px;
  .notice-cont {
    padding-top: 60px;
    h3 {
      text-align: center;
      margin-bottom: 20px;
    }
  } // 다비치 소식, 신규오픈매장 공통 레이아웃
  .notice-davich {
    .notice-desc-wrap {
      display: flex;
      flex-direction: column;

      .notice-desc {
        flex: 1;
        width: 100%;
        display: flex;
        align-items: center;
        border-top: 1px solid $color-gray;
        padding: 18px 0;
        &:last-child {
          border-bottom: 1px solid $color-gray;
        }
        .notice-ttl {
          font-size: 15px;
          line-height: 25px;
        }
      }
    }
  }
  .notice-store {
    .store-slider {
      height: 206px;
      overflow: hidden;
      width: 100%;
      position: relative;
      .store-slider-inner {
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        .store-slide {
          width: 100%;
          height: 100%;
          position: relative;
          flex-shrink: 0;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .store-desc {
            color: $color-white;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 63vw;
            max-width: 33vw;
            font-weight: 700;
            h4 {
              font-size: 25px;
              margin-bottom: 10px;
              line-height: 31px;
            }
            p {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

// 화면전환 시 효과
.do-transition {
  animation: slide 0.1s linear forwards;
}

@keyframes slide {
  from {
    margin-left: 100%;
  }

  to {
    margin-left: 0%;
  }
}

.do-transition-reverse {
  animation: slide-reverse 0.1s linear forwards;
}

@keyframes slide-reverse {
  from {
    margin-left: 100%;
  }

  to {
    margin-left: 0%;
  }
}
</style>
