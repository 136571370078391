<template>
  <div class="ly-w1400">
    <sub-title-1 :title="title" :content="content" :content2="content2"> </sub-title-1>
    <section>
      <tab-menu :tabTitle="tabTitle"></tab-menu>
      <h2 class="hidden">다비치 고객가치경영연구원 인재 양성 사상, 사관 공채 지원 작성하기</h2>
      <div class="tab-items">
        <div class="tab-item recruit ly-cont display">
          <div class="recruit-board board-items scroll-active">
            <p class="board-item recruiting" v-for="item in listToShow" :key="item.id">
              <router-link
                :to="`/education/recruitDetail/${item.id}`"
                class="firsta"
                :class="{ isClose: isClosing(item.due_date) }"
                >{{ item.title }}</router-link
              >
              <span class="firstspan" :class="{ isClose: isClosing(item.due_date) }">~ {{ item.due_date }}</span>
            </p>
          </div>
          <b-pagination id="pagination" v-model="currentPage" :per-page="perPage"></b-pagination>
          <button class="el-btn el-btn-c" id="btnMoreData" @click="getMoreData">더보기</button>
        </div>
        <!-- @@@@@@@@@@@@@@@@@@@@@@사관공채지원 탭@@@@@@@@@@@@@@@@@@@@@@@@@@@@ -->
        <div class="tab-item apply">
          <!-- <div class="thought scroll-active">
            <div class="img-wrap">
              <img src="https://img.davich.com/image/sub/education/education-apply.png" alt="오민사상" />
              <h4>오민사상</h4>
            </div>
            <div class="thought-item thought-item-1">
              <h4>민본(<span>民本</span>)</h4>
              <p>
                기본/원칙/법칙을 지키고 소통을 원활히 해서<br />
                지속적으로 성장하고 발전함
              </p>
            </div>
            <div class="thought-item thought-item-2">
              <h4>민복(<span>民福</span>)</h4>
              <p>
                배려와 봉사를 바탕으로<br />
                개인은 물론 주위 및 전체와 화합하고 상생함
              </p>
            </div>
            <div class="thought-item thought-item-3">
              <h4>민성(<span>民性</span>)</h4>
              <p>
                정직과 양심으로 고객을 대하고<br />
                투명경영을 통하여 신뢰사회를 이룩함
              </p>
            </div>
            <div class="thought-item thought-item-4">
              <h4>민격(<span>民格</span>)</h4>
              <p>
                최고의 제품과 서비스를 제공하여<br />
                소비자에게 맑고 밝은 세상을 만들어 줌
              </p>
            </div>
            <div class="thought-item thought-item-5">
              <h4>민생(<span>民生</span>)</h4>
              <p>
                끊임 없는 교육을 통해 전문성을 확보하고,<br />
                변화·혁신을 주도하며 맡은 일에 책임을 다함
              </p>
            </div>
            <h3>다비치 고객가치경영연구원은 오민사상을 기본으로 인재를 양성합니다.</h3>
            <p>
              기본을 지키며(민본) 정직하게 고객을 대하며(민성) 교육을 통한 꾸준한 전문성 함양과(민생) <br />
              최고의 제품과 서비스로(민격) 불우한 이웃에게도 봉사를 통해 (민복)
              <br />
              맑고 밝은 세상을 만들겠다는 신념인 “오민사상”의 철학아래 교육에 임합니다. <br />
            </p>
          </div> -->
          <div id="ly-form" class="ly-form apply-form ly-cont">
            <!-- <h2 class="scroll-fade-in-f">사관∙공채 지원</h2> -->
            <div class="ly-form-cont scroll-fade-in-f">
              <h3>지원 내용</h3>
              <div class="form-cont">
                <p class="apply-classify-form">
                  <em>구분<span>&#42;</span></em>
                  <span class="radio-form-wrap">
                    <span class="radio-form">
                      <input id="apply-officer" type="radio" name="apply-classify" value="1" v-model="type" checked />
                      <label for="apply-officer">사관</label>
                    </span>
                    <span class="radio-form">
                      <input id="apply-officer-2" type="radio" value="2" v-model="type" name="apply-classify" />
                      <label for="apply-officer-2">공채</label>
                    </span>
                    <span class="radio-form">
                      <input id="apply-officer-3" type="radio" value="3" v-model="type" name="apply-classify" />
                      <label for="apply-officer-3">코디네이터</label>
                    </span>
                  </span>
                </p>
                <p class="name-form">
                  <label for="name">성함<span>&#42;</span></label>
                  <input id="name" type="text" placeholder="성함을 입력해주세요." v-model="name" maxlength="20" />
                </p>
                <p class="email-form">
                  <label for="email">이메일<span>&#42;</span></label>
                  <input
                    id="email"
                    type="email"
                    placeholder="이메일 주소를 입력해주세요."
                    v-model="tempEmail"
                    @blur="sumDomain"
                    required
                  />
                  <span class="">&#64;</span>
                  <label for="input-domain" class="hidden">이메일 도메인 주소창</label>
                  <input id="input-domain" type="text" v-model="inputDomain" @blur="sumDomain" />
                  <label for="select-domain" class="hidden">이메일 도메인 주소 선택</label>

                  <select id="select-domain" v-model="domain" @change="sumDomain">
                    <option :value="0">직접입력</option>
                    <option :value="'naver.com'">naver.com</option>
                    <option :value="'hanmail.net'">hanmail.net</option>
                    <option :value="'gmail.com'">gmail.com</option>
                    <option :value="'daum.net'">daum.net</option>
                    <option :value="'nate.com'">nate.com</option>
                  </select>
                </p>
                <p class="number-form">
                  <label for="phonenumber">휴대폰<span>&#42;</span></label>
                  <span>
                    <!--01 연락처 입력부분-->
                    <span class="certifi-num-wrap">
                      <input
                        id="phonenumber"
                        type="tel"
                        placeholder="'-'제외하고 숫자만입력"
                        v-model="mobile_no"
                        maxlength="11"
                      />
                      <!--참고_인증번호 받기 버튼 활성화 시 클래스 active 추가-->
                      <!--버튼(인증번호 받기 전)-->
                      <button id="get-authnum-btn" class="certifi-num-btn" @click="certifyMobile">인증번호 받기</button>
                      <!--버튼(인증번호 받은 후)-->
                      <!-- <button class="certifi-num-btn reissuance">재발급</button> -->
                      <!--연락처 올바르지 않을 시 나타나는 문구-->
                      <span class="caution hidden">올바른 휴대폰 번호를 입력해주세요.</span>
                    </span>
                    <!--02 인증번호 입력부분-->
                    <span class="certifi-num-wrap">
                      <!-- <label for="certification" class="">인증번호 입력</label> -->
                      <input
                        class="hidden"
                        id="certifi-input"
                        type="text"
                        placeholder="인증번호를 입력해주세요."
                        v-model="authNumber"
                        maxlength="6"
                      />
                      <!--문구(인증번호 받은 후)-->
                      <span class="caution hidden" id="timer"
                        >인증번호가 발송되었습니다. <em> 유효시간 {{ timeStr }}초</em></span
                      >
                      <!--문구(인증번호 틀린 후)-->
                      <span class="caution hidden">인증번호가 일치하지 않습니다. <em> 유효시간 : 3:00초</em></span>
                      <!--문구(인증번호 유효시간 초과 시)-->
                      <span class="caution hidden">인증번호를 재발급 해주세요. 유효시간 초과</span>
                      <!--문구(인증번호 인증 완료 후)-->
                      <span class="caution confirm hidden">인증이 완료되었습니다.</span>
                      <!--참고_인증하기 버튼 활성화 시 클래스 active 추가-->
                      <button class="certifi-num-btn hidden" id="certifi-btn" @click="confirmMobile">
                        인증번호 확인
                      </button>
                    </span>
                  </span>
                </p>
                <p class="file-form">
                  <label for="upload-name">지원서 첨부</label>
                  <span>
                    <input id="upload-name" class="upload-name" :value="file.name" placeholder="첨부파일" />
                    <label for="avatar">찾아보기</label>
                    <span id="delete-file"
                      ><span>
                        <img src="https://img.davich.com/image/sub/partnership/icon-x-square.png" alt="x icon" />
                        파일삭제</span
                      ></span
                    >
                    <input
                      type="file"
                      id="avatar"
                      name="avatar"
                      accept="PDF, DOC, DOCX, PPT, PPTX, HWP, GUL,
                  GIF, JPG, PNG, ZIP, TXT"
                    />
                    <em
                      >50MB를 초과 할 수 없으며 PDF, DOC, DOCX, PPT, PPTX, HWP, GUL, GIF, JPG, PNG, ZIP, TXT 파일만
                      가능합니다.</em
                    >
                  </span>
                </p>
                <!-- 지원서 첨부 부분 -->
              </div>
            </div>
            <div class="ly-form-agree">
              <h3>개인정보 수집동의</h3>
              <div class="agree-cont">
                <div class="agree-txt" v-html="contents"></div>
                <div class="agree-chk">
                  <input id="agree-chk" type="checkbox" v-model="isAgree" />
                  <label for="agree-chk"
                    >수집하는 개인정보 항목, 수집/이용목적, 개인정보 보유기간에 동의합니다.<span>&#42;</span></label
                  >
                  <div class="chk-icon-wrap">
                    <img
                      class="chk-icon"
                      src="https://img.davich.com/image/sub/icon-form-checkbox.jpg"
                      alt="icon checkbox"
                    />
                  </div>
                </div>
                <div class="notice-form">
                  <p>※ 지원서는 공고 게시판에서 양식 다운로드 후 작성해서 첨부해주시기 바랍니다.</p>
                  <p>※ 접수 후 24시간 이내에 접수 확인문자가 오지 않을 경우 070-7428-6637로 연락 주시기 바랍니다.</p>
                </div>
              </div>
            </div>
            <!-- 개인정보 수집동의 부분 -->
            <button class="el-btn question-btn question-btn-left" @click="postFormData">지원하기</button>
            <!-- 지원하기 버튼 -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SubTitle1 from "../../components/SubTitle1.vue";
import TabMenu from "../../components/TabMenu.vue";

import axios from "@/api/axios-auth";
import { mapGetters, mapState } from "vuex";

export default {
  beforeCreate() {
    this.$store.dispatch("education/FETCH_RECRUIT");
  },

  components: {
    SubTitle1,
    TabMenu,
  },

  data() {
    return {
      title: "사관∙공채∙코디네이터 모집",
      content: "안경업계 최초로 자체 훈련기관으로 ",
      content2: "서울지방노동청의 승인을 받은 전문 교육기관에서 여러분의 꿈을 이루세요.",
      tabTitle: ["사관∙공채∙코디네이터 공고", "사관∙공채∙코디네이터 지원"],

      currentPage: 1,
      perPage: 10,

      //formData
      type: "1",
      name: "",
      email: "",
      domain: 0,
      mobile_no: "",
      file: "",

      tempEmail: "",
      inputDomain: "",

      authnumber_id: 0,
      authNumber: null,

      isCertified: false,
      isAgree: false,

      timer: null,
      timeCounter: 180,
      timeStr: "03:00",
    };
  },

  computed: {
    ...mapGetters("education", ["getRecruit"]),
    ...mapState({
      contents: (state) => state.others.privacy[0].contents,
    }),

    listToShow() {
      const self = this;
      let result;

      result = self.getRecruit.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
      return result;
    },
  },

  watch: {
    name(value) {
      const self = this;

      //공백인것,특수문자인것 제거
      const result = value.replace(/\s/g, "").replace(/[/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]/g, "");
      self.$nextTick(() => (self.name = result));
    },

    tempEmail(value) {
      const self = this;

      const result = value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/, "").replace(/[@]/g, "");
      self.$nextTick(() => (self.tempEmail = result));
    },

    mobile_no(value) {
      const self = this;
      const btn = document.getElementById("get-authnum-btn");
      if (self.mobile_no.length == 11) {
        btn.classList.add("active");
      } else {
        btn.classList.remove("active");
      }

      //숫자아닌것 제거
      const result = value.replace(/\D/g, "");
      self.$nextTick(() => (self.mobile_no = result));
    },

    authNumber(value) {
      const self = this;
      const btn = document.getElementById("certifi-btn");
      if (self.authNumber.length == 6) {
        btn.classList.add("active");
      } else {
        btn.classList.remove("active");
      }

      //숫자아닌것 제거
      const result = value.replace(/\D/g, "");
      self.$nextTick(() => (self.authNumber = result));
    },

    domain() {
      const self = this;
      const domainInput = document.getElementById("input-domain");

      if (self.domain == "0") {
        domainInput.removeAttribute("disabled");
        domainInput.setAttribute("type", "text");
      } else {
        domainInput.setAttribute("disabled", true);
        domainInput.setAttribute("type", "hidden");
        self.inputDomain = self.domain;
      }
    },
  },

  mounted() {
    //게시물 10개 안될 시 더보기버튼 숨김
    const btn = document.getElementById("btnMoreData");
    if (this.perPage >= this.getRecruit.length) {
      btn.style.display = "none";
    }

    const self = this;
    let typeOfFile = typeof self.file;
    let delBtn = document.querySelector("#delete-file");

    //파일 첨부 시 경로 알려주기
    const inputEl = document.querySelector("#avatar");
    inputEl.addEventListener("change", function () {
      let fileList = inputEl.files;
      let file = fileList[0];

      //파일용량체크
      let maxSize = 50 * 1024 * 1024;
      if (file.size > maxSize) {
        alert("첨부파일 사이즈는 50MB 이내로 등록 가능합니다.");
        inputEl.value = "";
        return false;
      }

      self.file = file;
      typeOfFile = typeof self.file;
      if (typeOfFile == "object") {
        delBtn.style.display = "block";
      }
    });

    //파일 삭제 기능
    const deletebtn = document.querySelector("#delete-file");
    deletebtn.addEventListener("click", function () {
      let fileList = inputEl.files;
      if (fileList.length > 0) {
        self.file = "";
        inputEl.value = "";
        // display none
        delBtn.style.display = "none";
      }
    });

    // recruit detail 지원하러가기 링크 이동
    if (this.$route.query.index == "1") {
      const tabBtn = document.querySelectorAll(".tab-btn");
      const tabCont = document.querySelectorAll(".tab-item");
      tabBtn[0].classList.remove("active");
      tabBtn[1].classList.add("active");
      tabCont[0].classList.remove("display");
      tabCont[1].classList.add("display");
    }

    // 지원하기 클릭 후 위치 이동
    if (this.$route.query.index == "2") {
      const tabBtn = document.querySelectorAll(".tab-btn");
      const tabCont = document.querySelectorAll(".tab-item");
      tabBtn[0].classList.remove("active");
      tabBtn[1].classList.add("active");
      tabCont[0].classList.remove("display");
      tabCont[1].classList.add("display");
    }
  },

  methods: {
    isClosing(due_date) {
      var currentDate = new Date();
      var dueDate = new Date(due_date);
      dueDate.setDate(dueDate.getDate() + 1);

      if (dueDate > currentDate) {
        // 마감일 지나지 않음.
        return false;
      } else {
        // 마감 지남
        return true;
      }
    },

    certifyMobile() {
      if (this.mobile_no == "") {
        alert("휴대폰 번호를 입력하세요");
        return 0;
      }
      const getAuthnumBtn = document.getElementById("get-authnum-btn");
      const certifyInput = document.getElementById("certifi-input");
      const certifyBtn = document.getElementById("certifi-btn");
      const countTimer = document.getElementById("timer");

      getAuthnumBtn.classList.add("active");
      certifyInput.classList.remove("hidden");
      certifyBtn.classList.remove("hidden");
      countTimer.classList.remove("hidden");
      this.setTimer();

      if (getAuthnumBtn.innerText != "재발급") {
        getAuthnumBtn.innerText = "재발급";
      } else {
        alert("인증번호가 재발송되었습니다.");
        //타이머 초기화
        this.timerStop(this.timer);
        this.timeCounter = 180;
        this.timeStr = "03:00";
        this.timer = null;
        this.timeStart();
      }

      axios
        .post("/message/authnumber", {
          board_name: "customer_voice",
          mobile_no: this.mobile_no,
        })
        .then((res) => {
          this.authnumber_id = res.data.id;
        })
        .catch((err) => console.log(err));
    },

    confirmMobile() {
      axios
        .post("/message/authnumber/check", {
          mobile_no: this.mobile_no,
          authnumber_id: this.authnumber_id,
          input_data: this.authNumber,
        })
        .then((res) => {
          if (res.status == 200) {
            alert("인증이 완료되었습니다.");

            this.isCertified = true;
            this.timerStop(this.timer);

            document.getElementById("get-authnum-btn").classList.add("hidden");
            document.getElementById("certifi-input").classList.add("hidden");
            document.getElementById("certifi-btn").classList.add("hidden");
            document.getElementById("timer").classList.add("hidden");

            document.getElementById("phonenumber").setAttribute("disabled", true);
            document.getElementById("get-authnum-btn").setAttribute("disabled", true);

            const confirm = document.getElementById("auth-confirm");
            confirm.classList.remove("hidden");
          }
        })
        .catch((err) => {
          switch (err.response.data.error_code) {
            case 1:
              alert("유효시간이 초과되었습니다.");
              break;
            case 2:
              alert("입력하신 번호가 일치하지 않습니다.");
              break;
          }
        });
    },

    getMoreData() {
      const btn = document.getElementById("btnMoreData");
      this.perPage += 5;

      //마지막 데이터에서 더보기버튼 숨김
      if (this.perPage >= this.getRecruit.length) {
        btn.style.display = "none";
      }
    },

    postFormData() {
      const self = this;

      //예외처리
      if (self.name.length == 0 || self.email.length == 0 || self.mobile_no.length == 0) {
        alert("필수항목을 기입해 주세요");
        return 1;
      }

      if (self.isAgree == false) {
        alert("개인정보 수집동의에 체크해주세요.");
        return 1;
      }
      if (self.isCertified == false) {
        alert("휴대폰 인증을 완료해주세요.");
        return 1;
      }
      if (self.isAgree == true && self.isCertified == true) {
        const frm = new FormData();
        frm.append("type", self.type);
        frm.append("name", self.name);
        frm.append("email", self.email);
        frm.append("mobile_no", self.mobile_no);
        frm.append("file", self.file);

        axios
          .post(`/education/officeropenrecruitment/apply`, frm, {
            headers: {
              "Content-Type": `multipart/form-data`,
            },
          })
          .then(() => {
            alert("지원이 완료되었습니다.");
            this.$router.replace({
              path: "/education/recruit",
              query: { index: 2 },
            });

            //입력폼 초기화
            const inputEl = document.querySelector("#avatar");
            inputEl.value = "";
            self.name = "";
            self.email = "";
            self.tempEmail = "";
            self.domain = 0;
            self.inputDomain = "";
            self.mobile_no = "";
            self.file = "";

            self.authnumber_id = 0;
            self.authNumber = null;

            self.isCertified = false;
            self.isAgree = false;

            self.timer = null;
            self.timeCounter = 180;
            self.timeStr = "03:00";
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    //타이머
    timeStart() {
      this.TimeCounter = 180;
      let interval = setInterval(() => {
        this.timeCounter--;
        this.timeStr = this.prettyTime();
        if (this.timeCounter <= 0) {
          this.timerStop(interval);
        }
      }, 1000);
      return interval;
    },
    timerStop(timer) {
      clearInterval(timer);
      this.timeCounter = 0;
    },
    prettyTime() {
      let time = this.timeCounter / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      return minutes.toString().padStart(2, "0") + ":" + secondes.toString().padStart(2, "0");
    },
    setTimer() {
      if (this.timer != null) {
        this.timerStop(this.timer);
        this.timer = null;
      }
      this.timer = this.timeStart();
    },

    //직접입력 이메일 합치기
    sumDomain() {
      const self = this;
      if (self.domain == "0") {
        let fullEmail = self.tempEmail + "@" + self.inputDomain;
        self.email = fullEmail;
      } else {
        self.email = self.tempEmail + "@" + self.domain;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.waiting {
  font-size: 40px;
  color: $color-blue;
  font-weight: bold;
  text-align: center;
  line-height: 50px;
}
.sub-title {
  background-image: url("https://img.davich.com/image/sub/subtitle/sub-edu-bg-02.png");
}

//사관공채공고
.recruit {
  padding: 70px 0 200px 0;
  width: 1200px;
  .recruit-board {
    border-top: 2px solid #222;
    .board-item {
      border-bottom: 1px solid #dedede;
      display: flex;
      &.board-recruiting {
        a {
          color: $color-blue;
        }
        span {
          color: $color-blue;
        }
      }
      a {
        display: flex;
        color: $color-black;
        margin: 30px 10px;
        width: 85vw;
        font-size: 20px;
        font-weight: 500;
        line-height: 20px;
        display: inline-block;
      }
      .firsta {
        color: #0056aa;
      }
      span {
        display: flex;
        width: 200px;
        font-size: 18px;
        color: #222;
        letter-spacing: -0.54px;
        margin: 30px 0;
        justify-content: right;
      }
      .firstspan {
        color: #0056aa;
      }
      .isClose {
        color: $color-black;
      }
    }
  }
  .el-btn {
    margin-top: 100px;
  }
}

//사관공채지원 오민사상
.apply {
  // padding: 70px 0 150px 0;
  .thought {
    width: 1780px;
    margin: auto;
    height: 1400px;
    background-color: #f0f8ff;
    position: relative;
    > p,
    .img-wrap,
    h3 {
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
    }
    .img-wrap {
      top: 234.5px;
      h4 {
        @include box-center;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: -0.9px;
        color: $color-blue;
      }
    }
    h3 {
      font-weight: bold;
      font-size: 30px;
      line-height: 45px;
      letter-spacing: -0.9px;
      text-align: center;
      bottom: 273px;
    }
    > p {
      bottom: 117px;
      text-align: center;
      font-size: 20px;
      line-height: 35px;
      letter-spacing: -0.6px;
    }
    .thought-item {
      position: absolute;
      h4 {
        font-size: 30px;
        font-weight: bold;
        letter-spacing: -0.9px;
        margin-bottom: 26.8px;
        color: $color-blue;
        span {
          font-family: $ch-font;
        }
      }
      p {
        @include font-main;
        line-height: normal;
      }
      &.thought-item-1 {
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        top: 85.6px;
        margin: auto;
      }
      &.thought-item-2 {
        left: 180px;
        text-align: right;
        top: 428.2px;
      }
      &.thought-item-3 {
        left: 1285.5px;
        top: 428.2px;
      }
      &.thought-item-4 {
        left: 309.5px;
        text-align: right;
        top: 828.2px;
      }
      &.thought-item-5 {
        left: 1194.5px;
        top: 828.2px;
      }
    }
  }

  // 사관·공채 지원 지원내용
  .apply-form {
    // margin-top: 100px;
    h2 {
      @include font-h-35;
      margin-bottom: 70px;
    }
    .ly-form-cont {
      .form-cont {
        p.apply-classify-form {
          em {
            display: inline-block;
            padding-top: 25px;
            width: 200px;
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
            letter-spacing: -0.66px;
            span {
              display: inline-block;
              margin-left: 4px;
              color: $color-skyblue;
            }
          }
          .radio-form-wrap {
            display: inline-block;
            width: 950px;
            padding-right: 50px;
            box-sizing: border-box;
            padding: 28px 0 27px 0;
            display: flex;
            align-items: center;
            .radio-form {
              display: flex;
              align-items: center;
              margin-right: 30px;
              &:last-child {
                margin-right: 0;
              }
              label {
                padding: 0 !important;
                width: auto;
              }
              input[type="radio"] {
                width: 28px !important;
                height: 28px;
                margin-right: 10px;
                &:checked {
                  & + label {
                    color: $color-blue;
                  }
                }
              }
            }
          }
        }
      }
    }

    //개인정보 수집동의 밑에 텍스트 박스
    .ly-form-agree {
      .agree-cont {
        .notice-form {
          background-color: #f0f8ff;
          padding: 29px 20px;
          @include font-main;
          margin-top: 56px;
        }
      }
    }
  }
}

#pagination {
  display: none;
}
</style>
