<template>
  <div class="how-to">
    <sub-title :title="title" :content="content" :content2="content2"> </sub-title>
    <section class="know-how">
      <div class="know-how-inner">
        <h2 class="scroll-fade-in-f">다비치만의 성공전략 노하우</h2>
        <div class="know-how-items ly-cont-m">
          <div class="know-how-item scroll-fade-in-f scroll-delay-1">
            <h3>
              <p>
                지역<br />
                핵심상권의 대표
              </p>
            </h3>
            <span
              >전문적인 상권분석을 통한<br />
              지역 최고의 1등 매장오픈</span
            >
          </div>
          <div class="know-how-item-wrap">
            <div class="know-how-item scroll-fade-in-f scroll-delay-2">
              <h3>
                <p>
                  전략정책을<br />
                  바탕으로 한<br />
                  운영 노하우 접목
                </p>
              </h3>
              <span
                >최고의 컨설팅 제공으로<br />
                다비치만의 차별화 정책</span
              >
            </div>
            <div class="know-how-item scroll-fade-in-f scroll-delay-3">
              <h3>
                <p>
                  지속적인<br />
                  신규고객 창출 확보
                </p>
              </h3>
              <span>다비치 고객만족 시스템</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /.know-how -->
    <section class="store-model">
      <div class="model-desc">
        <h2 class="scroll-fade-in-f">점포 모델</h2>
        <p class="scroll-fade-in-f scroll-delay-1 ly-cont-m">
          다비치안경점은 대로변에 위치하고 전면 간판 12m 노출, 60~80평 이상의 대형 매장에서 성공적으로 운영되고
          있습니다.
        </p>
      </div>

      <div class="model-corner-slider">
        <div class="model-corner-slider-inner">
          <div class="corner-slide slide-main-1">
            <div class="img-wrap">
              <img
                src="https://img.davich.com/mobile/image/sub/partnership/img-partnership-slide-main.png"
                alt="1층 모델"
              />
            </div>
            <p>1층 모델</p>
          </div>
          <div class="corner-slide slide-main-2">
            <div class="img-wrap">
              <img
                src="https://img.davich.com/mobile/image/sub/partnership/img-partnership-slide-main-02.png"
                alt="2층 모델"
              />
            </div>
            <p>2층 모델</p>
          </div>
          <div class="corner-slide slide-1">
            <div class="img-wrap">
              <img
                src="https://img.davich.com/image/sub/partnership/img-partnership-slide-01.png"
                alt="13579 정찰코너"
              />
            </div>
            <p>13579 정찰코너</p>
          </div>
          <div class="corner-slide slide-2">
            <div class="img-wrap">
              <img
                src="https://img.davich.com/image/sub/partnership/img-partnership-slide-02.png"
                alt="콘텍트렌즈 코너"
              />
            </div>
            <p>콘택트렌즈 코너</p>
          </div>
          <div class="corner-slide slide-3">
            <div class="img-wrap">
              <img src="https://img.davich.com/image/sub/partnership/img-partnership-slide-03.png" alt="비비엠 코너" />
            </div>
            <p>비비엠 코너</p>
          </div>
          <div class="corner-slide slide-4">
            <div class="img-wrap">
              <img
                src="https://img.davich.com/image/sub/partnership/img-partnership-slide-04.png"
                alt="수입안경 코너"
              />
            </div>
            <p>수입안경 코너</p>
          </div>
          <div class="corner-slide slide-5">
            <div class="img-wrap">
              <img src="https://img.davich.com/image/sub/partnership/img-partnership-slide-05.png" alt="매장 전경" />
            </div>
            <p>매장 전경</p>
          </div>
          <div class="corner-slide slide-6">
            <div class="img-wrap">
              <img src="https://img.davich.com/image/sub/partnership/img-partnership-slide-06.png" alt="다비치 카페" />
            </div>
            <p>다비치 카페</p>
          </div>
          <div class="corner-slide slide-7">
            <div class="img-wrap">
              <img src="https://img.davich.com/image/sub/partnership/img-partnership-slide-07.png" alt="트루뷰존" />
            </div>
            <p>트루뷰존</p>
          </div>
          <div class="corner-slide slide-8">
            <div class="img-wrap">
              <img src="https://img.davich.com/image/sub/partnership/img-partnership-slide-08.png" alt="비전케어 LAB" />
            </div>
            <p>비전케어 LAB</p>
          </div>
          <div class="corner-slide slide-9">
            <div class="img-wrap">
              <img src="https://img.davich.com/image/sub/partnership/img-partnership-slide-09.png" alt="검안실" />
            </div>
            <p>검안실</p>
          </div>
          <div class="corner-slide slide-10">
            <div class="img-wrap">
              <img src="https://img.davich.com/image/sub/partnership/img-partnership-slide-10.png" alt="키오스크" />
            </div>
            <p>키오스크</p>
          </div>
          <div class="corner-slide slide-11">
            <div class="img-wrap">
              <img
                src="https://img.davich.com/image/sub/partnership/img-partnership-slide-11.png"
                alt="난시멀티포컬피팅센터"
              />
            </div>
            <p>난시멀티포컬피팅센터</p>
          </div>
        </div>
        <ul class="slide-pagination">
          <li></li>
        </ul>
      </div>
    </section>
    <!-- /.store-model -->

    <section class="procedure">
      <div class="procedure-inner">
        <h2 class="scroll-fade-in-f">개설 절차</h2>
        <div class="procedure-info ly-cont-m">
          <div class="info-desc scroll-fade-in-f scroll-delay-1">
            <h3>Counseling</h3>
            <p>
              신규 오픈 희망 시 담당자와 가맹상담 진행<br />
              &#40;상담 후 예비점주 등록 필요 &#41;
            </p>
          </div>
          <div class="info-desc scroll-fade-in-f scroll-delay-2">
            <h3>Process Period</h3>
            <p>
              점포 확보에 소요되는 시간에 따라 오픈까지의 전체기간 차이 발생<br />
              &#40;점포 계약 후 오픈까지 평균 1-2개월 소요 &#41;
            </p>
          </div>
        </div>
        <div class="opening-procedure">
          <div class="procedure-items ly-cont-m">
            <hr class="line1" />
            <div class="procedure-item scroll-fade-in-f">
              <p>01&#46; 접수</p>
              <span>본사방문<br />(창업설명회 참석) <br />또는 창업센터 전화상담</span>
            </div>
            <div class="procedure-arrow scroll-fade-in-f">
              <img src="https://img.davich.com/mobile/image/sub/partnership/icon-arrow.png" alt="right arrow icon" />
            </div>

            <div class="procedure-item scroll-fade-in-f">
              <p>02&#46; 상담</p>
              <span
                >지역별 가맹 개발자와 상담<br />
                &#40;점포 입점 가능여부, 다비치 전략 정책 설명, 정보공개서 &#41;</span
              >
            </div>
            <hr class="line2" />
            <div class="procedure-item scroll-fade-in-f">
              <p>
                03&#46; 상권조사 및<br />
                현장답사
              </p>
              <span
                >해당 지역 실사를 통한
                <br />
                점포 후보 매장 조사</span
              >
            </div>

            <div class="procedure-arrow scroll-fade-in-f">
              <img src="https://img.davich.com/mobile/image/sub/partnership/icon-arrow.png" alt="right arrow icon" />
            </div>
            <div class="procedure-item scroll-fade-in-f">
              <p>04&#46; 점포계약</p>
              <span>점포 임대차 계약 <br />(건물주)</span>
            </div>
            <hr class="line3" />
            <div class="procedure-item scroll-fade-in-f">
              <p>05&#46; 계약</p>
              <span>가맹 계약<br />(다비치 점주)</span>
            </div>
            <div class="procedure-arrow scroll-fade-in-f">
              <img src="https://img.davich.com/mobile/image/sub/partnership/icon-arrow.png" alt="right arrow icon" />
            </div>

            <div class="procedure-item scroll-fade-in-f">
              <p>06&#46; 시공</p>
              <span
                >인테리어 시공 25일<br />
                (변동사항 있음)</span
              >
            </div>
            <hr class="line4" />
            <div class="procedure-item scroll-fade-in-f">
              <p>07&#46; 점주교육</p>
              <span
                >(신규자 교육 5박 6일,<br />
                파견근무 2주)</span
              >
            </div>
            <div class="procedure-arrow scroll-fade-in-f">
              <img src="https://img.davich.com/mobile/image/sub/partnership/icon-arrow.png" alt="right arrow icon" />
            </div>
            <div class="procedure-item scroll-fade-in-f">
              <p>08&#46; 오픈</p>
              <span>다비치 안경점 오픈</span>
            </div>
            <hr class="line5" />
            <div class="procedure-item scroll-fade-in-f">
              <p>09&#46; 사후관리</p>
              <span
                >30일 과정 및 지속적인<br />
                컨설팅</span
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /.procedure -->
    <section class="available">
      <h2 class="hidden">다비치 오픈 지역</h2>
      <article class="available-store">
        <h3 class="scroll-fade-in-f">오픈 가능 지역</h3>
        <div class="pas scroll-fade-in-f scroll-delay-1 ly-cont-m">
          <p>
            해당지역 안경사님께<br />
            오픈 우선권을 드립니다!
          </p>
          <div class="pas-items">
            <div class="pas-item scroll-fade-in-f">
              <div class="img-wrap">
                <img src="https://img.davich.com/image/sub/partnership/icon-partnership-01.png" alt="medal icon" />
              </div>
              <p>
                지역 내 1등이 가능한<br />
                상권 및 점포
              </p>
            </div>

            <div class="pas-item scroll-fade-in-f scroll-delay-1">
              <div class="img-wrap">
                <img src="https://img.davich.com/image/sub/partnership/icon-partnership-02.png" alt="medal icon" />
              </div>
              <p>
                전국 시단위 이상<br />
                지역
                <span
                  >(단, 읍단위의 경우<br />
                  시 수준 상권 형성 지역)</span
                >
              </p>
            </div>
            <div class="pas-item scroll-fade-in-f scroll-delay-2">
              <div class="img-wrap">
                <img src="https://img.davich.com/image/sub/partnership/icon-partnership-03.png" alt="medal icon" />
              </div>
              <p>
                시가지 핵심상권,<br />
                역세권, 대규모 역사,<br />
                핵심지역
              </p>
            </div>
            <div class="pas-item scroll-fade-in-f scroll-delay-3">
              <div class="img-wrap">
                <img src="https://img.davich.com/image/sub/partnership/icon-partnership-04.png" alt="medal icon" />
              </div>
              <p>
                대단위 아파트 단지 내 상가
                <span
                  >(단, 기존 가맹점 상권 내<br />
                  오픈 불가능)</span
                >
              </p>
            </div>
          </div>
        </div>
        <div class="qualify ly-cont-m">
          <div class="qualify-desc scroll-fade-in-f">
            <h4>가입자격</h4>
            <p>
              <span>&#45; 현재 안경원 운영 또는 신규 개설 희망자 </span>
              <span>&#45; 체인 가족 공동체로서 한마음이 될 수 있는 안경사</span>
            </p>
          </div>
          <div class="qualify-desc scroll-fade-in-f scroll-delay-1">
            <h4>가입조건</h4>
            <p>
              <span>&#45; 가맹점 가입비 : 2,500만원(VAT별도)</span>
              <span>&#45; 정기 지급금 : 월 100만원(VAT별도)</span>
            </p>
          </div>
        </div>
      </article>
      <article class="place">
        <partner-list></partner-list>
      </article>
    </section>
    <!-- /.available -->
  </div>
</template>

<script>
import PartnerList from "../../../components/mobile/PARTNERSHIP/MPartnerList.vue";
import SubTitle from "../../../components/mobile/MSubTitle1.vue";

export default {
  components: {
    PartnerList,
    SubTitle,
  },
  data() {
    return {
      title: "가맹점 개설안내",
      content: "우리지역 1등 매장! ",
      content2: "No.1 브랜드 다비치와 함께하세요.",
    };
  },
  mounted() {
    /*01 점포모델 - 버튼 클릭 시 색상/이미지 링크 변경*/
    // let modelBtn = document.querySelectorAll(".model-btns button");
    // for (var i = 0; i < modelBtn.length; i++) {
    //   let modelImgLink = modelBtn[i].dataset.alt;
    //   modelBtn[i].addEventListener("click", function () {
    //     // 버튼 색상변경
    //     for (var z = 0; z < modelBtn.length; z++) {
    //       modelBtn[z].classList.remove("active");
    //     }
    //     this.classList.add("active");
    //     //src 변경
    //     let modelImg = document.querySelector(".model-img img");
    //     modelImg.src = modelImgLink;
    //   });
    // }
    /*02 점포모델 - 슬라이드*/
    // const slideList = document.querySelector(".model-corner-slider-inner"); // Slide parent dom
    // const slideContents = document.querySelectorAll(".corner-slide"); // each slide dom
    // const slideLen = slideContents.length; // slide length
    // const slideWidth = 285; // slide width
    // const slideSpeed = 300; // slide speed
    // const startNum = 0; // initial slide index (0 ~ 4)
    // const pagination = document.querySelector(".slide-pagination"); // dot
    // slideList.style.width = slideWidth * (slideLen + 3) + "px";
    // // Copy first and last slide
    // let firstChild = slideList.firstElementChild;
    // let secondChild = document.querySelector(".slide-2");
    // let thirdChild = document.querySelector(".slide-3");
    // let forthChild = document.querySelector(".slide-4");
    // let clonedSecond = secondChild.cloneNode(true);
    // let clonedThird = thirdChild.cloneNode(true);
    // let clonedForth = forthChild.cloneNode(true);
    // let clonedFirst = firstChild.cloneNode(true);
    // // Add copied Slides
    // slideList.appendChild(clonedFirst);
    // slideList.appendChild(clonedSecond);
    // slideList.appendChild(clonedThird);
    // slideList.appendChild(clonedForth);
    // slideList.style.transform = "translate3d(0px, 0px, 0px)";
    // let curIndex = startNum; // current slide index (except copied slide)
    // let curSlide = slideContents[curIndex]; // current slide dom
    // curSlide.classList.add("slide_active");
    // // dot button 추가
    // let pageChild = "";
    // for (var i = 0; i < slideLen; i++) {
    //   pageChild += '<li class="dot';
    //   pageChild += i === startNum ? " dot-active" : "";
    //   pageChild += '" data-index="' + i + '"><a href="#"></a></li>';
    // }
    // pagination.innerHTML = pageChild;
    // // dot button css
    // var pageDots = document.querySelectorAll(".dot"); // each dot from pagination
    // for (var i = 0; i < pageDots.length; i++) {
    //   // 버튼 css
    //   if (pageDots[i].classList.contains("dot-active")) {
    //     pageDots[i].style.backgroundColor = "#0056aa";
    //     pageDots[i].style.width = "20px";
    //     pageDots[i].style.height = "8px";
    //     pageDots[i].style.borderRadius = "8px";
    //     pageDots[i].style.marginRight = "8px";
    //   } else {
    //     pageDots[i].style.backgroundColor = "#ccc";
    //     pageDots[i].style.width = "8px";
    //     pageDots[i].style.height = "8px";
    //     pageDots[i].style.marginRight = "8px";
    //     pageDots[i].style.borderRadius = "50%";
    //   }
    // }
    // // slide move
    // setInterval(function () {
    //   if (curIndex <= slideLen - 1) {
    //     slideList.style.transition = slideSpeed + "ms";
    //     slideList.style.transform =
    //       "translate3d(-" + slideWidth * (curIndex + 1) + "px, 0px, 0px)";
    //     pageDots[curIndex].style.backgroundColor = "#ccc";
    //     pageDots[curIndex].style.width = "8px";
    //     pageDots[curIndex].style.height = "8px";
    //     pageDots[curIndex].style.borderRadius = "50%";
    //     //  pageDots[curIndex].classList.remove("dot-active");
    //     if (curIndex < 5) {
    //       // pageDots[curIndex + 1].classList.add("dot-active");
    //       pageDots[curIndex + 1].style.backgroundColor = "#0056aa";
    //       pageDots[curIndex + 1].style.width = "20px";
    //       pageDots[curIndex + 1].style.height = "8px";
    //       pageDots[curIndex + 1].style.borderRadius = "8px";
    //     }
    //   }
    //   if (curIndex === slideLen - 1) {
    //     setTimeout(function () {
    //       slideList.style.transition = "0ms";
    //       slideList.style.transform = "translate3d(0px, 0px, 0px)";
    //     }, slideSpeed);
    //     curIndex = -1;
    //     pageDots[curIndex + 1].classList.add("dot-active");
    //     pageDots[curIndex + 1].style.backgroundColor = "#0056aa";
    //     pageDots[curIndex + 1].style.width = "20px";
    //     pageDots[curIndex + 1].style.height = "8px";
    //     pageDots[curIndex + 1].style.borderRadius = "8px";
    //   }
    //   ++curIndex;
    // }, 2500);
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";
section {
  padding: 70px 0 100px 0;
}
.how-to {
  .sub-title {
    background-image: url("https://img.davich.com/mobile/image/sub/subtitle/sub-partnership-bg.png");
    background-size: cover;
  }
  h2,
  .available h3 {
    @include font-h-30;
    margin-bottom: 40px;
    text-align: center;
  }
  /*다비치 만의 성공전략 노하우*/
  .know-how {
    .know-how-inner {
      .know-how-items {
        display: flex;
        flex-direction: column;
        align-items: center;
        .know-how-item {
          h3 {
            width: 40.5vw;
            height: 40.5vw;
            max-width: 170px;
            max-height: 170px;
            color: $color-blue;
            text-align: center;
            border-radius: 50%;
            border: 1px solid $color-blue;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: -0.54px;
            line-height: normal;
            margin-bottom: 15px;
            @include box-center-flex;
          }
          > span {
            line-height: 30px;
            letter-spacing: -0.48px;
          }
        }
        .know-how-item-wrap {
          margin-top: 25px;
          display: flex;
          .know-how-item {
            &:nth-child(1) {
              margin-right: 4vw;
            }
          }
        }
      }
    }
  }
  /*점포모델*/
  .store-model {
    max-width: 100%;
    overflow: hidden;
    .model-desc {
      margin-bottom: 50px;
      > p {
        @include font-main-m;
      }
    }
    .model-corner-slider {
      margin-left: 7.5vw;
      overflow: hidden;
      .model-corner-slider-inner {
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;
        &::-webkit-scrollbar {
          width: 16px;
        }
        &::-webkit-scrollbar-track {
          background-color: #f9f9f9;
        }
        &::-webkit-scrollbar-thumb {
          background-clip: padding-box;
          border: 4px solid transparent;
          background-color: #cbcbcb;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-button {
          width: 0;
          height: 0;
        }
        .corner-slide {
          margin-right: 20px;
          padding-bottom: 20px;
          .img-wrap {
            width: 265px;
            height: 265px;
            margin-bottom: 20px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
      .slide-pagination {
        padding-top: 45px;
      }
    }
  }
  /*개설절차*/
  .procedure {
    background-color: #f9f9f9;
    .procedure-info {
      .info-desc {
        h3 {
          @include font-h-22;
          margin-bottom: 20px;
          font-family: $en-font;
        }
        p {
          @include font-main-m;
          margin-bottom: 70px;
        }
      }
    }
    .opening-procedure {
      position: relative;
      .procedure-items {
        margin-bottom: -30px;
        display: flex;
        flex-wrap: wrap;
        max-width: 368px;
        .procedure-item {
          margin-bottom: 30px;
          width: 38vw;
          max-width: 170px;
          p {
            width: 38vw;
            height: 38vw;
            max-width: 170px;
            max-height: 170px;
            border-radius: 50%;
            margin-bottom: 15px;
            @include font-h-20;
            border: 1px solid $color-blue;
            background-color: #f9f9f9;
            color: $color-blue;
            @include box-center-flex;
            text-align: center;
          }
          span {
            display: inline-block;
            @include font-main-m;
            letter-spacing: -0.68px;
            text-align: center;
            width: 100%;
          }
        }
        .procedure-arrow {
          height: 20px;
          display: flex;
          align-items: center;
          padding-top: 76.8px;
          img {
            //화살표
            height: 20px;
          }
        }
      }
    }
  }
  /*오픈가능지역*/
  .available {
    /*자격 안내*/
    .available-store {
      .pas {
        > p {
          text-align: center;
          font-size: 22px;
          font-weight: 500;
          line-height: 35px;
          letter-spacing: 0.66px;
          margin-bottom: 70px;
        }
        .pas-items {
          display: flex;
          justify-content: center;
          max-width: 400px;
          margin: 0 auto;
          flex-wrap: wrap;
          .pas-item {
            width: 40vw;
            max-width: 170px;
            display: flex;
            align-items: center;
            flex-direction: column;
            text-align: center;
            margin-right: 4.2vw;
            margin-bottom: 70px;
            &:nth-child(2n) {
              margin-right: 0;
            }
            .img-wrap {
              margin-bottom: 20px;
              height: 78.9px;
              img {
                height: 100%;
              }
            }
            p {
              @include font-h-20;
              color: $color-blue;
              span {
                display: block;
                color: $color-black;
                @include font-main-m;
                font-weight: 400;
                margin-top: 10px;
              }
            }
          }
        }
      }
      .qualify {
        margin-bottom: 30px;
        .qualify-desc {
          border: 1px solid #dedede;
          padding: 20px;
          &:first-child {
            margin-bottom: 30px;
          }
          h4 {
            @include font-h-20;
            margin-bottom: 21px;
          }
          p {
            span {
              display: block;
              @include font-main-m;
            }
          }
        }
      }
    }
  }
}
</style>
