export const mutations = {
  SET_RECRUIT: function (state, resData) {
    let result = [];
    let sorted;

    for (let item of resData) {
      let obj = {};
      obj.id = item.id;
      obj.title = item.title;
      obj.due_date = item.due_date;
      obj.contents = item.details;
      obj.imgSrc = [];
      obj.formInfo = [];
      for (let attc of item.attachments) {
        if (attc.type == "applicationform") {
          let urlObj = {}
          urlObj["formUrl"] = attc.download_url; 
          urlObj["name"] = attc.originalname; 
          
          obj.formInfo.push(urlObj);
        } else {
          obj.imgSrc.push(attc.download_url);
        }
      }
      result.push(obj);
    }

    //id순으로 정렬
    sorted = result.sort(function (a, b) {
      return b.id - a.id;
    });

    //정렬된 리스트에 인덱스 부여
    for (let i = 0; i < sorted.length; i++) {
      sorted[i]["index"] = sorted.length - i;
    }

    state.boardData = sorted;
  },
  SET_PIC_VIDEO: function (state, resData) {
    let sorted;

    //id순으로 정렬
    sorted = resData.sort(function (a, b) {
      return b.id - a.id;
    });

    let result = [];
    for (let item of sorted) {
      //유투브링크에서 유니크주소 추출
      let uniqueUrl = item.youtube_link.split("=")[1];

      let obj = {};
      obj.id = item.id;
      obj.url = item.youtube_link;
      obj.uniqueUrl = uniqueUrl;
      obj.imgurl = `https://img.youtube.com/vi/${uniqueUrl}/sddefault.jpg`;
      obj.title = item.title;

      result.push(obj);
    }

    state.picVideoData = result;
  },

  SET_SUCCESS: function (state, resData) {
    let sorted;

    //id순으로 정렬
    sorted = resData.sort(function (a, b) {
      return b.id - a.id;
    });

    let result = [];
    for (let item of sorted) {
      //유투브링크에서 유니크주소 추출
      let uniqueUrl = item.youtube_link.split("=")[1];

      let obj = {};
      obj.id = item.id;
      obj.url = item.youtube_link;
      obj.uniqueUrl = uniqueUrl;
      obj.imgurl = `https://img.youtube.com/vi/${uniqueUrl}/sddefault.jpg`;
      obj.title = item.title;
      obj.subtitle = item.subtitle;
      obj.story = item.story;

      result.push(obj);
    }

    state.successStory = result;
  },

  SET_DEPARTMENT: function (state, resData) {
    let department = []; //조직 테이블 데이터
    for (let item of resData[0]) {
      if (item.available == 1) {
        department.push(item);
      }
    }
    let employee = resData[1]; // 연구원 테이블 데이터

    //조직ID와 연구원ID 같을 시 조직객체에 push
    for (let departItem of department) {
      departItem["employee"] = [];
      for (let emploItem of employee) {
        if (departItem.id == emploItem.department_id) {
          departItem["employee"].push(emploItem);
        }
      }
      //employee order순으로 정렬
      departItem.employee.sort(function (a, b) {
        return a.order - b.order;
      });
    }

    state.departmentData = department;
  },
};
