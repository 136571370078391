import { render, staticRenderFns } from "./2_m_howto.vue?vue&type=template&id=15474c32&scoped=true&"
import script from "./2_m_howto.vue?vue&type=script&lang=js&"
export * from "./2_m_howto.vue?vue&type=script&lang=js&"
import style0 from "./2_m_howto.vue?vue&type=style&index=0&id=15474c32&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15474c32",
  null
  
)

export default component.exports