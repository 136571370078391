<template>
  <div>
    <section class="quick-menu to-index">
      <h2 class="hidden">quick menu</h2>
      <div class="quick-items">
        <router-link
          to="/support/m_findStore"
          class="quick-item quick-franchisee scroll-fade-in"
        >
          <div class="quick-item-inner">
            <div class="quick-img">
              <img
                src="https://img.davich.com/mobile/image/main/icon-quick-01.png"
                alt="map icon"
              />
            </div>
            <h3>매장찾기</h3>
          </div>
        </router-link>
        <router-link
          to="/about/m_information"
          class="quick-item quick-recruit scroll-fade-in scroll-delay-1"
        >
          <div class="quick-item-inner">
            <div class="quick-img">
              <img
                src="https://img.davich.com/mobile/image/main/icon-quick-02.png"
                alt="icon"
              />
            </div>
            <h3>경영이념</h3>
          </div>
        </router-link>
        <router-link
          to="/support/m_ask"
          class="quick-item quick-customer scroll-fade-in scroll-delay-2"
        >
          <div class="quick-item-inner">
            <div class="quick-img">
              <img
                src="https://img.davich.com/mobile/image/main/icon-quick-03.png"
                alt="qna icon"
              />
            </div>
            <h3>고객의 소리</h3>
          </div>
        </router-link>
        <router-link
          to="/support/m_giftCard"
          class="quick-item quick-gift-card scroll-fade-in scroll-delay-3"
        >
          <div class="quick-item-inner">
            <div class="quick-img">
              <img
                src="https://img.davich.com/mobile/image/main/icon-quick-04.png"
                alt="giftcard icon"
              />
            </div>
            <h3>상품권 구매</h3>
          </div>
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.quick-menu {
  background-color: $color-blue;
  padding: 20px 0;
  .quick-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .quick-item {
      width: 50vw;
      height: 50vw;
      max-width: 180px;
      max-height: 180px;
      position: relative;
      @include box-center-flex;
      .quick-item-inner {
        .quick-img {
          width: 15vw;
          height: 15vw;
          max-width: 70px;
          max-height: 70px;
          margin: auto;
          img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
          }
        }
        h3 {
          font-size: 18px;
          text-align: center;
          color: $color-white;
          font-weight: bold;
          margin-top: 18px;
        }
      }
    }
  }
}

// .quick-menu {
//   background-color: $color-blue;
//   .quick-items {
//     .quick-item {
//       width: 50vw;
//       height: 50vw;
//       border-radius: 50%;
//       position: relative;
//       .quick-item-inner {
//         .quick-img {
//           img {
//           }
//         }
//         h3 {
//           font-size: 18px;
//           text-align: center;
//           color: $color-white;
//           font-weight: bold;
//         }
//       }
//     }
//   }
// }

// .quick-menu {
//   background-color: #f9f9f9;
//   height: 485px;
//   @include box-center-flex;
//   .quick-items {
//     @include box-center-flex;
//     .quick-item {
//       margin-right: 222px;
//       &:last-child {
//         margin-right: 0;
//       }
//       .quick-img {
//         width: 130px;
//         height: 130px;
//         border-radius: 50%;
//         background-color: $color-blue;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         margin-bottom: 60px;
//         position: relative;
//         img {
//           width: 50px;
//           transition: all 0.3s ease;
//         }
//         &:before {
//           content: "";
//           position: absolute;
//           top: 50%;
//           left: 50%;
//           transform: translate(-50%, -50%);
//           width: 100%;
//           height: 100%;
//           background-color: $color-blue;
//           opacity: 0.1;
//           z-index: -1;
//           border-radius: 50%;
//           transition: 0.3s;
//         }
//       }
//       h3 {
//         font-size: 20px;
//         text-align: center;
//       }
//       &:hover {
//         .quick-img {
//           img {
//             transform: translateY(-7px);
//           }
//           &:before {
//             width: 200px;
//             height: 200px;
//             z-index: 0;
//           }
//         }
//         h3 {
//           color: $color-darkblue;
//         }
//       }
//     }
//   }
// }
</style>
