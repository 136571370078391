export const mutations = {
  SET_NEW_OPEN: function (state, resData) {
    let newOpen;

    newOpen = resData.filter((item) => {
      return item.is_newopened == 1;
    });

    for (let item of newOpen) {
      let month = item.open_fullyear.split(".")[1];
      let day = item.open_fullyear.split(".")[2];

      let openDate = `${month}월 ${day}일`;
      item.openDate = openDate;

      //매장명 길이데이터
      item.lengthName = item.name.length;
    }

    state.newOpen = newOpen;
  },

  SET_OPENED: function (state, resData) {
    let franchiseData = [];

    //year데이터 모두 추출(중복제외)
    let yearSet = new Set();
    for (let item of resData) {
      yearSet.add(item.open_fullyear.split(".")[0]);
    }

    //year데이터를 가진 객체생성 후 franchiseData에 담음
    for (let year of yearSet) {
      let obj = {};
      obj.year = year;
      obj.tempdata = [];
      obj.data = [];

      franchiseData.push(obj);
    }

    //생성된 객체에 년도별로 데이터 필터링
    for (let item of franchiseData) {
      item.tempdata = resData.filter((store) => {
        let filtered;
        let storeYear = store.open_fullyear.split(".")[0];
        if (item.year == storeYear) {
          filtered = store;
        }
        return filtered;
      });

      //month 중복제거
      let monthSet = new Set();
      for (let tempItem of item.tempdata) {
        monthSet.add(tempItem.open_fullyear.split(".")[1]);
      }

      //년도별 객체안의 data멤버에 month별로 구분해서 넣기
      for (let month of monthSet) {
        let dataObj = {};
        dataObj.month = month;
        dataObj.location = [];
        item.tempdata.map((item) => {
          let itemMonth = item.open_fullyear.split(".")[1];
          if (itemMonth == month) {
            dataObj.location.push(item.name);
          }
        });
        item.data.push(dataObj);
      }

      // month 최신순으로 정렬
      item.data = item.data.sort(function (a, b) {
        return b.month - a.month;
      });
    }

    //year 최신순으로 정렬
    franchiseData = franchiseData.sort(function (a, b) {
      return b.year - a.year;
    });

    state.opened = franchiseData;
  },

  SET_OPEN_AREA: function (state, resData) {
    let openArea = [
      { class: "서울지역", area: [] },
      { class: "영남지역", area: [] },
      { class: "경기지역", area: [] },
      { class: "그외지역", area: [] },
    ];

    for (let item of resData) {
      const parentID = item.parent_id;

      let areaObj = {};
      areaObj.middleArea = item.name;
      areaObj.smallArea = item.store_area_details;

      switch (parentID) {
        case 1:
          openArea[0].area.push(areaObj);
          break;
        case 2:
          openArea[1].area.push(areaObj);
          break;
        case 3:
          openArea[2].area.push(areaObj);
          break;
        case 4:
          openArea[3].area.push(areaObj);
          break;
      }
    }

    state.openArea = openArea;
  },

  SET_MANAGER(state, resData) {
    let result = [];

    for (let item of resData) {
      let obj = {};

      obj.id = item.id;
      obj.name = item.name;
      obj.mobile_no = item.mobile_no;
      obj.email = item.email;
      obj.details = item.details;

      result.push(obj);
    }

    state.manager = result;
  },

  SET_TYPE: function (state, resData) {
    let sorted;

    sorted = resData.sort(function (a, b) {
      return a.order - b.order;
    });

    state.partnershipType = sorted;
  },
};
