<template>
  <div>
    <section class="myfeed-insta to-index">
      <h2 class="scroll-fade-in">instagram</h2>
      <div class="instafeed scroll-fade-in scroll-delay-1">
        <div
          class="instafeed-inner"
          v-for="item in latestInstaData"
          :key="item.id"
        >
          <a class="instafeed-cont" :href="item.permalink" target="_blank">
            <img
              v-if="item.thumbnail_url"
              class="instafeed-img"
              :src="item.thumbnail_url"
              alt="instaimg"
            />
            <img
              v-else
              class="instafeed-img"
              :src="item.media_url"
              alt="instaimg"
            />
            <img
              class="instafeed-type"
              src="https://img.davich.com/image/main/icon-instagram-carousel.png"
              alt="instagram carousel type icon"
            />
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  computed: {
    latestInstaData() {
      var state = this.$store.state.instaItem;
      return state;
    },
  },
  data() {
    return {
      acvite: false,
    };
  },
  created() {
    this.$store.dispatch("FETCH_INSTA_M");
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.myfeed-insta {
  height: auto;
  padding-top: 60px;
  h2 {
    font-size: 25px;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 20px;
    font-family: $en-font;
    text-align: center;
  }

  .instafeed {
    margin-bottom: -1px;
    display: flex;
    flex-wrap: wrap;

    .instafeed-inner {
      width: 33vw;
      height: 33vw;
      position: relative;
      margin-right: 1px;
      margin-bottom: 1px;
      &:nth-child(3n) {
        margin-right: 0;
      }
      .instafeed-cont {
        display: inline-block;
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        .instafeed-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .instafeed-type {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 10vw;
          height: 10vw;
        }
      }
    }
  }
}
</style>
