<template>
  <div class="detail-section-inner">
    <div class="detail-img">
      <div class="main-img-wrap">
        <img v-if="storeProps.imgSrc" class="main-img" :src="storeProps.imgSrc[0]" alt="" />
        <img v-else class="main-img" src="https://img.davich.com/image/sub/education/education_team_ready.png" alt="" />
      </div>
      <div class="sub-imgs">
        <button class="sub-img-wrap" v-for="(src, index) in storeProps.imgSrc" :key="index">
          <img :data-alt="index + 1" class="sub-img" :src="src" alt="" />
        </button>
      </div>
    </div>
    <div class="detail-ttl">
      <h3>{{ storeProps.name }}</h3>
      <div class="classify-items">
        <em class="classify-item">
          <div class="store-icon-wrap ear-icon-wrap earaidIcon">
            <img src="https://img.davich.com/image/sub/support/map/icon-store-all.png" alt="car icon" />
          </div>
          <span>보청기</span>
        </em>
        <em class="classify-item">
          <div class="store-icon-wrap car-icon-wrap parkingIcon parkingIcon">
            <img src="https://img.davich.com/image/sub/support/map/icon-store-all.png" alt="car icon" />
          </div>
          <span>주차장</span>
        </em>
        <em class="classify-item">
          <div class="store-icon-wrap lens-icon-wrap lensIcon">
            <img src="https://img.davich.com/image/sub/support/map/icon-store-all.png" alt="lens icon" />
          </div>
          <span>렌즈샵</span>
        </em>
      </div>
    </div>
    <div class="detail-txt">
      <p class="detail-address">
        <span class="store-icon-wrap detail-store-icon-wrap address-icon-wrap addressIcon">
          <img src="https://img.davich.com/image/sub/support/map/icon-store-all.png" alt="address icon" />
        </span>
        <span class="address">
          <input
            id="address-data"
            name="ourtext"
            class="hidden"
            type="hidden
              "
            v-model="storeProps.addr"
          />
          <div class="address-detail">
            {{ storeProps.addr }}
            <button @click="copyToClipboard">주소복사</button>
          </div>
        </span>
      </p>
      <p class="detail-number" v-if="storeProps.tel">
        <span class="store-icon-wrap detail-store-icon-wrap phone-icon-wrap phoneIcon">
          <img src="https://img.davich.com/image/sub/support/map/icon-store-all.png" alt="phone icon" />
        </span>
        <span>{{ storeProps.tel }}</span>
      </p>
      <p class="detail-site" v-if="storeProps.blogUrl">
        <span class="store-icon-wrap detail-store-icon-wrap site-icon-wrap siteIcon">
          <img src="https://img.davich.com/image/sub/support/map/icon-store-all.png" alt="site icon" />
        </span>
        <a :href="storeProps.blogUrl" target="_blank"
          ><span>{{ storeProps.blogUrl }}</span></a
        >
      </p>
      <p class="detail-location" v-if="storeProps.locationInfo">
        <span class="store-icon-wrap detail-store-icon-wrap location-icon-wrap locationIcon">
          <img src="https://img.davich.com/image/sub/support/map/icon-store-all.png" alt="location icon" />
        </span>
        <span>{{ storeProps.locationInfo }}</span>
      </p>
      <p class="detail-sale">
        <span class="store-icon-wrap detail-store-icon-wrap sale-icon-wrap saleIcon">
          <img src="https://img.davich.com/image/sub/support/map/icon-store-all.png" alt="sale icon" />
        </span>
        <span
          ><span v-for="dcItem in storeProps.discount" :key="dcItem.id"
            ><strong>{{ dcItem.dc_rate }}% 할인</strong>{{ dcItem.ven_name }}</span
          >
        </span>
      </p>
    </div>
    <div class="kakao-reserv-btns">
      <a :href="storeProps.kakaoUrl" target="_blank" class="kakao-btn">
        <div class="store-icon-wrap kakao-icon-wrap kakaoIcon">
          <img src="https://img.davich.com/mobile/image/sub/support/map/icon-store-all-01.png" alt="kakao icon" />
        </div>
        <span>카카오톡 채널</span>
      </a>
      <a href="http://www.davichmarket.com/front/visit/visit-welcome" class="visit-btn" target="_blank"
        ><div class="store-icon-wrap reserv-icon-wrap reservIcon">
          <img src="https://img.davich.com/mobile/image/sub/support/map/icon-store-all-02.png" alt="reservation icon" />
        </div>
        <span>방문예약</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["storeProps"],
  mounted() {
    // 상세 내용 없을경우 아이콘 제거
    let iconWrap = document.querySelectorAll(".detail-store-icon-wrap");
    for (var i = 0; i < iconWrap.length; i++) {
      var textNode = iconWrap[i].nextSibling;
      if (textNode == null) {
        iconWrap[i].style.display = "none";
      }
    }

    window.addEventListener("load", this.setScreenSize);
    window.addEventListener("resize", this.setScreenSize);
  },
  updated() {
    // 상세 내용 없을경우 아이콘 제거
    let iconWrap = document.querySelectorAll(".detail-store-icon-wrap");
    for (var i = 0; i < iconWrap.length; i++) {
      var textNode = iconWrap[i].nextSibling;
      if (textNode.innerText == "") {
        iconWrap[i].style.display = "none";
      }
    }

    //
    //setScreenSize();
    window.addEventListener("load", this.setScreenSize);
    window.addEventListener("resize", this.setScreenSize);
  },
  methods: {
    copyToClipboard() {
      let copySpan = document.getElementById("address-data");

      copySpan.select();
      document.execCommand("copy");
      alert("복사되었습니다, 감사합니다.");
    },

    // 버튼 하단 위치
    setScreenSize() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
      // let detailBtns = document.querySelector(".kakao-reserv-btns");
      // detailBtns.style.top = vh * 100 + "px";
    },
  },
  destroyed() {
    window.addEventListener("load", this.setScreenSize);
    window.removeEventListener("resize", this.setScreenSize);
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.m-find-store {
  .detail-img {
    margin-top: 45px;
    .main-img-wrap {
      width: 100%;
      height: 54.2vw;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .sub-imgs {
      margin-top: 12px;
      display: flex;
      &::-webkit-scrollbar {
        display: none;
      }
      .sub-img-wrap {
        margin-right: 12px;
        flex: 1;
        height: 10vw;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .detail-ttl {
    margin-top: 30px;
    margin-bottom: 20px;
    h3 {
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.6px;
      margin-bottom: 10px;
    }
    .classify-items {
      display: flex;
      .classify-item {
        display: flex;
        span {
          font-size: 15px;
        }
      }
    }
  }
  .detail-txt {
    // height: calc(
    //   var(--vh, 1vh) - 49.5px - 203.25px - 45px - 10vw - 116px - 60px
    // );
    height: calc((var(--vh, 1vh) * 100) - 262.38px - 61px - 60px - 37.5px - 45px - 50px);
    overflow: auto;
    overflow-x: hidden;
    color: #777;
    p {
      display: flex;
      margin-bottom: 7px;
      .store-icon-wrap {
        margin-top: 5px;
        flex-shrink: 0;
      }
      span {
        @include font-main-m;
        span {
          color: $color-blue;
          display: block;
          line-height: 41px;
          overflow: scroll;
          strong {
            font-size: 13px;
            border: 1px solid #0056aa;
            padding: 4px 9px;
            margin: 0px 10px 0 2px;
          }
          em {
            font-size: 15px;
            border: 1px solid #0056aa;
            padding: 4px 13px;
            margin: 0 10px 0 2px;
          }
        }
        button {
          display: inline-block;
          width: 65px;
          height: 30px;
          border: 1px solid #dedede;
          font-size: 13px;
          line-height: 30px;
          color: #777;
          border-radius: 15px;
        }
      }
    }
  }
  .detail-site {
    a {
      color: #777;
    }
  }
  .kakao-reserv-btns {
    position: absolute;
    width: 100%;
    top: calc(var(--vh, 1vh) * 100 - 60px);
    // bottom: 0px;
    left: 0;
    background-color: #fff;
    display: table;
    border-collapse: collapse;
    text-align: center;
    font-size: 17px;
    //safari에서 안나올때
    // -webkit-backface-visibility:hidden;
    // -webkit-transform:translateZ(0);
    // -webkit-transform:translate3d(0, 0, 0);

    .kakao-btn {
      width: 50%;
      display: table-cell;
      height: 60px;
      line-height: 60px;
      .kakaoIcon {
        margin-bottom: -3px;
        width: 24px;
        height: 19px;
        img {
          width: 100%;
        }
      }
    }
    .visit-btn {
      width: 50%;
      display: table-cell;
      height: 60px;
      line-height: 60px;
      background-color: $color-blue;
      color: $color-white;
      .reservIcon {
        width: 22px;
        height: 22px;
        margin-bottom: -4px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    a {
      flex: 1;
      height: 60px;
      border: 1px solid $color-black;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 5px;
      }
    }
  }
  .address {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .address-detail {
      display: inline-block;
      flex-shrink: 0;
      font-size: 16px;
      color: #777;
      margin-right: 10px;
      max-width: 100%;
    }
  }

  // 이미지 모음
  .store-icon-wrap {
    position: relative;
    overflow: hidden;
    margin-right: 5px;
    display: inline-block;
    img {
      position: absolute;
    }
    //분류
    &.wifiIcon {
      width: 21px;
      height: 21px;
      img {
        left: 0px;
        top: 0px;
      }
    }
    &.earaidIcon {
      width: 21px;
      height: 21px;
      img {
        left: -43px;
        top: 0;
      }
    }
    &.fittingIcon {
      width: 22px;
      height: 21px;
      img {
        left: -64px;
        top: -22px;
      }
    }
    &.lensIcon {
      width: 23px;
      height: 21px;
      img {
        left: -85px;
        top: 0px;
      }
    }
    &.parkingIcon {
      width: 23px;
      height: 21px;
      img {
        left: -85px;
        top: -22px;
      }
    }
    //정보
    &.addressIcon {
      width: 21px;
      height: 21px;
      img {
        left: 0px;
        top: -22px;
      }
    }
    &.phoneIcon {
      width: 21px;
      height: 21px;
      img {
        left: -22px;
        top: 0px;
      }
    }
    &.siteIcon {
      width: 21px;
      height: 21px;
      img {
        left: -21px;
        top: -22px;
      }
    }
    &.locationIcon {
      width: 22px;
      height: 21px;
      img {
        left: -42px;
        top: -22px;
      }
    }
    &.saleIcon {
      width: 21px;
      height: 21px;
      img {
        left: -64px;
        top: 0;
      }
    }
    //버튼
    &.kakaoIcon {
      // width: 26px;
      // height: 21px;
      img {
        left: 0px;
        top: 0px;
      }
    }
    &.reservIcon {
      // width: 16px;
      // height: 21px;
      img {
        left: 0px;
        top: 0px;
      }
    }
  }
}
</style>
