<template>
  <div>
    <router-link
      :to="`/about/m_contributionPostDetail/${boardData.id}`"
      v-bind="boardData"
      class="post-list-item"
    >
      <div class="img-wrap">
        <img class="post-img" :src="boardData.main_image" alt="search icon" />
      </div>
      <h3>
        {{ boardData.title }}
      </h3>
      <span>{{ boardData.created_dttm }}</span>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ["boardData"],
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.post-list-item {
  width: 100%;
  height: 100%;
  .img-wrap {
    @include img-wrap;
    width: 100%;
    height: 40vw;
    //height: 27vw;
    img {
      //@include img;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  h3 {
    line-height: 24px;
    font-size: 16px;
    letter-spacing: -0.75px;
    text-align: left;
    //3줄이상 ... 처리
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin: 15px 0 10px 0;
  }
  span {
    font-size: 16px;
    color: #aaa;
  }
}

.container {
  margin-bottom: 100px;
  display: inline-block;
}
</style>
