<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!-- <top-button></top-button> -->
    <mobile-main-header v-if="isMobileIndex == true"></mobile-main-header>
    <main-header v-else></main-header>
    <router-view></router-view>
    <scroll-animation></scroll-animation>
    <mobile-main-footer v-if="isMobileIndex == true && $route.name != 'm_findStore'"></mobile-main-footer>
    <main-footer v-if="isMobileIndex == false && this.$route.name != 'findStore'"></main-footer>
    <mobile-top-button v-if="isMobileIndex == true"></mobile-top-button>
    <top-button v-else></top-button>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import MainHeader from "./components/MainHeader.vue";
import MainFooter from "./components/MainFooter.vue";
import ScrollAnimation from "./components/ScrollAnimation.vue";
import MobileMainHeader from "./components/mobile/MMainHeader.vue";
import MobileMainFooter from "./components/mobile/MMainFooter.vue";
import TopButton from "./components/TopButton.vue";
import MobileTopButton from "./components/mobile/MTopButton.vue";

import dotenv from "dotenv";

export default {
  name: "App",

  data() {
    return {
      isMobileIndex: false,
    };
  },
  components: {
    // HelloWorld
    MainHeader,
    MainFooter,
    ScrollAnimation,
    MobileMainHeader,
    MobileMainFooter,
    TopButton,
    MobileTopButton,
  },
  created() {
    dotenv.config();
    if (process.env.NODE_ENV == "development") {
      console.log("개발서버입니다");
    } else if (process.env.NODE_ENV == "production") {
      console.log("Welcome Davich Homepage");
    }

    const self = this;

    function detectMobileDevice(agent) {
      const mobileRegex = [/Android/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

      return mobileRegex.some((mobile) => agent.match(mobile));
    }

    const isMobile = detectMobileDevice(window.navigator.userAgent);

    if (isMobile) {
      self.isMobileIndex = true;
    } else {
      self.isMobileIndex = false;
    }

    this.$store.dispatch("FETCH_POPUP");
    this.$store.dispatch("FETCH_MAIN_VIDEO");
    this.$store.dispatch("FETCH_COUNT");
    this.$store.dispatch("FETCH_INSTA");
    this.$store.dispatch("FETCH_HISTORY");

    //products
    this.$store.dispatch("products/FETCH_GLASSES_FRAME");
    this.$store.dispatch("products/FETCH_GLASSES_LENS");
    this.$store.dispatch("products/FETCH_CONTACT_LENS");
    this.$store.dispatch("products/FETCH_HEARING_AID");

    this.$store.dispatch("support/FETCH_STORE");

    //가맹점현황 데이터
    this.$store.dispatch("partnership/FETCH_OPENED");
    //광고홍보영상 데이터
    this.$store.dispatch("whatsnew/FETCH_CF");

    this.$store.dispatch("others/FETCH_PRIVACY");
  },
};
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
