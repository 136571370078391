export const mutations = {
  SET_PRODUCT_DATA: function (state, resData) {
    let result = [];

    for (let item of resData) {
      let obj = {};
      obj.id = item.id;
      obj.name = item.name;
      obj.imgSrc = [];

      item.attachments.sort((a, b) => {
        if (a.order > b.order) return 1;
        if (a.order === b.order) return 0;
        if (a.order < b.order) return -1;
      });

      for (let attc of item.attachments) {
        if (attc.type == "logo") {
          obj.logoSrc = attc.download_url;
        } else {
          obj.imgSrc.push(attc.download_url);
        }
      }
      obj.imgSrc.sort((a, b) => {
        if (a.order > b.order) return 1;
        if (a.order === b.order) return 0;
        if (a.order < b.order) return -1;
      });

      obj.content = item.description;
      obj.outlinkUrl = item.outlink_url;

      result.push(obj);
    }

    let sorted;
    sorted = result.sort(function (a, b) {
      return a.id - b.id;
    });

    const productId = resData[0].product_id;
    switch (productId) {
      case 1:
        state.glassesFrame = sorted;
        break;
      case 2:
        state.glassesLens = sorted;
        break;
      case 3:
        state.contantLens = sorted;
        break;
      case 4:
        state.hearingAid = sorted;
        break;
    }
  },
};
