import { fetchOpenArea, fetchOpened, fetchManager, fetchPartnershipType } from "@/api/index";

export const actions = {
  FETCH_OPENED: function (context) {
    fetchOpened()
      .then((res) => {
        context.commit("SET_NEW_OPEN", res.data);
        context.commit("SET_OPENED", res.data);
      })
      .catch((err) => console.log(err));
  },

  FETCH_OPEN_AREA: function (context) {
    fetchOpenArea()
      .then((res) => {
        context.commit("SET_OPEN_AREA", res.data);
      })
      .catch((err) => console.log(err));
  },

  FETCH_MANAGER(context) {
    fetchManager()
      .then((res) => {
        context.commit("SET_MANAGER", res.data);
      })
      .catch((err) => console.log(err));
  },

  FETCH_TYPE(context) {
    fetchPartnershipType()
      .then((res) => {
        context.commit("SET_TYPE", res.data);
      })
      .catch((err) => console.log(err));
  },
};
