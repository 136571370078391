<template>
  <div></div>
</template>

<script>
//마우스 스크롤 따라서 슉슉 나오도록 애니메이션 적용해주는 컴포넌트
export default {
  watch: {
    $route() {
      function animation() {
        var items;
        var winH;
        var itemsFont;

        var initModule = function () {
          items = document.querySelectorAll(".scroll-fade-in"); // 대상
          itemsFont = document.querySelectorAll(".scroll-fade-in-f"); // font 대상
          winH = window.innerHeight; // 모니터창 자체 크기
          _addEventHandlers();
        };

        var _addEventHandlers = function () {
          window.addEventListener("scroll", _checkPosition);
          window.addEventListener("load", _checkPosition);
          window.addEventListener("resize", initModule);
        };

        var _checkPosition = function () {
          for (let i = 0; i < items.length; i++) {
            var posFromTop = items[i].getBoundingClientRect().top; //대상이 모니터 상단 높이를 기준으로 얼마나 떨어져 있는지
            if (winH > posFromTop) {
              //모니터 창 자체크기보다 대상의 떨어진 높이가 작아질때 액티브 효과를 준다
              items[i].classList.add("scroll-active");
            }
          }
          for (let i = 0; i < itemsFont.length; i++) {
            var posFromTopFont = itemsFont[i].getBoundingClientRect().top;
            if (winH > posFromTopFont) {
              itemsFont[i].classList.add("scroll-active-font");
            }
          }
        };

        return {
          init: initModule,
        };
      }
      animation().init();
    },
  },
  mounted() {
    function animation() {
      var items;
      var winH;
      var itemsFont;

      var initModule = function () {
        items = document.querySelectorAll(".scroll-fade-in"); // 대상
        itemsFont = document.querySelectorAll(".scroll-fade-in-f"); // font 대상
        winH = window.innerHeight; // 모니터창 자체 크기
        _addEventHandlers();
      };

      var _addEventHandlers = function () {
        window.addEventListener("scroll", _checkPosition);
        window.addEventListener("load", _checkPosition);
        window.addEventListener("resize", initModule);
      };

      var _checkPosition = function () {
        for (let i = 0; i < items.length; i++) {
          items[i].style.opacity = "0";

          var posFromTop = items[i].getBoundingClientRect().top; //대상이 모니터 상단 높이를 기준으로 얼마나 떨어져 있는지
          if (winH > posFromTop) {
            //모니터 창 자체크기보다 대상의 떨어진 높이가 작아질때 액티브 효과를 준다
            items[i].classList.add("scroll-active");
          }
        }
        for (let i = 0; i < itemsFont.length; i++) {
          itemsFont[i].style.opacity = "0";
          var posFromTopFont = itemsFont[i].getBoundingClientRect().top;
          if (winH > posFromTopFont) {
            itemsFont[i].classList.add("scroll-active-font");
          }
        }
      };

      return {
        init: initModule,
      };
    }

    animation().init();
  },
  destroyed() {
    window.removeEventListener("scroll", _checkPosition);
    window.removeEventListener("load", _checkPosition);
    window.removeEventListener("resize", initModule);
  },
};
</script>

<style></style>
