import {
  fetchGlassesFrame,
  fetchGlassesLens,
  fetchContactLens,
  fetchHearingAid,
} from "@/api/index";

export const actions = {
  FETCH_GLASSES_FRAME: function (context) {
    fetchGlassesFrame()
      .then((res) => {
        context.commit("SET_PRODUCT_DATA", res.data);
      })
      .catch((err) => console.log(err));
  },

  FETCH_GLASSES_LENS: function (context) {
    fetchGlassesLens()
      .then((res) => {
        context.commit("SET_PRODUCT_DATA", res.data);
      })
      .catch((err) => console.log(err));
  },

  FETCH_CONTACT_LENS: function (context) {
    fetchContactLens()
      .then((res) => {
        context.commit("SET_PRODUCT_DATA", res.data);
      })
      .catch((err) => console.log(err));
  },

  FETCH_HEARING_AID: function (context) {
    fetchHearingAid()
      .then((res) => {
        context.commit("SET_PRODUCT_DATA", res.data);
      })
      .catch((err) => console.log(err));
  },
};
