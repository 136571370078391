<template>
  <div class="partner-place">
    <div class="place-btn">
      <button class="location active" @click="selectLocation('', 0)">
        전체
      </button>
      <button class="location" @click="selectLocation('서울지역', 1)">
        서울지역
      </button>
      <button class="location" @click="selectLocation('영남지역', 2)">
        영남지역
      </button>
      <button class="location" @click="selectLocation('경기지역', 3)">
        경기지역
      </button>
      <button class="location" @click="selectLocation('그외지역', 4)">
        그외지역
      </button>
    </div>

    <div
      class="place-list ly-cont"
      v-for="(area, i) in filterLocation"
      :key="i"
    >
      <h3 class="place-name">{{ area.class }}</h3>
      <ul class="place-city-wrap">
        <li class="place-city" li v-for="(area, i) in area.area" :key="i">
          <ul v-if="i % 2 == 0" class="place-country">
            <li class="place-region">
              {{ area.middleArea }}
            </li>
            <li class="place-district-wrap">
              <span
                class="place-district"
                v-for="(smallArea, i) in area.smallArea"
                :key="i"
              >
                {{ smallArea.name }}
              </span>
            </li>
          </ul>
          <ul v-else class="place-country">
            <li class="place-region place-region-r">
              {{ area.middleArea }}
            </li>
            <li class="place-district-wrap">
              <span
                class="place-district"
                v-for="(smallArea, i) in area.smallArea"
                :key="i"
              >
                {{ smallArea.name }}
              </span>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <div class="btn-wrap ly-cont">
      <router-link to="/partnership/inquiryFranchise" class="el-btn el-btn-long"
        >가맹점 개설문의​</router-link
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      selectedClass: "",
    };
  },
  methods: {
    selectLocation(area, index) {
      //var activeItem;
      this.selectedClass = area;
      const btnArr = document.getElementsByClassName("location");

      // if (activeItem.classList.contains("active")) {
      //   activeItem.classList.remove("active");
      // }

      for (var i = 0; i < btnArr.length; i++) {
        btnArr[i].classList.remove("active");
      }

      btnArr[index].classList.add("active");

      //activeItem == btnArr[index];
    },
  },
  computed: {
    ...mapGetters("partnership", ["getOpenArea"]),

    filterLocation() {
      let selectedClass = this.selectedClass;

      return this.getOpenArea.filter((item) => {
        let filtered = true;
        if (selectedClass && selectedClass.length > 0) {
          filtered = item.class == selectedClass;
        }
        return filtered;
      });
    },
  },

  beforeCreate() {
    this.$store.dispatch("partnership/FETCH_OPEN_AREA");
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.partner-place {
  .place-btn {
    border-bottom: 1px solid #dedede;
    display: flex;
    justify-content: center;

    button {
      @include font-tab-20;
      padding: 26px 0 25px 0;
      width: 200px;
      text-align: center;
      position: relative;
      &.active {
        color: $color-blue;
      }
      &:before {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 30px;
        background-color: $color-gray;
      }
      &:last-child {
        &:before {
          display: none;
        }
      }
    }
  }
  .place-list {
    padding-top: 65px;
    h3 {
      @include font-h-25;
      margin-bottom: 30px;
    }
    .place-city-wrap {
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid $color-black;
      border-bottom: 1px solid $color-black;
      .place-city {
        @include font-main;
        height: auto;
        width: 700px;
        .place-country {
          display: flex;
          border-bottom: 1px solid #dedede;
          height: 100%;
          .place-region {
            width: 160px;
            padding: 16.5px 0;
            background-color: #f9f9f9;
            font-weight: 500;
            border-right: 1px solid #dedede;
            text-align: center;
            flex-shrink: 0;
            &.place-region-r {
              border-left: 1px solid #dedede;
            }
          }
          .place-district-wrap {
            display: flex;
            flex-wrap: wrap;
            padding: 16.5px 20px;
            .place-district {
              flex-shrink: 0;
              display: flex;

              margin-right: 17px;
              position: relative;
              &:nth-child(2) {
                // margin-left: 50px;
              }
              &:before {
                content: "/";
                position: absolute;
                right: -13px;
              }
              &:last-child {
                &:before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
  .btn-wrap {
    display: flex;
    justify-content: center;
    margin-top: 140px;
    .el-btn-l {
      width: 250px;
    }
  }
}
</style>
