<template>
  <div class="ly-w1400">
    <sub-title :title="title" :content="content"> </sub-title>
    <section class="ly-cont davich-event scroll-active">
      <h2 class="hidden">다비치 이벤트</h2>
      <!-- <tab-menu-2 :tabTitle="tabTitle"></tab-menu-2> -->
      <div class="event-items">
        <div class="event-item" v-for="item in listToShow" :key="item.id">
          <a :href="item.url" class="event-img-wrap" target="_blank">
            <img :src="item.imgSrc" alt="이벤트 이미지" />
            <!-- <em class="badge"> D-<span>71</span> </em> -->
          </a>
          <a :href="item.url" target="_blank">
            <p>{{ item.start }} ~ {{ item.end }}</p>
            <h3>{{ item.title }}</h3>
          </a>
        </div>
      </div>
      <b-pagination
        id="pagination"
        :total-rows="rows"
        v-model="currentPage"
        :per-page="perPage"
      ></b-pagination>
      <button class="el-btn el-btn-c" id="btnMoreData" @click="getMoreData">
        더보기
      </button>
    </section>
  </div>
</template>

<script>
import SubTitle from "../../components/SubTitle1.vue";
import { mapGetters } from "vuex";

export default {
  beforeCreate() {
    this.$store.dispatch("whatsnew/FETCH_EVENT");
  },

  components: {
    SubTitle,
    // TabMenu2,
  },
  data() {
    return {
      title: "다비치 이벤트",
      content: "다비치는 지금, 다양한 이벤트를 한눈에 확인하세요.",
      // tabTitle: ["전체", "혜택", "쿠폰", "세일", "사전예약", "얼리버드"],

      currentPage: 1,
      perPage: 4,

      lengthListToShow: 0,
    };
  },

  computed: {
    ...mapGetters("whatsnew", ["getEventData"]),

    listToShow() {
      const self = this;
      let result;

      self.lengthListToShow = self.getEventData.length;

      result = self.getEventData.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
      return result;
    },

    rows() {
      const self = this;
      return self.lengthListToShow;
    },
  },

  watch: {
    rows() {
      if (this.rows <= 4) {
        const btn = document.getElementById("btnMoreData");
        btn.style.display = "none";
      }
    },
  },

  methods: {
    getMoreData() {
      const btn = document.getElementById("btnMoreData");
      this.perPage += 4;

      //마지막 데이터에서 더보기버튼 숨김
      if (this.perPage >= this.rows) {
        btn.style.display = "none";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.sub-title {
  background-image: url("https://img.davich.com/image/sub/subtitle/sub-what-bg-02.png");
}

//콘텐츠 목록
.davich-event {
  padding: 70px 0 150px 0;
  .event-items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: -80px;
    .event-item {
      width: 680px;
      margin-bottom: 80px;
      .event-img-wrap {
        width: 100%;
        img {
          width: 100%;
          // height: 100%;
          height: 270px;
          object-fit: cover;
        }
        .badge {
          background-color: $color-blue;
          display: block;
          width: 100px;
          height: 50px;
          color: $color-white;
          text-align: center;
          line-height: 50px;
          font-size: 22px;
          font-weight: 400;
          position: absolute;
          top: 30px;
          left: 30px;
          font-size: 25px;
        }
      }
      a {
        display: block;
        p {
          margin-top: 20px;
          font-size: 18px;
          color: #aaa;
          text-align: center;
        }
        h3 {
          margin-top: 10px;
          //margin-top: 30px;
          font-size: 25px;
          font-weight: bold;
          line-height: 40px;
          text-align: center;
        }
      }
    }
  }
  button {
    margin-top: 150px;
  }
}

.search-comp {
  margin-right: 10px;
  margin-top: -2px;
  float: right;
}

#pagination {
  display: none;
}
</style>
