<template>
  <div class="list-by-year scroll-fade-in-f scroll-delay-1">
    <div class="open-year">
      <!-- 연혁 년도 각각 표시하는 부분 -->
      {{ year }}년
    </div>
    <div>
      <p v-for="item in data" :key="item.index">
        <!-- 월,오픈위치 각각 표시하는 부분 -->
        <span class="open-month">{{ item.month }}월</span>
        <span
          class="open-date"
          v-for="(location, index) in item.location"
          :key="index"
          >{{ location }} &nbsp;
        </span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["franchiseData"],
  data() {
    return {
      year: 0,
      data: [],
    };
  },
  created() {
    this.year = this.franchiseData.year;
    this.data = this.franchiseData.data;
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.list-by-year {
  display: flex;
  line-height: 35px;
  margin-bottom: 150px;
  .open-year {
    @include font-h-25;
    width: 200px;
    padding-top: 10px;
  }
  p {
    display: flex;
    padding: 17.5px 0;
    line-height: 35px;
    .open-month {
      @include font-main;
      font-weight: bold;
      margin-right: 40px;
      min-width: 38px;
    }
    .open-date {
      @include font-main;
    }
  }
}
</style>
