<template>
  <div>
    <section class="main-fullbanner">
      <h2 class="hidden">다비치의 활동</h2>
      <router-link
        to="/about/m_contribution"
        class="fullbanner-cont banner-volun banner- to-index"
      >
        <div class="fullbanner-desc">
          <h3 class="main-ttl-v1-m scroll-fade-in-f">눈건강 시력 지킴이</h3>
          <p class="scroll-fade-in-f scroll-delay-2">
            눈건강 시력 지킴이는 소외된 어려운 이웃까지 맑고 밝게 비춰주는
            봉사단으로 전국 사회봉사시설을 방문해 시력검사 및 돋보기, 교정용
            안경을 무료로 제공합니다.<br />
            다비치는 모두가 함께하는 더 나은 사회를 만들기 위해 기여하겠습니다.
          </p>
          <div class="m-btn-all scroll-fade-in-f">
            <span></span>
            <span></span>
            <p class="hidden">전체보기</p>
          </div>
        </div>
      </router-link>
      <router-link
        to="/education/m_researchCenter"
        class="fullbanner-cont banner-customer to-index"
      >
        <div class="fullbanner-desc ly-cont">
          <h3 class="main-ttl-v1-m scroll-fade-in-f">고객가치경영연구원</h3>
          <p class="scroll-fade-in-f scroll-delay-2">
            안경업계 최초 서울지방노동청에서 자체 훈련기관으로 승인 받은 안경사
            전문 교육기관입니다. 다비치 고객가치경영연구원에서 여러분의 꿈을
            이루세요.
          </p>
          <div class="m-btn-all scroll-fade-in-f">
            <span></span>
            <span></span>
            <p class="hidden">전체보기</p>
          </div>
        </div>
      </router-link>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.main-fullbanner {
  overflow: hidden;
  height: auto;
  .fullbanner-cont {
    display: block;
    height: max(650px, 98vw);
    //height: 650px;
    position: relative;
    width: 100%;
    overflow: hidden;
    transition: 0.6s;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .fullbanner-desc {
      position: absolute;
      width: 85vw;
      z-index: 1;
      top: 60px;
      left: 50%;
      transform: translateX(-50%);
      h3 {
        margin-bottom: 30px;
      }
      p {
        line-height: 30px;
      }
      .m-btn-all {
        position: absolute;
        top: 1px;
      }
    }
    &.banner-volun {
      background: bottom center/ cover no-repeat
        url("https://img.davich.com/mobile/image/main/img-main-fullbanner-01.png");
      .m-btn-all {
        left: 210px;
      }
    }
    &.banner-customer {
      background-image: url("https://img.davich.com/mobile/image/main/img-main-fullbanner-02.png");
      background-position: center center;
      background-size: cover;
      .fullbanner-desc {
        h3 {
          text-align: right;
        }
        .m-btn-all {
          right: 220px;
        }
      }
    }
  }
}
</style>
