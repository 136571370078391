<template>
  <div>
    <div class="container">
      <div class="slider to-index">
        <div class="slide" v-for="item in videoData" :key="item.id">
          <video muted autoplay loop playsinline poster="https://img.davich.com/mobile/image/main/img-main-page.png">
            <source :src="item.mobileUrl" type="video/mp4" />
            <strong>Your browser does not support the video tag.</strong>
          </video>
          <div class="slide-desc">
            <h2 class="en-font">
              {{ item.title }}
            </h2>
            <p>
              {{ item.subtitle }}
            </p>
          </div>
        </div>

        <div class="arrow-wrap">
          <button class="arrow-left">
            <img src="https://img.davich.com/mobile/image/main/btn-caret-left.png" alt="" />
          </button>
          <button class="arrow-right">
            <img src="https://img.davich.com/mobile/image/main/btn-caret-right.png" alt="" />
          </button>
        </div>
        <div class="video-control">
          <div class="progress-wrap en-font">
            <div class="progress-bar">
              <p>01</p>
              <div class="progress">
                <div class="progress__filled bar0"></div>
              </div>
            </div>
            <div class="progress-bar">
              <p>02</p>
              <div class="progress">
                <div class="progress__filled bar1"></div>
              </div>
            </div>
            <div class="progress-bar">
              <p>03</p>
              <div class="progress">
                <div class="progress__filled bar2"></div>
              </div>
            </div>
            <div class="progress-bar">
              <p>04</p>
              <div class="progress">
                <div class="progress__filled bar3"></div>
              </div>
            </div>
            <div class="progress-bar">
              <p>05</p>
              <div class="progress">
                <div class="progress__filled bar4"></div>
              </div>
            </div>
          </div>
          <div class="slider-ctrl-btn-box">
            <button class="slider-ctrl-btn__play sm" @click="playVideo()">
              <img src="https://img.davich.com/image/main/btn-play.png" alt="play button" />
            </button>
            <button class="slider-ctrl-btn__pause sm on" @click="pauseVideo()">
              <img src="https://img.davich.com/image/main/btn-stop.png" alt="play button" />
            </button>
          </div>
        </div>
        <button class="scroll-arrow" @click="scrollDown()">
          <img src="https://img.davich.com/mobile/image/main/icon-scroll-arrow.png" alt="" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  beforeCreate() {
    this.$store.dispatch("FETCH_MAIN_VIDEO");
  },

  data() {
    return {
      ranNumber: 0,
    };
  },

  computed: {
    ...mapState({
      videoData: (state) => state.videoData,
    }),
  },

  created() {
    let self = this;
    function ranNumber(min, max) {
      let ranNumber = Math.floor(Math.random() * (max - min + 1)) + min;
      self.ranNumber = ranNumber;
    }
    ranNumber(0, 4);
  },

  updated() {
    const videoList = document.querySelectorAll("video");
    const itemCount = videoList.length;
    const nextItem = document.querySelector(".arrow-right");
    const previousItem = document.querySelector(".arrow-left");
    let count = this.ranNumber;
    let videoNow = videoList[count];

    const progressList = document.getElementsByClassName("progress__filled");
    let progressBar = progressList[count];

    function handleProgress() {
      const percent = (videoNow.currentTime / videoNow.duration) * 100;

      progressBar.parentElement.style.display = "flex";
      progressBar.style.flexBasis = `${percent}%`;
    }

    videoNow.classList.add("active"); //랜덤하게 나온 비디오에 active

    function showNextItem() {
      videoList[count].classList.remove("active");
      progressBar.parentElement.style.display = "none"; //기본 bar 하이드

      if (count < itemCount - 1) {
        count++;
      } else {
        count = 0;
      }
      videoList[count].classList.add("active");

      videoNow = videoList[count]; //변수재설정
      progressBar = progressList[count]; //변수재설정
      progressBar.parentElement.style.display = "flex";
      videoNow.addEventListener("timeupdate", handleProgress);
    }

    function showPreviousItem() {
      videoList[count].classList.remove("active");
      progressBar.parentElement.style.display = "none"; //기본 bar 하이드

      if (count > 0) {
        count--;
      } else {
        count = itemCount - 1;
      }
      videoList[count].classList.add("active");

      videoNow = videoList[count]; //변수재설정
      progressBar = progressList[count]; //변수재설정
      progressBar.parentElement.style.display = "flex";
      videoNow.addEventListener("timeupdate", handleProgress);
    }

    nextItem.addEventListener("click", showNextItem);
    previousItem.addEventListener("click", showPreviousItem);
    videoNow.addEventListener("timeupdate", handleProgress);
  },

  methods: {
    pauseVideo() {
      const video = document.getElementsByClassName("active")[0];
      let btnPlay = document.querySelector(".slider-ctrl-btn__play");
      let btnPause = document.querySelector(".slider-ctrl-btn__pause");
      btnPlay.classList.add("on");
      btnPause.classList.remove("on");
      video.pause();
    },
    playVideo() {
      const video = document.getElementsByClassName("active")[0];
      let btnPlay = document.querySelector(".slider-ctrl-btn__play");
      let btnPause = document.querySelector(".slider-ctrl-btn__pause");
      video.play();
      btnPlay.classList.remove("on");
      btnPause.classList.add("on");
    },
    scrollDown() {
      let brandContent = document.querySelector(".count-year");
      let scrollContent = window.pageYOffset + brandContent.getBoundingClientRect().top - 64.5;
      window.scrollTo({
        top: scrollContent,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";
.container {
  background-color: black;
  position: relative;
  width: 100vw;
  height: 177.77vw;
  overflow: hidden;
  .slider {
    width: 100%;
    height: 100%;
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $color-black;
      opacity: 0.05;
      z-index: 2;
    }
    .slide {
      width: 100%;
      height: 100%;
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: none;
        &.active {
          display: block;
          animation: fadeImg 0.8s;
        }
      }
      video.active + .slide-desc {
        display: block;
      }
      .slide-desc {
        width: 85vw;
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99;
        text-align: center;
        color: $color-white;
        h2 {
          font-size: 40px;
          letter-spacing: -1.2px;
          font-weight: bold;
          margin-bottom: 10px;
          img {
            height: 40px;
          }
        }
        p {
          font-size: 17px;
          opacity: 0.8;
          letter-spacing: -0.51px;
          font-weight: 300;
          line-height: normal;
        }
      }
    }
    .arrow-wrap {
      @include box-center;
      width: 100vw;
      z-index: 999;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .arrow-left,
      .arrow-right {
        width: 15vw;
        height: 15vw;
        img {
          height: 7.4vw;
        }
      }
    }
    .scroll-arrow {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: absolute;
      bottom: 50px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 9;
      border: 1px solid $color-white;
      img {
        @include box-center;
        width: 22px;
        height: 22px;
      }
    }
  }
}

.video-control {
  display: none;
}

//화면전환 시 효과
@keyframes fadeImg {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
