<template>
  <div class="ly-w1400">
    <sub-title :title="title" :content="content"> </sub-title>
    <section class="greeting-cont">
      <div class="greeting-cont-inner ly-cont">
        <div class="greeting-ttl">
          <h2 class="scroll-fade-in-f">
            차별화된 경쟁력으로 <br />
            국내 최대 안경체인 브랜드로 성장
          </h2>
          <p class="scroll-fade-in-f scroll-delay-1">
            다비치안경은 고객이 전국 어느 매장에서나 동일한 서비스를 제공받을 수 있도록
            <br />다비치안경체인의 전략 정책을 담은 서비스와 절차를 모두 표준화 및 시스템화 했으며,<br />
            제조사 직거래 유통 방식으로 좋은 품질의 제품을 정액정찰제로 제공했습니다.<br />
            이로서 280여개의 대형 체인망을 갖추며,
            <br />국민에게 신뢰받는 국내 최대 안경체인 브랜드로 자리매김했습니다.
          </p>
        </div>
        <!--greeting title-->
        <div class="greeting-txt">
          <h3 class="scroll-fade-in-f">글로벌 기업으로 도약</h3>
          <p class="scroll-fade-in-f scroll-delay-1">
            다비치안경은 더 큰 도약을 이루고자 합니다.<br />
            중국 시장을 비롯한 아시아 및 전 세계의 1,000여개의 다비치 체인망을 구축해<br />
            전 세계에게 ‘Light Up Your Life’ 비전으로 건강한 시야를 제공하고<br />
            명실상부한 글로벌 그룹으로 성장하겠습니다.
          </p>
          <h3 class="scroll-fade-in-f">맑은 세상, 인류를 행복하게</h3>
          <p class="greet-inAddition scroll-fade-in-f scroll-delay-1">
            또한 다비치안경은 온 세상을 맑고 밝게 다 비추는 마음으로<br />
            소외되고 어려운 생활을 하는 이웃에게 사회적 책임을 다하려고 합니다.<br />
            판매 수익의 일부를 기부하고 봉사활동을 통해 시력 검사 및 교정용 안경을 제공하는 등<br />
            다양한 방법으로 지원하여 더 나은 사회를 만들기 위해 기여하겠습니다.
          </p>
          <p class="greet-aspiration scroll-fade-in-f scroll-delay-2">
            앞으로 다비치안경은 다비치의 브랜드 가치를 제고하기 위해 노력해 나가겠습니다.<br />
            신성장동력을 발굴하고 지속 가능한 성장을 위해 시대에 부응하는 혁신에 앞장서며<br />
            모든 기업 활동에서 다비치다운 길을 걸으며 고객과 함께하겠습니다.
          </p>
          <p class="greet-thanks scroll-fade-in-f scroll-delay-2">
            여러분들의 많은 관심과 사랑을 부탁드립니다. 감사합니다.
          </p>
          <strong class="scroll-fade-in-f scroll-delay-3">대표 김인규</strong>
        </div>
        <!--greeting text-->
      </div>
      <div class="greetings-img">
        <img
          class="greetings-img-01 scroll-fade-in-f"
          src="https://img.davich.com/image/sub/about/img-greetings-01.png"
          alt=""
        />
        <img
          class="greetings-img-02 scroll-fade-in-f scroll-delay-1"
          src="https://img.davich.com/image/sub/about/img-greetings-02.png"
          alt=""
        />
        <img
          class="greetings-img-03 scroll-fade-in-f scroll-delay-2"
          src="https://img.davich.com/image/sub/about/img-greetings-03.png"
          alt=""
        />
      </div>
      <!--greeting image-->
    </section>
  </div>
</template>

<script>
import SubTitle from "../../components/SubTitle1.vue";
export default {
  components: {
    SubTitle,
  },
  data() {
    return {
      title: "대표 인사말",
      content: "전문성과 신뢰를 바탕으로 한 최고의 안경전문기업, 다비치입니다.",
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.sub-title {
  background-image: url("https://img.davich.com/image/sub/subtitle/sub-about-bg-01.png");
}

.greeting-cont {
  display: flex;
  width: 1400px;
  margin: 0 auto;
  padding: 130px 0;
  .greeting-cont-inner {
    line-height: normal;
    margin-right: 100px;
    //title
    .greeting-ttl {
      margin-bottom: 100px;
      width: 700px;
      h2 {
        font-weight: 500;
        font-size: 45px;
        letter-spacing: -1.5px;
        margin-bottom: 50px;
      }
      p {
        @include font-main;
      }
    }
    //desc
    .greeting-txt {
      h3 {
        position: relative;
        font-size: 30px;
        letter-spacing: -0.9px;
        margin-bottom: 40px;
        padding-left: 80px;
        font-weight: 500;
        &:before {
          content: "";
          position: absolute;
          width: 50px;
          height: 1px;
          top: 22px;
          left: 0;
          background-color: $color-black;
        }
      }
      p {
        margin-bottom: 80px;
        @include font-main;
        &.greet-inAddition,
        &.greet-aspiration,
        &.greet-thanks {
          margin-bottom: 30px;
        }
      }
      strong {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
  .greetings-img {
    .greetings-img-01,
    .greetings-img-02,
    .greetings-img-03 {
      width: 600px;
      margin-top: 20px;
    }
    .greetings-img-01 {
      margin-top: 0;
    }
  }
}
</style>
