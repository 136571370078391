<template>
  <div>
    <sub-title :title="title" :content="content" :content2="content2">
    </sub-title>
    <section class="davich-event">
      <h2 class="hidden">다비치 이벤트</h2>
      <!-- <tab-menu-2 :tabTitle="tabTitle"></tab-menu-2> -->
      <div class="event-items ly-cont-m">
        <div
          class="event-item scroll-fade-in-f"
          v-for="item in listToShow"
          :key="item.id"
        >
          <a :href="item.url" target="_blank" class="event-img-wrap">
            <img :src="item.imgSrc" alt="" />
            <!-- <em class="badge"> D-<span>71</span> </em> -->
          </a>
          <a :href="item.url" target="_blank">
            <p>{{ item.start }} ~ {{ item.end }}</p>
            <h3>{{ item.title }}</h3>
          </a>
        </div>
      </div>

      <b-pagination
        id="pagination"
        :total-rows="rows"
        v-model="currentPage"
        :per-page="perPage"
      ></b-pagination>

      <div class="m-event-btn ly-cont-m">
        <button class="el-btn-m" id="btnMoreData" @click="getMoreData">
          더보기
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import SubTitle from "../../../components/mobile/MSubTitle1.vue";
// import TabMenu2 from "../../../components/mobile/MTabMenu2.vue";
import { mapGetters } from "vuex";

export default {
  beforeCreate() {
    this.$store.dispatch("whatsnew/FETCH_EVENT");
  },

  components: {
    SubTitle,
    // TabMenu2,
  },
  data() {
    return {
      title: "다비치 이벤트",
      content: "다비치는 지금",
      content2: " 다양한 이벤트를 한눈에 확인하세요.",
      // tabTitle: ["전체", "혜택", "쿠폰", "세일", "사전예약", "얼리버드"],

      currentPage: 1,
      perPage: 4,

      lengthListToShow: 0,
    };
  },

  computed: {
    ...mapGetters("whatsnew", ["getEventData"]),

    listToShow() {
      const self = this;
      let result;

      self.lengthListToShow = self.getEventData.length;

      result = self.getEventData.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
      return result;
    },

    rows() {
      const self = this;
      return self.lengthListToShow;
    },
  },

  watch: {
    rows() {
      if (this.rows <= 4) {
        const btn = document.getElementById("btnMoreData");
        btn.style.display = "none";
      }
    },
  },

  methods: {
    getMoreData() {
      const btn = document.getElementById("btnMoreData");
      this.perPage += 4;

      //마지막 데이터에서 더보기버튼 숨김
      if (this.perPage >= this.rows) {
        btn.style.display = "none";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.sub-title {
  background-image: url("https://img.davich.com/mobile/image/sub/subtitle/sub-what-bg-02.png");
  background-size: cover;
}

//콘텐츠 목록
.davich-event {
  margin-top: 50px;
  margin-bottom: 100px;
  .event-items {
    justify-content: space-between;
    .event-item {
      width: 100%;
      margin-bottom: 10px;
      margin: 0px auto 10px auto;
      .event-img-wrap {
        position: relative;
        width: 100%;
        overflow: hidden;
        img {
          width: 100%;
          // height: 100%;
          height: 270px;
          object-fit: cover;
          display: block;
          margin: 30px auto 0 auto;
        }
        .badge {
          background-color: #0056aa;
          display: block;
          width: 50px;
          height: 35px;
          color: $color-white;
          text-align: center;
          line-height: 35px;
          font-size: 14px;
          font-weight: 400;
          position: absolute;
          top: 40px;
          left: 10px;
        }
      }
      a {
        p {
          margin-top: 15px;
          font-size: 15px;
          color: #aaa;
          text-align: center;
        }
        h3 {
          width: 85vw;
          margin-top: 5px;
          font-size: 17px;
          font-weight: bold;
          line-height: 25px;
          text-align: center;
        }
      }
    }
  }
  .m-event-btn {
    margin-top: 100px;
  }
}
#pagination {
  display: none;
}
</style>
