<template>
  <div class="partner-place">
    <div class="place-btn">
      <button class="location active" @click="selectLocation('', 0)">
        전체
      </button>
      <button class="location" @click="selectLocation('서울지역', 1)">
        서울지역
      </button>
      <button class="location" @click="selectLocation('영남지역', 2)">
        영남지역
      </button>
      <button class="location" @click="selectLocation('경기지역', 3)">
        경기지역
      </button>
      <button class="location" @click="selectLocation('그외지역', 4)">
        그외지역
      </button>
    </div>

    <div
      class="place-list ly-cont-m"
      v-for="(area, i) in filterLocation"
      :key="i"
    >
      <h3 class="place-name scroll-fade-in-f">{{ area.class }}</h3>
      <ul class="place-city-wrap scroll-fade-in-f scroll-delay-1">
        <li class="place-city" li v-for="(area, i) in area.area" :key="i">
          <ul class="place-country">
            <li class="place-region">
              <span> {{ area.middleArea }}</span>
            </li>
            <li class="place-district">
              <ul>
                <li v-for="(smallArea, i) in area.smallArea" :key="i">
                  {{ smallArea.name }} <span>/</span>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <div class="btn-wrap ly-cont-m">
      <router-link to="/partnership/m_inquiryFranchise" class="el-btn-m"
        >가맹점 개설문의하기​</router-link
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  beforeCreate() {
    this.$store.dispatch("partnership/FETCH_OPEN_AREA");
  },
  data() {
    return {
      selectedClass: "",
    };
  },
  methods: {
    selectLocation(area, index) {
      //var activeItem;
      this.selectedClass = area;
      const btnArr = document.getElementsByClassName("location");

      // if (activeItem.classList.contains("active")) {
      //   activeItem.classList.remove("active");
      // }

      for (var i = 0; i < btnArr.length; i++) {
        btnArr[i].classList.remove("active");
      }

      btnArr[index].classList.add("active");

      //activeItem == btnArr[index];
    },
  },
  computed: {
    ...mapGetters("partnership", ["getOpenArea"]),

    filterLocation() {
      let selectedClass = this.selectedClass;

      return this.getOpenArea.filter((item) => {
        let filtered = true;
        if (selectedClass && selectedClass.length > 0) {
          filtered = item.class == selectedClass;
        }
        return filtered;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.partner-place {
  width: 100%;
  overflow: hidden;
  .place-btn {
    border-bottom: 1px solid #dedede;
    display: flex;
    height: 60px;
    flex-wrap: nowrap;
    overflow-x: auto;
    // scroll bar hidden
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    button {
      display: inline-block;
      height: 100%;
      flex: 0 0 auto;
      width: auto;
      @include font-tab-20;
      margin-right: 30px;
      text-align: center;
      position: relative;
      &:first-child {
        margin-left: 7.5vw;
      }
      &.active {
        color: $color-blue;
        border-bottom: 2px solid $color-blue;
      }
    }
  }
  .place-list {
    padding-top: 65px;
    h3 {
      @include font-h-22;
      margin-bottom: 30px;
    }
    .place-city-wrap {
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid $color-black;
      border-bottom: 1px solid $color-black;
      .place-city {
        @include font-main-m;

        .place-country {
          display: flex;
          border-bottom: 1px solid #dedede;
          width: 85vw;
          .place-region {
            width: 100px;
            background-color: #f9f9f9;
            font-weight: 500;
            border-right: 1px solid #dedede;
            text-align: center;
            @include box-center-flex;
          }

          .place-district {
            padding: 20px;
            flex: 1;
            width: calc(100% - 100px);
            li {
              display: inline;
              position: relative;
              &:last-child {
                span {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
  .btn-wrap {
    display: flex;
    justify-content: center;
    margin-top: 100px;
  }
}
</style>
